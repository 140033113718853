import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    audio:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E1/Audio/G6 SB Track 2.02.WAV",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E1/Key/answerKey.png",
    component: DesignUnderLine,
    titleQuestion: [{ title: `POP IT OUT, THEN USE STICKERS` }],
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E1/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E1/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E1/5.jpg",
        },
      ],
    ],
    questions: [],
  },

  2: {
    // Exercise num
    audio: "",
    video: "",
    component: T6,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E2/Key/answerKey.png",
    titleImage: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/E2/1.png",
    // titleQuestion: [{ num: '2', title: "Read the study strategy. Then copy and complete the word web with words in exercise 1. Think of more words to add to the web." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>vegetables: # </p>" +
          "<p>fruit: # </p>" +
          "<p>snack: # </p>" +
          "<p>drinks: # </p>" +
          "<p>meat: # </p>",

        answer: [
          "chips, salad, beans",
          "apple",
          "nuts, crisps, sweets",
          "water, fizzy drinks, juice",
          "chicken",
        ],
        type: "longAnwser",
      },
    ],
  },
};

export default json;
