import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P8-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/5.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/7.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/8.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/9.jpg" },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8pencil.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/12.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/13.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E1/14.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P8-E2",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/5.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/7.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/8.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/9.jpg" },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8pencil.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/12.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/13.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E2/14.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P8-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/5.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/7.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/8.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/9.jpg" },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8pencil.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/12.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/13.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/E3/14.jpg",
        },
      ],
    ],
  },
};

export default json;
