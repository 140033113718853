export const dataBook = {
  1: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page1/1.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  2: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page2/1.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  3: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page3/1.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  4: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/1.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  5: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page5/5.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  6: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page6/6.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  7: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page7/7.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  8: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/3.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/4.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/5.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/6.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/7.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/8.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/9.jpg",
        excerciseNum: 3,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/10.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page8/11.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  9: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/4.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/5.jpg",
        excerciseNum: 3,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/6.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/7.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  10: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page10/10.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  11: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page11/11.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  12: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/4.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/5.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/6.jpg",
        excerciseNum: 3,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/7.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page12/8.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  13: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page13/1.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  14: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page14/14.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  15: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page15/15.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  16: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/16.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  17: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/4.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/5.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/6.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/7.jpg",
        excerciseNum: 3,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/8.jpg",
        excerciseNum: 4,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/9.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/10.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  18: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page18/18.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  19: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page19/19.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  20: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page20/20.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  21: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page21/21.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  22: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page22/22.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  23: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page23/23.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  24: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page24/24.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  25: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page25/25.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  26: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page26/26.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  27: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page27/27.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  28: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page28/28.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  29: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page29/29.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  30: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page30/30.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  31: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page31/31.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  32: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page32/32.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  33: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page33/33.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  34: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page34/34.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  35: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page35/35.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  36: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page36/36.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  37: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page37/37.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  38: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page38/38.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  39: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page39/39.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  40: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page40/40.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  41: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/41.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  42: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/42.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  43: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page43/43.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  44: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page44/44.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  45: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page45/45.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  46: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page46/46.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  47: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page47/47.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  48: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page48/48.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  49: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page49/49.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  50: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page50/50.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  51: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page51/51.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  52: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/52.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  53: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page53/53.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  54: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/54.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  55: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page55/55.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  56: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/3.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/4.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/5.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/6.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/7.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/8.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/9.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  57: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/4.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/5.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/6.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/7.jpg",
        excerciseNum: 3,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/8.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page57/9.jpg",
        excerciseNum: 3,
      },
    ],
  ],
  58: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page58/58.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  59: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page59/59.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  60: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page60/60.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  61: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page61/61.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  62: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page62/62.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  63: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page63/63.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  64: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page64/64.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  65: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page65/65.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  66: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page66/66.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  67: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page67/67.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  68: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page68/68.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  69: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page69/69.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  70: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page70/70.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  71: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page71/71.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  72: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page72/72.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  73: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page73/73.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  74: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page74/74.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  75: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page75/75.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  76: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page76/76.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  77: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page77/77.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  78: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page78/78.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  79: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page79/79.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  80: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/80.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  81: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page81/81.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  82: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page82/82.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  83: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page83/83.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  84: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/4.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/5.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/6.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/7.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/8.jpg",
        excerciseNum: 1,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/9.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/10.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/11.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/12.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/13.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/14.jpg",
        excerciseNum: 3,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/15.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/16.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  85: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/4.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/5.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/6.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/7.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page85/8.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  86: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page86/86.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  87: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page87/87.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  88: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page88/88.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  89: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/1.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/3.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/4.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/5.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/6.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/7.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/8.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/9.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/10.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/11.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/12.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/13.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/14.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/15.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/16.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/17.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/18.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/19.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/20.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/21.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/22.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/23.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/24.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/25.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/26.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/27.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/28.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/29.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/30.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/31.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/32.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/33.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/34.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page89/35.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  90: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/4.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/5.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/6.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/7.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/8.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/9.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/10.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/11.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/12.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/13.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/14.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/15.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page90/16.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  91: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/4.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/5.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/6.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/7.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/8.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/9.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/10.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/11.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/12.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/13.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/14.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/15.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/16.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page91/17.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  92: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/4.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/5.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/6.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/7.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/8.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/9.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/10.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/11.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/12.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/13.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/14.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/15.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/16.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page92/17.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  93: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page93/93.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  94: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page94/94.jpg",
        excerciseNum: 1,
      },
    ],
  ],
  95: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/4.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/5.jpg",
        excerciseNum: 0,
      },
    ],

    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/6.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  96: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/1.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/2.jpg",
        excerciseNum: 0,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/3.jpg",
        excerciseNum: 1,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/4.jpg",
        excerciseNum: 2,
      },
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/5.jpg",
        excerciseNum: 0,
      },
    ],
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/6.jpg",
        excerciseNum: 0,
      },
    ],
  ],
  97: [
    [
      {
        imgUrl: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page97/1.jpg",
        excerciseNum: 0,
      },
    ],
  ],
};
