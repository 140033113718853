// import pathSVG from 'https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page84/E2/jconFile'
import PaintColorType from "../../components/ExcerciseTypes/Design/PaintColorType";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P25-E1",
    audio: "",
    video: "",
    component: PaintColorType,
    titleQuestion: [
      {
        title: `1. COLOR YOUR FAVORITE TOY`,
      },
    ],
    recorder: true,
    isAllowSubmit: true,
    totalInput: 5,
    isHiddenCheck: true,

    questionSVG: [
      [
        {
          version: "1.0",

          viewBox: "0 0 2441 1397.000000",
          style: {
            width: 900,
            height: 900,
            marginTop: 9,
            stroke: "black",
            strokeWidth: 0.1,
            fill: "transparent",
          },
          g: `
          <g transform="translate(0.000000,1397.000000) scale(0.100000,-0.100000)"
          fill="#fff" stroke="none">
          <path id="80" fill="transparent" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M0 6985 l0 -6985 10985 0 10985 0 0 6985 0 6985 -10985 0 -10985 0 0
          -6985z m7580 5587 c0 -5 93 -12 208 -15 251 -8 705 -38 712 -47 3 -4 70 -12
          150 -19 279 -23 455 -42 475 -51 11 -4 112 -18 225 -31 113 -12 207 -25 210
          -28 3 -4 95 -18 205 -31 110 -13 202 -26 205 -30 3 -4 102 -20 220 -36 118
          -15 217 -31 220 -35 3 -3 80 -17 172 -30 92 -13 165 -26 163 -29 -2 -3 67 -16
          153 -29 86 -13 159 -27 162 -30 3 -4 86 -20 185 -36 99 -15 182 -31 185 -35 3
          -3 68 -17 145 -30 77 -13 142 -26 145 -30 3 -4 75 -19 160 -34 85 -15 157 -31
          160 -35 3 -4 59 -18 125 -30 66 -13 122 -27 125 -30 3 -4 58 -18 122 -31 64
          -13 115 -27 112 -32 -3 -4 4 -8 15 -8 29 0 198 -39 261 -60 30 -10 91 -26 135
          -36 44 -10 82 -20 85 -24 6 -8 153 -50 173 -50 9 0 17 -3 19 -8 2 -4 46 -20
          98 -36 52 -15 97 -31 100 -35 3 -3 39 -17 80 -30 41 -13 77 -27 80 -31 3 -4
          43 -20 90 -35 46 -16 90 -34 97 -41 7 -6 37 -20 68 -29 30 -10 55 -21 55 -26
          0 -4 10 -11 23 -14 44 -13 107 -40 107 -47 0 -3 33 -19 73 -34 39 -16 77 -33
          82 -39 6 -6 30 -20 55 -30 25 -11 47 -22 50 -25 8 -10 95 -50 108 -50 7 0 12
          -5 12 -10 0 -6 26 -22 57 -36 32 -13 60 -29 63 -33 3 -5 25 -19 50 -31 25 -12
          47 -25 50 -29 3 -3 25 -17 50 -30 25 -14 47 -27 50 -31 3 -4 29 -20 59 -36 30
          -16 65 -39 79 -51 14 -13 31 -23 38 -23 7 0 17 -6 21 -13 4 -7 30 -26 58 -42
          27 -16 61 -39 75 -52 14 -13 28 -23 31 -23 3 0 20 -12 37 -27 36 -33 108 -95
          138 -120 83 -69 145 -127 327 -309 114 -113 207 -208 207 -211 0 -2 6 -10 13
          -17 16 -17 126 -141 166 -186 17 -19 31 -38 31 -41 0 -3 16 -23 35 -45 19 -21
          43 -48 53 -59 10 -11 26 -37 37 -57 11 -21 23 -38 27 -38 4 0 20 -21 35 -47
          15 -27 31 -52 36 -58 4 -5 13 -18 20 -27 8 -13 37 -21 95 -30 45 -6 82 -14 82
          -18 0 -4 17 -11 38 -15 76 -13 207 -48 212 -55 3 -4 38 -18 78 -30 39 -12 72
          -25 72 -29 0 -4 18 -13 41 -20 22 -7 83 -32 134 -57 51 -24 96 -44 99 -44 4 0
          16 -9 27 -19 12 -11 50 -25 87 -31 37 -7 69 -16 72 -19 3 -3 70 -19 150 -35
          80 -16 147 -32 150 -35 3 -4 52 -18 110 -31 58 -12 107 -26 110 -30 3 -4 46
          -18 95 -30 50 -13 92 -26 95 -30 3 -3 52 -19 110 -34 58 -16 107 -31 110 -35
          3 -4 39 -18 80 -30 41 -13 77 -27 80 -30 3 -3 39 -17 80 -30 41 -13 77 -27 80
          -30 3 -4 44 -20 93 -35 48 -16 87 -31 87 -35 0 -4 32 -17 70 -30 39 -12 70
          -26 70 -32 0 -5 6 -9 12 -9 17 0 152 -52 158 -61 3 -4 30 -17 60 -29 30 -12
          62 -28 71 -36 8 -8 21 -14 27 -14 13 0 85 -32 92 -41 3 -4 34 -19 70 -34 36
          -15 67 -30 70 -34 3 -4 32 -19 65 -34 33 -14 56 -27 50 -27 -5 0 18 -15 52
          -33 153 -78 179 -93 195 -110 10 -9 24 -17 31 -17 7 0 23 -9 34 -19 11 -11 44
          -32 72 -46 28 -15 51 -31 51 -35 0 -4 15 -15 33 -23 18 -9 44 -25 57 -37 45
          -38 129 -100 135 -100 4 0 33 -27 65 -60 32 -33 65 -60 72 -60 8 0 23 -8 34
          -17 10 -10 37 -30 59 -45 22 -15 49 -41 60 -58 11 -16 25 -30 30 -30 6 0 16
          -12 22 -27 7 -16 26 -44 43 -63 16 -19 30 -40 30 -46 0 -5 8 -19 18 -30 10
          -10 31 -49 46 -86 16 -38 32 -68 35 -68 7 0 36 -92 47 -147 4 -18 10 -33 14
          -33 10 0 30 -151 30 -223 0 -64 20 -199 30 -207 4 -3 19 -93 35 -200 16 -107
          32 -197 35 -200 4 -3 17 -86 30 -185 12 -99 26 -182 30 -185 4 -3 17 -88 30
          -190 13 -102 26 -187 30 -190 4 -3 19 -97 35 -210 15 -113 31 -207 35 -210 8
          -6 50 -307 50 -357 0 -18 5 -33 10 -33 6 0 10 -65 10 -175 0 -110 -4 -175 -10
          -175 -5 0 -10 -10 -10 -22 0 -44 -32 -199 -44 -211 -7 -7 -23 -40 -36 -75 -12
          -35 -28 -69 -34 -76 -15 -16 -56 -74 -56 -79 0 -8 -135 -141 -155 -154 -11 -7
          -30 -17 -42 -22 -13 -6 -23 -15 -23 -20 0 -5 -13 -14 -29 -21 -16 -6 -44 -22
          -61 -35 -17 -13 -57 -34 -88 -46 -31 -13 -63 -29 -69 -35 -9 -8 -13 -46 -14
          -111 0 -80 -33 -323 -38 -288 -1 6 -13 -33 -27 -85 -14 -52 -29 -97 -33 -100
          -4 -3 -18 -38 -30 -77 -13 -40 -26 -73 -30 -73 -3 0 -17 -27 -30 -60 -13 -33
          -27 -60 -31 -60 -3 0 -16 -24 -29 -54 -12 -30 -32 -66 -44 -81 -58 -72 -159
          -164 -203 -185 -27 -13 -52 -29 -56 -35 -4 -7 -28 -20 -53 -29 -25 -10 -45
          -21 -45 -25 0 -5 -25 -16 -55 -26 -31 -9 -61 -22 -68 -29 -7 -7 -54 -25 -104
          -41 -51 -16 -93 -32 -93 -35 0 -4 -43 -17 -95 -30 -52 -13 -95 -26 -95 -30 0
          -3 -55 -17 -122 -31 -68 -13 -125 -27 -128 -30 -3 -4 -60 -13 -128 -20 -100
          -10 -140 -10 -215 0 -51 7 -94 16 -97 20 -3 4 -44 18 -92 31 -49 12 -88 27
          -88 31 0 5 -6 9 -14 9 -19 0 -116 41 -116 49 0 4 -23 15 -51 26 -28 11 -58 27
          -67 35 -9 9 -35 26 -57 40 -43 26 -65 43 -125 103 -21 20 -43 37 -49 37 -6 0
          -11 6 -11 13 0 8 -9 22 -20 32 -19 17 -22 17 -74 1 -30 -10 -56 -21 -58 -24
          -2 -4 -45 -20 -95 -36 -51 -17 -93 -33 -93 -38 0 -4 -5 -8 -12 -8 -19 0 -152
          -43 -158 -51 -3 -4 -36 -16 -74 -28 -38 -12 -74 -25 -80 -30 -6 -4 -54 -21
          -106 -37 -52 -15 -97 -31 -100 -35 -3 -4 -42 -17 -87 -29 -45 -13 -81 -26 -78
          -29 2 -4 -35 -18 -83 -31 -48 -13 -93 -30 -100 -37 -7 -7 -20 -13 -29 -13 -22
          0 -157 -41 -163 -49 -3 -4 -43 -18 -90 -31 -47 -13 -87 -27 -90 -31 -3 -3 -50
          -19 -105 -34 -55 -15 -102 -30 -105 -34 -3 -4 -44 -18 -93 -31 -48 -12 -85
          -25 -82 -28 3 -3 -36 -17 -87 -30 -51 -13 -95 -27 -98 -32 -3 -4 -16 -10 -30
          -11 -22 -4 -26 -11 -37 -78 -7 -41 -16 -77 -20 -80 -5 -3 -19 -41 -33 -83 -13
          -43 -27 -78 -31 -78 -3 0 -19 -25 -34 -55 -16 -30 -32 -55 -37 -55 -4 0 -8 -6
          -8 -13 0 -8 -9 -22 -20 -32 -11 -10 -20 -22 -20 -28 0 -5 -11 -18 -25 -29 -14
          -11 -25 -23 -25 -27 0 -12 -87 -101 -99 -101 -6 0 -16 -9 -23 -20 -7 -11 -31
          -33 -52 -48 -22 -15 -51 -37 -64 -50 -14 -12 -31 -22 -38 -22 -7 0 -21 -8 -31
          -18 -10 -10 -43 -29 -73 -41 -30 -13 -57 -27 -60 -31 -3 -5 -41 -19 -85 -32
          -44 -12 -82 -26 -86 -30 -3 -4 -50 -18 -105 -30 -54 -12 -101 -25 -104 -29 -3
          -3 -79 -19 -170 -35 -91 -15 -167 -31 -170 -34 -5 -7 -299 -50 -337 -50 -12 0
          -23 -4 -25 -9 -3 -9 -355 -51 -426 -51 -22 0 -42 -4 -44 -9 -4 -12 -293 -31
          -477 -31 -142 0 -279 14 -291 29 -3 3 -38 17 -77 29 -40 13 -73 26 -73 30 0 4
          -25 19 -55 32 -30 13 -55 28 -55 32 0 4 -13 14 -29 22 -15 8 -52 38 -81 65
          -29 27 -59 52 -66 55 -8 3 -19 16 -25 28 -6 13 -28 37 -49 53 -22 17 -39 33
          -40 37 0 12 -54 88 -62 88 -4 0 -17 20 -29 45 -12 25 -24 45 -28 45 -4 0 -18
          21 -31 48 -13 26 -31 55 -40 64 -9 10 -24 40 -34 68 -10 27 -22 50 -26 50 -5
          0 -19 29 -32 65 -12 36 -26 65 -30 65 -3 0 -16 30 -29 68 -12 37 -25 69 -29
          72 -4 3 -20 46 -35 95 -16 50 -31 92 -34 95 -4 3 -17 49 -30 102 -13 54 -27
          98 -31 98 -4 0 -17 58 -30 130 -13 71 -27 130 -31 130 -4 0 -11 29 -14 65 -4
          36 -11 68 -16 71 -5 3 -9 -1 -9 -9 0 -7 -8 -22 -18 -33 -10 -10 -28 -40 -41
          -67 -12 -26 -26 -47 -29 -47 -4 0 -29 -37 -57 -82 -27 -45 -61 -92 -75 -106
          -24 -24 -28 -24 -135 -19 -98 5 -199 27 -215 47 -3 3 -39 17 -80 30 -41 14
          -77 28 -80 31 -3 3 -34 16 -70 29 -36 13 -67 27 -70 31 -3 4 -39 19 -79 33
          -41 14 -77 30 -80 34 -4 5 -35 19 -71 31 -36 13 -67 27 -70 31 -3 4 -34 18
          -70 31 -36 13 -70 28 -76 34 -6 6 -43 21 -82 34 -39 13 -69 28 -66 33 3 4 -1
          8 -9 8 -17 0 -120 41 -127 51 -3 3 -34 17 -70 30 -36 13 -66 26 -68 30 -2 4
          -36 20 -75 35 -40 15 -74 30 -77 34 -3 4 -33 18 -67 30 -35 12 -63 26 -63 30
          0 4 -36 20 -80 36 -44 15 -80 31 -80 35 0 4 -29 17 -65 30 -36 12 -65 26 -65
          30 0 4 -31 17 -70 30 -38 12 -70 26 -70 30 0 4 -34 19 -75 34 -41 15 -75 31
          -75 34 0 4 -29 18 -65 30 -36 13 -65 26 -65 30 0 4 -24 15 -52 25 -29 11 -60
          25 -68 31 -8 7 -50 25 -92 40 -43 15 -78 31 -78 35 0 5 -25 16 -56 26 -31 9
          -61 23 -67 29 -12 11 -144 65 -160 65 -5 0 -14 6 -21 13 -7 7 -41 22 -75 32
          -33 11 -61 23 -61 27 0 3 -30 16 -67 29 -38 12 -70 25 -73 28 -7 8 -52 25
          -122 47 -32 9 -58 21 -58 25 0 5 -31 18 -70 30 -38 11 -70 24 -70 28 0 4 -36
          17 -80 30 -44 13 -80 27 -80 32 0 4 -40 19 -90 35 -49 15 -90 30 -90 34 0 4
          -36 17 -80 30 -44 13 -80 27 -80 31 0 3 -39 19 -87 34 -49 16 -90 31 -93 35
          -3 3 -38 17 -77 29 -40 13 -73 27 -73 32 0 5 -6 9 -12 9 -19 0 -152 43 -158
          52 -3 4 -46 20 -95 35 -50 15 -92 31 -95 35 -3 4 -34 16 -68 26 l-62 20 -80
          -77 c-44 -42 -93 -87 -110 -101 -17 -14 -43 -36 -58 -50 -15 -14 -47 -36 -72
          -49 -25 -13 -48 -28 -51 -33 -3 -4 -32 -18 -65 -29 -52 -19 -73 -20 -184 -15
          -267 14 -615 39 -620 45 -3 4 -63 13 -135 19 -200 19 -389 44 -395 52 -3 4
          -27 10 -55 14 -78 10 -178 37 -193 52 -7 8 -22 14 -32 14 -10 0 -21 4 -25 9
          -3 5 -19 12 -35 16 -17 4 -30 10 -30 15 0 4 -22 20 -49 34 -28 14 -60 35 -73
          47 -62 56 -178 170 -178 175 0 6 -40 63 -58 82 -7 8 -20 28 -29 45 -9 18 -19
          34 -23 37 -4 3 -18 28 -30 55 -12 28 -25 52 -29 55 -4 3 -20 39 -36 80 -16 41
          -32 77 -35 80 -3 3 -16 39 -28 80 -13 41 -27 84 -32 95 -19 46 -30 83 -41 142
          -6 35 -16 63 -20 63 -5 0 -9 10 -9 23 0 13 -3 26 -6 30 -6 6 -48 -17 -72 -40
          -21 -20 -93 -37 -162 -37 -65 0 -170 27 -170 45 0 5 -5 9 -11 9 -14 0 -103 41
          -109 50 -3 4 -43 10 -90 13 -47 3 -98 11 -115 19 -42 19 -125 82 -155 118 -14
          16 -36 42 -49 57 -12 16 -30 42 -39 58 -8 17 -21 35 -27 40 -7 6 -21 32 -31
          59 -9 26 -22 53 -28 60 -6 6 -22 43 -35 81 -13 39 -27 72 -31 75 -5 3 -19 46
          -32 95 -13 50 -26 93 -31 96 -4 3 -19 66 -34 140 -15 74 -30 136 -34 139 -3 3
          -17 75 -30 160 -12 85 -26 157 -30 160 -10 8 -39 297 -46 458 -3 78 -10 142
          -15 142 -4 0 -8 36 -8 80 0 47 4 80 10 80 6 0 10 28 10 63 0 55 3 66 25 85 28
          25 46 27 62 11 9 -9 12 63 13 287 0 185 4 300 10 302 6 2 10 40 10 85 0 152
          45 667 59 677 4 3 18 82 31 175 12 94 26 172 30 175 4 3 17 75 30 160 13 85
          26 157 30 160 4 3 19 83 35 178 15 94 32 172 36 172 5 0 9 10 10 23 0 12 8 53
          18 91 17 67 17 69 -2 122 -11 30 -23 54 -27 54 -4 0 -20 46 -36 103 -15 56
          -32 105 -37 108 -5 4 -10 49 -10 100 -1 88 1 97 29 144 16 28 40 54 52 60 12
          5 22 13 22 18 0 4 21 17 47 28 26 11 50 24 53 28 7 9 109 51 126 51 7 0 19 6
          26 13 7 8 44 24 81 36 37 13 67 26 67 29 0 4 21 15 46 26 24 10 51 24 58 32
          17 16 75 52 99 60 9 4 17 10 17 15 0 4 19 19 43 33 23 14 38 26 32 26 -5 0 7
          11 28 24 21 13 45 30 55 39 9 8 31 28 48 44 17 15 47 37 68 48 21 11 42 28 47
          37 5 10 22 24 37 32 15 8 39 25 53 37 15 13 44 38 65 54 21 17 48 39 59 50 28
          26 82 65 91 65 3 0 19 12 34 28 16 15 45 41 64 57 20 17 46 39 59 51 12 11 38
          31 57 44 19 13 40 29 46 35 12 11 77 65 144 119 91 74 124 102 159 134 20 17
          43 34 53 37 9 3 23 17 32 30 9 14 23 25 31 25 7 0 21 10 29 21 8 12 32 32 53
          45 21 13 34 24 29 24 -6 0 10 13 35 29 25 15 57 39 71 52 59 55 85 76 111 93
          28 17 43 29 90 71 14 12 42 33 64 46 21 12 42 29 46 36 4 7 22 20 40 29 18 9
          39 24 49 34 9 10 44 26 77 36 34 10 61 21 61 24 0 4 64 18 143 30 78 13 144
          26 147 30 5 8 426 60 483 60 20 0 37 4 39 9 4 11 388 40 642 48 108 3 196 10
          196 15 0 4 162 8 360 8 198 0 360 -4 360 -8z"/>
          <!-- khung xe -->
          <path id="1" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M6838 12523 c-107 -2 -178 -7 -178 -13 0 -6 -36 -10 -82 -10 -158 0
          -808 -49 -808 -60 0 -4 -33 -10 -72 -14 -144 -12 -328 -39 -328 -47 0 -5 -10
          -9 -23 -9 -43 0 -232 -40 -235 -50 -2 -6 -8 -10 -13 -10 -13 0 -92 -52 -102
          -67 -4 -7 -14 -13 -22 -13 -7 0 -22 -11 -33 -25 -11 -14 -24 -25 -29 -25 -5 0
          -24 -14 -43 -32 -19 -17 -49 -41 -65 -52 -32 -22 -51 -38 -109 -92 -20 -19
          -40 -34 -45 -34 -4 0 -30 -20 -57 -45 -27 -24 -53 -45 -57 -45 -5 0 -20 -13
          -34 -30 -13 -16 -28 -30 -33 -30 -4 0 -24 -15 -42 -32 -19 -18 -49 -44 -67
          -58 -17 -14 -43 -37 -58 -52 -14 -16 -31 -28 -37 -28 -7 0 -23 -12 -37 -27
          -14 -16 -35 -35 -48 -43 -13 -8 -35 -27 -49 -42 -14 -16 -30 -28 -35 -28 -9 0
          -24 -13 -100 -87 -18 -18 -37 -33 -42 -33 -5 0 -21 -11 -35 -25 -77 -74 -89
          -85 -97 -85 -5 0 -24 -15 -43 -33 -19 -18 -51 -44 -70 -59 -19 -14 -49 -40
          -67 -56 -17 -16 -47 -40 -65 -53 -36 -25 -55 -41 -107 -88 -19 -17 -37 -31
          -40 -31 -3 0 -24 -16 -46 -36 -22 -19 -52 -42 -66 -49 -15 -8 -29 -21 -32 -29
          -3 -8 -22 -22 -41 -31 -20 -10 -33 -21 -30 -26 3 -5 0 -9 -6 -9 -10 0 -90 -49
          -100 -61 -3 -3 -19 -14 -37 -23 -18 -9 -36 -22 -40 -29 -4 -7 -29 -24 -55 -36
          -27 -13 -48 -26 -48 -30 0 -4 -33 -21 -72 -37 -40 -15 -75 -31 -78 -34 -3 -4
          -31 -14 -63 -24 -32 -10 -63 -24 -70 -31 -7 -7 -30 -18 -51 -26 -21 -8 -48
          -27 -59 -44 -12 -16 -25 -31 -29 -33 -10 -5 -10 -112 0 -112 4 0 18 -43 31
          -95 14 -52 27 -95 31 -95 4 0 17 -26 29 -59 20 -55 25 -60 74 -81 28 -12 55
          -26 58 -31 3 -5 29 -18 58 -28 29 -10 61 -25 71 -34 10 -8 44 -24 76 -37 32
          -12 61 -25 64 -28 7 -10 67 -38 113 -53 20 -7 37 -16 37 -20 0 -4 26 -17 58
          -30 31 -12 59 -26 62 -30 3 -5 27 -18 53 -29 26 -12 47 -25 47 -30 0 -6 6 -10
          14 -10 17 0 109 -49 119 -64 8 -11 4 -201 -9 -428 l-7 -108 -38 -10 c-22 -6
          -39 -14 -39 -19 0 -5 -21 -16 -47 -26 -27 -10 -57 -25 -68 -34 -11 -10 -44
          -28 -72 -41 -29 -13 -53 -27 -53 -31 0 -4 -10 -11 -22 -15 -13 -3 -45 -21 -73
          -40 -27 -18 -62 -38 -77 -45 -16 -7 -28 -16 -28 -20 0 -5 -24 -20 -54 -34 -30
          -14 -56 -30 -58 -34 -2 -5 -17 -17 -34 -27 -62 -38 -130 -84 -155 -104 -15
          -12 -35 -27 -46 -33 -38 -22 -98 -65 -123 -87 -14 -12 -53 -44 -86 -72 -43
          -35 -64 -59 -68 -80 -3 -16 -9 -99 -12 -184 -4 -85 -11 -154 -16 -154 -4 0 -8
          -139 -8 -308 l0 -308 50 -18 c28 -10 50 -21 50 -25 0 -4 25 -18 55 -32 30 -13
          55 -27 55 -30 0 -3 29 -18 65 -34 36 -15 65 -31 65 -35 0 -4 17 -15 39 -23 21
          -9 45 -22 52 -29 8 -7 46 -27 84 -43 39 -16 72 -32 75 -35 7 -10 51 -32 55
          -28 1 2 13 46 25 98 12 52 25 97 30 100 4 3 20 44 35 93 15 48 30 87 34 87 4
          0 15 25 26 56 11 31 24 62 30 68 6 6 19 32 29 59 10 26 24 54 31 61 8 8 22 27
          32 43 35 58 89 126 128 164 51 49 152 129 163 129 5 0 17 8 28 18 20 19 85 52
          102 52 6 0 17 6 25 14 17 17 125 51 193 62 27 4 52 11 55 16 8 12 270 12 278
          0 3 -5 24 -12 46 -15 49 -8 168 -46 175 -57 3 -4 25 -18 50 -30 25 -12 47 -26
          50 -29 3 -4 23 -18 45 -31 44 -27 57 -37 110 -86 20 -19 39 -34 42 -34 3 0 46
          -42 95 -92 129 -134 128 -133 128 -146 0 -6 11 -19 25 -28 14 -9 33 -32 41
          -51 9 -18 19 -31 22 -29 3 3 18 -19 34 -50 16 -30 38 -64 48 -74 10 -10 24
          -34 31 -54 7 -20 17 -36 21 -36 5 0 17 -20 28 -45 11 -25 23 -45 28 -45 5 0
          20 -27 32 -60 12 -33 26 -60 30 -60 4 0 20 -34 35 -75 15 -41 31 -75 34 -75 4
          0 18 -36 31 -79 14 -43 28 -77 31 -75 3 2 16 -34 29 -79 12 -45 25 -84 29 -87
          8 -5 45 -155 57 -227 4 -24 10 -43 14 -43 4 0 18 -64 30 -142 13 -79 26 -145
          30 -148 9 -6 38 -244 46 -378 4 -57 10 -98 14 -92 12 20 20 -137 26 -535 3
          -216 2 -427 -4 -490 -7 -79 -6 -112 2 -120 6 -6 48 -22 94 -34 45 -13 82 -27
          82 -31 0 -4 37 -17 83 -29 45 -13 84 -26 87 -30 5 -7 68 -27 165 -53 22 -6 42
          -14 45 -18 3 -4 39 -17 80 -29 41 -13 77 -26 80 -30 3 -4 50 -20 105 -36 55
          -16 102 -32 105 -36 3 -3 39 -17 80 -30 41 -13 77 -26 80 -30 3 -3 37 -16 75
          -29 39 -13 72 -26 75 -30 3 -4 43 -20 90 -35 47 -15 87 -31 90 -34 3 -4 39
          -18 80 -31 41 -13 77 -28 80 -32 3 -4 35 -17 70 -28 35 -11 67 -24 70 -28 3
          -4 44 -21 93 -37 48 -16 87 -32 87 -36 0 -4 31 -17 69 -29 39 -12 71 -24 73
          -28 2 -4 41 -20 88 -36 47 -16 87 -33 90 -37 3 -4 34 -17 70 -30 36 -12 67
          -25 70 -29 3 -4 32 -17 65 -29 33 -12 62 -26 65 -29 3 -4 41 -20 85 -35 43
          -15 82 -31 85 -35 3 -5 34 -19 70 -32 36 -13 67 -27 70 -30 3 -4 32 -17 65
          -29 33 -12 62 -26 65 -30 3 -4 39 -20 80 -36 41 -16 77 -31 80 -34 3 -3 32
          -17 65 -30 33 -14 62 -27 65 -30 7 -9 113 -51 127 -51 7 0 16 -6 20 -13 4 -7
          35 -22 67 -32 33 -11 65 -25 71 -32 6 -6 42 -21 78 -34 37 -12 64 -25 62 -28
          -6 -5 83 -43 128 -54 15 -4 27 -11 27 -16 0 -5 29 -19 65 -31 36 -12 65 -25
          65 -29 0 -4 29 -18 65 -30 36 -13 65 -27 65 -31 0 -4 35 -20 78 -35 42 -15 79
          -30 82 -34 7 -10 110 -51 126 -51 8 0 14 -4 14 -8 0 -5 33 -22 73 -38 39 -15
          74 -31 77 -35 3 -3 33 -17 68 -29 34 -12 62 -26 62 -29 0 -4 29 -18 65 -30 36
          -13 65 -26 65 -30 0 -4 17 -13 38 -20 54 -18 116 -44 122 -52 3 -3 35 -17 73
          -29 37 -12 67 -26 67 -31 0 -4 29 -17 65 -29 36 -12 65 -26 65 -31 0 -5 9 -9
          21 -9 18 0 20 4 14 27 -3 15 -10 30 -14 33 -4 3 -18 57 -31 120 -13 63 -27
          117 -31 120 -4 3 -17 70 -30 150 -13 80 -26 147 -30 150 -12 10 -59 429 -59
          525 0 34 -5 55 -12 57 -17 6 -19 935 -2 946 5 4 15 58 22 119 14 138 43 316
          52 323 4 3 17 48 30 100 12 52 26 97 30 100 4 3 17 37 30 75 13 39 26 72 30
          75 3 3 19 37 35 75 16 39 33 72 38 73 4 2 17 23 29 45 11 23 24 44 27 47 4 3
          17 21 29 42 12 20 29 39 37 42 8 3 15 11 15 18 0 21 166 186 225 224 17 10 39
          27 49 36 11 10 25 18 31 18 6 0 20 8 31 18 20 18 140 72 162 72 6 0 12 4 12 9
          0 11 82 36 148 47 23 4 42 10 42 14 0 11 178 30 280 30 105 0 276 -17 305 -29
          11 -5 65 -19 120 -31 55 -13 102 -26 105 -29 3 -4 47 -21 98 -37 50 -16 92
          -32 92 -35 0 -4 29 -17 65 -30 35 -12 62 -26 60 -30 -3 -4 20 -17 50 -29 30
          -12 55 -25 55 -29 0 -4 25 -20 55 -36 30 -15 55 -32 55 -36 0 -4 8 -10 18 -13
          9 -4 32 -20 51 -36 18 -17 36 -30 40 -30 3 0 26 -18 51 -40 25 -22 48 -40 52
          -40 12 0 218 -208 218 -219 0 -6 14 -20 30 -33 17 -12 30 -27 30 -33 0 -6 15
          -26 33 -43 50 -49 70 -75 92 -116 11 -21 29 -48 41 -59 11 -12 29 -37 39 -55
          11 -17 22 -32 25 -32 4 0 17 -23 30 -50 13 -27 26 -50 30 -50 3 0 20 -28 36
          -63 16 -34 34 -67 40 -73 6 -7 18 -33 28 -58 10 -25 21 -46 25 -46 5 0 19 -28
          31 -63 12 -34 26 -64 30 -67 4 -3 20 -40 35 -83 15 -42 32 -77 36 -77 5 0 9
          -5 9 -12 0 -18 43 -152 50 -158 4 -3 17 -48 30 -100 14 -52 27 -97 31 -100 3
          -3 16 -57 29 -120 13 -63 27 -117 31 -120 8 -6 48 -279 56 -385 3 -44 11 -83
          16 -87 14 -9 14 -537 0 -547 -5 -3 -15 -71 -22 -151 -7 -80 -19 -192 -27 -249
          -13 -101 -13 -105 7 -137 11 -19 26 -34 33 -34 7 0 21 -9 31 -20 10 -11 22
          -20 27 -20 5 0 17 -8 28 -18 10 -9 40 -24 67 -31 26 -8 51 -18 54 -23 3 -6 55
          -18 115 -29 159 -27 525 -24 729 6 42 7 42 8 56 62 7 30 18 60 24 67 6 6 24
          47 39 91 15 44 32 82 37 86 5 3 17 28 27 56 10 28 23 57 29 65 6 7 21 36 34
          63 13 28 26 52 29 55 4 3 18 28 32 56 40 80 63 101 119 109 27 4 47 10 44 14
          -2 5 46 18 108 31 62 13 114 26 117 30 3 4 61 20 130 35 69 15 127 31 130 35
          3 4 50 18 105 30 55 13 102 26 105 30 3 4 50 18 105 30 55 13 102 27 105 31 3
          4 52 19 110 33 57 15 107 30 110 34 3 5 44 19 93 32 48 14 87 27 87 31 0 3 37
          17 83 29 45 13 84 27 87 31 3 4 41 17 85 29 44 12 98 30 120 40 22 10 69 26
          105 36 36 10 67 21 70 24 5 7 115 42 173 56 15 3 27 9 27 13 0 4 35 18 78 31
          42 13 79 27 82 31 3 4 37 17 75 30 39 12 72 25 75 29 3 4 43 19 90 35 47 15
          87 31 90 35 3 4 30 15 60 25 30 9 64 23 75 30 11 6 49 22 85 35 36 13 67 26
          70 30 3 4 41 20 85 35 44 16 82 31 85 35 3 4 34 18 70 31 36 12 67 26 70 30 3
          3 41 19 85 34 44 16 82 32 85 36 4 5 33 18 65 29 32 11 61 24 65 28 3 5 33 19
          68 31 34 13 62 27 62 32 0 5 6 9 12 9 19 0 153 52 156 61 2 4 34 17 73 30 38
          12 69 26 69 30 0 4 30 17 68 30 37 12 69 25 72 29 3 4 43 20 90 35 47 15 87
          31 90 35 3 4 28 13 56 19 40 10 62 10 95 1 24 -7 46 -15 49 -19 3 -4 39 -17
          80 -31 41 -13 82 -29 90 -36 8 -7 40 -22 70 -34 30 -12 57 -26 60 -30 3 -5 30
          -20 60 -33 48 -21 56 -22 81 -10 16 8 38 27 49 43 21 29 51 66 80 99 18 20 60
          104 60 120 0 6 4 11 9 11 5 0 18 35 28 77 25 108 25 406 0 593 -11 74 -22 137
          -26 140 -4 3 -18 79 -31 170 -13 91 -27 167 -30 170 -4 3 -17 88 -30 190 -13
          102 -27 187 -30 190 -8 5 -60 396 -60 447 0 17 -4 33 -9 35 -10 3 -51 458 -51
          567 0 51 -4 70 -15 75 -8 3 -18 -1 -22 -8 -7 -12 -26 -23 -93 -58 -14 -7 -27
          -16 -30 -19 -3 -4 -27 -17 -55 -30 -27 -12 -52 -26 -55 -30 -3 -3 -34 -16 -70
          -29 -36 -13 -67 -26 -70 -30 -3 -4 -50 -20 -105 -35 -55 -15 -102 -30 -105
          -34 -7 -10 -212 -51 -255 -51 -19 0 -35 -4 -35 -10 0 -6 -87 -10 -240 -10
          -171 0 -240 3 -240 11 0 8 -5 8 -15 -1 -14 -12 -11 -35 4 -26 4 3 19 -6 34
          -20 14 -13 30 -24 35 -24 5 0 17 -9 27 -20 10 -11 22 -20 27 -20 14 0 86 -77
          104 -111 25 -50 13 -92 -43 -150 -26 -26 -54 -49 -60 -49 -7 0 -20 -11 -29
          -25 -9 -14 -23 -25 -31 -25 -7 0 -24 -10 -37 -23 -12 -12 -41 -31 -65 -41 -23
          -11 -45 -25 -49 -31 -4 -7 -35 -25 -69 -40 -35 -15 -63 -31 -63 -35 0 -4 -25
          -17 -56 -29 -30 -11 -62 -27 -70 -35 -8 -8 -40 -24 -71 -36 -32 -12 -60 -25
          -63 -29 -3 -4 -30 -18 -60 -30 -30 -13 -57 -27 -60 -30 -3 -4 -34 -18 -70 -31
          -36 -13 -67 -27 -71 -32 -3 -4 -39 -20 -80 -34 -40 -15 -76 -30 -79 -34 -3 -4
          -30 -15 -60 -25 -30 -9 -64 -23 -75 -30 -11 -6 -50 -22 -87 -34 -38 -13 -68
          -27 -68 -32 0 -5 -6 -9 -14 -9 -20 0 -170 -50 -176 -59 -3 -4 -38 -16 -79 -27
          -41 -10 -80 -24 -87 -31 -7 -7 -17 -13 -23 -13 -21 0 -206 -55 -209 -62 -2 -4
          -47 -17 -100 -29 -53 -12 -99 -26 -102 -30 -3 -4 -52 -17 -110 -29 -58 -13
          -107 -26 -110 -31 -3 -4 -82 -20 -175 -36 -94 -15 -172 -31 -175 -34 -3 -4
          -74 -15 -158 -24 -166 -19 -485 -17 -532 2 -42 18 -80 63 -86 104 -4 22 -10
          39 -15 39 -5 0 -9 65 -9 145 0 90 4 145 10 145 6 0 10 16 10 35 0 36 -18 48
          -26 17 -3 -9 -22 -29 -42 -42 -20 -14 -42 -32 -49 -40 -6 -8 -28 -24 -50 -35
          -21 -11 -44 -26 -51 -33 -8 -7 -28 -20 -45 -29 -18 -9 -34 -19 -37 -23 -3 -4
          -34 -20 -69 -34 -36 -14 -67 -30 -70 -34 -7 -9 -139 -52 -159 -52 -7 0 -11 -4
          -8 -9 3 -5 -38 -19 -92 -32 -53 -12 -99 -26 -102 -30 -6 -8 -280 -59 -316 -59
          -13 0 -24 -4 -24 -8 0 -28 -612 -54 -764 -32 -82 11 -90 15 -125 52 -21 21
          -44 57 -51 79 -7 21 -17 39 -22 39 -5 0 -18 23 -30 50 -11 28 -24 50 -29 50
          -4 0 -15 21 -25 48 -10 26 -26 55 -36 65 -10 10 -18 24 -18 32 0 8 -9 32 -20
          52 -11 21 -20 43 -20 50 0 7 -3 13 -8 13 -4 0 -18 27 -31 60 -13 33 -27 60
          -30 60 -3 0 -15 28 -26 61 -10 34 -25 67 -31 74 -7 6 -25 49 -40 96 -16 46
          -32 86 -35 89 -7 5 -39 106 -39 120 0 4 62 11 138 15 75 4 142 10 147 15 6 4
          78 13 160 20 83 7 151 13 153 15 9 8 -15 35 -48 54 -22 12 -40 26 -40 31 0 7
          -55 46 -83 58 -5 2 -20 15 -34 28 -14 13 -32 24 -39 24 -7 0 -17 6 -21 13 -10
          15 -89 67 -103 67 -5 0 -10 4 -10 9 0 5 -17 20 -37 32 -21 13 -44 29 -51 36
          -7 7 -19 13 -26 13 -7 0 -24 10 -38 23 -13 12 -43 32 -66 45 -23 13 -64 38
          -93 57 -28 19 -57 35 -65 35 -8 0 -14 4 -14 10 0 5 -20 19 -45 31 -25 12 -45
          25 -45 29 0 4 -24 17 -52 30 -29 12 -57 27 -63 34 -5 7 -32 23 -60 36 -27 13
          -54 29 -60 35 -5 6 -30 20 -55 30 -25 11 -48 24 -52 31 -4 6 -34 21 -67 34
          -33 13 -58 27 -56 31 3 3 -18 16 -45 27 -28 12 -50 26 -50 31 0 6 -5 11 -11
          11 -14 0 -123 50 -129 59 -3 4 -24 15 -46 24 -23 8 -58 27 -79 41 -21 14 -55
          31 -76 36 -22 6 -39 15 -39 19 0 9 -78 45 -122 56 -16 4 -28 11 -28 15 0 4
          -26 18 -57 31 -32 13 -64 29 -70 35 -7 7 -35 20 -63 29 -27 9 -50 20 -50 24 0
          4 -32 18 -71 31 -40 14 -77 29 -83 36 -6 6 -41 21 -76 33 -36 13 -67 26 -70
          30 -3 4 -44 20 -92 35 -49 16 -88 31 -88 35 0 3 -38 17 -85 31 -47 14 -85 28
          -85 31 0 4 -42 17 -92 29 -51 13 -96 27 -99 32 -3 5 -14 69 -24 141 -10 73
          -21 134 -25 137 -3 3 -17 75 -29 160 -13 85 -27 157 -30 160 -4 3 -20 79 -36
          170 -16 91 -32 167 -36 170 -3 3 -17 60 -29 128 -13 67 -28 122 -32 122 -4 0
          -8 8 -8 18 0 25 -43 216 -50 222 -3 3 -19 61 -34 130 -16 69 -32 127 -36 130
          -3 3 -17 49 -30 103 -12 53 -26 97 -30 97 -4 0 -16 37 -26 82 -10 44 -24 87
          -31 94 -7 7 -13 17 -13 22 0 18 -51 187 -58 192 -4 3 -19 41 -32 85 -14 44
          -28 81 -32 83 -4 2 -17 37 -29 78 -12 41 -24 72 -27 69 -5 -5 -49 109 -59 153
          -3 12 -9 22 -13 22 -4 0 -18 28 -31 63 -12 34 -26 64 -30 67 -3 3 -17 30 -30
          60 -12 30 -26 57 -30 60 -3 3 -17 30 -30 60 -12 30 -28 58 -34 62 -7 4 -22 34
          -36 68 -13 33 -27 60 -31 60 -4 0 -17 19 -29 43 -12 23 -24 44 -28 47 -3 3
          -12 15 -18 28 -30 52 -39 66 -55 83 -9 9 -24 34 -33 53 -10 20 -20 34 -23 32
          -3 -3 -11 6 -19 21 -7 14 -12 28 -10 30 2 2 42 6 88 10 46 3 86 9 89 13 5 7
          750 60 843 60 26 0 47 4 47 8 0 7 376 32 755 49 77 3 142 9 145 13 9 12 1066
          52 1215 45 76 -3 78 -2 45 10 -19 8 -30 14 -25 15 6 0 -33 12 -85 26 -52 14
          -99 29 -105 33 -5 5 -55 19 -110 32 -55 13 -102 27 -105 31 -3 4 -62 20 -130
          35 -69 15 -127 30 -130 34 -3 3 -60 17 -128 31 -67 13 -120 27 -117 30 4 3
          -22 11 -57 17 -35 6 -96 16 -135 23 -40 7 -73 15 -73 19 0 3 -76 19 -169 35
          -93 15 -168 31 -166 34 2 4 -67 17 -153 30 -86 13 -159 27 -162 30 -3 4 -90
          20 -195 35 -104 16 -192 31 -195 35 -3 3 -84 17 -180 30 -96 13 -177 26 -180
          30 -3 4 -88 17 -190 30 -102 12 -189 26 -195 30 -5 4 -116 20 -245 36 -129 15
          -237 31 -240 35 -3 4 -93 17 -200 30 -107 12 -197 25 -200 29 -5 6 -402 51
          -447 50 -12 0 -23 4 -25 9 -3 9 -423 47 -643 58 -71 3 -133 10 -136 15 -6 10
          -823 18 -1131 11z m-253 -393 c-4 -6 24 -10 77 -10 130 0 588 -45 598 -59 3
          -4 78 -18 168 -31 89 -13 162 -27 162 -31 0 -4 19 -10 43 -14 63 -10 211 -47
          217 -55 3 -3 49 -16 103 -29 53 -13 97 -27 97 -30 0 -4 44 -18 98 -30 53 -13
          99 -27 102 -31 3 -3 52 -19 110 -34 58 -16 107 -31 110 -35 3 -3 45 -17 95
          -30 49 -12 92 -26 95 -30 6 -8 151 -51 173 -51 9 0 17 -4 19 -8 2 -5 48 -21
          103 -37 55 -15 102 -31 105 -35 3 -4 41 -16 84 -28 44 -12 82 -25 85 -30 3 -5
          17 -12 31 -15 52 -11 175 -50 180 -56 3 -3 40 -17 83 -29 43 -12 84 -27 90
          -32 7 -6 24 -19 40 -31 15 -11 27 -26 27 -34 0 -7 11 -20 25 -29 14 -9 25 -22
          25 -30 0 -7 11 -25 24 -39 13 -14 33 -46 46 -71 12 -25 25 -46 29 -46 5 0 19
          -27 32 -59 13 -33 26 -58 29 -56 3 2 19 -30 34 -71 16 -41 32 -74 36 -74 4 0
          18 -28 31 -62 13 -35 28 -68 34 -75 7 -6 20 -37 30 -70 10 -32 21 -60 25 -63
          4 -3 20 -41 36 -85 15 -44 31 -82 35 -85 4 -3 17 -38 29 -77 12 -40 25 -73 29
          -73 3 0 17 -38 31 -85 14 -47 28 -85 32 -85 3 0 14 -26 23 -57 9 -32 24 -78
          34 -103 10 -25 29 -81 42 -125 12 -44 26 -82 30 -85 5 -3 18 -46 30 -95 13
          -50 26 -92 30 -95 5 -3 18 -46 30 -95 13 -50 26 -92 30 -95 4 -3 20 -59 35
          -125 16 -66 31 -122 35 -125 3 -3 17 -52 29 -110 13 -58 27 -107 30 -110 4 -3
          17 -61 30 -130 13 -69 27 -127 31 -130 4 -3 20 -86 35 -185 15 -99 31 -182 34
          -185 17 -12 52 -406 41 -449 -5 -20 -15 -70 -21 -111 -7 -41 -16 -79 -20 -85
          -4 -5 -20 -91 -34 -190 -15 -99 -31 -187 -35 -196 -5 -8 -19 -94 -31 -190 -13
          -96 -26 -176 -30 -179 -4 -3 -17 -93 -30 -200 -13 -107 -26 -197 -30 -200 -4
          -3 -12 -59 -19 -125 -7 -66 -19 -176 -27 -245 -8 -69 -14 -139 -14 -157 0 -17
          -4 -33 -9 -35 -5 -1 -19 -122 -32 -268 -13 -146 -26 -267 -30 -270 -7 -5 -49
          -557 -49 -637 0 -24 -4 -43 -8 -43 -13 0 -30 -392 -44 -962 l-12 -506 -50 20
          c-28 11 -53 23 -56 28 -3 5 -30 18 -60 30 -30 12 -57 25 -60 30 -3 4 -30 18
          -60 31 -30 13 -61 29 -68 36 -7 7 -19 13 -25 13 -15 0 -120 43 -127 51 -3 4
          -29 15 -58 24 -29 10 -59 23 -66 30 -7 7 -45 23 -84 36 -40 13 -72 26 -72 30
          0 4 -38 20 -85 35 -46 15 -83 30 -80 33 2 4 -32 18 -76 31 -43 13 -79 26 -79
          30 0 4 -39 20 -87 36 -49 15 -89 31 -91 35 -2 4 -36 18 -75 30 -40 12 -74 25
          -77 29 -3 4 -39 18 -80 31 -41 13 -77 26 -80 30 -7 8 -157 59 -175 59 -8 0
          -15 4 -15 9 0 4 -33 18 -72 31 -40 12 -75 26 -78 29 -3 4 -41 18 -85 31 -44
          13 -82 27 -85 31 -3 4 -48 20 -100 35 -52 16 -98 32 -101 36 -3 5 -39 18 -80
          29 -41 12 -76 25 -79 28 -3 4 -50 20 -105 36 -55 16 -102 32 -105 36 -3 4 -39
          17 -80 30 -41 12 -77 25 -80 29 -3 3 -43 16 -90 29 -47 13 -87 26 -90 30 -3 4
          -52 21 -110 36 -58 16 -107 32 -110 36 -3 4 -41 17 -85 29 -44 12 -82 25 -85
          29 -3 4 -45 18 -95 31 -49 12 -92 26 -95 30 -3 4 -54 19 -115 35 -60 15 -112
          31 -115 35 -3 4 -49 18 -102 31 -54 12 -98 26 -98 30 0 3 -47 17 -104 29 -58
          13 -103 27 -100 31 2 4 -8 9 -23 11 l-28 3 1 100 c1 158 -16 539 -25 545 -4 3
          -13 50 -20 105 -16 127 -33 229 -41 235 -3 3 -16 59 -29 125 -13 66 -27 122
          -31 125 -3 3 -19 60 -35 128 -16 67 -32 122 -36 122 -4 0 -17 40 -30 89 -13
          49 -26 88 -29 86 -4 -2 -17 32 -29 75 -13 44 -26 80 -30 82 -4 2 -20 44 -36
          93 -16 50 -31 92 -35 95 -3 3 -17 33 -29 68 -13 34 -26 62 -30 62 -3 0 -17 27
          -30 60 -13 33 -27 60 -32 60 -4 0 -15 23 -25 50 -10 28 -25 58 -34 68 -9 9
          -27 37 -40 62 -14 25 -31 51 -37 58 -7 7 -20 28 -29 47 -9 19 -20 35 -24 35
          -5 0 -20 21 -35 46 -15 25 -34 54 -44 64 -9 9 -32 37 -51 60 -134 165 -353
          390 -380 390 -5 0 -17 11 -28 25 -11 14 -23 25 -26 25 -9 0 -62 38 -94 68 -14
          12 -30 22 -37 22 -6 0 -20 8 -31 18 -10 10 -43 28 -73 41 -29 12 -59 27 -65
          32 -6 5 -18 15 -26 22 -12 9 -15 30 -12 100 2 50 7 85 13 82 5 -4 9 11 9 32 0
          79 52 578 61 581 5 2 9 16 9 30 0 48 43 387 50 392 4 3 18 85 31 182 13 97 26
          175 29 173 3 -2 18 70 34 160 16 91 34 166 40 169 20 8 -11 22 -82 36 -37 7
          -69 16 -72 20 -3 4 -59 17 -125 30 -66 13 -122 27 -125 31 -3 4 -64 17 -137
          30 -72 13 -129 27 -127 31 3 5 -5 8 -18 8 -48 0 -68 11 -63 34 22 107 40 176
          46 176 4 0 13 24 20 52 14 60 48 158 49 143 1 -5 12 19 26 55 13 35 28 67 33
          70 4 3 20 30 35 60 32 67 75 143 84 150 10 8 31 41 47 78 9 17 19 32 23 32 5
          0 18 18 30 39 12 22 26 41 30 43 8 3 52 64 52 72 0 2 13 19 29 36 16 18 41 46
          56 63 44 53 243 257 251 257 4 0 16 10 28 23 32 33 143 117 155 117 5 0 14 6
          18 13 4 8 33 27 63 43 30 16 57 32 60 36 3 4 28 16 55 28 27 12 52 25 55 29 3
          5 34 19 70 32 36 12 68 27 71 31 3 5 48 20 100 35 52 15 96 30 99 34 3 4 48
          17 100 29 52 12 104 25 115 30 46 21 364 60 493 60 41 0 72 4 72 10 0 6 49 10
          126 10 81 0 123 -4 119 -10z m13696 -5781 c24 -7 46 -16 49 -19 3 -3 28 -19
          55 -35 28 -16 63 -43 79 -62 16 -18 33 -30 38 -27 4 3 8 -1 8 -8 0 -7 13 -26
          28 -43 15 -16 39 -48 52 -70 13 -22 30 -46 37 -53 7 -7 13 -22 13 -32 0 -10 4
          -21 9 -24 5 -3 15 -35 22 -71 7 -36 16 -65 21 -65 11 0 10 -137 -1 -144 -5 -3
          -12 -22 -15 -41 -8 -39 -34 -48 -43 -15 -3 11 -9 20 -14 20 -5 0 -9 5 -9 11 0
          24 -32 120 -45 132 -7 7 -22 32 -34 56 -22 43 -31 55 -82 111 -16 17 -29 34
          -29 37 0 9 -34 32 -46 33 -7 0 -15 -16 -18 -35 -6 -32 -90 -204 -106 -215 -4
          -3 -20 -30 -36 -60 -16 -30 -37 -61 -46 -68 -10 -7 -18 -19 -18 -25 0 -7 -16
          -31 -36 -52 -56 -63 -69 -78 -95 -115 -13 -19 -37 -45 -53 -59 -16 -14 -47
          -45 -70 -70 -45 -49 -40 -44 -158 -156 -80 -76 -118 -109 -218 -190 -25 -19
          -56 -45 -70 -58 -28 -24 -54 -41 -87 -56 -13 -6 -23 -14 -23 -19 0 -5 -18 -18
          -39 -30 -21 -12 -41 -29 -45 -37 -3 -8 -12 -15 -21 -15 -8 0 -26 -17 -40 -37
          -14 -21 -36 -55 -50 -75 -14 -21 -29 -38 -33 -38 -4 0 -13 -12 -19 -27 -15
          -34 -31 -57 -60 -91 -13 -14 -23 -31 -23 -38 0 -8 -9 -19 -20 -26 -11 -7 -20
          -17 -20 -22 0 -5 -17 -32 -39 -60 -21 -28 -44 -62 -51 -76 -7 -14 -21 -31 -32
          -38 -10 -7 -18 -19 -18 -25 0 -7 -10 -23 -23 -37 -12 -14 -34 -41 -47 -60 -14
          -19 -32 -42 -40 -51 -23 -24 -52 -60 -85 -104 -16 -22 -51 -61 -77 -86 -27
          -26 -48 -50 -48 -54 0 -12 -155 -165 -167 -165 -7 0 -13 -6 -13 -13 0 -7 -18
          -23 -40 -37 -21 -13 -37 -26 -35 -28 2 -3 -16 -18 -40 -34 -25 -17 -45 -33
          -45 -38 0 -4 -8 -11 -17 -14 -27 -9 -65 -33 -72 -44 -3 -5 -26 -20 -51 -32
          -25 -12 -47 -25 -50 -29 -3 -3 -21 -13 -40 -23 -19 -9 -63 -30 -96 -46 -34
          -17 -81 -36 -105 -43 -24 -7 -46 -16 -49 -19 -7 -8 -151 -50 -172 -50 -10 0
          -18 -4 -18 -8 0 -15 -219 -46 -400 -57 -169 -9 -600 31 -600 57 0 4 -7 8 -16
          8 -25 0 -169 43 -172 52 -2 4 -19 10 -38 14 -19 3 -37 10 -40 15 -3 5 -13 9
          -23 9 -10 0 -27 8 -39 18 -12 10 -39 24 -61 32 -22 7 -48 20 -58 29 -10 9 -36
          28 -58 41 -22 13 -53 37 -70 52 -16 15 -36 28 -42 28 -7 0 -13 4 -13 10 0 5
          -18 23 -40 39 -21 15 -36 31 -33 35 3 3 1 6 -5 6 -15 0 -202 184 -202 199 0 6
          -6 11 -14 11 -8 0 -17 9 -21 20 -3 11 -16 27 -28 37 -13 10 -33 35 -46 56 -12
          20 -26 37 -30 37 -4 0 -20 22 -36 50 -17 27 -33 50 -37 50 -4 0 -17 19 -29 42
          -12 23 -26 47 -33 53 -6 6 -20 29 -31 51 -11 21 -23 41 -26 44 -4 3 -17 30
          -30 60 -12 30 -26 57 -30 60 -10 7 -39 91 -39 113 0 9 -4 17 -10 17 -5 0 -10
          -5 -10 -11 0 -6 -7 -17 -15 -25 -8 -8 -22 -40 -32 -72 -10 -31 -21 -60 -26
          -63 -21 -15 -39 -205 -33 -354 5 -158 24 -305 38 -305 4 0 8 -16 8 -35 0 -19
          -3 -35 -7 -35 -12 0 -53 43 -53 57 0 7 -4 13 -9 13 -10 0 -61 104 -61 124 0 8
          -4 16 -10 18 -20 6 -42 146 -47 303 -6 197 10 311 62 421 19 41 35 79 35 84 0
          5 6 13 14 17 7 4 19 19 26 33 15 29 37 54 111 129 88 87 104 101 115 101 6 0
          19 8 30 19 12 10 48 29 82 41 34 13 62 26 62 30 0 5 39 13 87 20 69 10 104 10
          170 0 46 -7 83 -15 83 -19 0 -4 18 -14 39 -21 76 -25 116 -81 69 -96 -13 -4
          -25 -10 -28 -13 -3 -4 -43 -17 -90 -31 -47 -13 -87 -27 -90 -31 -3 -4 -35 -19
          -72 -34 -38 -15 -68 -31 -68 -36 0 -5 -5 -9 -12 -9 -17 0 -78 -43 -78 -55 0
          -6 20 -19 45 -30 25 -11 45 -23 45 -26 0 -8 113 -59 129 -59 6 0 11 -4 11 -10
          0 -5 33 -19 73 -31 39 -12 74 -25 77 -29 3 -4 48 -18 100 -30 52 -13 97 -27
          100 -31 6 -9 208 -45 307 -55 37 -4 70 -11 73 -15 4 -5 118 -14 255 -21 195
          -9 301 -9 485 1 129 6 237 16 238 22 2 5 19 9 38 9 52 0 378 51 384 60 3 4 52
          17 109 29 58 12 107 25 110 29 4 4 47 17 96 30 50 13 92 28 93 33 2 5 10 9 18
          9 23 0 198 53 204 62 3 4 37 17 75 29 39 12 72 25 75 29 3 4 35 18 72 30 37
          12 65 26 62 31 -3 5 2 9 11 9 20 0 148 50 155 61 3 4 30 17 60 29 30 12 57 25
          60 29 3 5 32 21 65 37 33 16 69 37 79 46 11 10 25 18 31 18 6 0 20 8 31 18 10
          10 39 29 63 42 25 14 54 33 65 43 12 10 63 52 114 93 50 42 92 79 92 83 0 4
          14 15 31 25 17 10 61 49 97 87 37 38 72 69 79 69 7 0 13 6 13 13 0 6 23 34 50
          61 28 27 50 53 50 58 0 5 14 21 30 35 17 13 30 31 30 39 0 9 4 13 8 10 5 -3
          19 13 31 34 12 22 26 40 30 40 4 0 17 18 30 40 12 22 27 43 34 47 7 5 18 18
          25 31 31 55 38 67 55 86 9 11 17 27 17 36 0 9 8 25 18 36 10 10 27 43 39 71
          21 51 21 53 3 63 -10 5 -25 10 -34 10 -8 0 -16 6 -19 13 -3 9 -39 12 -133 13
          -71 1 -137 5 -146 9 -18 7 -26 45 -10 45 5 0 15 12 23 28 8 15 19 32 24 37 22
          23 118 78 148 86 42 11 162 4 218 -12z m502 -1071 c3 -13 9 -48 13 -78 4 -30
          10 -60 15 -65 13 -17 32 -370 27 -492 -4 -83 -10 -119 -21 -131 -22 -25 -199
          -200 -217 -215 -30 -25 -92 -57 -109 -57 -11 0 -25 15 -36 40 -10 22 -21 40
          -25 40 -4 0 -17 22 -30 50 -13 27 -26 48 -28 45 -3 -2 -16 19 -29 47 -13 29
          -27 55 -31 58 -4 3 -18 28 -32 55 -14 28 -29 52 -33 53 -4 2 -19 27 -34 55
          -14 29 -29 54 -33 57 -4 3 -16 21 -27 41 -11 20 -27 44 -36 54 -25 24 -21 50
          11 70 15 10 31 24 35 32 4 7 14 13 21 13 7 0 26 12 41 26 16 14 45 35 67 46
          21 11 38 24 38 29 0 4 25 21 55 35 30 15 55 30 55 34 0 3 19 17 43 30 23 13
          44 27 47 30 3 4 25 18 50 30 25 12 47 27 51 31 6 10 118 58 135 59 7 0 14 -10
          17 -22z m-6083 -2008 c7 -11 219 -40 338 -47 56 -3 102 -10 102 -15 0 -12 277
          -10 285 2 3 6 29 10 57 10 l50 0 -7 -47 c-4 -27 -10 -50 -14 -53 -4 -3 -18
          -47 -31 -97 -13 -51 -27 -93 -31 -93 -4 0 -19 -39 -34 -87 -15 -49 -30 -90
          -34 -93 -9 -7 -51 -112 -51 -128 0 -6 -4 -12 -9 -12 -4 0 -19 -28 -31 -62 -12
          -35 -26 -65 -30 -68 -4 -3 -17 -35 -29 -72 l-23 -68 -156 0 c-179 0 -422 24
          -435 43 -5 6 -6 99 -3 206 3 107 6 308 6 448 l0 254 38 -7 c20 -4 39 -10 42
          -14z"/>
          
          <!-- //Kinh xe -->
          <path id="2" style="cursor: pointer; stroke: black; stroke-width: 50px"  d="M6113 12013 c-29 -2 -53 -8 -53 -12 0 -5 -32 -11 -71 -15 -69 -6
          -130 -29 -171 -65 -11 -10 -36 -31 -55 -47 -45 -38 -169 -163 -210 -211 -77
          -90 -133 -162 -133 -171 0 -5 -7 -12 -14 -15 -8 -3 -23 -21 -32 -39 -9 -18
          -22 -36 -29 -40 -7 -5 -24 -29 -36 -55 -13 -27 -29 -54 -36 -61 -8 -8 -21 -28
          -29 -45 -9 -16 -21 -35 -26 -41 -14 -16 -63 -103 -88 -156 -12 -24 -25 -47
          -28 -50 -4 -3 -21 -36 -37 -75 -16 -38 -32 -72 -35 -75 -3 -3 -17 -34 -30 -70
          -13 -36 -27 -67 -30 -70 -4 -3 -17 -39 -29 -80 -13 -41 -26 -77 -30 -80 -8 -5
          -61 -219 -61 -244 0 -8 -4 -16 -9 -18 -5 -1 -12 -21 -15 -43 l-7 -41 95 -19
          c53 -10 96 -21 96 -25 0 -4 51 -17 113 -30 61 -13 114 -27 117 -30 3 -4 59
          -20 125 -36 66 -16 122 -32 125 -35 3 -4 48 -17 100 -29 52 -13 97 -26 100
          -30 3 -4 49 -18 103 -30 53 -12 97 -26 97 -29 0 -4 53 -20 118 -36 64 -16 119
          -32 122 -36 3 -4 46 -18 95 -30 50 -12 92 -26 95 -30 3 -4 52 -19 110 -34 58
          -15 107 -30 110 -34 3 -4 46 -18 95 -31 50 -13 92 -27 95 -31 3 -3 43 -16 90
          -28 46 -12 87 -26 90 -30 3 -4 37 -15 75 -25 91 -23 139 -39 145 -48 3 -4 47
          -18 98 -31 50 -13 90 -26 87 -29 -4 -4 140 -48 159 -48 3 0 6 9 6 19 0 11 3
          21 8 23 4 2 20 93 36 203 15 110 31 202 36 205 4 3 10 23 14 45 23 140 41 231
          46 235 4 3 17 61 30 130 12 69 26 127 29 130 4 3 20 66 35 140 15 74 32 140
          37 145 4 6 18 56 30 110 11 55 24 102 28 105 5 3 18 48 31 100 13 52 26 97 30
          100 3 3 14 34 23 70 27 102 41 145 48 150 4 3 16 38 28 79 11 41 24 77 29 80
          4 3 18 41 32 84 13 42 26 77 30 77 12 0 44 110 34 119 -5 5 -54 19 -109 31
          -55 13 -102 26 -105 30 -3 4 -60 18 -127 30 -68 13 -123 26 -123 30 0 4 -67
          18 -149 31 -82 13 -147 27 -145 31 3 5 -3 8 -13 8 -10 0 -106 12 -213 25 -107
          14 -214 25 -238 25 -24 0 -41 4 -38 8 11 18 -524 28 -721 15z"/>
          <!-- sau khung xe -->
          <path id="3" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M5555 11883 c-11 -3 -22 -9 -25 -13 -3 -4 -38 -17 -77 -29 -40 -13
          -73 -26 -73 -31 0 -5 -17 -14 -37 -20 -21 -7 -42 -16 -47 -21 -6 -5 -18 -9
          -27 -9 -10 0 -24 -6 -31 -13 -7 -7 -35 -24 -63 -37 -27 -13 -52 -27 -55 -30
          -3 -4 -25 -17 -50 -31 -24 -13 -52 -32 -62 -41 -9 -9 -31 -24 -48 -33 -32 -17
          -58 -35 -95 -70 -34 -31 -83 -65 -94 -65 -6 0 -15 -9 -21 -19 -5 -11 -24 -28
          -40 -38 -17 -9 -30 -23 -30 -30 0 -7 -5 -13 -10 -13 -11 0 -110 -96 -110 -107
          0 -4 -15 -20 -32 -37 -18 -17 -49 -51 -68 -75 -19 -25 -42 -52 -50 -61 -9 -8
          -27 -33 -40 -55 -13 -22 -32 -47 -42 -57 -10 -10 -18 -24 -18 -32 0 -7 -11
          -25 -24 -39 -14 -15 -30 -42 -37 -62 -8 -20 -22 -45 -32 -56 -10 -10 -28 -46
          -41 -79 -12 -33 -25 -58 -27 -55 -3 2 -16 -28 -29 -68 -13 -39 -27 -74 -30
          -77 -12 -8 -32 -80 -27 -94 3 -8 44 -18 106 -26 55 -7 98 -16 96 -20 -2 -3 62
          -17 143 -30 81 -13 149 -26 152 -30 3 -3 35 -12 72 -18 37 -7 81 -15 96 -18
          28 -6 29 -4 36 35 3 23 10 41 14 41 5 0 18 43 31 95 13 52 26 95 30 95 4 0 18
          41 31 92 14 50 30 96 35 102 6 6 21 45 35 86 13 41 27 77 31 80 7 5 33 69 46
          115 4 11 10 22 13 25 4 3 20 34 35 70 15 36 30 67 34 70 4 3 18 30 30 60 13
          30 27 57 31 60 3 3 17 27 31 55 13 27 32 57 42 67 9 10 17 25 17 33 0 8 8 23
          18 33 9 10 28 38 41 62 13 25 32 53 42 63 11 10 26 31 34 47 9 17 20 30 25 30
          6 0 10 5 10 11 0 16 68 103 133 169 48 50 48 54 2 43z"/>
          <!-- Khung xe truoc -->
          <path id="5" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M8206 11666 c-4 -19 -10 -36 -15 -38 -9 -3 -51 -129 -51 -152 0 -8
          -4 -16 -10 -18 -5 -1 -21 -48 -35 -103 -14 -55 -29 -102 -33 -105 -4 -3 -18
          -50 -32 -105 -13 -55 -27 -102 -31 -105 -5 -3 -18 -53 -30 -110 -12 -58 -26
          -107 -30 -110 -4 -3 -20 -64 -34 -135 -15 -72 -31 -132 -35 -135 -4 -3 -17
          -59 -30 -125 -12 -66 -26 -122 -30 -125 -3 -3 -17 -63 -29 -135 -12 -71 -26
          -132 -30 -135 -4 -3 -20 -84 -36 -180 -16 -96 -31 -177 -35 -180 -6 -4 -28
          -143 -45 -287 -6 -45 -4 -53 9 -53 9 0 16 -4 16 -8 0 -4 21 -14 48 -21 26 -8
          65 -22 87 -33 22 -11 71 -30 109 -44 39 -13 72 -28 75 -33 4 -4 36 -18 73 -30
          37 -12 68 -26 70 -30 2 -4 32 -17 68 -30 36 -13 67 -27 70 -31 3 -3 40 -19 83
          -35 42 -15 77 -31 77 -34 0 -4 32 -17 70 -30 39 -13 68 -26 65 -29 -3 -3 28
          -17 67 -31 40 -13 79 -30 85 -37 7 -7 42 -22 78 -34 36 -12 65 -25 65 -30 0
          -4 27 -17 60 -28 33 -12 67 -28 76 -36 8 -9 24 -16 34 -16 10 0 22 -4 25 -10
          3 -5 14 -10 24 -10 10 0 27 -8 39 -18 11 -10 52 -29 91 -42 39 -13 71 -27 71
          -30 0 -4 28 -18 63 -31 34 -13 67 -28 74 -35 7 -7 42 -23 78 -35 36 -13 65
          -27 65 -30 0 -4 28 -17 63 -30 34 -12 64 -26 67 -30 3 -4 30 -17 60 -29 30
          -12 57 -26 60 -31 3 -5 37 -20 75 -34 38 -13 71 -28 74 -33 3 -5 34 -20 68
          -33 34 -13 60 -26 58 -29 -2 -4 20 -14 50 -24 30 -9 60 -23 67 -30 7 -7 47
          -25 88 -41 41 -16 77 -32 80 -36 3 -4 30 -17 60 -30 30 -13 61 -29 68 -36 7
          -7 18 -13 22 -13 5 0 44 -16 86 -36 42 -19 78 -33 80 -31 7 6 -17 231 -25 237
          -4 3 -17 77 -29 165 -13 88 -28 162 -33 163 -5 2 -9 13 -9 24 0 34 -51 302
          -60 313 -4 6 -18 60 -30 120 -13 61 -27 112 -32 113 -4 2 -8 10 -8 18 0 25
          -43 199 -50 204 -4 3 -19 56 -35 118 -15 61 -31 112 -36 112 -5 0 -9 7 -9 16
          0 23 -43 168 -51 174 -4 3 -12 25 -19 50 -16 63 -34 114 -41 120 -4 3 -17 39
          -29 80 -12 41 -25 77 -29 80 -4 3 -20 46 -36 95 -16 50 -32 92 -36 95 -3 3
          -18 37 -32 75 -14 39 -26 66 -27 60 0 -5 -12 23 -26 64 -14 41 -31 80 -39 87
          -7 8 -23 43 -35 79 -12 36 -25 65 -29 65 -4 0 -18 26 -31 58 -13 31 -27 59
          -30 62 -4 3 -18 30 -31 60 -13 30 -29 60 -35 65 -7 6 -23 35 -35 65 -13 30
          -30 62 -38 70 -7 8 -20 29 -27 46 -7 18 -17 34 -21 36 -5 1 -19 23 -32 48 -30
          54 -44 66 -96 81 -22 6 -42 14 -45 18 -3 4 -41 18 -85 31 -44 13 -82 27 -85
          31 -3 4 -43 17 -90 29 -47 13 -87 27 -90 31 -3 4 -50 19 -105 34 -55 15 -102
          31 -105 35 -3 4 -45 18 -95 30 -49 13 -92 27 -95 30 -3 4 -56 20 -117 35 -62
          16 -113 32 -113 36 0 4 -44 17 -97 30 -54 12 -100 26 -103 29 -3 4 -21 10 -41
          14 -36 6 -37 5 -43 -28z"/>
          <!-- duoi kinh duoi -->
          <path id="6" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4790 10035 c0 -14 -4 -25 -8 -25 -8 0 -32 -118 -49 -230 -3 -25 -9
          -47 -13 -50 -8 -5 -60 -356 -60 -397 0 -13 -4 -23 -8 -23 -7 0 -30 -158 -48
          -330 -4 -36 -10 -67 -14 -70 -4 -3 -18 -111 -31 -240 -12 -129 -26 -237 -30
          -240 -4 -3 -10 -50 -14 -105 l-6 -100 48 -19 c26 -11 50 -22 53 -26 3 -3 29
          -18 59 -34 29 -15 63 -36 74 -47 11 -10 26 -19 33 -19 7 0 19 -8 26 -19 7 -10
          27 -25 43 -35 63 -35 118 -83 288 -253 134 -135 156 -158 187 -202 14 -18 36
          -46 50 -61 48 -52 80 -94 80 -107 0 -7 9 -17 20 -23 11 -6 20 -15 20 -21 0 -5
          20 -39 44 -75 25 -35 48 -74 51 -84 4 -11 10 -20 15 -20 4 0 18 -21 31 -47 12
          -27 26 -50 30 -53 4 -3 20 -34 35 -70 16 -36 33 -70 39 -76 6 -6 19 -39 30
          -73 10 -33 22 -61 26 -61 4 0 17 -30 30 -67 12 -38 25 -70 29 -73 6 -5 43
          -119 56 -175 4 -16 11 -32 15 -35 4 -3 18 -44 30 -90 12 -47 25 -87 28 -90 4
          -3 18 -57 31 -120 13 -63 27 -117 30 -120 8 -6 47 -247 56 -350 4 -41 11 -77
          15 -80 15 -10 32 -385 27 -588 l-5 -202 23 0 c13 0 24 -4 24 -9 0 -5 17 -12
          38 -16 82 -14 217 -49 222 -56 3 -4 48 -17 100 -29 52 -13 97 -26 100 -29 3
          -4 46 -18 95 -31 50 -12 92 -26 95 -30 3 -4 52 -19 110 -35 58 -15 110 -31
          115 -35 6 -4 43 -17 84 -29 41 -11 77 -24 80 -29 3 -4 45 -19 94 -32 48 -14
          87 -28 87 -32 0 -8 72 -30 78 -23 5 5 -39 198 -48 210 -5 6 -18 71 -31 145
          -13 74 -26 137 -30 140 -6 5 -42 240 -54 365 -4 33 -10 69 -15 80 -11 25 -37
          319 -46 520 -4 91 -11 153 -18 157 -15 10 -15 1266 0 1276 7 4 14 73 17 167
          11 260 40 710 47 715 3 3 12 75 18 160 21 256 45 485 52 490 4 3 18 98 31 213
          19 157 22 207 13 207 -7 0 -19 6 -26 13 -7 8 -51 24 -98 36 -47 13 -87 26 -90
          30 -5 7 -115 43 -172 57 -16 3 -28 10 -28 15 0 5 -6 9 -13 9 -19 0 -151 42
          -157 50 -3 3 -43 17 -90 30 -47 13 -87 27 -90 31 -3 3 -50 19 -105 34 -55 15
          -102 30 -105 34 -3 4 -43 18 -90 31 -47 12 -87 26 -90 30 -3 4 -45 18 -95 30
          -49 13 -92 27 -95 31 -3 4 -55 20 -115 36 -61 15 -112 31 -115 35 -3 4 -46 17
          -95 29 -50 12 -92 26 -95 30 -6 8 -208 59 -234 59 -10 0 -15 4 -12 8 3 5 -42
          19 -99 32 -58 13 -105 27 -105 31 0 4 -12 9 -27 12 -83 14 -189 40 -193 47 -5
          7 -152 40 -178 40 -7 0 -12 -11 -12 -25z m542 -749 c20 -8 38 -19 40 -23 1 -5
          20 -21 41 -36 20 -15 37 -33 37 -39 0 -6 7 -19 15 -28 8 -9 21 -43 28 -75 8
          -36 20 -63 32 -69 20 -12 59 -43 83 -66 12 -12 14 -33 8 -125 -3 -60 -10 -111
          -16 -113 -5 -2 -10 -10 -10 -18 0 -15 -36 -20 -42 -5 -1 6 -24 16 -51 23 -46
          12 -48 11 -72 -14 -44 -48 -149 -63 -255 -38 -28 7 -54 19 -57 26 -3 8 -10 14
          -17 14 -36 0 -129 137 -141 209 -5 34 -12 41 -47 54 -39 16 -40 17 -34 54 15
          93 30 151 38 147 4 -3 8 2 8 11 0 9 7 29 15 46 15 28 18 29 65 23 42 -5 51 -4
          56 11 4 9 25 26 48 37 33 15 56 18 116 14 41 -2 91 -11 112 -20z"/>
          <!-- tay cam kh can to  -->
          <path id="7" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M5161 9273 c-19 -8 -61 -45 -61 -54 0 -4 11 -10 25 -13 14 -4 25 -11
          25 -16 0 -6 6 -10 14 -10 19 0 118 -42 133 -56 7 -7 37 -20 68 -29 30 -10 55
          -21 55 -25 0 -6 44 -30 55 -30 3 0 5 9 5 20 0 11 -4 20 -9 20 -5 0 -12 14 -16
          30 -9 39 -74 106 -122 126 -21 9 -41 20 -44 26 -7 11 -108 20 -128 11z"/>
          <!-- tay cam kh can to  -->
          <path id="8" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4989 9131 c-7 -23 -16 -41 -21 -41 -4 0 -8 -13 -8 -29 0 -25 6 -31
          37 -42 21 -6 43 -17 50 -24 6 -7 36 -20 65 -30 29 -9 55 -20 58 -25 3 -4 34
          -17 70 -29 36 -13 67 -26 70 -30 3 -4 43 -21 90 -36 47 -16 87 -33 90 -36 3
          -4 17 -10 31 -14 23 -6 27 -3 33 26 12 62 7 100 -13 106 -11 3 -22 11 -25 18
          -3 8 -30 21 -60 31 -31 10 -56 21 -56 25 0 6 -81 41 -127 54 -13 3 -23 10 -23
          15 0 5 -29 18 -65 30 -36 12 -65 25 -65 29 0 4 -19 12 -42 18 -24 7 -51 15
          -60 18 -13 5 -19 -2 -29 -34z"/>
          <!-- tay cam kh can to  -->
          
          <path id="9" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M5050 8873 c18 -62 30 -82 59 -99 16 -10 28 -21 25 -25 -8 -13 77
          -33 146 -33 60 -1 150 19 150 33 0 3 -37 17 -83 30 -45 14 -84 28 -87 32 -3 4
          -41 19 -84 34 -43 15 -85 33 -93 41 -25 25 -43 18 -33 -13z"/>
          <!-- cua truoc -->
          <path id="10" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M7580 9053 c-7 -65 -16 -120 -20 -123 -6 -5 -42 -325 -57 -505 -3
          -44 -9 -82 -13 -85 -4 -3 -18 -135 -31 -295 -12 -159 -25 -292 -29 -295 -8 -6
          -38 -477 -47 -732 -3 -104 -10 -188 -15 -188 -4 0 -8 -209 -8 -465 0 -256 4
          -465 8 -465 5 0 12 -71 15 -157 8 -194 37 -526 47 -533 4 -3 10 -32 13 -65 18
          -164 42 -313 49 -309 4 3 8 -5 8 -17 0 -33 52 -303 60 -309 3 -3 14 -42 24
          -87 l19 -81 78 -22 c44 -13 79 -26 79 -30 0 -4 32 -16 70 -25 39 -10 76 -23
          83 -29 7 -6 56 -24 110 -40 53 -16 97 -33 97 -37 0 -5 6 -9 13 -9 19 0 151
          -42 157 -50 6 -7 139 -50 157 -50 6 0 18 -6 25 -13 7 -6 47 -23 88 -36 41 -13
          80 -27 86 -32 6 -5 42 -18 80 -30 38 -12 71 -25 74 -29 3 -4 46 -20 95 -36 50
          -15 92 -31 95 -34 3 -4 34 -17 70 -30 36 -13 67 -26 70 -30 3 -4 40 -18 83
          -31 42 -13 77 -27 77 -30 0 -4 37 -20 83 -35 45 -15 84 -30 87 -34 3 -3 34
          -17 70 -29 36 -13 67 -27 70 -31 3 -3 39 -19 80 -34 41 -15 77 -31 80 -35 3
          -4 34 -18 69 -30 36 -13 71 -29 78 -36 7 -8 36 -21 65 -31 29 -9 55 -20 58
          -24 3 -4 32 -20 65 -35 33 -16 62 -31 65 -35 7 -9 46 -30 57 -30 4 0 7 125 5
          277 -2 153 1 391 7 528 18 416 34 680 41 685 3 3 12 91 18 195 20 319 35 500
          42 505 4 3 14 95 23 205 33 389 41 465 49 465 4 0 8 15 8 33 0 55 43 432 50
          437 3 3 17 93 29 200 13 107 26 197 30 200 3 3 19 93 35 200 15 107 31 197 35
          200 4 3 17 64 30 135 13 72 27 132 32 135 4 3 11 28 14 56 l7 51 -56 23 c-31
          13 -56 27 -56 31 0 3 -27 17 -60 29 -33 12 -60 26 -60 31 0 4 -10 11 -22 14
          -43 12 -108 40 -108 46 0 3 -33 19 -72 35 -40 15 -76 32 -79 37 -3 4 -31 18
          -62 31 -31 13 -54 26 -50 29 3 4 0 7 -8 7 -15 0 -141 51 -149 60 -3 4 -32 17
          -65 30 -33 13 -65 29 -72 36 -6 6 -35 19 -64 28 -28 10 -58 23 -65 31 -8 7
          -43 23 -79 35 -36 12 -65 25 -65 28 0 7 -63 34 -107 47 -13 3 -23 10 -23 15 0
          5 -29 18 -65 30 -36 12 -65 25 -65 29 0 4 -17 13 -37 20 -73 24 -123 46 -123
          52 0 4 -31 17 -70 30 -38 12 -70 26 -70 30 0 4 -34 19 -75 34 -41 15 -75 31
          -75 35 0 3 -25 14 -55 24 -30 9 -60 22 -67 29 -7 7 -46 24 -85 37 -40 14 -73
          28 -73 31 0 4 -35 20 -77 35 -43 15 -80 31 -83 35 -3 3 -36 18 -75 32 -38 14
          -65 26 -60 27 6 0 -24 13 -65 27 -41 14 -77 29 -80 33 -3 4 -41 19 -85 35 -44
          16 -82 32 -85 35 -3 4 -36 17 -75 30 -38 13 -72 26 -75 30 -7 9 -99 40 -120
          40 -14 0 -19 -20 -30 -117z m508 -819 c7 -8 20 -14 28 -14 16 0 75 -28 84 -39
          3 -4 22 -20 43 -35 20 -16 37 -34 37 -40 0 -7 9 -22 20 -33 19 -21 37 -79 42
          -138 2 -24 11 -34 41 -48 20 -9 37 -22 37 -27 0 -6 5 -10 12 -10 6 0 20 -7 30
          -17 15 -13 18 -31 18 -102 0 -83 -15 -220 -25 -230 -8 -7 -115 29 -115 38 0 5
          -11 11 -25 14 -18 3 -29 -1 -36 -14 -10 -20 -79 -59 -103 -59 -8 0 -16 -5 -18
          -12 -5 -15 -176 -17 -186 -2 -4 6 -29 18 -55 28 -27 10 -60 29 -74 42 -14 13
          -30 24 -34 24 -5 0 -15 13 -24 30 -8 16 -24 38 -35 48 -17 16 -50 113 -50 152
          0 8 -22 25 -52 37 l-51 23 6 56 c3 30 10 61 15 67 5 7 17 44 27 82 10 39 25
          80 34 93 15 21 21 22 76 17 59 -6 61 -5 83 24 12 17 29 31 37 31 7 0 20 6 28
          14 27 28 157 28 185 0z"/>
          <path id="11" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M7943 8200 c-52 -21 -81 -80 -39 -80 8 0 16 -3 18 -7 4 -11 75 -43
          93 -43 7 0 20 -6 27 -13 16 -15 134 -67 153 -67 7 0 15 -4 17 -9 4 -11 100
          -53 106 -46 3 3 0 17 -8 33 -53 112 -81 145 -158 183 -26 13 -50 28 -53 33 -7
          9 -81 25 -114 25 -11 0 -30 -4 -42 -9z"/>
          <path id="12" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M7727 8013 c-27 -93 -25 -97 43 -124 33 -13 60 -27 60 -30 0 -3 35
          -19 78 -34 42 -16 79 -31 82 -35 3 -4 37 -17 75 -30 39 -13 72 -26 75 -30 3
          -4 34 -17 70 -29 36 -13 67 -26 70 -29 3 -4 29 -16 59 -26 45 -16 55 -17 62
          -5 13 21 11 133 -3 151 -7 8 -38 25 -68 38 -30 12 -57 25 -60 29 -8 10 -80 41
          -95 41 -8 0 -18 6 -22 13 -4 7 -35 22 -67 32 -33 11 -65 25 -71 32 -7 6 -48
          23 -91 38 -43 15 -81 31 -84 36 -4 5 -26 12 -51 15 l-46 6 -16 -59z"/>
          <path id="13" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M7800 7735 c0 -14 4 -25 9 -25 5 0 12 -11 15 -25 15 -58 122 -135
          187 -135 11 0 19 -4 19 -10 0 -6 28 -10 65 -10 37 0 65 4 65 10 0 6 7 10 15
          10 20 0 65 22 65 32 0 4 -7 8 -15 8 -8 0 -15 4 -15 9 0 5 -10 11 -22 14 -58
          13 -162 47 -167 55 -3 4 -39 18 -78 32 -40 13 -73 27 -73 31 0 6 -53 29 -66
          29 -2 0 -4 -11 -4 -25z"/>
          <path id="14" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M18948 4696 c-26 -13 -45 -25 -42 -28 3 -3 -16 -14 -41 -24 -26 -9
          -52 -22 -59 -28 -6 -7 -42 -25 -81 -41 -38 -16 -72 -32 -75 -36 -3 -4 -32 -18
          -65 -30 -33 -12 -62 -25 -65 -29 -3 -4 -39 -17 -80 -30 -41 -13 -77 -26 -80
          -31 -6 -8 -180 -59 -204 -59 -8 0 -16 -4 -18 -10 -1 -5 -48 -19 -103 -31 -55
          -12 -102 -25 -105 -28 -5 -8 -236 -51 -269 -51 -12 0 -21 -4 -21 -8 0 -14
          -422 -62 -541 -62 -33 0 -59 -4 -59 -10 0 -6 -52 -10 -135 -10 -74 0 -135 4
          -135 9 0 4 -37 11 -82 15 -115 9 -372 47 -378 55 -3 4 -61 18 -130 31 -69 13
          -127 26 -130 30 -3 4 -54 17 -115 30 -60 12 -112 26 -115 30 -3 4 -58 20 -122
          35 -65 15 -118 31 -118 35 0 8 -80 23 -87 16 -3 -2 12 -19 33 -38 20 -18 51
          -48 67 -65 17 -18 37 -33 44 -33 8 0 12 -4 9 -8 -3 -5 13 -19 34 -33 22 -13
          40 -27 40 -31 0 -4 18 -17 39 -27 21 -11 45 -26 52 -34 8 -7 37 -24 64 -37 28
          -13 52 -26 55 -30 7 -8 56 -29 110 -47 25 -9 47 -19 50 -23 6 -8 137 -50 157
          -50 7 0 13 -4 13 -8 0 -9 174 -52 209 -52 10 0 21 -4 23 -9 2 -6 57 -18 123
          -28 173 -25 571 -25 750 1 72 10 132 21 135 25 3 4 60 17 127 30 67 13 123 26
          125 30 2 3 48 17 103 30 55 13 102 27 105 31 3 3 50 19 105 34 55 16 102 32
          105 36 3 4 39 17 80 29 41 13 77 26 80 30 3 4 41 20 85 35 44 16 82 31 85 35
          3 4 32 17 65 30 48 18 68 33 100 73 22 28 61 73 86 99 25 27 44 51 42 52 -1 2
          8 14 22 27 14 13 25 27 25 30 0 7 67 82 136 153 32 32 32 33 -28 3z"/>
          <path id="15" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M15550 4084 c0 -8 8 -17 63 -76 19 -21 32 -39 30 -42 -2 -2 11 -14
          29 -27 18 -13 44 -36 56 -51 13 -16 30 -28 37 -28 7 0 20 -11 29 -25 9 -14 23
          -25 31 -25 7 0 20 -8 27 -18 7 -10 30 -28 51 -41 20 -12 37 -26 37 -30 0 -4
          27 -21 59 -36 33 -16 58 -32 56 -36 -2 -3 20 -16 50 -28 30 -13 55 -27 55 -31
          0 -5 32 -21 70 -36 39 -15 70 -30 70 -34 0 -3 30 -17 68 -30 37 -13 69 -26 72
          -30 8 -11 135 -40 203 -47 34 -3 67 -10 73 -15 31 -24 647 -25 663 -1 3 6 29
          13 56 17 67 10 169 36 175 45 3 5 35 18 72 30 37 12 68 25 70 29 2 4 28 18 58
          32 30 13 63 32 73 42 10 10 24 18 32 18 7 0 33 15 57 33 24 18 56 40 71 50 16
          10 37 26 47 35 11 9 36 31 56 47 87 71 284 262 284 276 0 9 -51 -8 -60 -20 -3
          -4 -32 -18 -65 -31 -33 -13 -62 -27 -65 -30 -3 -4 -32 -17 -65 -31 -33 -13
          -62 -27 -65 -30 -3 -3 -42 -19 -87 -34 -46 -15 -83 -31 -83 -34 0 -3 -38 -17
          -85 -31 -47 -14 -85 -28 -85 -31 0 -4 -44 -18 -97 -30 -54 -13 -101 -27 -104
          -32 -7 -10 -199 -44 -302 -53 -37 -4 -70 -11 -73 -15 -3 -5 -84 -9 -180 -9
          -96 0 -173 4 -170 9 3 4 -29 11 -72 15 -102 8 -336 46 -342 55 -3 4 -50 18
          -105 31 -55 12 -102 26 -105 30 -3 4 -41 18 -85 30 -44 13 -82 27 -85 31 -3 4
          -41 20 -85 34 -44 15 -82 30 -85 34 -3 4 -32 18 -65 31 -33 13 -66 30 -73 37
          -8 8 -30 20 -50 29 -20 8 -39 18 -42 23 -8 13 -100 64 -100 55z"/>
          <path id="16" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M20665 5138 c-11 -6 -22 -14 -25 -18 -3 -4 -26 -15 -53 -26 -26 -10
          -58 -28 -71 -40 -14 -11 -42 -29 -63 -40 -21 -10 -44 -27 -51 -36 -7 -10 -19
          -18 -26 -18 -7 0 -24 -12 -37 -27 -13 -16 -33 -33 -43 -39 -36 -19 -39 -37
          -16 -86 27 -58 44 -66 66 -30 9 16 32 39 52 52 21 14 39 28 42 31 15 19 62 32
          110 32 59 0 130 -18 130 -34 0 -5 6 -9 13 -9 8 0 22 -9 32 -20 10 -11 23 -19
          29 -17 12 4 8 131 -5 135 -5 2 -9 13 -9 25 0 12 -5 57 -12 100 -12 81 -20 89
          -63 65z"/>
          <path id="17" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M20520 4812 c-56 -26 -101 -71 -122 -119 -12 -29 -26 -53 -32 -53 -8
          0 -8 -2 0 -8 5 -4 21 -35 33 -69 13 -34 26 -63 30 -65 4 -2 20 -33 36 -70 15
          -38 34 -68 41 -68 19 0 112 51 145 79 17 14 51 46 76 71 l45 45 -5 69 c-4 38
          -10 72 -15 75 -5 3 -17 21 -26 39 -9 18 -28 37 -40 42 -13 5 -26 14 -28 21 -5
          14 -114 23 -138 11z"/>
          <path id="18" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M14730 3015 c0 -113 2 -135 15 -135 8 0 15 5 15 10 0 15 79 50 113
          50 16 0 26 4 22 10 -4 6 12 10 39 10 25 0 46 -4 46 -10 0 -5 8 -10 18 -10 27
          0 92 -25 113 -44 11 -9 23 -16 27 -16 15 -1 68 -57 86 -92 l20 -36 16 21 c9
          12 25 47 36 77 10 30 22 57 26 60 6 5 48 159 48 177 0 4 -78 8 -173 9 -171 1
          -408 27 -415 45 -2 5 -15 9 -28 9 l-24 0 0 -135z"/>
          <path id="19" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M14851 2838 c-62 -14 -121 -75 -121 -126 -1 -20 -3 -74 -6 -119 l-6
          -83 30 0 c17 0 32 -5 34 -11 2 -7 64 -13 181 -17 l176 -5 11 28 c34 89 19 189
          -37 254 -20 22 -42 41 -50 41 -7 0 -16 6 -19 14 -6 15 -87 37 -127 35 -12 -1
          -42 -6 -66 -11z"/>
          <path id="20" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12320 11470 c-526 -17 -1135 -43 -1140 -50 -3 -3 -117 -12 -255 -18
          -395 -19 -645 -35 -639 -41 3 -3 -55 -11 -130 -17 -74 -5 -138 -13 -142 -16
          -3 -4 -1 -13 5 -20 26 -29 83 -123 103 -169 11 -27 24 -49 28 -49 4 0 18 -25
          30 -55 12 -30 25 -53 29 -51 4 3 17 -22 30 -55 12 -32 27 -59 32 -59 5 0 9 -6
          9 -14 0 -20 41 -116 50 -116 4 0 10 -10 13 -22 9 -39 49 -143 56 -148 5 -3 18
          -34 30 -70 13 -36 26 -67 30 -70 4 -3 18 -39 31 -80 13 -41 27 -77 31 -80 4
          -3 19 -45 35 -95 15 -49 33 -99 39 -110 7 -11 21 -50 30 -87 10 -38 21 -68 25
          -68 3 0 17 -40 29 -90 13 -49 27 -90 30 -90 4 0 20 -53 36 -117 16 -65 32
          -119 36 -121 4 -2 17 -47 30 -100 12 -53 26 -99 30 -102 3 -3 17 -50 29 -105
          13 -55 26 -102 30 -105 3 -3 19 -68 35 -145 16 -77 32 -142 35 -145 4 -3 17
          -61 30 -130 13 -69 26 -127 30 -130 7 -5 50 -262 50 -298 0 -12 4 -22 8 -22 5
          0 16 -64 26 -142 9 -79 19 -163 22 -186 l5 -44 87 7 c48 4 89 11 90 16 2 5 13
          9 25 9 43 0 392 44 397 50 5 7 308 50 349 50 13 0 21 3 17 7 -4 4 66 18 156
          31 92 13 167 28 173 35 6 7 26 24 45 37 19 13 38 28 41 32 7 11 68 46 92 54 9
          4 17 11 17 16 0 5 22 18 50 30 27 11 47 24 44 29 -3 5 1 9 10 9 18 0 109 38
          116 49 3 4 42 21 88 37 45 16 82 32 82 35 0 4 33 17 73 30 39 12 74 25 77 30
          3 4 41 18 85 30 44 12 82 25 85 29 5 7 187 60 208 60 6 0 12 4 12 9 0 5 39 18
          88 30 48 12 101 28 117 35 17 8 77 25 135 37 58 13 107 27 110 31 3 4 50 17
          105 29 55 11 102 24 105 28 3 4 57 18 120 31 63 13 117 27 120 30 3 4 70 19
          150 35 80 15 150 31 155 35 10 8 169 40 198 40 34 0 16 -18 -25 -24 -23 -4
          -45 -10 -48 -14 -3 -4 -58 -20 -122 -36 -64 -15 -118 -31 -120 -35 -2 -3 -43
          -17 -93 -30 -49 -12 -92 -26 -95 -30 -3 -4 -45 -18 -95 -31 -49 -12 -92 -27
          -95 -31 -3 -4 -53 -20 -110 -35 -58 -15 -107 -31 -110 -35 -3 -4 -46 -18 -95
          -31 -50 -13 -92 -26 -95 -29 -3 -4 -24 -12 -47 -18 l-43 -12 0 -59 c0 -65 -8
          -75 -65 -81 -30 -4 -30 -4 -33 54 -3 49 -6 57 -22 57 -11 0 -20 -3 -20 -8 0
          -4 -39 -18 -87 -31 -49 -13 -90 -27 -93 -31 -3 -3 -39 -17 -80 -29 -41 -13
          -77 -27 -80 -31 -3 -4 -45 -19 -95 -35 -49 -15 -92 -31 -95 -35 -3 -4 -36 -17
          -75 -30 -38 -13 -72 -26 -75 -30 -3 -4 -34 -17 -70 -29 -36 -13 -67 -26 -70
          -30 -3 -3 -41 -19 -85 -34 -44 -15 -81 -32 -83 -37 -2 -6 -10 -10 -17 -10 -15
          0 -104 -39 -90 -40 25 -1 301 44 305 49 3 4 86 20 185 35 99 16 182 32 185 36
          3 3 64 17 135 29 72 13 132 27 135 30 3 4 57 18 120 31 63 13 117 26 120 30 3
          4 59 20 125 34 66 15 122 31 125 35 3 4 51 18 107 31 57 13 101 26 98 28 -3 3
          37 16 88 29 50 13 94 28 95 34 2 5 10 9 18 9 21 0 198 53 204 61 3 4 36 16 75
          28 38 12 72 25 75 30 3 4 43 21 90 37 47 16 90 34 97 40 6 6 36 19 67 28 31
          10 56 21 56 25 0 4 29 18 65 30 36 13 65 27 65 31 0 5 23 16 51 25 66 22 100
          40 92 48 -3 4 1 7 10 7 10 0 26 5 37 10 44 22 65 33 106 56 24 13 50 24 58 24
          8 0 19 6 23 13 4 7 32 23 62 37 30 14 59 30 65 35 6 6 35 21 64 34 28 14 52
          28 52 33 0 5 15 15 34 23 19 8 40 20 48 28 7 7 34 23 61 36 26 12 50 29 55 36
          4 7 21 20 39 29 18 9 36 22 40 29 4 6 27 25 50 40 23 16 44 35 47 43 7 17 -12
          60 -39 90 -11 12 -31 37 -45 55 -32 42 -71 86 -142 161 -32 33 -58 63 -58 67
          0 8 -590 590 -675 666 -37 33 -80 72 -95 88 -16 15 -31 27 -34 27 -9 0 -62 38
          -94 68 -14 12 -31 22 -38 22 -7 0 -17 6 -21 13 -4 8 -21 22 -38 32 -16 10 -35
          23 -40 29 -14 15 -65 45 -97 57 -16 6 -28 15 -28 20 0 5 -18 18 -39 29 -22 10
          -41 23 -43 28 -1 4 -29 21 -60 36 -32 16 -58 32 -58 36 0 4 -22 17 -50 30 -27
          13 -50 26 -50 30 0 4 -22 17 -50 29 -27 12 -50 27 -50 32 0 5 -5 9 -11 9 -13
          0 -122 52 -129 62 -3 4 -27 16 -53 27 -26 11 -47 24 -47 29 0 4 -29 19 -64 32
          -35 14 -62 28 -60 32 3 5 -1 8 -8 8 -14 0 -140 51 -148 60 -6 7 -46 24 -90 41
          -19 7 -37 16 -40 19 -3 4 -36 17 -75 30 -38 13 -72 26 -75 29 -3 4 -25 13 -50
          20 -25 7 -58 18 -75 24 -37 14 -722 18 -1075 7z m1133 -342 c42 -6 77 -15 77
          -20 0 -4 8 -8 17 -8 31 0 208 -54 221 -67 7 -7 18 -13 24 -13 13 0 82 -32 88
          -41 3 -4 26 -17 52 -28 25 -10 47 -23 49 -28 2 -4 25 -18 50 -30 25 -13 54
          -32 64 -43 10 -11 23 -20 29 -20 6 0 22 -10 36 -23 14 -13 43 -35 65 -50 77
          -52 237 -199 248 -230 11 -30 -13 -67 -45 -67 -15 0 -28 5 -28 10 0 6 -21 20
          -47 33 -27 12 -52 27 -58 34 -5 6 -35 21 -65 34 -30 13 -57 26 -60 30 -3 3
          -36 17 -75 30 -38 12 -71 27 -73 31 -2 4 -10 8 -18 8 -29 0 -12 -16 41 -39 30
          -14 55 -28 55 -32 0 -4 12 -14 28 -21 54 -28 77 -43 90 -60 7 -10 19 -18 25
          -18 7 0 24 -12 39 -27 15 -16 52 -50 82 -78 31 -27 56 -55 56 -61 0 -6 11 -19
          25 -28 14 -9 23 -20 21 -25 -3 -4 7 -21 22 -37 16 -16 34 -45 42 -64 8 -19 22
          -46 32 -60 18 -26 58 -166 58 -206 0 -13 5 -26 10 -29 12 -7 14 -175 2 -175
          -4 0 -13 -35 -19 -77 -7 -43 -15 -91 -18 -108 -5 -20 -4 -26 3 -16 18 25 293
          70 348 57 21 -6 24 -9 14 -21 -7 -8 -18 -15 -26 -15 -7 0 -17 -6 -21 -13 -4
          -8 -30 -25 -57 -38 -28 -13 -61 -34 -74 -46 -14 -13 -31 -23 -38 -23 -7 0 -17
          -6 -21 -13 -4 -7 -29 -25 -55 -40 -26 -15 -60 -39 -74 -52 -15 -14 -33 -25
          -40 -25 -7 0 -17 -6 -21 -14 -7 -12 -78 -66 -87 -66 -2 0 -23 -19 -48 -42
          -104 -100 -135 -128 -141 -128 -4 0 -38 -31 -77 -70 -76 -76 -111 -88 -144
          -51 -17 19 -17 22 -1 73 10 29 24 57 31 61 8 4 14 16 14 26 0 10 7 24 15 31 8
          7 15 16 15 21 0 5 9 18 20 29 11 11 20 26 20 34 0 9 -9 14 -20 13 -11 -1 -20
          -5 -20 -9 0 -4 -45 -8 -100 -8 -55 0 -100 4 -100 9 0 4 -21 11 -47 15 -62 8
          -193 47 -193 57 0 4 -22 15 -50 24 -27 9 -53 22 -57 28 -5 7 -31 25 -60 40
          l-52 27 -6 -55 c-4 -30 -11 -55 -16 -55 -5 0 -9 -7 -9 -15 0 -47 -45 -128
          -102 -185 -34 -33 -65 -60 -70 -60 -5 0 -21 -11 -35 -24 l-26 -24 36 -7 c21
          -4 37 -11 37 -15 0 -4 28 -15 61 -25 34 -11 67 -24 73 -30 6 -6 32 -21 59 -34
          46 -23 63 -53 34 -64 -18 -7 -261 15 -267 24 -3 5 -135 13 -292 20 -223 9
          -349 9 -565 0 -153 -7 -280 -16 -283 -20 -3 -4 -41 -11 -85 -15 -44 -3 -122
          -13 -174 -20 -103 -16 -119 -13 -130 18 -13 33 -1 54 35 66 19 7 34 16 34 20
          0 5 30 19 67 30 37 11 70 24 73 29 6 9 209 51 250 51 16 0 30 5 30 10 0 6 18
          10 41 10 50 0 45 10 -20 35 -28 11 -51 23 -51 27 0 4 -18 17 -39 28 -22 10
          -41 23 -43 28 -1 4 -20 18 -40 31 -21 12 -38 27 -38 32 0 5 -6 9 -12 9 -15 0
          -195 173 -228 220 -12 17 -33 43 -46 57 -13 14 -24 31 -24 37 0 6 -12 22 -25
          35 -14 13 -25 29 -25 35 0 7 -8 21 -18 32 -21 22 -58 97 -67 137 -4 15 -10 27
          -14 27 -9 0 -37 88 -47 145 -4 22 -12 43 -18 47 -17 11 -15 340 2 346 6 2 12
          12 12 22 0 10 11 50 24 89 17 50 20 71 12 71 -25 0 -131 -24 -136 -30 -10 -13
          -177 -30 -294 -30 -119 0 -250 16 -242 29 3 4 -16 13 -42 20 -61 16 -114 72
          -110 116 3 28 7 31 56 39 28 5 52 13 52 18 0 4 9 8 21 8 37 0 284 43 289 50 3
          4 69 20 148 35 78 15 142 31 142 35 0 4 19 10 43 14 64 10 181 39 187 46 3 3
          55 19 115 35 61 16 112 31 115 35 3 3 44 17 92 30 48 12 85 27 82 31 -3 5 2 9
          10 9 20 0 149 42 156 50 15 19 51 16 75 -6 23 -21 35 -74 17 -74 -4 0 -18 -20
          -30 -45 -12 -25 -26 -45 -32 -45 -5 0 -10 -5 -10 -11 0 -15 -99 -107 -127
          -119 -38 -16 -26 -27 36 -33 79 -9 215 -37 221 -46 3 -5 37 -18 75 -31 39 -13
          72 -26 75 -30 3 -4 30 -18 60 -30 30 -13 64 -31 75 -40 11 -9 37 -25 57 -35
          21 -11 35 -21 33 -24 -3 -2 6 -10 20 -18 14 -7 36 -19 50 -26 14 -8 23 -16 20
          -18 -2 -3 13 -16 33 -29 20 -14 69 -56 107 -94 l70 -70 7 105 c5 83 11 114 32
          156 14 29 26 57 26 63 0 6 11 20 25 32 14 12 25 27 25 33 0 6 14 19 30 28 17
          10 30 23 30 28 0 5 20 18 45 29 25 11 45 23 45 27 0 11 118 41 192 49 43 5 54
          9 45 18 -13 12 -154 56 -181 56 -9 0 -16 4 -16 8 0 10 -132 34 -300 57 -179
          24 -205 33 -213 74 -10 50 15 75 100 101 43 13 80 28 83 31 5 7 90 23 170 33
          50 6 191 -2 283 -16z"/>
          <path id="21" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M13465 10660 c-3 -5 -16 -10 -29 -10 -36 0 -150 -40 -159 -57 -5 -8
          -19 -18 -32 -23 -13 -5 -31 -25 -40 -44 -10 -20 -21 -36 -25 -36 -27 0 -39
          -174 -18 -258 17 -67 32 -80 54 -43 9 16 32 36 50 44 49 24 151 39 208 32 63
          -8 209 -46 216 -55 3 -4 26 -18 53 -30 26 -12 47 -26 47 -30 0 -4 10 -10 23
          -14 33 -8 133 -108 159 -158 12 -24 25 -45 29 -48 14 -11 39 -85 45 -136 4
          -30 11 -51 15 -48 5 3 9 -9 9 -25 0 -17 -4 -31 -10 -31 -5 0 -10 -13 -10 -29
          0 -67 -69 -163 -137 -190 -18 -8 -33 -17 -33 -21 0 -13 -159 -32 -214 -26
          l-51 6 35 -17 c19 -10 37 -21 40 -25 5 -7 71 -32 130 -50 19 -6 37 -14 40 -18
          6 -8 101 -27 187 -36 68 -7 216 11 236 30 14 12 47 32 90 54 15 7 27 17 27 20
          0 4 10 18 23 32 32 35 57 77 57 94 0 8 7 22 16 30 26 27 47 169 41 286 -5 105
          -24 210 -38 210 -3 0 -17 26 -30 58 -12 31 -26 59 -30 62 -4 3 -14 18 -22 33
          -29 56 -203 247 -225 247 -6 0 -17 9 -24 19 -8 10 -31 29 -53 42 -22 13 -42
          27 -45 31 -3 4 -33 19 -65 34 -33 14 -62 29 -65 33 -8 11 -110 39 -167 47 -29
          4 -53 10 -53 15 0 13 -247 11 -255 -1z"/>
          <path id="22" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M11843 10617 c-24 -5 -43 -14 -43 -18 0 -5 -6 -9 -13 -9 -38 0 -167
          -98 -167 -127 0 -7 -6 -16 -13 -20 -23 -14 -37 -87 -37 -191 0 -96 15 -201 29
          -212 4 -3 12 -21 18 -40 l10 -35 12 42 c6 23 15 43 20 45 4 2 14 16 22 33 8
          16 23 34 35 40 11 5 28 18 37 27 17 17 98 38 147 38 47 0 185 -29 188 -40 2
          -5 11 -10 21 -10 19 0 207 -94 231 -116 90 -79 120 -110 120 -121 0 -7 8 -17
          19 -22 10 -6 26 -27 36 -48 10 -21 21 -40 25 -43 17 -11 52 -151 57 -228 7
          -99 -10 -181 -44 -218 -13 -14 -23 -29 -23 -33 0 -4 -17 -16 -37 -27 -21 -10
          -43 -24 -50 -31 -21 -19 -151 -36 -222 -29 -65 7 -66 6 -41 -11 14 -9 27 -20
          30 -23 3 -4 28 -17 55 -30 28 -13 55 -29 60 -35 6 -7 33 -20 60 -30 28 -10 52
          -21 55 -25 8 -10 162 -49 225 -56 30 -4 55 -11 55 -15 0 -5 50 -9 110 -9 67 0
          110 4 110 10 0 6 11 10 24 10 35 0 180 44 201 61 44 35 115 111 115 124 0 7 8
          21 18 31 14 15 17 37 17 151 0 84 -4 136 -11 141 -6 4 -21 46 -34 94 -12 48
          -27 85 -31 82 -5 -3 -9 1 -9 9 0 22 -42 117 -51 117 -5 0 -9 6 -9 14 0 7 -8
          24 -19 37 -10 13 -19 29 -20 34 0 6 -9 19 -18 30 -22 24 -41 54 -49 77 -3 10
          -9 16 -13 14 -4 -3 -18 12 -31 32 -14 20 -37 47 -52 60 -16 13 -28 30 -28 38
          0 8 -6 14 -14 14 -8 0 -25 13 -38 29 -13 15 -38 39 -57 52 -18 13 -48 37 -65
          52 -48 44 -59 53 -91 72 -16 10 -35 23 -40 29 -6 6 -33 22 -60 36 -28 14 -53
          30 -57 36 -4 6 -29 19 -55 29 -26 10 -54 24 -60 31 -7 7 -45 22 -85 35 -40 12
          -75 25 -78 29 -18 26 -308 39 -397 17z"/>
          <path id="22" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M13380 10150 c0 -5 -9 -10 -19 -10 -11 0 -32 -11 -47 -25 -32 -30
          -45 -103 -22 -126 8 -8 17 -22 21 -31 6 -16 63 -38 101 -38 10 0 36 5 56 11
          85 26 95 171 14 201 -19 6 -34 16 -34 20 0 4 -16 8 -35 8 -19 0 -35 -4 -35
          -10z"/>
          <path id="22" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M11830 9982 c-84 -38 -107 -107 -69 -204 16 -40 82 -62 161 -55 65 6
          61 4 118 74 12 14 14 113 3 113 -5 0 -17 13 -28 30 -11 16 -33 34 -49 40 -32
          11 -113 12 -136 2z"/>
          <path id="25" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M2589 9818 c-7 -40 -16 -75 -19 -78 -4 -3 -18 -75 -31 -160 -12 -85
          -26 -157 -30 -160 -5 -3 -11 -36 -15 -73 -6 -61 -5 -67 12 -67 11 0 31 -6 46
          -14 15 -8 53 -20 85 -26 115 -22 208 -44 213 -50 3 -4 57 -17 120 -30 63 -12
          117 -26 120 -30 3 -4 53 -18 113 -30 59 -12 107 -26 107 -30 0 -4 17 -11 38
          -15 20 -3 47 -9 59 -12 20 -4 21 0 28 94 4 54 11 100 15 103 4 3 9 61 11 130
          4 124 4 125 -19 128 -12 2 -20 7 -18 11 3 4 -31 20 -75 35 -43 16 -79 32 -79
          36 0 5 -23 16 -50 25 -28 9 -56 22 -63 29 -7 7 -39 23 -72 35 -33 12 -62 26
          -65 30 -3 3 -36 20 -75 36 -38 16 -72 32 -75 35 -3 3 -32 17 -65 30 -33 13
          -62 27 -65 31 -5 6 -124 59 -134 59 -2 0 -10 -33 -17 -72z"/>
          <path id="71" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M16184 9317 c-34 -36 -164 -133 -164 -123 0 2 -14 -9 -31 -25 -17
          -16 -37 -29 -45 -29 -8 0 -14 -4 -14 -10 0 -5 -18 -19 -40 -30 -22 -11 -40
          -24 -40 -28 0 -4 -27 -20 -60 -37 -33 -16 -60 -32 -60 -35 0 -4 -25 -17 -55
          -31 -30 -13 -55 -28 -55 -32 0 -4 -21 -17 -47 -28 -26 -11 -50 -24 -53 -28 -3
          -4 -36 -20 -75 -36 -38 -16 -75 -35 -82 -41 -7 -6 -35 -20 -63 -30 -27 -10
          -50 -21 -50 -25 0 -3 -28 -17 -62 -29 -35 -12 -65 -26 -68 -30 -3 -4 -41 -19
          -85 -35 -44 -15 -82 -31 -85 -35 -3 -4 -36 -17 -75 -30 -38 -13 -72 -26 -75
          -30 -3 -4 -48 -20 -100 -35 -52 -16 -97 -32 -100 -36 -3 -3 -41 -17 -85 -29
          -44 -13 -82 -27 -85 -31 -3 -4 -45 -17 -95 -29 -49 -13 -92 -26 -95 -30 -3 -4
          -54 -20 -115 -36 -60 -15 -112 -31 -115 -35 -3 -4 -52 -17 -110 -30 -58 -13
          -107 -27 -110 -31 -5 -7 -107 -32 -182 -45 -16 -3 -28 -8 -28 -12 0 -4 -66
          -20 -147 -36 -82 -16 -150 -33 -153 -36 -3 -4 -64 -17 -137 -30 -72 -13 -130
          -26 -128 -30 2 -3 -78 -18 -178 -34 -100 -16 -184 -32 -187 -35 -3 -4 -81 -17
          -175 -30 -93 -13 -172 -26 -175 -30 -3 -3 -70 -14 -150 -24 -80 -10 -147 -20
          -149 -22 -2 -2 73 -4 167 -4 95 0 172 -4 172 -8 0 -5 44 -12 98 -15 128 -8
          435 -47 442 -56 3 -4 68 -18 145 -30 77 -13 142 -26 145 -31 3 -4 59 -17 125
          -29 66 -12 122 -26 125 -30 3 -4 66 -20 140 -36 74 -16 137 -32 140 -36 3 -4
          48 -17 100 -29 52 -13 97 -26 100 -30 3 -4 57 -20 120 -35 63 -15 117 -31 120
          -35 3 -4 43 -18 90 -30 47 -13 87 -26 90 -30 3 -4 39 -17 80 -30 41 -13 77
          -26 80 -30 6 -7 139 -50 157 -50 6 0 18 -6 25 -13 7 -7 48 -24 91 -37 42 -13
          77 -27 77 -30 0 -4 34 -17 75 -30 41 -13 72 -26 70 -30 -2 -4 36 -19 85 -35
          50 -16 90 -32 90 -36 0 -3 30 -17 68 -29 37 -12 69 -26 72 -31 3 -5 42 -21 85
          -36 44 -15 86 -33 92 -40 7 -6 36 -19 65 -29 29 -9 59 -23 66 -31 7 -7 20 -13
          27 -13 18 0 86 -31 103 -47 7 -7 19 -13 26 -13 16 0 109 -40 116 -50 3 -4 29
          -15 58 -24 29 -10 58 -23 65 -30 6 -6 40 -22 75 -35 34 -13 62 -26 62 -30 0
          -3 21 -14 48 -24 63 -25 96 -41 102 -49 3 -3 30 -16 59 -28 30 -11 67 -30 82
          -41 35 -24 54 -24 79 1 12 12 40 30 63 42 23 12 44 25 47 29 3 4 34 19 70 34
          36 15 67 30 70 34 3 3 40 17 82 31 43 14 78 28 78 31 0 3 38 16 85 29 47 13
          85 26 85 30 0 4 62 20 138 35 75 16 139 32 142 36 3 4 66 17 140 30 74 13 137
          26 140 30 3 3 88 16 190 29 102 12 194 26 205 30 28 12 288 30 434 30 67 0
          121 3 121 6 0 12 -45 110 -55 120 -6 7 -20 34 -30 60 -10 27 -21 51 -25 54 -4
          3 -20 32 -35 65 -16 33 -32 62 -36 65 -5 3 -15 22 -23 42 -9 20 -21 42 -28 50
          -8 7 -22 33 -32 58 -11 25 -25 50 -31 55 -7 6 -23 34 -36 63 -13 28 -27 52
          -31 52 -4 0 -20 26 -34 58 -15 31 -33 62 -40 67 -8 6 -20 27 -29 48 -8 20 -19
          37 -23 37 -4 0 -18 21 -32 48 -14 26 -31 53 -38 61 -7 7 -22 31 -33 52 -10 21
          -23 39 -27 39 -4 0 -13 12 -19 28 -15 33 -31 56 -60 90 -13 14 -23 31 -23 38
          0 7 -6 17 -13 21 -7 4 -25 27 -40 51 -15 23 -32 42 -39 42 -6 0 -9 3 -5 6 7 8
          -39 72 -70 97 -13 10 -23 23 -23 29 0 5 -14 24 -31 41 -17 18 -90 94 -164 170
          -73 75 -137 137 -142 137 -5 0 -24 15 -41 33 -46 47 -82 77 -91 77 -4 0 -16 9
          -26 20 -10 11 -24 20 -32 20 -7 0 -13 4 -13 8 0 5 -26 22 -57 38 -32 16 -62
          34 -68 41 -5 7 -27 19 -47 28 -21 8 -38 19 -38 23 0 5 -29 19 -65 31 -36 13
          -65 26 -65 30 0 4 -44 21 -97 36 -54 16 -100 32 -103 36 -6 8 -203 49 -239 49
          -15 0 -31 4 -36 9 -6 5 -35 11 -65 14 -48 4 -58 2 -76 -16z"/>
          <path id="26" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M2468 9119 c-18 -114 -14 -129 38 -129 44 0 288 -41 294 -50 3 -4 65
          -18 138 -30 72 -13 132 -27 132 -31 0 -5 20 -9 45 -9 25 0 45 4 45 8 0 5 26
          19 58 32 31 13 62 29 69 36 7 6 35 20 63 30 27 10 50 21 50 26 0 8 -38 20
          -152 44 -46 10 -85 21 -88 24 -3 4 -57 18 -120 30 -63 13 -117 26 -120 30 -3
          3 -61 17 -130 30 -69 14 -127 27 -130 30 -6 7 -127 30 -157 30 -16 0 -21 -13
          -35 -101z"/>
          <path id="27" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M17400 8970 c14 -11 28 -20 33 -20 4 0 6 -3 5 -8 -2 -4 35 -47 82
          -95 105 -107 150 -158 150 -168 0 -4 14 -19 31 -33 17 -14 28 -26 24 -26 -8 0
          46 -73 62 -83 7 -4 20 -22 29 -40 9 -18 25 -41 36 -52 28 -30 148 -195 148
          -204 0 -4 10 -19 23 -33 26 -30 56 -76 64 -100 3 -10 9 -18 14 -18 5 0 18 -18
          29 -40 11 -22 24 -40 28 -40 5 0 22 -27 38 -60 16 -33 32 -60 36 -60 4 0 17
          -20 29 -45 12 -25 25 -45 29 -45 4 0 17 -22 30 -50 13 -27 27 -50 31 -50 5 0
          17 -20 28 -45 11 -25 24 -45 28 -45 4 0 17 -21 27 -47 11 -25 27 -54 36 -63 8
          -8 26 -40 39 -70 13 -30 29 -58 35 -62 6 -4 11 -11 12 -15 2 -15 36 -88 44
          -93 4 -3 20 -34 35 -69 14 -35 32 -70 39 -76 7 -7 21 -38 30 -69 14 -43 22
          -56 37 -56 33 0 159 31 159 39 0 4 26 15 58 25 31 10 64 24 72 31 17 15 126
          65 140 65 6 0 10 4 10 9 0 5 20 19 45 31 25 12 45 25 45 29 0 5 18 16 40 26
          23 10 53 29 68 42 15 13 32 23 38 23 6 0 22 10 36 23 13 12 45 32 71 45 26 13
          47 27 47 32 0 4 20 17 45 29 25 12 45 25 45 30 0 5 24 21 53 35 28 14 72 39
          97 55 25 16 51 30 58 30 6 1 12 5 12 10 0 5 32 22 70 37 39 15 70 30 70 34 0
          4 34 18 75 31 41 13 75 29 75 35 0 6 -16 19 -35 28 -19 9 -35 20 -35 24 0 4
          -26 21 -58 37 -31 17 -59 33 -60 38 -2 4 -23 17 -45 29 -23 11 -44 24 -47 28
          -3 3 -25 17 -50 31 -25 13 -50 29 -56 34 -6 6 -35 21 -65 35 -30 14 -56 28
          -59 32 -3 4 -27 17 -53 28 -26 12 -47 24 -47 28 0 5 -22 16 -49 26 -26 9 -57
          25 -67 34 -11 10 -45 28 -76 41 -32 13 -58 26 -58 30 0 3 -27 17 -60 29 -33
          13 -60 27 -60 31 0 4 -24 15 -52 25 -29 11 -57 23 -62 27 -6 5 -18 9 -28 9
          -10 0 -18 4 -18 9 0 5 -15 14 -32 22 -18 7 -70 30 -114 51 -45 21 -87 38 -93
          38 -6 0 -11 3 -11 8 0 4 -36 20 -80 36 -44 15 -80 31 -80 35 0 3 -33 17 -72
          31 -40 13 -75 27 -78 31 -3 4 -41 19 -85 35 -44 15 -82 31 -85 35 -3 3 -39 17
          -80 30 -41 13 -77 27 -80 31 -3 4 -37 17 -75 29 -39 12 -72 25 -75 29 -3 4
          -43 17 -90 29 -47 13 -94 28 -105 35 -11 7 -58 23 -105 35 -47 13 -89 27 -95
          31 -10 8 -187 50 -208 50 -7 0 -1 -9 13 -20z"/>
          <path id="28" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M2410 8776 c-13 -99 -26 -183 -30 -186 -6 -4 -30 -195 -30 -237 0
          -15 40 -4 47 14 3 8 23 23 44 34 22 11 39 23 39 27 0 5 22 19 49 33 27 13 48
          27 46 30 -2 3 21 18 51 34 29 16 54 32 54 36 0 4 18 16 41 28 22 12 50 30 62
          42 12 11 37 27 55 36 17 8 32 19 32 23 0 4 18 17 41 29 22 12 50 30 61 41 12
          11 25 20 29 20 13 0 80 58 72 63 -5 3 -45 11 -91 18 -45 7 -82 16 -82 19 0 4
          -78 18 -174 31 -96 13 -172 27 -170 31 3 5 -16 8 -43 9 -26 0 -55 2 -64 4 -14
          2 -20 -25 -39 -179z"/>
          <path id="29" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M3855 7938 c-38 -6 -72 -13 -75 -16 -3 -4 -38 -18 -77 -31 -40 -13
          -73 -27 -73 -31 0 -3 -22 -16 -49 -28 -27 -13 -64 -36 -82 -53 -75 -68 -159
          -153 -159 -161 0 -5 -4 -6 -10 -3 -5 3 -10 0 -10 -8 0 -8 -9 -22 -20 -32 -11
          -10 -20 -23 -20 -29 0 -6 -12 -22 -25 -35 -14 -13 -25 -29 -25 -35 0 -7 -8
          -21 -18 -32 -10 -10 -29 -45 -42 -76 -13 -32 -27 -58 -31 -58 -4 0 -17 -30
          -30 -67 -12 -38 -25 -70 -30 -73 -8 -5 -59 -245 -59 -278 0 -11 -4 -22 -9 -24
          -15 -5 -42 -322 -48 -555 -4 -152 -10 -223 -17 -223 -6 0 -5 -4 3 -9 9 -6 10
          -11 2 -15 -13 -8 -16 -206 -3 -206 5 0 12 -125 15 -277 8 -351 7 -344 63 -372
          25 -11 51 -21 59 -21 8 0 15 -4 15 -9 0 -11 82 -36 149 -47 l44 -7 -6 34 c-3
          19 -9 116 -13 217 -4 100 -11 182 -15 182 -5 0 -9 169 -9 375 0 206 4 375 8
          375 4 0 13 71 20 158 14 181 38 345 52 350 6 2 10 11 10 21 0 30 41 176 51
          179 5 2 9 10 9 17 0 16 38 107 48 115 4 3 18 29 32 59 14 30 30 59 35 65 6 6
          21 25 33 43 60 86 141 164 199 193 24 12 43 25 43 29 0 4 28 16 63 27 85 26
          267 26 352 0 33 -10 62 -22 65 -25 3 -4 35 -20 73 -36 37 -15 67 -31 67 -35 0
          -4 18 -15 41 -25 22 -10 45 -27 50 -37 5 -10 15 -18 22 -18 7 0 32 -18 57 -40
          25 -22 48 -40 52 -40 9 0 156 -149 151 -154 -2 -2 11 -17 29 -33 35 -31 52
          -54 67 -90 6 -12 16 -25 24 -30 8 -4 24 -27 36 -50 12 -24 25 -43 29 -43 4 0
          18 -25 31 -55 14 -30 28 -55 31 -55 4 0 20 -31 35 -70 15 -38 31 -70 34 -70 4
          0 17 -33 31 -72 13 -40 27 -75 31 -78 4 -3 17 -39 30 -80 12 -41 25 -77 29
          -80 5 -3 18 -52 31 -110 13 -58 26 -107 30 -110 9 -7 47 -251 55 -355 4 -49
          12 -93 18 -97 17 -11 15 -760 -1 -766 -8 -2 -13 -21 -13 -45 0 -23 -9 -121
          -20 -217 -11 -96 -16 -178 -12 -182 4 -4 46 -15 92 -25 47 -9 87 -19 90 -23 8
          -10 121 -32 128 -25 23 23 5 955 -19 963 -5 2 -9 22 -9 44 0 63 -40 411 -49
          433 -5 11 -21 99 -36 195 -15 96 -31 177 -35 180 -4 3 -17 52 -30 110 -12 58
          -25 107 -29 110 -4 3 -17 43 -31 90 -13 47 -26 87 -30 90 -4 3 -19 44 -35 93
          -16 48 -32 87 -36 87 -4 0 -17 28 -30 63 -12 34 -26 64 -30 67 -3 3 -17 25
          -29 50 -12 25 -26 47 -29 50 -3 3 -17 29 -30 58 -14 28 -28 52 -32 52 -3 0
          -19 25 -34 55 -16 30 -32 55 -36 55 -4 0 -18 18 -30 40 -13 22 -26 40 -31 40
          -5 0 -13 11 -18 24 -5 14 -21 32 -35 42 -14 9 -25 22 -25 29 0 6 -23 34 -51
          61 -28 27 -80 79 -117 117 -37 37 -71 67 -77 67 -6 0 -16 9 -23 20 -7 11 -19
          20 -27 20 -8 0 -22 11 -31 25 -9 14 -21 25 -27 25 -5 0 -24 10 -41 23 -42 32
          -210 113 -266 129 -25 6 -47 15 -50 20 -7 9 -168 28 -220 27 -19 -1 -66 -6
          -105 -11z"/>
          <path id="30" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M11590 7760 l-35 -6 30 -9 c17 -4 32 -11 35 -15 3 -4 37 -17 75 -30
          39 -13 72 -26 75 -29 6 -8 68 -34 123 -52 20 -7 37 -16 37 -20 0 -3 28 -17 63
          -29 34 -12 64 -26 67 -30 3 -4 34 -20 70 -35 36 -15 67 -30 70 -34 3 -4 31
          -18 63 -30 31 -13 57 -27 57 -31 0 -4 27 -18 60 -30 33 -12 60 -26 60 -30 0
          -4 32 -20 70 -35 39 -15 70 -31 70 -35 0 -4 24 -17 53 -31 28 -13 54 -27 57
          -30 3 -4 30 -17 60 -30 30 -13 60 -29 65 -35 6 -7 34 -22 63 -34 28 -13 52
          -26 52 -29 0 -4 23 -18 50 -30 28 -12 50 -26 50 -30 0 -3 29 -20 65 -36 36
          -16 65 -32 65 -36 0 -4 21 -17 48 -30 26 -12 49 -26 52 -29 8 -11 94 -54 103
          -52 4 1 7 -2 7 -8 0 -5 22 -21 49 -35 27 -13 51 -28 53 -32 1 -4 24 -18 50
          -31 26 -13 50 -27 53 -33 4 -5 25 -19 48 -30 23 -12 44 -25 47 -29 3 -4 28
          -19 55 -35 28 -15 54 -33 58 -41 4 -8 14 -14 21 -14 7 0 24 -10 37 -22 14 -13
          46 -34 72 -48 26 -14 47 -28 47 -32 0 -4 14 -15 31 -23 16 -9 38 -24 48 -33
          21 -20 78 -62 85 -62 5 0 41 -32 107 -95 30 -29 44 -35 80 -35 67 0 503 43
          509 50 3 4 64 12 135 19 323 29 470 45 475 51 3 4 113 18 245 30 132 13 242
          27 245 31 4 5 307 39 353 39 9 0 17 5 17 10 0 6 -7 10 -15 10 -8 0 -15 3 -15
          8 0 4 -29 18 -65 31 -36 14 -65 28 -65 32 0 4 -25 15 -56 25 -31 9 -61 22 -67
          29 -7 6 -47 24 -89 39 -43 16 -78 32 -78 35 0 4 -30 18 -67 30 -38 13 -70 26
          -73 30 -7 10 -110 51 -126 51 -8 0 -14 4 -14 10 0 5 -15 13 -32 19 -59 18
          -123 42 -128 49 -3 4 -36 18 -75 31 -38 14 -72 27 -75 31 -3 3 -34 17 -70 30
          -36 13 -67 27 -70 30 -3 4 -43 20 -90 35 -47 15 -87 31 -90 35 -3 4 -36 17
          -75 30 -38 13 -72 26 -75 30 -3 4 -45 20 -95 35 -49 16 -92 31 -95 35 -3 4
          -39 18 -80 31 -41 13 -77 26 -80 30 -3 3 -39 16 -80 29 -41 13 -77 26 -80 29
          -3 4 -48 20 -100 36 -52 15 -97 31 -100 35 -3 3 -43 16 -90 29 -47 13 -89 26
          -95 31 -5 4 -50 17 -100 30 -49 12 -92 25 -95 29 -6 8 -246 61 -272 61 -10 0
          -18 4 -18 9 0 5 -55 19 -122 32 -68 12 -125 26 -128 30 -3 4 -84 20 -180 35
          -96 16 -177 31 -180 35 -5 6 -299 49 -337 49 -13 0 -23 4 -23 8 0 27 -727 55
          -840 32z"/>
          <path id="31" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M20170 7627 c-36 -11 -67 -23 -70 -26 -8 -9 -114 -51 -128 -51 -6 0
          -12 -4 -12 -9 0 -6 -27 -20 -59 -32 -33 -12 -68 -30 -77 -40 -9 -11 -22 -19
          -29 -19 -14 0 -77 -30 -85 -40 -3 -4 -27 -17 -55 -31 -27 -13 -55 -29 -62 -35
          -7 -7 -34 -22 -60 -35 -26 -13 -55 -29 -63 -37 -8 -8 -29 -21 -47 -29 -18 -7
          -33 -17 -33 -21 0 -4 -25 -19 -55 -32 -30 -13 -55 -28 -55 -32 0 -4 -18 -17
          -39 -27 -21 -11 -45 -26 -52 -34 -8 -7 -36 -24 -64 -37 -27 -13 -54 -29 -60
          -36 -5 -6 -38 -22 -72 -35 -35 -13 -63 -27 -63 -31 0 -4 -20 -10 -45 -14 -25
          -3 -45 -10 -45 -15 0 -5 5 -9 10 -9 6 0 34 -24 63 -52 29 -29 62 -58 72 -64
          11 -6 24 -20 30 -32 5 -12 17 -24 25 -27 8 -3 24 -18 35 -35 11 -16 25 -30 32
          -30 6 0 20 -11 30 -25 18 -25 24 -26 178 -37 266 -17 715 3 715 33 0 5 7 9 16
          9 24 0 158 41 164 50 3 4 38 17 78 29 39 12 72 26 72 31 0 6 6 10 13 10 17 0
          139 48 146 58 3 4 29 18 56 32 79 39 105 67 99 104 -10 64 -48 206 -55 206 -3
          0 -17 27 -29 60 -12 33 -26 60 -30 60 -5 0 -16 20 -26 45 -9 25 -22 49 -28 53
          -6 4 -20 24 -31 46 -11 21 -32 50 -47 65 -16 14 -28 30 -28 35 0 11 -73 81
          -99 95 -28 15 -56 13 -131 -12z"/>
          <path id="32" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M3953 7483 c-18 -2 -33 -8 -33 -14 0 -5 -8 -9 -17 -9 -23 0 -113 -34
          -121 -46 -4 -5 -25 -18 -47 -29 -35 -18 -84 -62 -151 -137 -12 -12 -29 -39
          -39 -58 -10 -19 -21 -37 -25 -40 -4 -3 -20 -39 -36 -80 -16 -41 -33 -80 -39
          -86 -6 -6 -19 -47 -29 -90 -10 -43 -22 -81 -26 -84 -9 -6 -50 -223 -50 -262 0
          -14 -4 -28 -9 -30 -17 -6 -31 -270 -31 -567 0 -310 17 -674 31 -679 5 -2 9
          -33 9 -68 0 -58 2 -64 21 -64 28 0 90 30 109 53 13 16 14 31 3 110 -6 51 -12
          109 -12 129 -1 20 -7 38 -13 40 -17 6 -19 785 -3 796 6 4 19 66 29 138 18 129
          43 258 45 239 1 -5 13 30 25 79 13 49 28 91 33 95 6 3 18 30 27 59 10 29 23
          58 29 65 7 6 22 32 34 57 20 41 94 134 133 166 74 62 103 83 131 94 19 8 39
          19 46 26 33 32 278 51 421 32 l53 -7 -21 26 c-11 15 -35 35 -53 46 -18 11 -34
          23 -37 27 -9 12 -63 37 -100 45 -19 4 -38 12 -42 18 -6 11 -189 18 -245 10z"/>
          <path id="33" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4089 7252 c-36 -10 -73 -25 -83 -34 -9 -9 -26 -20 -39 -23 -12 -4
          -31 -15 -42 -25 -108 -99 -135 -126 -135 -138 0 -7 -7 -15 -15 -19 -9 -3 -25
          -24 -37 -47 -11 -22 -26 -45 -33 -51 -7 -5 -23 -37 -35 -70 -13 -33 -27 -62
          -30 -65 -9 -6 -50 -134 -50 -154 0 -8 -4 -16 -9 -18 -6 -1 -19 -52 -31 -113
          -12 -60 -26 -119 -31 -130 -16 -36 -29 -237 -29 -466 0 -221 16 -489 29 -499
          4 -3 17 -72 30 -155 12 -82 26 -152 30 -155 4 -3 18 -52 30 -110 13 -58 26
          -107 30 -110 4 -3 20 -50 36 -105 16 -55 32 -102 36 -105 4 -3 17 -34 30 -70
          12 -36 25 -67 29 -70 4 -3 18 -30 31 -60 12 -30 26 -57 30 -60 3 -3 19 -29 34
          -59 15 -30 32 -60 39 -67 6 -6 29 -33 51 -60 22 -27 51 -58 63 -69 13 -11 37
          -32 52 -46 16 -15 47 -35 69 -45 22 -9 38 -20 36 -24 -2 -4 22 -17 53 -30 31
          -13 60 -27 63 -32 10 -15 142 -38 221 -38 85 0 172 15 191 34 6 6 35 21 64 33
          29 12 58 26 65 32 55 45 78 62 78 57 0 -12 127 123 156 166 16 24 35 49 41 56
          7 7 20 30 30 50 9 20 23 42 29 47 7 6 20 34 29 63 10 28 21 52 24 52 4 0 18
          44 32 98 13 53 27 99 31 102 13 10 28 213 28 386 0 213 -18 518 -31 522 -5 2
          -9 20 -9 39 0 49 -41 327 -49 333 -4 3 -17 57 -29 120 -12 63 -26 116 -32 118
          -5 2 -10 11 -10 19 0 22 -41 157 -49 163 -4 3 -21 43 -37 90 -16 47 -34 90
          -40 96 -6 7 -19 34 -29 60 -10 27 -21 51 -25 54 -4 3 -18 25 -30 50 -12 25
          -26 47 -30 50 -4 3 -19 29 -34 58 -14 28 -30 52 -35 52 -6 0 -12 9 -16 19 -5
          17 -139 157 -194 204 -12 10 -43 28 -69 40 -26 12 -49 25 -52 28 -3 3 -27 14
          -55 24 -75 26 -279 31 -366 7z m261 -222 c0 -5 7 -10 16 -10 31 0 126 -32 143
          -49 9 -9 30 -26 48 -39 45 -32 102 -91 132 -137 14 -22 33 -48 41 -57 9 -9 22
          -34 29 -55 8 -21 19 -42 26 -48 6 -5 20 -32 30 -60 10 -27 22 -52 26 -55 4 -3
          20 -55 36 -115 15 -61 31 -112 34 -115 8 -6 49 -239 49 -276 0 -13 4 -24 8
          -24 13 0 33 -228 39 -445 6 -202 -18 -587 -37 -593 -6 -2 -10 -15 -10 -29 0
          -42 -41 -228 -51 -231 -5 -2 -9 -8 -9 -14 0 -17 -53 -143 -62 -146 -5 -2 -16
          -18 -25 -35 -19 -38 -24 -45 -98 -119 -31 -32 -59 -58 -64 -58 -4 0 -16 -8
          -27 -18 -11 -10 -42 -27 -69 -38 -86 -33 -284 -21 -320 20 -5 6 -26 19 -46 29
          -42 22 -129 99 -129 115 0 6 -10 19 -22 29 -13 10 -33 39 -46 66 -13 26 -27
          47 -31 47 -4 0 -18 26 -31 58 -13 31 -26 59 -30 62 -9 7 -60 155 -60 174 0 9
          -4 16 -9 16 -5 0 -20 44 -32 98 -13 53 -26 99 -30 102 -8 6 -49 235 -49 277 0
          17 -4 33 -9 35 -13 4 -31 241 -31 395 0 146 19 377 31 381 5 2 9 13 9 25 0 41
          39 250 47 256 5 3 17 41 28 85 10 43 25 84 32 92 7 7 13 16 13 20 0 18 57 132
          77 154 13 14 23 28 23 33 0 5 32 39 72 77 61 58 82 71 142 90 39 13 78 26 86
          31 22 11 110 11 110 -1z"/>
          <path id="34" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4218 6978 c-27 -6 -48 -14 -48 -19 0 -5 -15 -15 -34 -23 -18 -8 -47
          -29 -64 -48 -72 -79 -84 -94 -94 -120 -5 -16 -13 -28 -18 -28 -4 0 -15 -20
          -24 -44 -16 -41 -16 -49 -2 -97 9 -29 20 -55 24 -58 4 -3 19 -40 32 -81 13
          -41 28 -77 32 -80 4 -3 20 -46 35 -95 15 -50 30 -92 34 -95 3 -3 16 -36 29
          -75 13 -38 26 -72 30 -75 3 -3 17 -42 30 -87 12 -46 27 -83 31 -83 5 0 9 -9 9
          -20 0 -17 5 -19 33 -13 67 13 77 20 77 57 0 19 4 36 8 38 4 2 18 64 31 138 13
          74 26 137 30 140 4 3 18 50 31 105 12 55 26 102 29 105 4 3 20 59 36 125 15
          66 31 122 34 125 4 3 14 38 24 78 19 83 15 100 -33 129 -17 9 -30 21 -30 25 0
          5 -21 18 -47 31 -27 12 -54 28 -61 35 -7 6 -24 12 -36 12 -13 0 -36 2 -52 4
          -16 2 -50 0 -76 -6z"/>
          <path id="35" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4617 6753 c-3 -10 -9 -40 -13 -68 -4 -27 -10 -52 -14 -55 -4 -3 -17
          -59 -30 -125 -13 -66 -26 -122 -30 -125 -7 -4 -19 -53 -53 -215 -6 -27 -13
          -52 -17 -55 -4 -3 -18 -59 -31 -125 -13 -66 -27 -121 -31 -123 -11 -5 -10 -32
          1 -32 5 0 29 -20 54 -44 l45 -44 12 29 c7 16 15 29 19 29 3 0 17 27 30 60 13
          33 27 60 31 60 4 0 17 25 30 55 13 30 27 55 31 55 4 0 12 12 18 28 12 33 40
          80 64 106 9 11 17 25 17 31 0 6 8 20 18 31 9 11 28 40 41 65 l23 45 -21 74
          c-11 40 -24 76 -29 79 -4 3 -19 34 -31 68 -13 34 -26 63 -30 65 -4 2 -19 27
          -35 57 -15 29 -36 62 -46 73 -17 19 -18 19 -23 1z"/>
          <path id="36" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M3852 6510 c-6 -22 -12 -46 -12 -54 0 -8 -4 -16 -10 -18 -11 -3 -35
          -113 -46 -205 -3 -35 -10 -63 -14 -63 -4 0 -11 -35 -14 -77 -4 -43 -9 -101
          -12 -130 -6 -50 -5 -52 20 -59 15 -3 31 -15 37 -25 5 -11 16 -19 23 -19 7 0
          24 -10 38 -22 26 -24 55 -42 101 -66 15 -7 27 -17 27 -22 0 -5 16 -17 35 -26
          20 -9 40 -25 46 -35 15 -30 46 -24 52 10 4 16 20 45 36 64 26 29 29 38 21 63
          -18 55 -22 64 -30 64 -4 0 -17 28 -30 62 -12 34 -28 68 -35 75 -7 7 -23 42
          -35 78 -12 36 -25 65 -29 65 -5 0 -19 30 -31 68 -12 37 -26 69 -30 72 -4 3
          -15 30 -25 60 -10 30 -26 71 -36 90 -11 19 -19 42 -19 50 -1 37 -16 37 -28 0z"/>
          <path id="37" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4850 6257 c0 -9 -4 -17 -8 -17 -5 0 -19 -26 -32 -57 -13 -32 -27
          -60 -30 -63 -4 -3 -18 -30 -31 -60 -12 -30 -26 -57 -30 -60 -3 -3 -19 -32 -34
          -65 -16 -33 -32 -62 -36 -65 -4 -3 -17 -26 -28 -52 -10 -25 -23 -47 -28 -49
          -4 -2 -17 -21 -27 -42 -18 -35 -18 -41 -5 -85 14 -44 17 -47 50 -46 81 2 285
          28 290 37 4 6 14 7 23 4 13 -5 16 1 16 36 0 75 -23 287 -32 287 -4 0 -8 15 -8
          33 0 40 -37 267 -44 275 -3 3 -6 -2 -6 -11z"/>
          <path id="38" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M3740 5685 c0 -156 18 -355 32 -355 4 0 8 -11 8 -24 0 -33 38 -236
          49 -261 5 -11 12 -29 16 -40 5 -17 10 -13 27 25 11 25 25 47 30 48 4 2 18 23
          30 45 11 23 24 44 28 47 4 3 16 21 26 40 11 20 31 49 47 65 15 16 27 35 27 42
          0 7 11 20 25 29 14 9 23 20 20 24 -3 4 1 13 9 19 10 8 11 23 5 59 -4 26 -10
          73 -14 104 -5 44 -11 58 -26 63 -10 4 -19 10 -19 14 0 4 -24 18 -52 32 -29 13
          -61 32 -70 42 -10 9 -24 17 -31 17 -7 0 -22 8 -33 18 -10 10 -41 31 -69 46
          -27 16 -53 34 -57 40 -4 6 -8 -56 -8 -139z"/>
          <path id="39" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4277 5712 c-25 -11 -57 -62 -57 -94 0 -16 -4 -28 -10 -28 -5 0 -10
          -7 -10 -16 0 -8 4 -13 9 -10 4 3 11 -29 14 -72 7 -93 25 -145 73 -217 34 -53
          38 -55 79 -55 50 0 77 8 69 21 -3 5 2 9 10 9 57 0 78 253 30 354 -44 91 -132
          137 -207 108z"/>
          <path id="40" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4893 5573 c-18 -2 -33 -8 -33 -12 0 -5 -57 -17 -127 -26 l-128 -19
          -3 -76 -3 -77 32 -13 c17 -8 43 -23 58 -35 14 -12 49 -33 78 -46 28 -13 50
          -26 47 -30 -2 -4 4 -10 15 -14 11 -3 36 -17 56 -31 21 -14 39 -23 41 -22 8 9
          16 408 8 406 -5 0 -24 -3 -41 -5z"/>
          <path id="41" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4122 5322 c-5 -16 -16 -35 -24 -43 -8 -8 -27 -41 -42 -74 -15 -33
          -30 -62 -34 -65 -4 -3 -18 -27 -32 -55 -14 -27 -33 -58 -42 -69 -10 -11 -18
          -26 -18 -33 0 -7 -10 -23 -22 -35 -23 -23 -31 -84 -11 -96 5 -4 20 -38 33 -77
          13 -38 26 -72 29 -75 4 -3 15 -25 26 -50 10 -25 27 -53 37 -63 19 -19 16 -25
          49 113 7 30 16 57 20 60 3 3 17 46 29 95 13 50 27 92 31 95 4 3 17 43 29 90
          13 47 26 87 30 90 18 13 19 59 3 76 -18 17 -53 88 -53 106 0 5 -6 16 -14 24
          -12 12 -16 10 -24 -14z"/>
          <path id="42" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4562 5280 c-6 -22 -12 -48 -12 -59 0 -23 31 -124 41 -131 4 -3 17
          -41 29 -85 12 -43 28 -88 35 -100 7 -11 23 -60 36 -110 12 -49 26 -92 30 -95
          3 -3 14 -31 23 -62 21 -74 36 -68 67 25 13 40 27 74 30 77 16 12 59 221 59
          283 0 20 5 37 10 37 6 0 10 15 10 34 0 32 -3 35 -60 56 -33 12 -60 25 -60 29
          0 3 -21 17 -47 31 -27 14 -54 30 -61 37 -6 6 -32 22 -57 34 -25 12 -45 26 -45
          31 0 22 -17 3 -28 -32z"/>
          <path id="43" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4470 5142 c-14 -9 -26 -20 -28 -24 -2 -5 -11 -8 -19 -8 -8 0 -13 -6
          -10 -12 2 -7 13 -71 22 -143 10 -71 22 -132 26 -135 4 -3 17 -97 28 -210 30
          -277 28 -270 51 -270 11 0 20 4 20 8 0 5 19 16 42 27 43 19 108 80 108 103 0
          6 5 12 10 12 15 0 12 37 -4 63 -8 12 -24 58 -36 102 -12 44 -26 82 -30 85 -4
          3 -18 41 -30 85 -12 44 -26 82 -30 85 -4 3 -17 44 -30 93 -13 48 -26 87 -30
          87 -4 0 -11 16 -14 35 -8 39 -11 41 -46 17z"/>
          <path id="44" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M4255 5128 c-2 -7 -11 -49 -20 -93 -10 -44 -21 -82 -25 -85 -4 -3
          -17 -59 -30 -125 -13 -66 -26 -122 -30 -125 -4 -3 -15 -56 -25 -119 -18 -110
          -18 -114 1 -124 10 -6 30 -22 44 -37 14 -14 43 -35 65 -45 22 -11 45 -25 52
          -32 13 -11 98 -33 133 -33 27 0 53 12 42 19 -11 6 -52 329 -52 402 0 21 -4 39
          -10 39 -5 0 -10 21 -11 48 0 26 -6 98 -12 161 l-12 115 -31 8 c-17 4 -37 15
          -44 23 -15 18 -29 19 -35 3z"/>
          <path id="45" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M2189 7234 c-11 -14 -14 -55 -12 -198 1 -116 6 -182 12 -184 6 -2 11
          -20 11 -40 0 -58 41 -376 50 -387 4 -5 10 -32 14 -60 12 -91 49 -280 55 -285
          4 -3 17 -52 30 -110 13 -58 26 -107 31 -110 4 -3 17 -39 29 -80 13 -41 26 -77
          30 -80 3 -3 12 -23 20 -45 17 -51 43 -109 52 -115 3 -3 17 -29 29 -57 12 -29
          25 -53 29 -53 4 0 15 -15 26 -32 52 -90 183 -208 231 -208 7 0 14 -4 16 -10 2
          -5 22 -12 46 -16 l44 -7 -7 144 c-3 79 -8 164 -10 189 -2 24 -7 156 -11 292
          -4 136 -11 248 -16 248 -11 0 -9 398 3 406 5 3 9 47 9 97 0 80 13 268 24 348
          4 24 -2 29 -55 50 -32 13 -59 27 -59 30 0 4 -31 20 -70 35 -38 15 -70 30 -70
          34 0 4 -27 18 -60 30 -33 12 -60 26 -60 30 0 4 -34 20 -75 35 -41 15 -75 31
          -75 35 0 4 -27 18 -60 30 -33 12 -60 26 -60 30 0 4 -10 10 -22 13 -13 2 -30 7
          -39 11 -9 4 -21 0 -30 -10z"/>
          <path id="46" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M18213 6913 c-46 -2 -83 -7 -83 -12 0 -4 -42 -11 -92 -14 -124 -8
          -341 -37 -353 -47 -5 -4 -32 -11 -60 -14 -84 -12 -260 -48 -265 -55 -3 -4 -49
          -17 -102 -30 -54 -12 -98 -26 -98 -30 0 -4 -32 -15 -71 -25 -39 -11 -76 -25
          -83 -31 -7 -7 -28 -18 -47 -23 -71 -21 -139 -49 -133 -55 3 -3 -12 -11 -35
          -18 -22 -7 -48 -19 -58 -28 -10 -9 -36 -26 -58 -36 -22 -11 -49 -28 -60 -37
          -64 -55 -125 -116 -125 -123 0 -5 -11 -20 -24 -34 -13 -15 -35 -47 -49 -73
          -14 -26 -27 -45 -30 -42 -2 3 -14 -20 -27 -51 -12 -30 -25 -55 -29 -55 -3 0
          -17 -27 -29 -60 -13 -33 -27 -60 -30 -60 -4 0 -18 -36 -32 -80 -14 -44 -28
          -80 -32 -80 -3 0 -18 -53 -33 -117 -15 -65 -30 -125 -35 -134 -26 -50 -36
          -305 -15 -362 13 -31 48 -41 170 -45 120 -4 445 22 455 37 3 4 76 18 162 31
          87 13 155 27 152 32 -3 4 3 8 13 8 28 0 267 53 273 60 3 4 49 18 103 31 53 12
          97 27 97 31 0 4 7 8 16 8 22 0 198 51 204 60 3 4 43 17 90 30 47 12 87 26 90
          30 7 9 129 50 153 50 10 0 16 4 13 8 -3 5 35 21 84 37 50 15 90 30 90 34 0 4
          33 17 73 31 39 13 78 30 85 37 7 7 22 13 32 13 10 0 22 5 25 10 3 6 15 10 25
          10 11 0 25 7 32 15 7 8 46 26 86 41 39 15 72 31 72 35 0 5 5 9 11 9 10 0 63
          24 203 91 22 11 46 19 53 19 7 0 13 5 13 10 0 6 21 20 47 31 26 11 50 23 53
          27 3 4 29 18 58 31 28 14 52 27 52 31 0 3 26 19 58 35 31 16 63 37 70 47 7 10
          18 18 24 18 18 0 88 77 88 97 0 20 -66 82 -100 95 -14 5 -20 1 -25 -20 -4 -15
          -20 -39 -38 -54 -17 -16 -27 -28 -22 -28 4 0 -10 -12 -31 -26 -21 -15 -40 -30
          -42 -35 -2 -5 -24 -19 -50 -31 -26 -13 -50 -28 -54 -34 -4 -6 -34 -21 -68 -35
          -33 -13 -60 -27 -60 -30 0 -4 -26 -17 -57 -30 -32 -12 -60 -26 -63 -29 -3 -3
          -34 -17 -70 -30 -36 -13 -67 -27 -70 -31 -3 -3 -43 -19 -90 -34 -47 -16 -87
          -31 -90 -35 -3 -4 -39 -17 -80 -30 -41 -13 -77 -26 -80 -29 -3 -4 -52 -20
          -110 -36 -58 -15 -107 -32 -110 -36 -3 -4 -43 -17 -90 -30 -47 -13 -87 -26
          -90 -30 -3 -5 -46 -18 -95 -30 -50 -13 -92 -26 -95 -30 -3 -4 -55 -19 -115
          -34 -61 -15 -112 -31 -115 -35 -3 -4 -53 -18 -112 -31 -58 -13 -105 -26 -103
          -30 2 -3 -45 -16 -105 -29 -60 -13 -107 -27 -105 -30 2 -3 -60 -19 -138 -35
          -78 -15 -147 -32 -152 -36 -20 -15 -202 -41 -290 -42 -86 0 -165 19 -165 40 0
          5 -12 22 -27 38 -27 29 -28 33 -28 148 0 73 4 120 11 125 6 4 19 40 30 81 10
          41 24 79 30 85 6 6 13 20 17 31 16 52 35 98 48 113 8 10 25 41 38 70 14 28 28
          52 32 52 5 0 15 13 23 28 8 15 30 46 50 68 20 21 36 44 36 50 0 6 20 24 45 40
          25 15 45 31 45 35 0 4 17 18 38 30 20 13 42 29 47 35 14 17 208 114 228 114
          10 0 17 4 17 9 0 5 33 19 73 31 39 12 74 25 77 29 6 8 191 61 214 61 8 0 16 4
          18 10 2 5 53 20 113 32 61 12 112 25 115 29 6 8 264 49 310 49 16 0 30 4 30 9
          0 11 329 31 553 34 l178 2 -38 38 c-84 84 -63 77 -239 75 -87 -1 -196 -4 -241
          -5z"/>
          <path id="47" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M17760 6650 c0 -5 -14 -10 -31 -10 -48 0 -284 -50 -287 -61 -2 -5
          -10 -9 -19 -9 -21 0 -167 -43 -173 -50 -3 -4 -34 -17 -70 -29 -36 -13 -67 -26
          -70 -29 -3 -4 -32 -18 -65 -31 -33 -13 -64 -29 -70 -35 -5 -6 -33 -22 -62 -35
          -29 -13 -54 -30 -57 -37 -3 -8 -12 -14 -20 -14 -8 0 -26 -13 -39 -30 -14 -16
          -30 -30 -35 -30 -16 0 -134 -139 -159 -188 -8 -14 -17 -29 -21 -32 -4 -3 -13
          -15 -19 -27 -32 -57 -39 -68 -50 -80 -6 -7 -19 -34 -29 -60 -10 -27 -22 -51
          -27 -54 -18 -13 -39 -115 -44 -216 -5 -92 -4 -105 16 -137 18 -29 30 -36 68
          -42 46 -7 213 11 223 24 3 4 68 18 145 31 77 13 142 26 145 30 3 4 56 18 118
          31 61 13 112 27 112 31 0 4 53 19 118 35 64 15 119 31 122 35 3 4 48 17 100
          29 52 13 97 26 100 31 3 4 46 17 95 30 50 12 92 25 95 29 3 4 52 19 110 34 58
          15 107 31 108 36 2 6 11 10 20 10 22 0 165 42 172 50 3 3 43 16 90 29 47 12
          103 31 125 42 22 10 66 26 98 34 31 9 57 20 57 24 0 3 36 17 80 30 44 13 80
          28 80 32 0 4 27 18 61 31 65 25 105 65 52 52 -15 -4 -30 -10 -33 -14 -3 -3
          -54 -19 -115 -35 -60 -16 -112 -32 -115 -36 -3 -4 -48 -17 -100 -30 -52 -13
          -97 -27 -100 -31 -3 -3 -46 -17 -95 -29 -50 -12 -92 -26 -95 -30 -3 -4 -59
          -20 -125 -35 -66 -16 -122 -31 -125 -35 -3 -4 -48 -17 -100 -29 -52 -13 -97
          -26 -100 -30 -3 -4 -59 -20 -125 -35 -66 -15 -122 -31 -125 -34 -3 -4 -52 -18
          -110 -31 -58 -12 -107 -26 -110 -30 -3 -3 -32 -12 -65 -19 -106 -22 -160 -35
          -165 -42 -6 -7 -265 -59 -292 -59 -10 0 -18 -4 -18 -8 0 -5 -37 -14 -82 -20
          -101 -15 -121 -15 -113 -2 3 6 19 10 35 10 15 0 30 4 32 8 2 5 66 21 143 37
          77 16 142 32 145 35 3 4 52 18 110 30 58 13 107 27 110 30 3 4 16 9 30 12 14
          3 61 14 105 23 44 10 82 21 85 25 3 5 57 20 120 35 63 14 117 31 118 36 2 5
          10 9 18 9 25 0 199 44 204 51 3 4 55 20 115 34 61 15 112 30 115 34 3 5 49 19
          103 32 53 12 97 26 97 30 0 4 42 17 93 29 50 13 94 26 97 30 3 4 50 20 105 36
          55 15 102 31 103 36 2 4 9 8 15 8 18 0 151 43 157 50 3 4 38 17 78 29 39 13
          72 26 72 30 0 3 21 13 48 20 46 14 112 41 122 51 3 3 26 15 53 27 26 13 47 27
          47 33 0 5 -12 17 -26 26 -24 16 -29 16 -77 -1 -29 -10 -54 -21 -57 -25 -3 -3
          -39 -17 -80 -30 -41 -14 -77 -28 -80 -31 -6 -7 -171 -59 -187 -59 -6 0 -13 -4
          -15 -8 -3 -10 -137 -52 -162 -52 -9 0 -16 -4 -16 -8 0 -5 -37 -19 -82 -31 -46
          -13 -85 -26 -88 -30 -3 -4 -52 -20 -110 -36 -58 -16 -107 -32 -110 -36 -3 -4
          -46 -17 -95 -30 -50 -12 -92 -25 -95 -28 -3 -4 -45 -18 -95 -31 -49 -12 -92
          -26 -95 -30 -3 -4 -59 -20 -125 -35 -66 -16 -122 -32 -125 -36 -3 -4 -48 -17
          -100 -29 -52 -13 -97 -26 -100 -30 -3 -4 -16 -9 -30 -12 -14 -3 -70 -15 -125
          -27 -54 -12 -102 -25 -105 -30 -3 -4 -59 -18 -125 -31 -66 -13 -122 -27 -125
          -30 -4 -6 -259 -50 -289 -50 -6 0 -11 4 -11 8 0 5 32 14 70 21 39 7 70 16 70
          20 0 3 52 17 115 31 63 13 115 28 115 32 0 4 6 8 13 8 25 -1 242 54 245 62 2
          4 11 8 19 8 23 0 197 43 203 50 5 7 101 33 185 50 27 6 52 15 55 19 3 4 48 18
          100 31 52 12 97 26 100 30 3 4 48 17 100 30 52 13 97 26 100 30 6 8 183 60
          205 60 7 0 15 4 17 8 2 5 41 19 88 32 47 12 87 25 90 29 3 4 43 18 90 31 47
          13 87 27 90 31 3 4 46 19 95 34 50 15 92 31 95 35 3 4 38 17 78 29 39 13 72
          26 72 30 0 3 21 13 48 20 26 8 71 25 100 39 l54 24 -32 29 c-30 28 -65 38 -72
          21 -3 -8 -134 -52 -155 -52 -6 0 -13 -4 -15 -10 -1 -5 -43 -21 -92 -36 -49
          -14 -98 -31 -110 -38 -11 -7 -52 -21 -91 -31 -38 -10 -72 -21 -75 -25 -3 -4
          -25 -12 -50 -19 -25 -7 -71 -19 -102 -28 -32 -8 -58 -19 -58 -24 0 -5 -7 -9
          -16 -9 -21 0 -168 -42 -174 -50 -3 -3 -45 -17 -95 -30 -49 -12 -92 -26 -95
          -30 -3 -4 -57 -20 -120 -35 -63 -16 -117 -32 -120 -36 -3 -4 -48 -17 -100 -29
          -52 -13 -97 -26 -100 -30 -3 -4 -50 -18 -105 -30 -55 -13 -102 -27 -105 -31
          -3 -4 -66 -19 -140 -35 -74 -15 -138 -32 -141 -37 -3 -4 -59 -18 -125 -30 -65
          -12 -121 -24 -124 -28 -7 -9 -174 -33 -191 -27 -8 4 -2 9 16 13 17 5 32 11 35
          14 3 4 55 18 115 31 61 13 112 27 115 30 3 4 61 20 130 35 69 15 127 31 130
          34 3 4 50 18 105 31 55 13 102 27 105 31 3 3 48 16 100 29 52 12 97 25 100 29
          3 4 57 20 120 36 63 16 117 32 120 35 3 4 47 17 98 30 50 12 92 25 92 29 0 4
          43 17 95 30 52 13 95 26 95 30 0 3 48 20 108 36 59 16 109 32 112 36 3 4 41
          17 85 29 44 12 82 26 85 30 3 4 50 20 105 36 55 16 102 32 105 35 3 4 34 17
          70 30 36 12 67 25 70 30 3 4 32 16 64 28 58 20 80 37 70 53 -11 17 -118 -1
          -134 -22 -3 -4 -45 -18 -95 -30 -49 -13 -92 -27 -95 -30 -6 -8 -153 -50 -174
          -50 -9 0 -16 -4 -16 -8 0 -5 -53 -21 -117 -37 -65 -16 -120 -32 -123 -36 -3
          -3 -48 -17 -100 -30 -52 -13 -97 -26 -100 -30 -3 -4 -57 -19 -120 -34 -63 -16
          -117 -31 -120 -35 -3 -4 -50 -17 -104 -29 -55 -12 -105 -26 -110 -30 -6 -5
          -56 -19 -111 -31 -55 -13 -102 -26 -105 -30 -3 -4 -63 -20 -135 -35 -71 -14
          -132 -30 -135 -34 -3 -3 -61 -18 -130 -31 -69 -13 -127 -27 -130 -30 -6 -8
          -129 -30 -164 -30 -16 0 -25 4 -21 10 3 6 17 10 31 10 13 0 24 4 24 8 0 5 46
          19 103 32 56 13 104 27 107 30 3 4 37 15 75 24 124 29 160 39 165 46 3 4 48
          17 100 30 52 12 97 25 100 29 3 4 48 18 100 30 52 13 97 26 100 30 3 5 57 21
          120 36 63 15 117 31 120 35 3 4 45 17 95 29 49 13 92 26 95 30 3 4 55 20 115
          35 61 16 112 32 115 35 3 4 46 18 95 31 50 12 92 26 95 30 3 4 43 18 90 30 47
          13 87 26 90 30 3 4 49 20 103 35 53 15 97 32 97 36 0 5 -38 9 -85 9 -47 0 -85
          -4 -85 -9 0 -4 -26 -11 -57 -15 -88 -10 -277 -47 -283 -55 -3 -4 -54 -17 -115
          -30 -60 -12 -112 -26 -115 -30 -5 -7 -61 -22 -158 -42 -28 -6 -50 -14 -48 -17
          2 -4 -52 -20 -120 -36 -68 -16 -124 -32 -124 -36 0 -4 -43 -17 -94 -29 -52
          -13 -96 -26 -98 -30 -2 -5 -52 -21 -113 -36 -60 -15 -112 -31 -115 -34 -3 -4
          -45 -18 -95 -31 -49 -12 -92 -26 -95 -30 -3 -3 -48 -17 -100 -31 -52 -13 -96
          -27 -98 -31 -6 -14 -22 -9 -22 6 0 13 86 46 119 46 6 0 11 4 11 9 0 5 32 17
          72 27 39 10 76 23 82 29 6 6 58 24 116 40 58 15 107 32 110 36 3 4 42 17 88
          29 45 12 82 26 82 31 0 5 6 9 13 9 19 0 191 53 197 61 3 4 43 17 90 29 47 13
          87 26 90 30 3 4 38 17 79 29 41 11 77 24 80 29 3 5 40 18 81 30 l75 20 -46 1
          c-54 1 -292 -38 -299 -48 -3 -5 -48 -18 -100 -31 -52 -13 -97 -26 -100 -30 -3
          -4 -43 -18 -90 -30 -47 -13 -87 -26 -90 -30 -5 -8 -169 -60 -187 -60 -6 0 -13
          -4 -15 -8 -3 -8 -134 -52 -156 -52 -7 0 -12 -4 -12 -9 0 -10 -129 -46 -137
          -38 -3 4 6 11 21 16 14 6 26 14 26 19 0 5 29 19 65 31 36 13 65 26 65 30 0 4
          42 20 93 36 50 16 94 33 97 36 3 4 41 17 85 30 44 13 82 26 85 29 7 10 100 40
          121 40 10 0 24 6 31 13 7 8 27 17 43 20 93 22 195 53 195 59 0 5 -25 8 -55 8
          -30 0 -55 -4 -55 -10z"/>
          <path id="48" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M16270 6343 c-19 -3 -37 -8 -40 -13 -3 -4 -120 -20 -260 -35 -140
          -16 -257 -31 -260 -35 -3 -4 -113 -17 -245 -30 -132 -13 -242 -27 -245 -30 -3
          -4 -120 -17 -260 -30 -140 -13 -257 -26 -260 -30 -5 -6 -377 -41 -595 -56 -49
          -4 -94 -10 -100 -14 -5 -4 -66 -11 -135 -14 -186 -10 -200 -13 -200 -42 0 -13
          4 -24 9 -24 5 0 19 -31 31 -70 12 -38 25 -70 28 -70 4 0 21 -39 37 -87 16 -49
          34 -93 40 -99 6 -6 19 -37 30 -68 11 -31 22 -56 26 -56 4 0 17 -28 30 -62 12
          -35 28 -66 35 -71 7 -4 15 -21 19 -37 4 -16 11 -32 16 -35 5 -4 9 -15 9 -26 0
          -11 6 -22 14 -25 7 -3 24 -28 37 -57 31 -66 60 -97 91 -97 13 0 28 -4 34 -10
          21 -21 338 -33 523 -21 97 7 177 16 179 21 2 6 16 10 30 10 41 0 266 41 272
          50 3 4 46 18 95 30 50 12 92 26 95 30 3 4 43 20 90 35 47 15 87 31 90 35 3 4
          30 18 60 31 30 13 61 29 68 36 8 7 36 23 63 34 27 12 49 24 49 28 0 4 15 15
          33 25 17 11 40 26 49 35 9 9 34 30 54 46 89 70 129 120 149 183 10 34 22 62
          26 62 4 0 17 29 29 65 12 36 25 65 30 65 4 0 20 34 35 75 16 42 32 78 37 81 5
          4 19 27 29 52 11 26 24 49 28 52 4 3 17 26 29 53 12 26 31 56 42 67 11 11 20
          27 20 35 0 15 -15 17 -90 8z"/>
          <path id="49" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12010 5784 c-101 -9 -215 -26 -220 -32 -3 -4 -40 -16 -84 -27 -43
          -10 -84 -24 -90 -30 -6 -6 -31 -19 -56 -30 -25 -10 -54 -26 -65 -34 -11 -9
          -36 -25 -55 -35 -19 -11 -44 -29 -56 -40 -12 -12 -45 -45 -75 -74 -73 -71 -92
          -93 -120 -138 -13 -22 -27 -41 -31 -42 -5 -2 -20 -27 -35 -55 -14 -29 -29 -54
          -32 -57 -4 -3 -18 -32 -31 -65 -13 -33 -27 -62 -31 -65 -4 -3 -17 -39 -30 -80
          -12 -41 -25 -77 -29 -80 -9 -6 -60 -218 -60 -248 0 -12 -4 -22 -8 -22 -11 0
          -28 -110 -43 -280 -7 -74 -17 -138 -23 -142 -17 -11 -15 -860 2 -865 7 -3 12
          -24 12 -56 0 -45 11 -164 36 -394 4 -34 11 -60 15 -57 5 3 9 -5 9 -17 0 -39
          51 -334 59 -339 4 -3 18 -57 31 -120 13 -63 27 -117 31 -120 4 -3 13 -27 20
          -53 7 -28 19 -51 28 -54 9 -2 24 -9 33 -14 24 -12 158 -12 158 1 0 6 7 10 16
          10 13 0 15 7 10 31 -3 17 -6 38 -6 47 0 8 -3 12 -7 8 -7 -7 -52 161 -53 196 0
          9 -4 18 -9 20 -5 2 -19 55 -32 118 -13 63 -26 117 -29 120 -7 6 -45 213 -56
          300 -4 30 -10 57 -14 60 -8 5 -35 200 -46 335 -4 44 -12 86 -18 94 -15 19 -16
          769 0 779 6 4 16 48 23 97 17 127 32 205 41 205 4 0 10 15 14 33 11 56 50 172
          61 183 6 7 20 34 30 60 10 27 25 55 32 62 7 8 21 28 30 45 8 18 22 36 29 40 8
          4 14 13 14 20 0 7 10 23 23 37 12 14 36 42 52 63 37 49 89 101 129 129 17 12
          42 33 55 45 14 12 38 27 53 34 15 6 28 15 28 19 0 5 21 19 48 31 26 13 52 28
          59 35 12 12 136 54 159 54 7 0 14 4 16 10 5 16 175 40 282 40 100 0 280 -23
          300 -39 6 -4 50 -20 99 -35 48 -16 87 -32 87 -36 0 -4 27 -18 60 -31 33 -13
          60 -27 60 -31 0 -4 18 -17 39 -27 21 -11 45 -26 53 -33 19 -18 76 -58 82 -58
          5 0 45 -34 86 -75 14 -14 29 -25 34 -25 15 0 246 -243 281 -296 9 -14 24 -33
          33 -43 27 -28 62 -79 62 -91 0 -5 4 -10 10 -10 5 0 19 -18 30 -40 11 -22 24
          -40 28 -40 5 0 22 -27 38 -60 16 -33 32 -60 36 -60 4 0 16 -20 27 -45 11 -25
          24 -45 28 -45 5 0 17 -23 27 -50 10 -28 24 -56 30 -63 7 -7 25 -43 41 -82 16
          -38 32 -72 35 -75 11 -11 27 -47 41 -92 7 -27 16 -48 20 -48 4 0 17 -33 29
          -72 12 -40 25 -75 30 -78 4 -3 20 -54 36 -115 15 -60 31 -112 34 -115 7 -6 50
          -210 50 -239 0 -11 4 -21 9 -23 11 -4 51 -300 51 -375 0 -29 5 -53 10 -53 6 0
          10 -80 10 -220 0 -140 -4 -220 -10 -220 -5 0 -10 -16 -10 -36 0 -19 -5 -67
          -12 -105 -8 -54 -8 -75 1 -93 14 -26 62 -46 109 -46 25 0 32 4 32 19 0 11 4
          21 9 23 5 2 18 66 28 143 26 198 26 757 0 960 -10 77 -22 149 -27 160 -5 11
          -19 74 -31 140 -12 66 -25 122 -29 125 -3 3 -19 59 -35 125 -16 66 -32 122
          -36 125 -3 3 -17 40 -30 82 -12 43 -25 76 -27 73 -3 -3 -16 28 -30 68 -14 39
          -28 74 -32 77 -4 3 -20 39 -35 80 -16 41 -34 80 -40 86 -6 7 -19 33 -29 58
          -10 25 -21 46 -26 46 -5 0 -16 20 -26 45 -9 25 -22 49 -28 53 -6 4 -24 33 -40
          65 -16 31 -33 57 -37 57 -5 0 -16 16 -25 36 -9 20 -23 38 -31 41 -7 3 -13 9
          -13 14 0 11 -42 72 -52 77 -4 2 -17 20 -30 40 -27 44 -76 98 -228 248 -137
          136 -154 152 -200 183 -19 13 -37 26 -40 30 -7 9 -41 29 -67 41 -13 5 -23 14
          -23 19 0 5 -20 18 -45 30 -25 12 -45 24 -45 29 0 7 -21 17 -100 48 -25 10 -51
          23 -57 30 -7 7 -36 20 -65 30 -29 9 -55 20 -58 25 -6 9 -155 49 -182 49 -10 0
          -18 4 -18 8 0 20 -333 46 -450 36z"/>
          <path id="50" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12150 5345 c-8 -2 -54 -9 -102 -16 -49 -7 -88 -16 -88 -20 0 -4 -31
          -17 -69 -28 -39 -12 -76 -27 -83 -34 -7 -6 -35 -23 -63 -36 -27 -14 -56 -33
          -63 -43 -7 -10 -20 -18 -27 -18 -8 0 -22 -11 -31 -25 -9 -14 -23 -25 -30 -25
          -8 0 -14 -4 -14 -8 0 -5 -23 -33 -51 -63 -74 -79 -84 -91 -99 -121 -7 -16 -17
          -28 -21 -28 -5 0 -21 -26 -36 -57 -15 -32 -32 -64 -39 -70 -7 -7 -20 -35 -29
          -63 -9 -27 -20 -50 -23 -50 -4 0 -18 -35 -32 -78 -14 -42 -28 -79 -31 -82 -8
          -6 -49 -180 -49 -208 0 -11 -4 -22 -10 -24 -5 -1 -17 -73 -26 -158 -12 -121
          -15 -214 -11 -420 5 -252 23 -489 37 -500 3 -3 17 -79 29 -170 13 -91 26 -167
          30 -170 4 -3 18 -66 31 -140 13 -74 26 -137 30 -140 3 -3 17 -60 30 -127 13
          -68 28 -123 32 -123 4 0 8 -7 8 -17 0 -14 2 -14 20 2 11 10 20 24 20 30 0 7
          11 20 25 29 14 9 25 21 25 27 0 16 74 86 99 94 14 4 20 12 17 23 -3 9 -8 53
          -12 97 -3 44 -10 85 -14 90 -4 6 -13 76 -19 156 -20 248 -8 765 19 811 6 10
          10 29 10 42 0 43 52 311 61 314 5 2 9 11 9 20 0 21 42 155 50 162 3 3 17 37
          29 75 13 39 27 72 30 75 4 3 19 34 35 70 15 36 31 67 34 70 4 3 17 26 30 53
          13 26 27 47 32 47 4 0 15 16 25 36 9 19 23 38 31 41 8 3 14 11 14 17 0 7 11
          23 25 36 14 13 25 29 25 37 0 7 4 12 9 11 5 -2 34 20 64 47 79 73 111 97 154
          114 21 8 41 19 44 24 4 5 37 20 73 32 36 12 63 24 60 27 -14 15 146 33 291 33
          158 0 339 -20 329 -37 -3 -4 4 -8 15 -8 12 0 21 3 21 7 0 4 -28 33 -62 66 -90
          84 -94 87 -104 87 -5 0 -22 12 -37 26 -15 14 -49 35 -75 46 -26 12 -50 25 -53
          30 -6 10 -143 58 -165 58 -8 0 -14 4 -14 8 0 20 -314 51 -370 37z"/>
          <path id="51" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12465 4878 c-27 -5 -52 -12 -55 -16 -3 -4 -31 -16 -64 -27 -32 -10
          -64 -24 -71 -31 -6 -6 -29 -21 -51 -32 -21 -11 -50 -30 -64 -41 -47 -40 -110
          -104 -110 -112 0 -4 -12 -21 -27 -37 -47 -49 -83 -96 -83 -107 0 -6 -8 -20
          -18 -31 -10 -10 -29 -45 -42 -76 -13 -31 -27 -55 -32 -52 -4 3 -8 -1 -8 -8 0
          -15 -42 -121 -51 -128 -7 -5 -43 -124 -54 -180 -4 -19 -11 -38 -16 -41 -5 -3
          -19 -66 -31 -140 -13 -74 -26 -136 -29 -139 -14 -10 -29 -257 -29 -471 0 -232
          15 -490 30 -524 4 -11 18 -92 30 -180 12 -88 25 -162 29 -165 4 -3 15 -45 24
          -95 28 -147 40 -200 47 -205 4 -3 18 -45 30 -95 12 -49 26 -92 30 -95 4 -3 17
          -39 29 -80 13 -41 26 -77 30 -80 4 -3 20 -41 36 -85 16 -44 32 -82 35 -85 4
          -3 18 -30 31 -60 12 -30 26 -57 30 -60 3 -3 17 -25 29 -50 12 -25 26 -47 30
          -50 4 -3 19 -29 34 -57 14 -29 31 -53 36 -53 6 0 10 -6 10 -12 0 -7 16 -31 35
          -52 19 -22 35 -42 35 -45 0 -4 18 -26 40 -51 22 -25 46 -52 53 -60 23 -25 108
          -95 128 -104 10 -5 19 -13 19 -17 0 -5 18 -18 39 -28 21 -11 45 -27 53 -34 8
          -8 42 -25 76 -37 34 -13 62 -26 62 -30 0 -19 180 -45 310 -45 131 0 340 28
          340 45 0 4 25 18 55 30 30 12 55 26 55 30 0 4 10 12 23 19 50 27 65 39 127
          105 36 37 78 82 95 100 16 17 40 50 53 74 13 24 32 52 43 63 10 10 19 25 19
          32 0 7 8 22 18 33 10 11 27 41 37 67 10 26 23 51 30 55 6 4 25 41 40 82 16 41
          34 84 40 95 7 11 20 47 30 80 10 33 21 62 25 65 7 5 33 104 46 173 3 20 10 37
          14 37 4 0 12 39 19 87 13 91 9 102 -31 103 -10 0 -18 4 -18 8 0 4 -15 13 -33
          20 -18 7 -43 21 -55 32 -21 20 -22 26 -16 153 4 73 11 134 16 135 12 5 10 412
          -2 412 -6 0 -10 31 -10 73 0 126 -45 497 -60 497 -4 0 -10 21 -14 46 -9 68
          -38 184 -46 184 -4 0 -17 38 -30 85 -13 47 -25 83 -28 80 -3 -3 -18 32 -33 78
          -16 45 -33 83 -39 85 -5 2 -10 9 -10 17 0 16 -40 108 -51 115 -4 3 -15 25 -25
          50 -9 25 -25 54 -35 65 -9 11 -25 40 -35 65 -9 25 -22 50 -28 55 -7 6 -22 30
          -35 55 -13 25 -26 47 -30 50 -4 3 -18 23 -31 45 -13 22 -30 46 -37 53 -7 8
          -18 23 -24 34 -13 24 -71 104 -98 133 -10 11 -26 32 -35 47 -10 15 -39 37 -64
          49 -26 13 -49 26 -52 30 -3 4 -41 17 -85 30 -44 12 -89 25 -100 30 -65 28
          -388 39 -500 17z m388 -378 c26 -7 47 -16 47 -21 0 -5 6 -9 12 -9 20 0 118
          -43 118 -52 0 -4 5 -8 11 -8 58 0 379 -320 379 -378 0 -6 6 -12 14 -15 8 -3
          22 -24 32 -46 10 -23 21 -41 24 -41 4 0 17 -24 31 -52 13 -29 27 -55 30 -58 4
          -3 19 -39 33 -79 14 -41 30 -77 34 -80 9 -6 52 -138 52 -159 0 -7 4 -12 8 -12
          5 0 19 -42 31 -92 13 -51 27 -95 31 -98 7 -5 60 -268 60 -300 0 -11 4 -20 9
          -20 23 0 50 -400 39 -605 -9 -184 -29 -395 -38 -395 -4 0 -10 -24 -14 -52 -9
          -68 -47 -232 -55 -238 -4 -3 -17 -40 -31 -82 -13 -43 -27 -78 -30 -78 -4 0
          -18 -27 -31 -60 -13 -33 -27 -60 -31 -60 -4 0 -19 -20 -33 -44 -14 -24 -32
          -47 -40 -50 -8 -3 -15 -11 -15 -19 0 -14 -57 -69 -100 -95 -14 -9 -32 -23 -41
          -32 -9 -9 -49 -26 -90 -39 -41 -13 -83 -28 -93 -34 -26 -13 -230 -11 -234 3
          -2 5 -11 10 -20 10 -25 0 -182 50 -182 58 0 4 -23 18 -52 32 -29 14 -63 35
          -76 47 -14 13 -30 23 -37 23 -7 0 -20 11 -29 25 -9 14 -22 25 -29 25 -13 0
          -32 17 -119 106 -32 33 -71 73 -88 89 -16 17 -30 36 -30 43 0 7 -7 15 -15 19
          -9 3 -25 23 -36 44 -11 22 -23 39 -28 39 -5 0 -11 8 -14 18 -7 21 -39 75 -50
          83 -4 3 -20 35 -36 72 -15 37 -30 65 -32 63 -3 -3 -15 25 -28 62 -13 37 -27
          69 -31 72 -3 3 -17 43 -30 90 -13 47 -27 87 -31 90 -4 3 -19 57 -34 120 -14
          63 -30 124 -35 135 -13 27 -50 239 -50 286 0 20 -4 39 -10 41 -19 7 -32 348
          -20 535 6 97 15 182 19 188 5 5 11 35 15 65 12 97 40 230 48 230 4 0 8 8 8 17
          0 21 51 186 60 193 3 3 17 30 29 60 13 30 27 57 30 60 4 3 17 22 30 43 12 20
          27 37 32 37 5 0 9 6 9 13 0 18 129 148 165 167 16 8 36 22 45 30 8 8 35 22 60
          30 25 8 47 18 51 22 3 4 34 14 70 23 75 17 278 14 352 -5z"/>
          <path id="52" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12481 4374 c-25 -8 -47 -17 -50 -21 -3 -5 -23 -17 -44 -27 -59 -30
          -151 -127 -190 -200 -12 -23 -27 -44 -34 -49 -6 -4 -19 -30 -28 -58 -17 -50
          -17 -52 6 -100 12 -27 25 -49 28 -49 4 0 17 -24 31 -52 13 -29 31 -59 38 -67
          7 -7 20 -31 29 -52 8 -22 19 -39 23 -39 4 0 17 -22 29 -50 12 -27 25 -50 29
          -50 4 0 21 -29 37 -65 16 -36 32 -65 35 -65 4 0 17 -24 31 -52 13 -29 29 -56
          35 -60 6 -4 19 -28 29 -53 11 -25 22 -45 26 -45 4 0 16 -24 29 -52 12 -29 27
          -57 33 -63 16 -13 47 -81 47 -101 0 -13 6 -15 27 -9 15 3 30 10 33 15 3 4 26
          11 53 15 26 4 47 9 47 12 0 29 45 299 50 303 4 3 15 48 25 100 10 52 24 109
          31 125 7 17 25 80 40 140 14 61 30 112 34 115 4 3 17 45 29 95 13 49 26 92 30
          95 5 3 18 47 30 98 l22 94 -23 22 c-13 13 -39 31 -58 42 -19 10 -37 22 -40 26
          -8 10 -99 48 -116 48 -8 0 -14 4 -14 9 0 5 -31 17 -69 25 -85 19 -239 19 -300
          0z"/>
          <path id="53" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M13137 4137 c-4 -18 -9 -54 -13 -82 -4 -27 -10 -52 -14 -55 -3 -3
          -17 -68 -30 -145 -13 -77 -26 -142 -30 -145 -4 -3 -17 -63 -30 -135 -13 -71
          -26 -132 -30 -135 -6 -4 -42 -193 -57 -305 -3 -22 -9 -42 -13 -45 -4 -3 -10
          -23 -12 -45 l-5 -40 54 -24 c29 -13 53 -27 53 -31 0 -14 74 -53 78 -41 2 6 9
          17 16 24 6 6 24 44 40 82 16 39 35 77 42 85 6 8 19 33 28 55 9 21 20 42 24 45
          4 3 16 25 27 50 10 25 24 50 30 55 5 6 19 30 30 55 10 25 24 50 30 55 6 6 24
          37 39 69 15 32 36 67 45 78 10 10 24 36 31 58 11 34 10 45 -10 104 -13 36 -27
          66 -30 66 -3 0 -19 27 -35 60 -16 33 -31 58 -33 55 -2 -2 -15 16 -27 40 -13
          24 -31 52 -40 62 -8 10 -32 38 -53 62 -37 45 -59 66 -85 88 -13 10 -16 7 -20
          -20z"/>
          <path id="72" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12055 3822 c-3 -18 -10 -32 -14 -32 -9 0 -38 -130 -47 -212 -4 -32
          -10 -55 -14 -53 -7 4 -25 -200 -33 -370 -4 -72 4 -82 86 -114 31 -13 57 -26
          57 -30 0 -3 27 -17 60 -30 33 -13 60 -27 60 -31 0 -4 24 -18 53 -30 28 -12 54
          -26 57 -30 3 -3 32 -19 65 -35 33 -15 62 -31 65 -35 3 -3 20 -14 38 -23 29
          -14 36 -14 48 -2 8 7 14 21 14 30 0 26 38 93 60 105 23 12 25 23 7 42 -7 7
          -26 37 -42 66 -15 28 -31 52 -35 52 -4 0 -17 20 -30 45 -13 25 -27 45 -31 45
          -4 0 -18 20 -30 45 -12 25 -25 45 -29 45 -3 0 -19 25 -35 55 -15 30 -32 55
          -36 55 -4 0 -16 18 -28 41 -12 22 -30 50 -41 61 -11 12 -20 28 -20 35 0 7 -9
          22 -19 32 -11 11 -31 43 -45 73 -14 29 -26 49 -26 43 0 -14 -33 42 -50 85 -8
          19 -17 37 -20 40 -4 3 -14 18 -22 34 l-16 30 -7 -32z"/>
          <path id="73" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M13506 3577 c-3 -15 -10 -27 -15 -27 -5 0 -19 -27 -31 -60 -12 -33
          -26 -60 -30 -60 -4 0 -19 -31 -34 -70 -15 -38 -32 -70 -37 -70 -5 0 -9 -6 -9
          -14 0 -20 -41 -116 -50 -116 -4 0 -18 -27 -30 -60 -12 -33 -26 -60 -30 -60 -4
          0 -18 -27 -30 -60 -12 -33 -26 -60 -30 -60 -4 0 -15 -21 -25 -46 -17 -44 -17
          -47 1 -87 28 -63 31 -65 83 -48 25 8 48 17 51 21 6 8 118 36 183 45 26 4 47
          11 47 15 0 5 39 11 88 15 48 4 90 9 93 11 9 5 -12 149 -22 152 -5 2 -9 12 -9
          22 0 37 -42 225 -51 228 -5 2 -9 11 -9 19 0 26 -51 227 -59 233 -4 3 -14 27
          -23 54 -14 46 -15 48 -22 23z"/>
          <path id="56" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M11946 2938 c19 -247 28 -328 35 -328 5 0 9 -11 9 -24 0 -36 40 -240
          49 -246 3 -3 17 -45 30 -95 12 -49 26 -92 31 -95 4 -3 15 -29 24 -59 16 -54
          97 -230 106 -231 6 0 50 97 50 111 0 5 4 9 9 9 5 0 19 26 32 58 12 31 25 59
          28 62 3 3 20 34 36 70 17 36 33 67 36 70 3 3 17 30 32 60 14 30 27 51 27 45 0
          -5 13 15 27 45 15 30 33 60 41 66 11 8 13 23 8 67 -4 32 -12 57 -17 57 -5 0
          -9 29 -9 64 l0 63 -51 22 c-29 11 -58 21 -65 21 -8 0 -14 4 -14 9 0 9 -95 51
          -116 51 -8 0 -14 4 -14 8 0 5 -31 22 -70 37 -38 15 -70 31 -70 35 0 4 -27 18
          -60 30 -32 12 -60 26 -62 31 -2 5 -17 11 -34 15 -29 6 -30 5 -28 -28z"/>
          <path id="57" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12743 2917 c-18 -7 -40 -23 -50 -37 -9 -14 -23 -31 -29 -38 -30 -30
          -38 -221 -14 -309 8 -26 16 -50 20 -53 3 -3 17 -27 30 -54 23 -49 75 -105 96
          -106 7 0 14 -7 17 -14 13 -32 160 -50 218 -26 16 7 39 29 51 49 12 20 25 38
          28 41 14 10 30 101 30 162 0 64 -15 148 -28 158 -4 3 -17 26 -30 52 -12 25
          -29 49 -37 52 -8 3 -15 12 -15 21 0 8 -19 27 -42 42 -24 14 -47 32 -53 38 -12
          15 -92 35 -132 34 -15 0 -43 -6 -60 -12z"/>
          <path id="58" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M13643 2773 c-18 -2 -33 -8 -33 -13 0 -4 -17 -11 -37 -15 -69 -12
          -178 -39 -183 -45 -3 -3 -27 -11 -55 -18 -27 -7 -66 -17 -86 -22 l-36 -10 9
          -56 c5 -30 7 -67 6 -80 -3 -22 4 -28 51 -44 30 -11 57 -24 60 -28 3 -5 35 -19
          71 -32 36 -13 67 -27 70 -31 3 -4 43 -19 90 -33 46 -15 87 -31 90 -35 3 -4 17
          -11 31 -14 25 -6 27 -4 31 31 6 46 1 306 -8 390 -4 50 -9 62 -22 60 -9 0 -32
          -3 -49 -5z"/>
          <path id="59" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M13214 2392 c-3 -15 -10 -37 -15 -50 -13 -34 -11 -54 11 -100 11 -22
          20 -46 20 -52 0 -5 5 -10 11 -10 5 0 20 -21 31 -47 11 -27 24 -50 28 -53 3 -3
          17 -31 30 -62 13 -32 27 -58 30 -58 4 0 19 -31 34 -70 15 -38 31 -70 35 -70 3
          0 17 -27 30 -60 13 -33 27 -60 30 -60 4 0 17 -27 30 -60 13 -33 29 -59 35 -57
          6 2 22 39 36 83 13 43 28 82 32 85 5 3 18 54 30 115 13 60 26 111 29 114 6 4
          39 221 39 251 0 5 -13 9 -30 9 -16 0 -30 4 -30 8 0 5 -39 19 -87 32 -49 13
          -90 26 -93 30 -3 4 -41 20 -85 35 -44 15 -82 31 -85 35 -3 3 -24 14 -47 23
          l-43 17 -6 -28z"/>
          <path id="60" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12590 2388 c0 -15 -39 -98 -55 -118 -7 -8 -20 -36 -30 -62 -10 -27
          -21 -48 -25 -48 -4 0 -18 -26 -31 -57 -13 -32 -28 -63 -34 -69 -5 -6 -21 -35
          -35 -65 -14 -30 -28 -56 -31 -59 -4 -3 -17 -26 -29 -52 -12 -27 -26 -48 -31
          -48 -5 0 -9 -9 -9 -20 0 -11 3 -20 8 -20 4 0 18 -21 32 -47 14 -27 29 -52 35
          -58 6 -5 27 -30 47 -55 54 -67 133 -150 144 -150 6 0 21 53 34 118 13 64 27
          119 31 122 4 3 16 52 28 109 12 57 25 109 30 115 8 10 33 115 48 199 3 15 9
          27 13 27 4 0 10 17 13 37 6 34 3 40 -48 91 -30 30 -60 67 -67 83 -13 30 -38
          47 -38 27z"/>
          <path id="61" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M13089 2200 c-18 -16 -38 -30 -45 -30 -7 0 -15 -4 -18 -8 -6 -10 13
          -85 23 -92 4 -3 18 -57 30 -120 12 -63 26 -119 30 -125 4 -5 21 -77 37 -160
          15 -82 31 -152 35 -155 3 -3 14 -58 24 -122 16 -99 22 -118 36 -118 10 0 24 6
          31 14 8 7 34 24 59 36 24 13 55 32 68 44 51 44 113 112 120 131 9 21 -4 62
          -24 80 -6 6 -22 32 -36 60 -13 27 -30 57 -38 67 -8 9 -25 41 -37 70 -13 29
          -23 49 -24 43 0 -5 -12 15 -26 45 -14 30 -28 57 -32 60 -4 3 -18 29 -31 58
          -14 28 -28 52 -31 52 -4 0 -15 21 -25 48 -10 26 -24 52 -31 58 -8 6 -21 27
          -29 47 -21 50 -30 52 -66 17z"/>
          <path id="62" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M12790 2169 c0 -28 -54 -325 -60 -329 -3 -3 -17 -65 -30 -137 -13
          -73 -26 -133 -30 -133 -10 0 -33 -167 -26 -185 3 -8 12 -15 21 -15 8 0 15 -5
          15 -10 0 -11 103 -60 125 -60 8 0 15 -5 17 -11 9 -26 295 -63 326 -41 10 6 8
          28 -10 103 -12 52 -24 96 -28 99 -4 3 -17 59 -30 125 -13 66 -26 122 -30 125
          -4 3 -17 65 -30 138 -13 72 -27 132 -30 132 -4 0 -13 41 -20 90 l-13 90 -36 0
          c-20 1 -54 7 -76 15 -48 18 -55 18 -55 4z"/>
          <path id="63" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M5230 4383 c-8 -10 -26 -42 -40 -70 -14 -29 -28 -53 -32 -53 -4 0
          -17 -18 -29 -40 -13 -22 -26 -40 -31 -40 -4 0 -14 -12 -22 -27 -23 -44 -141
          -173 -157 -173 -5 0 -19 -10 -32 -22 -12 -13 -36 -33 -52 -46 l-30 -22 35 6
          c19 4 37 11 38 16 2 4 9 8 17 8 17 0 103 38 123 56 30 24 176 171 210 209 17
          21 32 44 32 52 0 7 7 13 15 13 20 0 19 -6 -15 -75 -16 -33 -30 -66 -30 -72 0
          -7 -4 -13 -10 -13 -5 0 -17 -15 -26 -32 -18 -36 -26 -45 -106 -129 -29 -31
          -62 -60 -73 -64 -19 -8 -19 -9 3 -12 12 -3 22 -9 22 -14 0 -11 47 -12 52 -1 2
          4 21 16 43 27 41 20 175 145 175 163 0 5 10 20 23 34 12 13 31 42 42 63 21 41
          65 95 65 79 0 -17 -22 -89 -29 -94 -4 -3 -15 -25 -26 -50 -10 -25 -26 -54 -35
          -65 -25 -32 -60 -84 -60 -89 0 -3 -21 -25 -47 -50 l-48 -46 57 0 c48 0 61 4
          85 26 15 14 40 37 55 49 15 13 37 42 49 64 11 23 24 41 28 41 4 0 20 29 36 65
          16 36 32 65 37 65 5 0 16 16 25 35 18 38 43 47 43 15 0 -11 -4 -20 -10 -20 -5
          0 -10 -8 -10 -18 0 -26 -34 -119 -51 -139 -8 -10 -25 -38 -39 -63 -13 -25 -32
          -51 -42 -59 -11 -7 -23 -24 -28 -37 -5 -13 -16 -24 -25 -24 -8 0 -15 -4 -15
          -10 0 -5 21 -10 46 -10 37 0 51 5 76 30 16 17 33 30 38 30 5 0 18 12 29 26 12
          15 21 23 21 18 0 -5 12 11 26 35 15 24 35 52 45 62 11 11 19 25 19 33 0 7 10
          24 23 38 12 13 29 37 37 53 14 27 15 27 18 6 4 -25 -26 -121 -37 -121 -4 0
          -18 -23 -32 -52 -13 -28 -50 -77 -83 -109 -32 -32 -55 -62 -51 -68 9 -15 72
          -14 82 2 4 7 19 18 33 25 46 22 62 36 176 150 61 61 117 112 123 112 6 0 11
          -11 11 -25 0 -14 -4 -25 -9 -25 -5 0 -14 -15 -20 -33 -12 -34 -119 -143 -176
          -178 -16 -11 -26 -19 -21 -19 6 0 1 -7 -10 -15 -18 -13 -17 -14 16 -15 42 0
          110 18 110 29 0 4 18 16 40 26 23 10 44 23 48 29 4 7 28 23 55 36 26 14 47 31
          47 37 0 7 4 13 9 13 13 0 116 101 129 127 11 20 8 22 -38 33 -28 7 -50 15 -50
          19 0 4 -40 18 -90 30 -49 13 -90 27 -90 30 0 4 -49 20 -109 36 -61 16 -108 32
          -105 34 5 5 -52 26 -118 41 -26 7 -48 15 -48 19 0 3 -38 17 -84 31 -47 13 -83
          27 -80 29 3 4 -131 50 -146 51 -3 0 -12 -8 -20 -17z"/>
          <path id="64" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M20215 4042 c-11 -3 -22 -7 -25 -11 -3 -4 -43 -17 -90 -30 -47 -13
          -87 -26 -90 -30 -3 -4 -41 -18 -85 -31 -44 -13 -82 -28 -85 -32 -7 -8 -158
          -58 -177 -58 -7 0 -13 -4 -13 -9 0 -5 -33 -19 -72 -32 -40 -12 -75 -25 -78
          -29 -3 -4 -36 -17 -75 -30 -38 -13 -79 -29 -90 -36 -11 -7 -46 -20 -77 -29
          -32 -9 -64 -22 -70 -28 -7 -7 -44 -23 -83 -36 -38 -14 -72 -27 -75 -31 -3 -4
          -42 -20 -87 -35 -45 -16 -80 -31 -77 -34 2 -2 -29 -16 -68 -29 -40 -14 -75
          -28 -78 -32 -3 -3 -34 -17 -70 -30 -36 -13 -67 -27 -70 -30 -3 -4 -44 -20 -93
          -37 -48 -16 -85 -32 -82 -35 3 -3 -28 -15 -70 -27 -41 -13 -75 -26 -75 -30 0
          -4 -28 -16 -61 -26 -34 -11 -67 -24 -73 -30 -6 -6 -53 -24 -103 -40 -51 -16
          -93 -32 -93 -35 0 -4 -35 -17 -77 -30 -43 -14 -81 -28 -84 -32 -5 -8 -109 -42
          -166 -55 -13 -3 -23 -9 -23 -13 0 -4 -36 -18 -80 -31 -44 -12 -80 -26 -80 -29
          0 -4 -37 -18 -82 -30 -46 -13 -85 -27 -88 -30 -3 -4 -50 -19 -105 -35 -55 -15
          -102 -31 -105 -35 -3 -3 -43 -17 -90 -30 -47 -13 -87 -26 -90 -30 -3 -3 -45
          -17 -95 -30 -49 -12 -92 -26 -95 -30 -3 -4 -59 -20 -125 -35 -66 -16 -122 -32
          -125 -36 -3 -3 -50 -17 -105 -29 -55 -13 -102 -26 -105 -30 -3 -4 -27 -11 -55
          -16 -43 -8 -51 -13 -60 -39 -5 -17 -13 -32 -16 -35 -3 -3 -16 -39 -29 -80 -13
          -41 -26 -77 -30 -80 -4 -3 -20 -48 -35 -100 -15 -52 -30 -97 -34 -100 -4 -3
          -18 -38 -30 -79 -12 -41 -29 -80 -37 -88 -21 -21 -17 -33 11 -33 14 0 25 4 25
          8 0 4 53 21 118 37 64 16 119 32 122 36 3 4 43 17 90 29 47 12 87 25 90 29 3
          4 44 18 92 31 48 13 85 27 83 31 -3 3 11 9 31 12 46 8 178 49 184 58 3 4 41
          17 85 29 44 12 82 26 85 30 3 4 41 18 85 30 44 13 82 27 85 30 3 3 46 19 95
          35 50 16 92 31 95 35 3 3 39 17 80 29 41 13 77 27 80 31 3 4 36 17 75 29 38
          12 72 25 75 29 3 4 46 20 95 36 50 16 92 32 95 36 3 4 34 17 70 29 36 13 67
          26 70 29 3 4 44 20 93 36 48 16 87 31 87 35 0 3 35 17 78 31 42 13 82 29 88
          35 6 5 37 19 70 30 32 10 61 22 64 26 3 4 41 20 85 34 44 15 82 30 85 34 6 8
          108 51 122 51 5 0 15 6 23 13 7 8 39 22 72 32 32 10 60 22 64 26 3 5 39 21 80
          35 40 15 76 30 79 34 3 4 34 18 70 31 36 13 71 29 78 36 7 7 19 13 26 13 15 0
          108 39 116 49 3 3 38 17 78 30 39 13 72 27 72 31 0 3 30 17 68 30 37 13 69 27
          72 31 3 4 36 19 75 34 38 14 72 29 75 33 3 5 31 16 63 26 32 10 63 24 70 31 7
          7 47 23 88 36 41 13 76 27 79 31 3 4 44 19 90 34 47 14 87 30 90 34 3 4 32 15
          65 24 33 10 69 23 80 30 11 7 56 25 100 41 44 16 82 31 85 35 3 4 26 16 52 28
          26 11 60 32 75 46 15 14 32 26 37 26 18 0 -33 36 -86 60 -29 14 -55 28 -58 32
          -3 4 -28 16 -55 28 -27 12 -52 25 -55 29 -3 5 -34 19 -70 32 -36 12 -67 26
          -70 30 -7 9 -60 15 -85 11z"/>
          <path id="65" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M20314 3522 c2 -4 -29 -18 -70 -32 -41 -13 -74 -27 -74 -31 0 -3 -30
          -16 -67 -29 -37 -12 -73 -29 -81 -36 -7 -8 -19 -14 -26 -14 -16 0 -119 -41
          -126 -50 -3 -4 -36 -17 -75 -31 -38 -13 -72 -27 -75 -31 -3 -3 -32 -17 -65
          -29 -33 -12 -62 -25 -65 -29 -3 -3 -41 -19 -85 -34 -44 -16 -82 -32 -85 -36
          -3 -4 -34 -18 -70 -31 -36 -13 -71 -29 -78 -36 -7 -7 -18 -13 -24 -13 -6 0
          -37 -10 -69 -21 l-59 -22 0 -83 c0 -46 -4 -84 -9 -84 -5 0 -12 -21 -16 -46 -8
          -53 -32 -126 -44 -134 -10 -6 -49 -67 -57 -90 -3 -8 -24 -28 -47 -45 -23 -16
          -48 -37 -55 -47 -7 -10 -19 -18 -25 -18 -7 0 -24 -10 -39 -23 -15 -13 -54 -32
          -88 -42 -33 -10 -60 -21 -60 -25 0 -16 -185 -33 -308 -27 l-127 5 45 -20 c24
          -10 47 -23 50 -27 6 -10 128 -51 151 -51 10 0 19 -4 21 -9 4 -12 179 -41 250
          -41 62 0 113 19 136 51 7 11 18 19 24 19 11 0 79 70 134 135 13 17 33 40 44
          53 11 12 20 25 20 28 0 4 16 29 35 56 20 27 43 66 51 88 10 24 23 40 33 40 10
          0 12 -3 5 -8 -6 -4 -22 -38 -34 -75 -13 -38 -28 -74 -34 -80 -6 -7 -23 -36
          -36 -64 -14 -29 -28 -53 -32 -53 -5 0 -8 -6 -8 -13 0 -7 -13 -27 -30 -45 -16
          -18 -30 -37 -30 -41 0 -4 -9 -16 -20 -26 -30 -27 -26 -47 8 -39 15 4 29 10 32
          14 3 4 20 11 37 15 18 3 41 16 50 28 10 12 31 30 46 40 15 10 31 26 34 35 4 9
          20 27 35 40 15 13 28 29 28 35 0 7 9 18 20 25 11 7 20 18 20 26 0 8 11 24 25
          37 13 13 25 29 25 36 0 15 32 86 42 93 10 8 48 158 48 191 0 15 7 29 15 33 12
          4 15 -4 15 -39 0 -25 -4 -45 -10 -45 -5 0 -10 -15 -10 -32 0 -43 -45 -229 -57
          -237 -6 -3 -20 -28 -32 -56 -12 -27 -25 -52 -29 -55 -5 -3 -18 -25 -31 -49
          -20 -39 -20 -43 -4 -36 10 4 41 14 69 21 59 16 100 51 129 109 11 22 25 43 32
          48 6 4 19 27 29 52 9 25 20 45 24 45 8 0 60 176 60 203 0 9 4 17 9 17 5 0 17
          45 26 100 13 68 22 100 32 100 14 0 19 -55 4 -64 -4 -3 -11 -35 -14 -72 -9
          -93 -38 -234 -48 -234 -5 0 -9 -6 -9 -13 0 -26 -50 -169 -65 -183 -17 -18 -20
          -34 -5 -34 12 0 70 59 70 71 0 5 5 9 10 9 17 0 58 50 86 105 15 28 32 57 39
          65 7 8 23 47 36 85 13 39 29 75 34 81 6 6 19 39 30 73 10 33 21 61 25 61 4 0
          11 16 14 35 4 19 12 35 18 35 5 0 8 -6 5 -13 -2 -7 -10 -39 -17 -72 -7 -33
          -15 -62 -19 -65 -4 -3 -17 -50 -30 -105 -13 -55 -27 -102 -31 -105 -11 -8 -61
          -153 -54 -159 7 -8 152 146 159 170 4 10 11 19 16 19 5 0 21 26 35 57 13 32
          28 60 33 63 4 3 18 37 31 75 13 39 26 72 30 75 7 5 33 104 46 173 3 20 10 37
          15 37 5 0 9 12 9 26 0 14 4 23 8 20 10 -6 -7 -148 -18 -156 -4 -3 -10 -25 -14
          -50 -10 -66 -38 -174 -46 -180 -9 -6 -51 -132 -46 -137 2 -2 11 12 20 32 9 19
          20 35 24 35 4 0 19 25 32 55 13 30 27 55 30 55 4 0 19 37 35 83 15 45 31 84
          35 87 4 3 18 46 30 95 13 50 27 92 31 95 7 6 25 107 35 203 6 64 5 67 -15 67
          -12 0 -20 -3 -17 -8z"/>
          <path id="67" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M19000 2969 c-25 -11 -57 -25 -72 -32 -17 -6 -28 -19 -28 -30 0 -27
          -30 -127 -38 -127 -4 0 -13 -14 -21 -31 -15 -34 -88 -109 -107 -109 -6 0 -14
          -6 -17 -13 -5 -14 -101 -47 -135 -47 -12 0 -22 -4 -22 -10 0 -6 -33 -10 -80
          -10 -47 0 -80 4 -80 10 0 6 -11 10 -25 10 -32 0 -135 39 -135 51 0 5 -11 9
          -25 9 -14 0 -25 -4 -25 -9 0 -5 -26 -17 -57 -27 -31 -10 -51 -20 -43 -22 7 -3
          21 -13 31 -23 11 -11 37 -26 59 -35 22 -9 40 -19 40 -23 0 -15 108 -33 226
          -38 117 -5 280 15 294 36 3 5 16 12 30 16 38 12 140 90 140 108 0 2 14 19 30
          37 17 18 30 38 30 45 0 7 5 15 10 17 12 4 60 140 60 168 0 10 4 20 8 22 5 2
          12 20 15 41 7 43 2 45 -63 16z"/>
          <path id="67" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M18821 2893 c0 -7 -16 -16 -33 -22 -61 -17 -133 -46 -145 -57 -7 -7
          -37 -20 -68 -29 -30 -10 -55 -21 -55 -25 0 -4 -30 -17 -67 -30 -37 -12 -73
          -29 -81 -36 -7 -8 -21 -14 -30 -14 -28 0 1 -17 50 -29 110 -26 235 -3 311 57
          73 59 133 146 124 180 -3 9 -5 12 -6 5z"/>
          <path id="68" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M15965 2516 c-70 -15 -94 -32 -112 -74 -8 -20 -18 -39 -22 -42 -9 -7
          -51 -95 -51 -108 0 -6 -6 -17 -14 -25 -8 -8 -25 -46 -39 -86 -13 -39 -26 -69
          -28 -66 -3 2 -14 -23 -24 -58 -10 -34 -24 -73 -31 -87 -7 -14 -17 -40 -23 -59
          l-11 -34 58 7 c103 12 130 23 137 56 4 17 12 36 18 43 7 6 23 44 36 82 13 39
          27 72 30 75 4 3 18 34 31 70 13 36 26 67 30 70 8 7 60 143 60 157 0 6 4 13 10
          15 5 2 16 20 25 41 15 36 15 37 -7 36 -13 -1 -45 -6 -73 -13z"/>
          <path id="69" style="cursor: pointer; stroke: black; stroke-width: 50px" d="M14206 1868 c-4 -46 -11 -84 -16 -86 -6 -2 -10 -11 -10 -19 0 -26
          -52 -228 -60 -233 -4 -3 -17 -38 -29 -77 -13 -40 -26 -73 -29 -73 -4 0 -18
          -29 -30 -64 -13 -35 -29 -66 -36 -69 -6 -3 -18 -24 -26 -46 -7 -23 -23 -52
          -34 -64 -12 -12 -30 -40 -41 -61 -21 -43 -41 -68 -92 -118 -18 -17 -33 -36
          -33 -41 0 -10 -73 -83 -136 -134 -24 -21 -33 -33 -23 -33 19 0 82 21 89 29 3
          3 21 14 40 23 53 27 69 39 115 88 84 88 115 125 126 152 6 16 15 28 20 28 5 0
          19 20 31 45 12 25 25 45 29 45 4 0 15 22 24 49 9 27 22 54 29 60 8 6 26 46 41
          89 16 42 32 79 36 82 4 3 17 34 30 70 12 36 26 67 30 70 4 3 13 18 19 34 6 16
          13 26 17 23 6 -6 -25 -135 -37 -152 -4 -5 -18 -44 -30 -85 -13 -41 -26 -77
          -29 -80 -4 -3 -14 -27 -23 -55 -24 -72 -42 -119 -49 -125 -11 -10 -49 -91 -49
          -105 0 -8 -5 -15 -10 -15 -6 0 -20 -20 -32 -45 -12 -25 -26 -45 -30 -45 -5 0
          -13 -10 -19 -22 -19 -43 -35 -67 -60 -87 l-24 -21 28 14 c28 14 40 23 100 81
          18 17 44 38 60 48 15 10 27 24 27 32 0 8 5 15 11 15 14 0 48 41 49 58 0 7 7
          15 15 18 8 3 24 26 36 50 12 24 25 41 29 39 3 -2 16 23 29 55 13 33 28 57 32
          54 5 -3 9 1 9 8 0 14 42 120 50 128 9 8 60 182 60 203 0 10 5 15 10 12 6 -3
          10 3 10 14 0 12 5 21 11 21 11 0 10 -13 -8 -162 -3 -27 -9 -48 -14 -48 -5 0
          -9 -8 -9 -18 0 -27 -51 -196 -60 -202 -5 -3 -18 -28 -30 -55 -12 -27 -24 -52
          -28 -55 -4 -3 -18 -25 -31 -50 -14 -25 -30 -49 -38 -53 -7 -4 -13 -13 -13 -20
          0 -7 -23 -37 -50 -67 -54 -59 -61 -80 -30 -80 11 0 20 5 20 10 0 6 11 10 25
          10 14 0 38 8 53 17 41 26 169 158 177 182 3 11 11 21 16 21 5 0 19 21 32 48
          12 26 27 52 33 59 7 7 20 35 30 63 10 27 21 50 25 50 3 0 19 44 35 98 16 53
          34 103 41 110 7 7 13 23 13 36 0 12 7 31 16 42 14 17 15 12 10 -66 -5 -86 -33
          -231 -46 -240 -4 -3 -17 -35 -29 -73 -13 -37 -28 -73 -35 -80 -6 -6 -20 -32
          -30 -57 -11 -25 -25 -49 -31 -55 -7 -5 -20 -25 -29 -43 -9 -18 -24 -36 -32
          -39 -19 -7 -18 -33 1 -33 8 0 15 5 15 10 0 6 11 10 25 10 47 0 90 27 135 85
          25 32 52 65 60 74 9 9 26 41 39 71 13 30 27 57 31 60 4 3 20 43 35 90 15 47
          31 87 36 90 4 3 17 32 30 65 12 33 28 64 35 68 8 4 14 17 14 27 0 10 5 22 10
          25 6 3 10 -4 10 -17 0 -29 -32 -171 -40 -178 -4 -3 -17 -43 -30 -90 -13 -47
          -27 -87 -32 -90 -4 -3 -14 -26 -23 -50 -16 -48 -36 -92 -46 -100 -7 -6 -39
          -71 -39 -80 0 -4 14 -5 30 -2 17 2 30 8 30 13 0 5 7 9 16 9 23 0 74 32 74 47
          0 7 9 17 20 23 10 6 27 26 36 45 9 20 19 33 22 30 7 -7 99 180 113 230 6 22
          15 42 19 45 4 3 18 41 32 85 13 44 28 78 33 75 12 -6 7 -124 -6 -128 -5 -2 -9
          -13 -9 -25 0 -30 -42 -191 -51 -197 -4 -3 -17 -26 -28 -52 -10 -25 -23 -47
          -28 -49 -9 -4 -45 -69 -38 -69 2 0 21 9 42 21 40 23 93 77 93 96 0 7 8 17 18
          22 9 5 28 31 42 58 14 26 31 54 38 61 21 23 70 136 90 208 27 96 46 98 40 4
          -3 -40 -10 -75 -15 -79 -5 -3 -14 -28 -20 -56 -7 -27 -15 -59 -19 -70 -6 -15
          1 -11 24 12 18 17 32 35 32 38 0 4 15 26 33 48 34 43 67 109 67 134 0 8 4 12
          9 9 5 -4 14 17 20 44 15 69 14 90 -5 90 -8 0 -13 -4 -10 -9 14 -22 -462 -39
          -629 -22 -66 7 -121 17 -123 22 -2 5 -14 9 -26 9 -29 0 -189 40 -196 49 -3 4
          -25 17 -50 30 -25 13 -47 26 -50 30 -3 4 -21 18 -40 31 -40 28 -96 84 -104
          105 -13 34 -24 10 -30 -67z"/>
          </g>
          `,
        },
      ],
    ],
    listColorQuestion: [
      {
        color: "red",
      },
      {
        color: "Pink",
      },
      {
        color: "yellow",
      },
      {
        color: "blue",
      },
      {
        color: "green",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P25-E2",
    audio: "",
    video: "",
    component: PaintColorType,
    titleQuestion: [
      {
        title: `1. COLOR YOUR FAVORITE TOY`,
      },
    ],
    recorder: true,
    isAllowSubmit: true,
    totalInput: 5,
    isHiddenCheck: true,

    questionSVG: [
      [
        {
          version: "1.0",
          viewBox: "0 0 2441 2001",
          X: 0,
          y: 0,
          style: {
            width: 900,
            height: 900,
            marginTop: 9,
            // stroke: 'black',
            // fill: 'transparent',
            // stroke: '#000',
            // strokeLinecap: 'round',
            // strokeLinejoin: 'round',
            // strokeWidth: '50px',
          },
          g: `
          <g transform="translate(0.000000,2001.000000) scale(0.100000,-0.100000)"
          fill="transparent" style="cursor: pointer; stroke: black; stroke-width: 50px"  >
          <path id="1"  d="M0 10005 l0 -10005 12205 0 12205 0 0 10005 0 10005 -12205 0 -12205
          0 0 -10005z m14875 8665 c88 -12 162 -25 165 -29 3 -4 50 -17 104 -29 55 -12
          102 -26 105 -31 4 -4 17 -11 31 -14 66 -14 165 -48 168 -57 2 -6 9 -10 17 -10
          15 0 108 -40 115 -50 3 -3 31 -17 63 -29 31 -13 57 -26 57 -30 0 -3 27 -19 60
          -35 33 -16 60 -33 60 -37 0 -3 14 -12 30 -19 17 -7 36 -20 43 -29 7 -9 24 -22
          38 -29 14 -8 43 -29 64 -48 21 -19 43 -34 49 -34 6 0 22 -11 36 -25 65 -65
          136 -125 147 -125 7 0 13 -4 13 -9 0 -5 17 -26 38 -48 20 -22 55 -62 77 -88
          22 -26 49 -57 61 -69 11 -12 29 -36 39 -53 11 -18 23 -33 26 -33 4 0 14 -12
          21 -27 23 -45 42 -76 56 -91 7 -8 20 -32 29 -53 8 -22 19 -39 23 -39 4 0 18
          -34 31 -75 13 -41 29 -75 34 -75 6 0 21 12 34 25 13 14 29 25 36 25 8 0 20 8
          27 18 7 9 36 28 63 41 28 14 52 28 55 31 7 10 114 60 128 60 7 0 12 4 12 9 0
          8 120 51 144 51 8 0 16 4 18 10 2 5 39 17 83 27 113 25 377 25 458 0 31 -10
          57 -23 57 -28 0 -5 7 -9 15 -9 20 0 95 -38 95 -48 0 -4 19 -19 43 -34 23 -15
          53 -41 67 -58 14 -16 35 -42 48 -56 12 -15 32 -48 45 -73 34 -71 39 -74 98
          -62 27 6 63 11 79 11 17 0 30 5 30 10 0 6 33 10 80 10 47 0 80 -4 80 -10 0 -5
          15 -10 33 -10 27 -1 85 -12 185 -37 12 -3 22 -9 22 -13 0 -4 23 -17 50 -28 28
          -12 50 -25 50 -31 0 -5 9 -12 20 -16 25 -8 110 -94 110 -112 0 -8 5 -11 10 -8
          6 3 10 3 10 -2 0 -4 7 -16 14 -26 13 -16 16 -16 72 5 33 12 61 25 64 29 9 11
          134 29 206 29 80 0 196 -17 204 -30 3 -5 19 -12 35 -16 40 -10 113 -42 133
          -60 20 -16 128 -122 147 -144 16 -18 45 -97 45 -122 0 -10 5 -18 10 -18 6 0
          10 -33 10 -80 0 -44 -4 -80 -8 -80 -4 0 -16 -32 -26 -71 -10 -39 -27 -78 -37
          -87 -10 -9 -19 -21 -19 -27 0 -20 -133 -165 -151 -165 -3 0 -19 -11 -34 -24
          -16 -14 -57 -33 -92 -43 -34 -10 -63 -21 -63 -25 0 -5 -47 -8 -105 -8 -58 0
          -105 4 -105 9 0 5 -15 11 -32 15 -47 9 -138 47 -138 57 0 5 -13 15 -29 24 -29
          15 -30 14 -36 -15 -4 -17 -10 -30 -15 -30 -5 0 -19 -18 -31 -39 -12 -21 -32
          -44 -45 -50 -13 -6 -24 -15 -24 -20 0 -5 -21 -20 -47 -33 -27 -13 -50 -26 -53
          -30 -15 -20 -178 -58 -246 -58 -19 0 -34 -4 -34 -10 0 -5 -25 -10 -55 -10 -30
          0 -55 5 -55 10 0 6 -19 10 -41 10 -52 0 -215 38 -232 53 -6 7 -32 23 -57 36
          -25 14 -51 33 -58 42 -18 26 -39 13 -61 -36 -36 -81 -170 -217 -263 -267 -24
          -13 -46 -28 -50 -34 -4 -7 -30 -20 -58 -30 -27 -10 -50 -21 -50 -25 0 -7 -73
          -30 -160 -50 -30 -8 -57 -17 -60 -20 -11 -16 -191 -33 -340 -33 -158 -1 -354
          20 -344 35 3 5 -5 9 -18 9 -29 0 -190 47 -197 58 -7 8 -58 32 -70 32 -4 0 -18
          -26 -31 -57 -13 -32 -26 -60 -30 -63 -3 -3 -19 -29 -34 -59 -15 -30 -34 -61
          -42 -69 -8 -7 -21 -27 -29 -43 -9 -16 -20 -29 -26 -29 -5 0 -12 -8 -16 -17
          -13 -40 -246 -270 -311 -308 -18 -11 -32 -23 -32 -27 0 -5 -10 -13 -22 -18
          -30 -13 -55 -30 -86 -57 -14 -13 -30 -23 -37 -23 -6 0 -20 -8 -31 -17 -10 -10
          -43 -29 -71 -42 -29 -14 -53 -28 -53 -32 0 -4 -34 -19 -75 -34 -41 -15 -75
          -31 -75 -35 0 -4 -36 -18 -80 -31 -44 -13 -80 -26 -80 -30 0 -4 -44 -18 -97
          -30 -54 -13 -100 -27 -103 -30 -25 -35 -687 -63 -927 -40 -154 15 -277 33
          -283 41 -3 4 -56 17 -117 30 -62 13 -113 26 -113 31 0 4 -33 17 -72 29 -40 12
          -75 25 -78 29 -3 4 -36 20 -75 36 -38 17 -80 40 -93 52 -45 42 -55 32 -75 -69
          -10 -51 -22 -95 -26 -98 -3 -3 -17 -43 -31 -90 -13 -47 -26 -87 -30 -90 -3 -3
          -19 -40 -34 -82 -15 -43 -31 -78 -35 -78 -3 0 -17 -27 -30 -60 -13 -33 -27
          -60 -31 -60 -4 0 -15 -18 -25 -41 -10 -22 -31 -55 -47 -72 -15 -18 -28 -37
          -28 -42 0 -6 -12 -25 -27 -42 -16 -17 -39 -45 -53 -64 -52 -67 -238 -259 -253
          -259 -5 0 -22 -12 -37 -27 -39 -37 -59 -54 -100 -82 -19 -13 -39 -29 -45 -35
          -5 -6 -28 -20 -50 -31 -22 -11 -50 -29 -62 -41 -12 -11 -40 -27 -63 -34 -22
          -7 -40 -16 -40 -20 0 -4 -30 -20 -67 -35 -38 -16 -70 -32 -73 -36 -3 -4 -34
          -17 -70 -30 -36 -12 -67 -25 -70 -29 -3 -4 -43 -18 -90 -30 -47 -13 -88 -27
          -91 -32 -6 -9 -237 -57 -276 -58 -13 0 -23 -4 -23 -8 0 -34 -573 -53 -840 -27
          -96 9 -177 20 -180 24 -3 4 -48 15 -100 24 -156 28 -210 40 -210 47 0 4 -40
          18 -90 31 -49 12 -90 26 -90 30 0 4 -26 15 -57 24 -32 9 -67 23 -79 30 -12 7
          -50 26 -85 40 -35 15 -66 30 -69 34 -3 4 -25 15 -50 26 -25 10 -54 26 -65 35
          -11 9 -38 25 -60 36 -22 10 -44 24 -50 30 -5 6 -43 33 -83 59 -40 27 -76 54
          -80 60 -4 7 -27 25 -51 41 -25 16 -88 72 -140 124 l-96 95 -2 -35 c-6 -81 -26
          -200 -34 -200 -5 0 -9 -6 -9 -13 0 -19 -49 -170 -58 -177 -4 -3 -18 -29 -31
          -57 -14 -29 -28 -53 -32 -53 -4 0 -11 -12 -17 -27 -5 -16 -18 -37 -28 -49 -23
          -25 -41 -52 -53 -81 -6 -12 -16 -25 -23 -30 -7 -4 -26 -25 -42 -46 -40 -55
          -201 -217 -214 -217 -7 0 -19 -12 -29 -26 -9 -14 -26 -30 -37 -35 -29 -12 -53
          -29 -88 -61 -17 -16 -36 -28 -44 -28 -8 0 -14 -4 -14 -10 0 -5 -19 -19 -42
          -31 -24 -12 -45 -25 -48 -29 -3 -4 -32 -19 -65 -35 -33 -15 -62 -31 -65 -35
          -3 -4 -32 -17 -65 -29 -33 -12 -62 -26 -65 -30 -6 -8 -191 -61 -215 -61 -8 0
          -15 -4 -15 -9 0 -15 -190 -36 -325 -35 -124 0 -274 18 -285 34 -3 4 -35 17
          -73 29 -37 13 -73 28 -80 34 -7 7 -23 18 -35 25 -12 6 -32 18 -44 25 -12 6
          -31 22 -41 35 -10 12 -22 22 -25 22 -4 0 -13 -26 -21 -57 -9 -41 -23 -67 -47
          -91 -19 -19 -34 -38 -34 -42 0 -4 -13 -13 -29 -19 -40 -17 -54 -47 -41 -91 5
          -19 10 -51 10 -70 0 -47 8 -56 55 -64 55 -10 135 -36 135 -45 0 -3 23 -15 51
          -26 29 -11 63 -32 78 -47 14 -16 30 -28 34 -28 13 0 97 -84 97 -97 0 -7 7 -16
          15 -20 19 -11 45 -85 51 -153 9 -84 -30 -500 -46 -500 -4 0 -10 -24 -13 -52
          -5 -44 -12 -58 -39 -81 -34 -29 -130 -77 -154 -77 -8 0 -14 -4 -14 -8 0 -5
          -43 -19 -95 -33 -52 -13 -95 -26 -95 -30 0 -4 -18 -9 -40 -13 -22 -3 -62 -11
          -89 -17 l-49 -10 6 -197 c2 -108 9 -198 13 -200 11 -5 12 -612 1 -612 -7 0
          -21 -406 -26 -740 l-1 -65 63 -3 c38 -2 62 1 62 8 0 13 277 14 282 1 2 -6 160
          -11 388 -13 l385 -3 0 335 c0 184 3 392 8 463 l7 127 -98 0 c-81 0 -100 3
          -112 18 -17 18 -44 73 -55 110 -3 12 -10 22 -14 22 -4 0 -18 35 -31 78 -14 42
          -28 80 -33 83 -5 3 -11 24 -14 46 l-5 39 52 12 c28 7 64 12 78 12 27 0 27 1
          27 68 1 73 27 167 51 176 7 3 14 11 14 19 0 18 142 146 173 156 7 2 35 15 62
          29 70 34 155 51 257 52 85 0 227 -23 238 -39 3 -4 27 -16 53 -27 26 -10 64
          -34 85 -51 20 -18 45 -35 55 -39 9 -3 17 -11 17 -19 0 -7 12 -24 28 -37 28
          -24 64 -83 77 -128 4 -13 13 -29 19 -36 7 -6 18 -37 25 -68 7 -31 16 -56 21
          -56 32 0 167 -23 173 -29 9 -9 -21 -193 -33 -201 -4 -3 -10 -18 -14 -35 -4
          -16 -16 -56 -27 -87 -25 -67 -46 -78 -145 -78 l-63 0 7 -82 c9 -107 9 -634 0
          -759 l-7 -96 452 -8 c249 -4 454 -10 457 -14 3 -5 122 -11 265 -14 336 -9
          1034 -46 1060 -57 11 -5 208 -19 438 -31 229 -13 417 -27 417 -31 0 -4 45 -8
          100 -8 l100 0 0 25 c0 14 -4 25 -9 25 -4 0 -11 57 -15 128 -12 241 -35 848
          -42 1122 -4 151 -10 277 -15 280 -4 3 -10 192 -13 420 -4 228 -9 439 -12 469
          l-6 54 -25 -29 c-14 -16 -32 -42 -40 -59 -9 -16 -19 -32 -23 -35 -4 -3 -17
          -27 -31 -55 -47 -100 -75 -105 -156 -30 -26 25 -53 45 -60 45 -6 0 -14 8 -17
          18 -3 9 -22 35 -41 56 -40 45 -43 64 -17 94 10 12 24 39 32 61 7 22 21 49 30
          59 10 10 25 33 34 50 9 17 22 35 29 39 8 4 17 15 21 23 4 8 12 24 19 35 17 26
          206 215 216 215 4 0 16 9 26 20 10 11 23 20 29 20 6 0 22 12 35 25 13 14 29
          25 35 25 7 0 21 8 32 18 22 20 137 72 162 73 13 0 14 2 3 6 -15 6 69 35 136
          48 21 4 40 10 43 15 7 11 231 39 378 46 67 4 122 10 122 14 0 4 102 10 228 14
          125 4 371 11 547 16 516 15 859 31 880 40 11 4 103 13 205 19 300 18 505 34
          510 41 3 3 131 17 285 30 154 12 282 27 283 32 2 4 18 8 35 8 58 -1 542 51
          562 60 11 5 115 19 230 31 116 13 212 26 213 31 2 4 16 8 30 8 15 0 71 5 124
          12 78 10 113 9 175 -1 43 -7 80 -16 83 -20 3 -4 36 -16 74 -26 37 -10 74 -24
          81 -32 8 -7 18 -13 23 -13 14 0 136 -51 142 -60 3 -4 26 -16 52 -28 26 -11 57
          -30 70 -41 12 -12 27 -21 33 -21 6 0 20 -8 31 -17 30 -28 102 -73 115 -73 7 0
          9 -3 6 -6 -6 -6 35 -44 47 -44 10 0 216 -208 216 -218 0 -6 9 -17 21 -25 20
          -15 54 -66 65 -99 4 -10 10 -18 15 -18 5 0 19 -21 31 -47 12 -27 25 -50 29
          -53 4 -3 15 -32 24 -65 15 -49 17 -82 11 -179 -8 -158 -28 -346 -36 -341 -3 2
          -12 -30 -20 -71 -7 -42 -18 -77 -24 -79 -6 -2 -57 -8 -113 -15 l-103 -12 0
          -194 c0 -107 4 -194 8 -194 5 0 14 -420 21 -932 7 -513 13 -967 14 -1008 l2
          -75 65 -2 c93 -4 130 -12 130 -28 0 -8 6 -18 14 -22 8 -4 24 -26 37 -48 13
          -22 29 -46 37 -54 7 -7 22 -33 34 -57 11 -24 23 -44 27 -44 3 0 17 -24 31 -52
          13 -29 27 -55 30 -58 10 -8 120 -237 120 -249 0 -6 4 -11 8 -11 5 0 19 -34 32
          -75 13 -41 27 -72 31 -70 3 3 9 -8 12 -23 8 -46 40 -147 48 -152 4 -3 17 -48
          29 -100 13 -52 26 -97 30 -100 8 -5 60 -296 60 -336 0 -13 4 -24 9 -24 47 0
          49 -1166 1 -1275 -4 -11 -20 -103 -35 -205 -15 -102 -31 -187 -34 -190 -4 -3
          -18 -53 -31 -112 -13 -60 -27 -108 -31 -108 -4 0 -10 -15 -14 -32 -9 -45 -37
          -132 -44 -138 -3 -3 -16 -34 -29 -70 -13 -36 -27 -66 -32 -68 -6 -2 -10 -7
          -10 -12 0 -4 -11 -33 -25 -64 -14 -31 -25 -58 -25 -61 0 -3 -9 -14 -20 -25
          -38 -38 -43 -68 -19 -119 12 -25 24 -48 28 -51 7 -5 44 -97 56 -137 3 -13 10
          -23 15 -23 5 0 17 -32 27 -71 l18 -72 135 6 c146 7 292 24 300 36 3 5 25 11
          50 15 66 10 184 39 190 46 3 4 46 19 95 34 50 16 92 31 95 35 3 4 33 18 68 30
          34 13 62 26 62 30 0 3 29 17 65 30 36 14 65 28 65 32 0 4 29 20 65 35 36 16
          65 31 65 35 0 4 23 16 50 28 28 12 50 25 50 30 0 4 26 21 57 36 31 16 68 39
          81 52 14 13 30 23 36 23 6 0 22 12 35 25 13 14 29 25 36 25 8 0 20 8 27 18 7
          9 26 23 41 31 15 8 47 32 70 53 23 21 46 38 51 38 5 0 18 9 30 20 42 41 89 80
          95 80 3 0 31 23 61 50 30 27 58 50 62 50 4 0 32 25 61 55 29 30 56 55 61 55
          11 0 355 347 401 405 22 27 55 66 73 86 19 21 44 52 58 71 26 36 65 84 103
          126 12 15 29 39 37 55 8 15 19 27 24 27 5 0 11 8 15 19 3 10 17 31 31 46 13
          14 36 49 49 76 14 27 29 49 33 49 4 0 18 21 32 48 13 26 29 50 36 54 6 4 19
          25 29 48 10 22 21 40 26 40 4 0 18 21 30 48 13 26 28 52 33 58 6 6 22 35 36
          65 14 30 33 63 43 73 9 11 17 27 17 36 0 9 8 25 18 36 10 10 28 44 41 74 13
          30 27 57 30 60 9 6 61 128 61 141 0 6 6 17 14 25 8 7 22 37 32 66 9 29 20 55
          25 58 8 5 37 79 46 118 3 12 9 22 13 22 5 0 20 39 35 88 15 48 31 89 35 92 8
          6 40 103 40 124 0 8 6 21 13 28 8 7 24 55 36 106 13 50 26 92 30 92 4 0 20 55
          36 123 16 67 32 124 36 127 7 5 11 23 40 170 7 36 16 67 19 70 8 6 50 238 50
          274 0 13 4 26 9 28 10 3 47 287 57 443 4 55 12 103 17 107 17 10 16 750 -1
          756 -7 2 -12 22 -12 50 0 40 -23 264 -46 450 -4 28 -11 52 -15 52 -5 0 -9 11
          -9 25 0 40 -42 290 -49 295 -4 3 -18 61 -31 130 -13 69 -26 127 -30 130 -4 3
          -20 59 -35 125 -16 66 -32 122 -36 125 -4 3 -17 43 -30 90 -13 47 -26 87 -30
          90 -4 3 -18 37 -30 75 -13 39 -26 72 -30 75 -4 3 -20 41 -35 85 -15 44 -31 82
          -35 85 -5 3 -19 34 -32 68 -13 34 -26 60 -29 57 -3 -3 -15 19 -28 50 -12 30
          -25 55 -29 55 -3 0 -17 24 -30 53 -13 28 -28 57 -35 62 -6 6 -21 31 -33 56
          -13 25 -32 54 -43 64 -11 10 -20 24 -20 32 0 7 -8 21 -18 31 -10 10 -29 36
          -42 57 -13 22 -36 53 -52 69 -15 17 -28 34 -28 39 0 5 -12 21 -27 36 -16 15
          -44 46 -63 70 -19 23 -44 51 -54 62 -15 14 -17 22 -8 31 8 8 20 2 49 -25 20
          -21 41 -37 45 -37 4 0 8 -4 8 -10 0 -18 143 -154 164 -156 12 -1 66 -5 121 -9
          110 -8 107 -12 48 55 -13 14 -23 30 -23 37 0 7 -11 20 -24 28 -48 32 -59 115
          -15 115 19 0 119 -95 119 -112 0 -5 7 -8 15 -8 8 0 15 -6 15 -14 0 -8 12 -25
          28 -38 15 -13 40 -41 57 -63 16 -22 40 -48 52 -58 13 -10 23 -24 23 -31 0 -7
          14 -22 30 -34 17 -13 30 -27 30 -33 0 -6 15 -25 33 -44 17 -18 42 -46 54 -62
          38 -47 87 -105 111 -130 12 -13 22 -28 22 -32 0 -4 9 -16 20 -26 11 -10 20
          -24 20 -32 0 -7 7 -16 15 -19 8 -3 23 -21 34 -39 19 -33 30 -46 77 -99 13 -15
          24 -29 24 -32 0 -6 42 -67 51 -74 4 -3 16 -24 28 -47 11 -23 27 -46 34 -50 7
          -4 24 -32 37 -60 14 -29 28 -53 31 -53 4 0 18 -21 30 -47 13 -27 28 -53 33
          -59 6 -6 22 -35 36 -65 14 -30 30 -59 36 -65 6 -7 18 -33 28 -58 10 -25 21
          -46 25 -46 5 0 16 -25 26 -55 9 -31 23 -61 30 -68 7 -7 25 -52 40 -99 14 -48
          30 -88 34 -90 8 -3 51 -135 51 -156 0 -7 4 -12 8 -12 5 0 19 -42 31 -92 13
          -51 26 -95 30 -98 4 -3 20 -66 36 -140 16 -74 32 -137 35 -140 7 -6 50 -263
          50 -297 0 -12 4 -23 9 -25 10 -3 37 -229 47 -390 4 -57 12 -106 18 -110 16
          -10 16 -986 0 -996 -6 -4 -14 -50 -18 -102 -10 -146 -39 -404 -46 -410 -3 -3
          -17 -72 -29 -155 -13 -82 -26 -152 -30 -155 -4 -3 -19 -68 -35 -145 -16 -77
          -31 -142 -35 -145 -4 -3 -17 -50 -30 -105 -13 -55 -28 -105 -32 -111 -5 -6
          -18 -44 -28 -84 -11 -40 -23 -76 -28 -79 -5 -3 -22 -50 -37 -103 -16 -54 -32
          -98 -35 -98 -4 0 -18 -36 -31 -79 -14 -43 -27 -78 -30 -76 -3 2 -16 -27 -29
          -63 -14 -37 -28 -69 -32 -72 -5 -3 -20 -37 -34 -75 -13 -38 -28 -71 -33 -74
          -4 -3 -19 -32 -31 -63 -13 -32 -26 -58 -30 -58 -3 0 -17 -27 -30 -60 -13 -33
          -27 -60 -31 -60 -4 0 -18 -27 -30 -60 -12 -33 -28 -64 -35 -68 -7 -4 -23 -31
          -35 -59 -12 -29 -26 -53 -30 -53 -3 0 -17 -23 -30 -50 -13 -27 -27 -50 -30
          -50 -4 0 -17 -22 -29 -50 -12 -27 -25 -50 -29 -50 -5 0 -21 -27 -37 -60 -16
          -33 -32 -60 -37 -60 -4 0 -17 -20 -29 -45 -12 -25 -25 -45 -29 -45 -4 0 -18
          -19 -30 -43 -12 -23 -26 -44 -30 -47 -4 -3 -20 -25 -35 -50 -15 -25 -31 -47
          -35 -50 -3 -3 -17 -23 -30 -45 -13 -22 -32 -49 -42 -59 -10 -11 -18 -23 -18
          -28 0 -4 -9 -17 -20 -28 -10 -10 -31 -37 -45 -60 -14 -22 -30 -40 -35 -40 -6
          0 -10 -6 -10 -13 0 -8 -9 -22 -20 -32 -11 -10 -20 -22 -20 -27 0 -4 -8 -16
          -17 -27 -10 -10 -34 -40 -53 -66 -19 -26 -47 -60 -62 -75 -16 -16 -28 -33 -28
          -38 0 -6 -11 -19 -25 -30 -14 -11 -25 -24 -25 -28 0 -5 -12 -22 -28 -39 -15
          -16 -40 -46 -55 -65 -42 -52 -118 -140 -157 -181 -19 -20 -62 -67 -95 -105
          -33 -37 -100 -109 -150 -160 -49 -51 -111 -115 -138 -143 -26 -28 -49 -51 -51
          -51 -2 0 -46 -43 -97 -95 -52 -52 -97 -95 -101 -95 -4 0 -32 -26 -63 -57 -51
          -52 -55 -58 -39 -70 10 -7 24 -13 31 -13 7 0 13 -4 13 -9 0 -5 17 -16 39 -24
          21 -9 45 -22 53 -29 7 -7 24 -19 36 -25 67 -39 81 -49 132 -97 48 -45 70 -74
          88 -115 10 -22 21 -38 25 -35 3 3 9 -11 13 -31 8 -45 -1 -156 -14 -164 -5 -3
          -17 -22 -27 -41 -17 -34 -148 -170 -164 -170 -4 0 -16 -8 -27 -18 -10 -9 -41
          -28 -69 -42 -27 -14 -54 -30 -60 -37 -5 -7 -35 -22 -65 -33 -30 -12 -57 -25
          -60 -29 -3 -5 -34 -18 -70 -30 -36 -13 -67 -26 -70 -30 -6 -8 -138 -51 -158
          -51 -7 0 -12 -4 -12 -9 0 -4 -47 -21 -105 -36 -58 -16 -105 -31 -105 -35 0 -4
          -47 -18 -105 -30 -57 -13 -102 -26 -99 -29 5 -5 -82 -26 -221 -52 -33 -7 -62
          -15 -65 -19 -3 -4 -61 -18 -130 -31 -69 -13 -127 -26 -130 -30 -3 -4 -66 -17
          -140 -29 -74 -12 -137 -26 -140 -29 -3 -4 -90 -20 -195 -36 -104 -15 -192 -31
          -195 -35 -5 -7 -307 -50 -347 -50 -13 0 -23 -4 -23 -9 0 -4 -89 -19 -197 -32
          -109 -12 -200 -26 -203 -30 -3 -3 -124 -19 -270 -34 -146 -16 -267 -31 -270
          -35 -3 -4 -70 -17 -150 -30 -80 -13 -147 -27 -150 -31 -3 -4 -99 -19 -215 -33
          -115 -15 -219 -31 -230 -36 -22 -9 -149 -23 -375 -41 -80 -6 -154 -15 -165
          -19 -35 -15 -656 -50 -882 -50 -75 0 -123 -4 -123 -10 0 -7 -153 -10 -437 -10
          -241 0 -544 -4 -673 -10 -386 -16 -843 -30 -1245 -37 -241 -4 -382 -10 -387
          -16 -9 -15 -2722 -14 -2740 0 -8 7 -76 12 -183 12 -94 1 -334 6 -535 11 -331
          9 -533 16 -1102 36 -103 4 -185 10 -183 14 3 4 -30 8 -73 9 -42 1 -201 8 -352
          16 -151 8 -338 17 -415 21 -77 4 -142 10 -145 14 -4 5 -681 51 -743 50 -10 0
          -16 4 -13 9 3 4 -37 11 -88 14 -221 13 -664 51 -661 57 7 11 -316 35 -475 35
          -186 0 -653 -25 -660 -35 -3 -4 -65 -11 -137 -15 -128 -7 -133 -6 -133 13 0
          18 14 21 175 37 96 10 175 22 175 26 0 3 46 10 102 14 157 11 158 20 5 35 -76
          7 -137 16 -137 20 0 4 -28 10 -63 14 -158 16 -332 40 -337 46 -3 4 -30 10 -60
          14 -165 20 -296 40 -300 46 -3 4 -27 10 -55 14 -140 19 -331 51 -335 56 -3 4
          -68 17 -145 30 -77 13 -142 26 -145 30 -3 4 -61 17 -130 30 -69 13 -127 27
          -130 30 -3 4 -22 10 -43 14 -20 4 -37 12 -37 18 0 5 10 8 23 5 12 -3 51 -11
          87 -18 36 -7 67 -15 70 -19 3 -4 64 -18 135 -31 72 -13 132 -26 135 -30 3 -4
          70 -17 150 -30 80 -12 147 -25 150 -29 5 -7 339 -60 378 -60 12 0 22 -4 22 -8
          0 -5 82 -19 182 -32 101 -12 188 -26 193 -30 6 -4 100 -18 210 -30 110 -13
          202 -26 205 -30 3 -4 54 -12 114 -18 83 -9 149 -9 280 0 94 6 173 15 176 18 6
          8 492 38 773 47 108 3 197 10 197 15 0 4 32 8 71 8 62 0 70 2 59 15 -7 8 -18
          15 -26 15 -7 0 -18 9 -24 20 -6 11 -29 29 -50 40 -22 11 -40 25 -40 30 0 9
          -39 34 -72 46 -10 3 -18 10 -18 15 0 12 -47 12 -52 0 -7 -20 -193 -44 -343
          -44 -141 -1 -310 21 -325 42 -3 4 -31 14 -62 24 -32 10 -63 23 -70 30 -8 7
          -39 25 -71 41 -31 16 -57 32 -57 36 0 5 -16 18 -34 30 -19 11 -38 30 -41 41
          -4 10 -10 17 -14 15 -4 -3 -18 13 -32 34 -13 22 -27 40 -30 40 -3 0 -16 24
          -30 53 -13 28 -27 55 -32 58 -4 3 -13 33 -19 66 l-11 60 -86 6 c-194 14 -840
          19 -1117 8 -344 -13 -475 -22 -469 -31 5 -8 -437 -50 -526 -50 -65 0 -66 1
          -110 45 -24 25 -50 45 -57 45 -7 0 -15 7 -18 15 -3 8 -22 24 -42 36 -20 12
          -46 30 -57 40 -50 47 -75 66 -116 88 -24 14 -48 30 -54 37 -5 6 -30 22 -55 35
          -25 13 -48 29 -52 35 -4 6 -25 19 -48 29 -22 10 -40 22 -40 26 0 5 -25 20 -55
          34 -30 15 -55 30 -55 34 0 4 -20 17 -45 29 -25 12 -45 26 -45 32 0 5 -5 10
          -10 10 -25 0 -80 46 -80 68 0 12 4 22 8 22 5 0 19 24 31 53 13 28 26 54 30 57
          11 8 53 92 44 88 -5 -1 -32 -12 -61 -22 -29 -11 -52 -23 -52 -27 0 -4 -25 -17
          -55 -29 -30 -12 -55 -25 -55 -29 0 -4 -19 -18 -42 -31 -24 -13 -45 -26 -48
          -30 -3 -3 -21 -17 -40 -30 -49 -34 -100 -82 -100 -95 0 -7 -11 -22 -24 -34
          -26 -24 -46 -88 -46 -143 0 -44 24 -121 38 -126 7 -2 12 -9 12 -16 0 -7 11
          -23 25 -36 14 -13 25 -29 25 -37 0 -7 4 -12 8 -10 5 1 28 -15 53 -36 24 -21
          58 -45 76 -52 18 -8 33 -18 33 -23 0 -5 24 -19 53 -33 28 -13 56 -29 60 -36 4
          -7 16 -13 26 -13 9 0 26 -7 37 -15 10 -8 27 -15 36 -15 9 0 23 -6 30 -13 7 -7
          47 -24 88 -37 41 -14 78 -29 81 -33 3 -4 32 -16 65 -27 49 -15 54 -18 28 -19
          -31 -1 -143 33 -154 47 -3 4 -38 18 -78 31 -39 13 -72 28 -72 32 0 4 -31 20
          -70 35 -38 15 -70 30 -70 34 0 3 -25 17 -55 31 -30 13 -55 27 -55 31 0 3 -16
          16 -36 29 -87 54 -193 185 -194 242 0 9 -4 17 -10 17 -5 0 -10 23 -10 50 0 28
          4 50 9 50 5 0 13 15 16 32 4 18 15 46 24 62 20 36 131 146 147 146 6 0 14 7
          17 14 3 8 21 23 39 32 18 9 38 22 43 29 6 6 30 20 55 30 25 11 47 23 50 27 7
          10 99 48 115 48 8 0 20 6 27 13 7 7 25 20 41 30 15 10 27 24 27 32 0 8 9 24
          20 35 10 10 29 38 40 60 11 22 30 50 40 60 11 11 20 26 20 34 0 7 10 24 22 38
          13 13 33 45 46 70 12 26 28 49 35 51 7 2 10 7 7 10 -8 9 -114 -24 -122 -37
          -11 -17 -350 -15 -355 1 -3 7 -12 13 -20 13 -49 0 -194 138 -239 229 -15 31
          -31 58 -34 61 -11 9 -39 113 -46 168 -4 29 -11 55 -15 58 -13 8 -11 314 1 314
          6 0 10 11 10 24 0 38 41 206 50 206 4 0 11 12 15 28 10 40 47 112 73 141 73
          81 108 112 150 132 26 13 52 28 59 35 40 40 327 52 471 20 45 -11 82 -22 82
          -27 0 -4 10 -11 23 -14 29 -9 107 -92 119 -127 6 -16 14 -28 18 -28 9 0 46
          -106 56 -163 3 -20 10 -37 14 -37 4 0 11 -29 15 -65 l7 -65 128 0 129 0 62 62
          c34 34 68 73 76 85 9 13 19 23 23 23 5 0 37 27 72 61 103 99 114 109 126 109
          6 0 17 8 24 18 15 20 40 36 86 57 17 9 32 19 32 23 0 5 24 19 53 32 28 13 58
          29 65 36 7 7 46 23 87 35 41 13 75 26 75 30 0 4 22 10 48 14 72 10 76 14 56
          66 -9 24 -23 49 -31 55 -7 6 -24 41 -36 78 -13 36 -26 64 -29 61 -6 -6 -48
          140 -48 164 0 7 -4 10 -9 7 -5 -3 -12 16 -16 42 -4 26 -9 50 -11 54 -5 7 -75
          -19 -84 -31 -11 -17 -105 -33 -187 -34 -88 0 -203 18 -203 32 0 4 -18 14 -40
          21 -22 7 -48 21 -58 30 -10 10 -33 25 -50 34 -17 9 -35 22 -39 29 -4 6 -27 25
          -51 41 -23 16 -42 32 -42 36 0 3 -18 26 -40 51 -22 24 -45 58 -50 76 -6 17
          -17 34 -25 37 -8 3 -15 12 -15 20 0 15 -30 77 -41 86 -4 3 -17 37 -30 75 -12
          39 -26 72 -29 75 -11 8 -39 131 -52 229 -7 50 -16 91 -20 91 -11 0 -10 398 1
          404 4 3 11 30 15 60 8 75 46 236 55 236 4 0 15 24 25 53 11 28 24 59 31 67 6
          8 21 31 32 52 24 41 64 90 105 127 16 14 28 31 28 38 0 6 8 14 19 18 10 3 31
          17 47 32 15 14 51 37 79 51 27 14 52 29 56 33 6 10 152 49 180 49 9 0 19 4 21
          9 4 13 178 31 302 31 116 0 308 -18 316 -30 3 -4 17 -9 33 -12 19 -4 27 -1 27
          8 0 8 9 22 20 31 11 10 20 23 20 29 0 17 31 72 58 102 12 14 22 31 22 37 0 7
          11 23 25 36 13 13 25 29 25 34 0 6 18 30 40 55 22 25 40 48 40 51 0 9 159 169
          168 169 4 0 27 18 52 40 25 22 53 42 63 46 9 3 17 10 17 15 0 5 20 18 45 30
          25 12 45 25 45 29 0 4 29 20 65 35 36 16 65 31 65 35 0 4 33 17 73 30 39 12
          74 26 77 30 5 9 167 50 194 50 9 0 16 4 16 8 0 9 184 35 375 54 72 6 132 15
          135 19 5 5 153 17 454 35 l108 7 -6 41 c-3 23 -9 193 -13 379 -3 185 -10 337
          -15 337 -11 0 -10 697 1 702 5 2 11 92 13 201 4 185 3 197 -14 197 -10 0 -18
          4 -18 9 0 5 -15 12 -33 16 -67 14 -142 40 -150 52 -4 7 -13 13 -19 13 -18 0
          -101 42 -122 62 -11 10 -25 18 -32 18 -7 0 -19 8 -26 18 -7 11 -23 24 -35 31
          -27 14 -63 80 -63 115 0 13 -4 26 -9 28 -13 4 -31 200 -31 332 0 111 12 186
          30 186 6 0 10 6 10 14 0 7 9 25 21 40 11 14 17 26 13 26 -4 0 11 14 32 31 21
          17 41 34 44 39 3 4 24 18 48 30 23 12 42 26 42 30 0 4 30 20 68 35 37 15 69
          30 72 34 6 8 139 51 158 51 7 0 12 4 12 10 0 5 16 12 35 16 l35 6 0 67 c1 80
          24 204 39 209 6 2 11 10 11 19 0 25 52 146 71 167 11 11 19 25 19 30 0 12 39
          63 78 103 12 13 22 27 22 31 0 18 146 151 181 164 11 4 19 11 19 16 0 5 22 19
          50 32 28 13 50 27 50 31 0 3 39 19 88 35 48 15 88 31 90 35 2 4 43 13 93 20
          64 9 89 17 89 27 0 15 -30 73 -42 82 -4 3 -14 22 -22 42 -9 20 -21 42 -29 50
          -7 7 -24 39 -37 71 -13 31 -27 57 -31 57 -3 0 -17 27 -29 60 -12 33 -26 60
          -30 60 -4 0 -20 37 -35 83 -15 45 -31 84 -35 87 -4 3 -18 41 -30 85 -12 44
          -26 82 -30 85 -11 7 -50 241 -50 296 0 24 -4 44 -10 44 -6 0 -10 45 -10 115 0
          70 4 115 10 115 6 0 10 22 10 48 0 55 37 274 48 282 4 3 18 41 30 85 13 44 27
          82 32 85 4 3 20 34 34 69 14 36 30 67 34 70 10 8 42 62 49 84 3 9 9 17 14 17
          5 0 15 13 23 29 21 40 194 215 233 235 18 9 36 22 40 28 4 7 28 24 53 37 25
          14 50 30 57 37 7 6 35 20 63 30 27 10 50 21 50 26 0 7 151 58 175 58 7 0 15 5
          17 10 4 12 210 50 270 50 24 0 37 4 33 10 -4 7 52 10 160 10 108 0 164 -3 160
          -10 -4 -6 9 -10 32 -10 66 -1 255 -38 264 -52 18 -27 26 14 32 155 6 142 24
          298 36 307 4 3 20 62 36 133 15 70 31 127 36 127 4 0 17 33 29 73 12 39 25 74
          30 77 4 3 17 32 29 65 12 33 26 62 29 65 4 3 20 33 36 68 16 34 32 62 35 62 3
          0 17 25 30 55 14 30 28 55 32 55 4 0 17 20 29 45 12 25 25 45 29 45 3 0 17 20
          31 45 14 25 28 45 31 45 4 0 17 17 29 37 12 21 31 47 41 58 43 45 68 75 90
          107 13 18 36 44 51 56 16 13 28 28 28 33 0 5 39 47 87 92 47 45 109 103 136
          130 27 26 52 47 56 47 3 0 31 23 61 50 30 27 60 50 67 50 7 0 17 9 23 20 6 11
          17 20 24 20 7 0 25 11 40 25 15 14 29 25 31 25 4 0 74 44 90 58 6 4 30 19 55
          32 25 14 50 30 56 35 6 6 35 21 65 35 30 14 59 30 65 36 7 6 34 19 60 29 27
          10 51 21 54 25 3 4 34 18 70 31 36 12 68 27 71 31 3 5 48 20 100 35 52 15 96
          30 99 34 5 6 90 29 170 45 19 3 37 10 40 14 9 13 229 41 367 47 73 3 130 10
          127 14 -3 5 29 9 70 9 41 0 77 -4 80 -8 3 -5 63 -12 133 -15 150 -7 357 -34
          361 -48 2 -5 13 -9 23 -9 28 0 213 -42 219 -50 3 -3 47 -18 99 -33 52 -15 97
          -30 100 -35 3 -4 26 -14 51 -22 25 -7 82 -31 128 -52 45 -21 87 -38 92 -38 6
          0 10 -4 10 -9 0 -4 29 -21 65 -36 36 -16 65 -32 65 -35 0 -4 18 -15 40 -25 23
          -10 44 -23 48 -29 4 -6 33 -24 65 -40 31 -16 57 -33 57 -38 0 -5 10 -13 23
          -18 29 -13 56 -31 79 -53 15 -14 22 -15 37 -5 11 7 22 19 26 28 12 33 48 80
          106 139 14 14 33 38 42 54 10 15 22 27 28 27 6 0 23 12 37 28 15 15 47 43 72
          62 24 19 51 42 60 50 8 8 40 28 70 44 30 17 60 35 67 42 7 6 33 20 57 30 25
          11 46 22 46 25 0 4 28 17 63 30 34 12 65 26 68 31 3 4 45 20 95 34 49 15 91
          30 94 33 6 9 131 39 194 47 25 4 46 10 46 14 0 8 191 37 325 50 50 5 232 7
          405 5 259 -2 343 -7 475 -25z m-13105 -16107 c41 -12 92 -25 113 -28 20 -4 37
          -11 37 -15 0 -5 8 -11 18 -14 9 -3 -10 -3 -42 -1 -33 2 -62 8 -65 13 -3 5 -37
          18 -76 29 -53 15 -71 24 -73 39 -2 11 0 15 5 8 4 -5 42 -20 83 -31z m360 -102
          c0 -5 21 -13 48 -19 l47 -10 -42 -1 c-23 0 -48 2 -57 5 -17 7 -22 34 -6 34 6
          0 10 -4 10 -9z"/>
          <path id="2" d="M14138 18653 c-54 -2 -98 -7 -98 -12 0 -4 -39 -11 -86 -15 -117 -9
          -287 -35 -294 -45 -3 -4 -27 -11 -54 -15 -73 -11 -201 -46 -204 -57 -2 -5 -9
          -9 -17 -9 -19 0 -138 -39 -145 -48 -3 -4 -32 -18 -65 -31 -33 -14 -62 -27 -65
          -31 -3 -4 -30 -19 -60 -34 -52 -25 -98 -54 -145 -91 -11 -8 -35 -26 -54 -40
          -19 -14 -31 -25 -25 -25 5 0 -6 -8 -25 -17 -38 -19 -164 -141 -201 -195 -13
          -20 -28 -34 -32 -32 -4 3 -8 0 -8 -6 0 -6 -10 -24 -22 -40 l-22 -30 52 -51
          c29 -29 51 -53 49 -55 -6 -6 -67 27 -67 36 0 5 -22 21 -50 36 -27 15 -50 30
          -50 34 0 3 -20 17 -45 29 -25 12 -45 26 -45 31 0 5 -10 12 -22 16 -13 4 -47
          24 -77 45 -30 22 -60 39 -68 39 -7 0 -21 8 -31 18 -9 9 -41 28 -70 41 -28 14
          -52 27 -52 31 0 4 -31 20 -70 35 -38 15 -70 31 -70 34 0 4 -31 18 -70 31 -38
          13 -70 27 -70 31 0 4 -38 17 -85 30 -47 12 -85 25 -85 29 0 4 -57 20 -127 35
          -71 16 -130 32 -133 36 -7 9 -286 49 -344 49 -25 0 -46 5 -46 10 0 6 -77 10
          -210 10 -133 0 -210 -4 -210 -10 0 -5 -19 -10 -41 -10 -54 0 -322 -40 -329
          -49 -3 -4 -60 -20 -127 -36 -68 -16 -123 -32 -123 -35 0 -4 -34 -17 -75 -29
          -41 -13 -75 -27 -75 -32 0 -5 -6 -9 -14 -9 -21 0 -116 -42 -116 -51 0 -5 -7
          -9 -16 -9 -16 0 -126 -50 -134 -62 -3 -4 -26 -16 -52 -27 -25 -10 -47 -23 -49
          -28 -2 -4 -29 -21 -59 -37 -30 -16 -59 -35 -63 -43 -4 -7 -14 -13 -21 -13 -7
          0 -24 -11 -38 -24 -14 -12 -43 -34 -64 -47 -22 -12 -45 -31 -52 -41 -7 -10
          -19 -18 -26 -18 -7 0 -25 -12 -40 -27 -14 -16 -43 -41 -63 -58 -20 -16 -82
          -74 -137 -129 -56 -54 -112 -108 -126 -120 -14 -12 -37 -39 -52 -61 -15 -22
          -37 -46 -48 -53 -11 -7 -20 -19 -20 -27 0 -7 -8 -20 -18 -27 -10 -7 -29 -29
          -42 -48 -13 -19 -30 -39 -36 -45 -7 -5 -22 -29 -34 -52 -12 -24 -25 -43 -29
          -43 -5 0 -18 -20 -31 -45 -13 -25 -26 -45 -30 -45 -4 0 -17 -24 -31 -52 -13
          -29 -31 -61 -39 -70 -9 -9 -22 -34 -29 -55 -8 -20 -20 -43 -27 -50 -7 -7 -22
          -42 -34 -78 -12 -36 -25 -65 -29 -65 -5 0 -18 -35 -31 -77 -13 -43 -26 -80
          -30 -83 -4 -3 -18 -48 -31 -100 -13 -52 -27 -97 -30 -100 -16 -13 -59 -322
          -59 -422 0 -35 -4 -58 -11 -58 -6 0 -9 -10 -7 -21 2 -15 17 -27 51 -40 27 -11
          55 -26 64 -34 8 -8 20 -15 27 -15 8 0 20 -8 27 -17 12 -15 12 -16 -5 -9 -11 4
          -43 14 -70 23 -28 9 -53 19 -56 23 -11 14 -234 60 -291 60 -16 0 -29 5 -29 10
          0 13 -383 14 -408 1 -9 -5 -55 -14 -102 -21 -96 -13 -222 -41 -230 -50 -3 -4
          -35 -17 -72 -29 -38 -13 -68 -26 -68 -31 0 -5 -13 -11 -30 -15 -16 -4 -32 -11
          -35 -16 -4 -5 -15 -9 -25 -9 -10 0 -23 -6 -27 -14 -4 -7 -24 -21 -43 -30 -19
          -9 -53 -32 -75 -51 -22 -19 -44 -35 -49 -35 -11 0 -116 -111 -116 -122 0 -5
          -4 -7 -9 -3 -5 3 -12 -3 -15 -12 -3 -10 -16 -29 -29 -43 -13 -14 -34 -45 -48
          -70 -13 -25 -31 -53 -40 -63 -9 -10 -22 -35 -29 -57 -7 -22 -17 -40 -21 -40
          -9 0 -59 -147 -59 -173 0 -10 -4 -15 -10 -12 -5 3 -10 4 -10 2 0 -3 -9 -49
          -20 -102 -11 -53 -20 -112 -20 -131 0 -19 -4 -34 -10 -34 -6 0 -10 -70 -10
          -191 0 -124 3 -188 10 -184 6 4 10 -6 10 -22 0 -44 42 -253 50 -248 5 3 11
          -12 15 -33 11 -58 48 -166 62 -180 7 -7 13 -20 13 -28 0 -8 18 -53 40 -99 22
          -46 40 -88 40 -94 0 -5 6 -16 13 -23 7 -7 24 -39 37 -70 13 -32 27 -58 30 -58
          4 0 20 -31 35 -70 15 -38 31 -70 36 -70 4 0 10 -11 13 -25 6 -22 12 -25 54
          -25 57 0 165 -19 172 -30 3 -4 23 -12 45 -18 34 -10 29 -10 -40 -5 -116 9
          -200 9 -288 2 -77 -7 -217 -36 -227 -48 -3 -3 -32 -17 -65 -31 -33 -13 -69
          -32 -79 -42 -11 -10 -25 -18 -30 -18 -6 0 -31 -18 -56 -40 -25 -22 -48 -40
          -52 -40 -13 0 -68 -65 -68 -79 0 -8 -3 -11 -6 -8 -4 4 -20 -10 -35 -31 -16
          -20 -38 -48 -49 -60 -11 -13 -20 -29 -20 -36 0 -7 -8 -21 -18 -32 -19 -20 -52
          -85 -52 -102 0 -6 -6 -17 -14 -24 -8 -8 -24 -55 -37 -104 -33 -123 -33 -358 0
          -478 12 -44 25 -82 30 -85 4 -4 14 -23 22 -44 7 -21 20 -46 29 -55 9 -9 25
          -36 36 -59 12 -26 28 -44 42 -48 31 -8 337 -7 418 1 50 5 65 11 69 25 4 11 10
          19 14 19 4 0 18 24 32 53 13 28 27 53 31 55 4 2 17 36 29 75 12 40 25 74 29
          77 24 16 52 326 32 357 -8 13 -62 6 -62 -8 0 -5 -29 -9 -65 -9 -51 0 -65 3
          -65 15 0 10 11 15 35 15 46 0 122 27 161 56 17 13 34 24 39 24 16 0 83 85 95
          121 7 22 17 39 22 39 4 0 8 23 8 50 0 28 -4 50 -9 50 -5 0 -19 19 -30 41 -12
          23 -28 48 -36 56 -8 8 -15 19 -15 24 0 15 40 -10 47 -29 9 -23 110 -122 124
          -122 7 0 9 -3 5 -7 -7 -7 76 -63 94 -63 5 0 10 -4 10 -9 0 -9 95 -51 116 -51
          8 0 14 -4 14 -9 0 -5 35 -18 78 -28 111 -26 400 -25 532 1 52 11 97 22 100 26
          3 4 42 18 88 30 45 12 82 26 82 30 0 4 34 20 75 35 41 15 75 30 75 34 0 4 24
          18 54 32 30 13 53 27 50 30 -2 3 17 16 41 28 25 12 45 26 45 30 0 4 12 13 26
          20 15 6 50 32 79 56 29 25 55 45 58 45 5 0 182 176 238 237 14 15 34 44 45 63
          10 19 25 39 32 43 7 5 22 28 33 53 12 24 25 44 29 44 5 0 18 25 31 55 12 30
          26 55 30 55 8 0 49 125 49 151 0 9 5 19 11 21 15 5 39 193 39 315 0 108 -27
          345 -40 353 -4 3 -11 32 -15 65 -13 99 18 48 51 -86 16 -63 31 -116 35 -119 8
          -7 29 -141 29 -192 0 -41 5 -47 76 -113 42 -38 90 -85 108 -102 18 -18 38 -33
          44 -33 6 0 19 -11 28 -25 9 -14 20 -25 26 -25 11 0 71 -41 80 -56 4 -6 25 -19
          48 -29 22 -10 40 -22 40 -26 0 -5 25 -20 55 -34 30 -15 55 -30 55 -34 0 -4 27
          -19 60 -32 33 -13 60 -26 60 -30 0 -3 27 -17 60 -30 33 -13 60 -27 60 -31 0
          -3 37 -19 83 -34 45 -15 84 -30 87 -35 3 -4 43 -17 90 -29 47 -12 87 -25 90
          -29 5 -8 194 -51 222 -51 9 0 18 -4 20 -10 2 -5 89 -19 193 -31 164 -18 227
          -21 455 -16 245 4 495 27 510 46 3 3 54 16 115 28 60 12 111 27 113 33 2 5 11
          10 20 10 21 0 155 42 162 50 3 4 41 19 85 35 44 16 82 32 85 36 3 4 28 17 55
          29 27 12 52 24 55 28 3 4 25 18 50 31 25 14 47 28 50 32 3 4 27 19 55 34 27
          16 56 35 64 42 7 8 26 22 40 31 14 9 32 25 39 34 7 10 18 18 24 18 13 0 228
          215 228 228 0 6 10 18 22 26 13 9 33 33 45 54 13 20 23 33 23 27 0 -11 38 51
          46 78 4 9 10 17 15 17 5 0 20 23 35 52 14 28 32 57 39 65 7 7 21 33 30 58 10
          25 22 45 26 45 5 0 9 6 9 12 0 15 42 121 51 128 8 7 46 126 54 172 4 21 10 36
          14 33 8 -4 37 111 47 187 6 44 5 48 -33 84 -22 22 -48 55 -59 74 -10 19 -22
          37 -25 40 -10 8 -29 48 -29 59 0 6 18 -7 40 -29 22 -22 40 -44 40 -50 0 -5 6
          -10 13 -10 7 0 28 -15 45 -32 58 -60 96 -89 150 -113 17 -9 32 -19 32 -24 0
          -5 27 -19 60 -31 33 -12 60 -26 60 -30 0 -9 146 -60 172 -60 9 0 18 -4 20 -9
          3 -10 189 -51 232 -51 14 0 26 -4 26 -9 0 -26 519 -55 763 -42 176 10 348 29
          357 40 3 4 48 17 100 30 52 13 97 27 100 31 3 4 46 21 95 38 50 17 86 31 80
          32 -5 0 19 12 55 27 36 14 67 29 70 33 3 4 31 20 63 35 31 16 57 31 57 35 0 3
          23 17 50 29 28 13 48 26 45 31 -3 4 9 13 26 20 16 7 35 20 42 29 13 17 39 35
          72 50 11 5 31 23 45 40 14 17 28 31 32 31 11 0 148 136 148 147 0 4 10 19 23
          33 43 47 117 152 117 165 0 8 7 21 16 30 20 19 54 84 54 101 0 7 6 19 14 26 7
          8 24 43 37 79 12 35 26 66 31 69 4 3 17 44 30 90 12 47 25 87 29 90 3 3 14 34
          22 70 l16 65 -5 -75 c-6 -72 -30 -216 -34 -195 -1 9 -39 -129 -40 -147 0 -5 9
          -8 19 -8 11 0 21 -4 23 -10 2 -5 50 -19 108 -32 58 -12 107 -25 110 -29 9 -13
          210 -29 358 -29 156 0 325 16 330 31 2 5 13 9 25 9 24 0 188 48 172 50 -5 0
          18 12 52 25 35 13 68 29 75 35 19 16 88 60 94 60 3 0 17 14 31 30 13 17 28 28
          33 25 4 -3 15 5 23 18 8 12 29 37 46 56 17 19 36 47 43 63 7 15 16 28 21 28 4
          0 17 21 28 47 11 26 24 50 28 53 8 5 22 45 41 118 13 51 30 55 30 7 0 -19 -4
          -35 -9 -35 -5 0 -12 -21 -16 -47 -4 -27 -11 -56 -16 -65 -5 -9 -6 -24 -3 -32
          6 -16 85 -56 109 -56 8 0 15 -5 17 -11 4 -12 147 -49 191 -49 15 0 27 -4 27
          -10 0 -6 52 -10 135 -10 83 0 135 4 135 10 0 6 8 10 19 10 29 0 125 32 136 45
          6 7 19 15 30 19 11 3 46 31 77 61 80 78 102 140 96 269 -3 70 -1 93 8 89 18
          -7 34 -83 34 -158 0 -41 5 -71 13 -78 15 -15 74 -43 107 -53 14 -4 28 -11 31
          -15 12 -20 165 -33 241 -21 64 10 181 57 197 80 4 4 22 18 41 31 33 22 90 92
          90 110 0 5 6 11 14 14 14 6 46 92 46 126 0 12 5 21 10 21 6 0 10 35 10 85 0
          47 -3 85 -7 85 -5 0 -16 27 -27 60 -10 34 -27 65 -37 70 -10 6 -24 22 -31 37
          -7 16 -26 34 -41 41 -15 7 -27 17 -27 22 0 5 -21 19 -47 32 -27 13 -50 26 -53
          30 -16 21 -144 42 -265 42 -140 1 -228 -17 -268 -55 -11 -10 -27 -19 -35 -19
          -7 0 -41 -27 -74 -60 -63 -63 -71 -69 -62 -45 4 8 10 15 14 15 4 0 11 8 14 18
          11 30 35 66 51 79 15 10 12 16 -22 50 -81 79 -123 114 -170 139 -26 15 -50 31
          -53 35 -8 11 -149 49 -183 49 -16 0 -26 4 -22 10 4 6 -30 10 -94 10 -56 0
          -103 -4 -106 -10 -3 -5 -16 -10 -29 -10 -40 0 -164 -37 -181 -54 -9 -9 -23
          -16 -30 -16 -23 0 -104 -43 -99 -52 3 -4 -1 -8 -8 -8 -12 0 -31 -15 -105 -83
          -45 -41 -22 1 30 54 53 55 110 99 127 99 6 0 10 4 10 10 0 5 19 19 42 31 67
          34 70 41 41 84 -44 64 -200 212 -250 237 -27 13 -50 26 -53 30 -10 13 -92 38
          -147 44 -29 4 -53 11 -53 15 0 5 -45 9 -100 9 -60 0 -100 -4 -100 -10 0 -5
          -12 -10 -26 -10 -37 0 -214 -42 -214 -51 0 -4 -29 -17 -65 -29 -36 -12 -71
          -27 -78 -34 -7 -7 -30 -19 -50 -27 -21 -7 -46 -20 -55 -29 -9 -9 -37 -26 -62
          -39 -25 -13 -47 -27 -50 -31 -3 -4 -24 -19 -48 -34 -41 -26 -53 -35 -106 -82
          -14 -12 -38 -31 -54 -43 -16 -11 -45 -35 -64 -53 -19 -18 -58 -55 -87 -81 -47
          -44 -51 -46 -36 -18 8 17 20 31 26 31 5 0 12 8 16 18 3 9 50 61 105 115 l99
          98 -12 34 c-7 19 -16 35 -19 35 -4 0 -17 25 -30 57 -13 31 -31 64 -41 74 -9 9
          -25 33 -34 53 -10 20 -21 36 -26 36 -5 0 -9 5 -9 10 0 12 -33 60 -67 98 -13
          14 -23 28 -23 32 0 4 -24 33 -52 63 -29 30 -75 80 -102 111 -27 31 -54 56 -59
          56 -5 0 -33 25 -62 55 -30 30 -58 55 -64 55 -5 0 -18 10 -28 23 -10 12 -36 33
          -58 47 -22 13 -51 34 -64 47 -13 13 -29 23 -36 23 -6 0 -22 11 -34 24 -12 13
          -41 32 -64 42 -23 10 -46 24 -52 30 -5 7 -35 22 -65 35 -30 13 -57 26 -60 30
          -7 9 -100 49 -116 49 -8 0 -14 4 -14 9 0 6 -34 20 -75 32 -41 13 -75 26 -75
          29 0 4 -53 20 -117 36 -65 16 -120 32 -123 36 -7 9 -272 48 -327 48 -23 0 -43
          4 -45 9 -3 11 -396 21 -550 14z"/>
          <path id="3" d="M16630 12547 c-63 -11 -117 -24 -120 -28 -3 -3 -88 -17 -190 -30
          -102 -12 -187 -26 -190 -30 -3 -3 -113 -17 -245 -30 -132 -13 -242 -26 -245
          -30 -3 -3 -77 -11 -165 -18 -88 -7 -241 -19 -340 -26 -99 -8 -208 -15 -242
          -15 -34 0 -63 -4 -65 -9 -3 -8 -403 -33 -745 -48 -84 -3 -153 -9 -153 -13 0
          -4 -80 -10 -177 -13 -253 -9 -785 -26 -937 -30 -72 -2 -131 -8 -133 -13 -2 -5
          31 -20 72 -33 41 -13 85 -32 97 -42 11 -11 25 -19 30 -19 14 0 162 -100 171
          -116 4 -8 13 -14 20 -14 7 0 25 -13 42 -30 116 -114 140 -139 168 -171 18 -20
          43 -50 57 -68 14 -17 32 -39 40 -49 28 -33 70 -102 63 -102 -5 0 -1 -5 7 -10
          16 -10 53 -71 97 -160 15 -30 32 -60 38 -66 6 -6 20 -37 30 -69 l20 -58 160 6
          c252 9 574 30 570 37 -2 3 78 12 178 18 332 23 452 33 472 42 11 5 58 11 105
          14 203 13 615 50 620 56 3 4 125 17 273 30 147 13 267 27 267 32 0 4 15 8 33
          8 19 0 123 9 233 20 109 12 228 23 264 27 36 3 67 9 70 13 3 3 104 16 225 29
          121 13 222 27 225 30 3 4 86 17 185 30 99 13 182 26 185 31 3 4 28 10 55 14
          28 4 70 12 94 18 l43 11 -6 45 c-3 25 -10 48 -14 51 -4 4 -16 27 -26 53 -10
          27 -23 51 -29 55 -7 5 -22 27 -35 50 -12 24 -31 53 -42 65 -50 59 -229 235
          -253 251 -16 10 -36 25 -45 33 -36 32 -127 94 -137 94 -6 0 -20 8 -31 18 -10
          10 -44 28 -74 41 -30 13 -58 27 -61 32 -3 5 -32 19 -65 30 -32 12 -61 24 -64
          28 -8 11 -151 42 -212 46 -29 2 -105 -6 -168 -18z"/>
          <path  d="M21610 12543 c0 -14 39 -73 48 -73 5 0 14 -11 20 -24 10 -22 19 -25
          96 -31 47 -4 83 -11 80 -16 -3 -5 15 -9 40 -9 25 0 46 3 46 8 0 4 -13 23 -30
          42 -16 19 -39 47 -52 61 -20 25 -31 28 -118 34 -52 3 -103 9 -112 11 -10 3
          -18 2 -18 -3z"/>
          <path  d="M21735 12364 c14 -15 25 -30 25 -33 0 -4 15 -8 33 -9 17 -1 68 -7
          112 -12 105 -13 104 -13 98 10 -8 32 -49 47 -161 59 -139 15 -135 15 -107 -15z"/>
          <path  d="M21780 12300 c0 -5 5 -10 11 -10 5 0 15 -13 20 -29 l10 -28 120 -8
          c66 -4 123 -4 127 -1 3 4 -6 17 -20 31 -21 19 -43 25 -124 34 -55 7 -109 14
          -121 16 -13 3 -23 1 -23 -5z"/>
          <path  d="M21861 12176 c16 -18 32 -26 56 -26 18 0 33 -4 33 -10 0 -6 37 -10
          90 -10 73 0 90 3 90 15 0 31 -43 44 -169 51 l-123 7 23 -27z"/>
          <path id="4"   d="M5416 12170 c-38 -10 -72 -23 -75 -28 -3 -6 -33 -19 -65 -31 -33 -11
          -62 -25 -65 -29 -3 -5 -26 -20 -51 -33 -74 -40 -150 -131 -150 -181 0 -9 -6
          -19 -12 -21 -10 -4 -10 -6 0 -6 6 -1 12 -12 12 -25 0 -52 59 -118 145 -161 22
          -11 42 -23 45 -26 7 -8 127 -49 144 -49 8 0 16 -4 18 -10 5 -16 202 -40 386
          -47 94 -3 172 -10 172 -15 0 -4 18 -8 40 -8 22 0 40 4 40 8 0 5 73 12 163 15
          170 7 370 32 375 48 2 5 10 9 19 9 26 0 163 43 163 51 0 4 15 12 33 18 45 15
          114 83 122 121 4 16 11 30 16 30 5 0 9 18 9 40 0 22 -4 40 -10 40 -5 0 -10 6
          -10 14 0 69 -187 214 -301 232 -21 4 -39 10 -39 15 0 11 -57 12 -64 1 -2 -4
          -7 -23 -11 -43 -6 -34 -5 -37 20 -43 33 -8 75 -35 75 -48 0 -5 7 -16 15 -24
          19 -19 20 -98 2 -116 -7 -7 -22 -26 -32 -41 -10 -15 -32 -35 -49 -44 -17 -9
          -33 -19 -36 -23 -6 -8 -165 -50 -191 -50 -10 0 -19 -4 -21 -10 -7 -20 -266
          -33 -433 -20 -88 7 -162 16 -165 20 -3 4 -34 10 -70 14 -74 7 -180 35 -180 47
          0 4 -10 11 -22 15 -13 4 -40 25 -62 48 -36 38 -38 44 -33 89 3 40 11 55 43 86
          21 21 62 46 89 56 28 10 54 22 60 27 5 4 18 8 28 8 15 0 18 5 13 23 -3 12 -6
          34 -6 50 0 31 -2 31 -94 7z"/>
          <path id="5"  d="M12088 12170 c-84 -8 -130 -18 -138 -28 -3 -4 -38 -18 -78 -31 -40
          -14 -75 -28 -78 -32 -3 -5 -27 -20 -55 -33 -27 -14 -64 -39 -82 -56 -17 -16
          -38 -30 -44 -30 -7 0 -13 -7 -13 -15 0 -8 -4 -15 -9 -15 -9 0 -110 -98 -148
          -144 -11 -14 -31 -43 -44 -63 -13 -21 -27 -40 -31 -41 -5 -2 -18 -23 -30 -45
          -11 -23 -24 -44 -27 -47 -11 -9 -31 -60 -31 -80 0 -20 53 -80 86 -98 18 -10
          22 -7 38 29 10 21 21 39 26 39 4 0 13 12 19 28 22 53 45 83 123 163 26 27 50
          49 54 49 10 0 57 40 48 40 -5 1 16 13 46 27 30 15 57 30 60 34 8 12 137 59
          161 59 11 0 18 4 15 9 -8 13 35 20 169 27 122 7 295 -9 295 -27 0 -5 7 -9 16
          -9 19 0 147 -50 154 -60 3 -4 21 -14 40 -22 20 -9 42 -21 51 -29 8 -8 30 -23
          49 -33 41 -23 90 -60 130 -98 15 -16 32 -28 37 -28 9 0 180 -175 238 -244 22
          -27 48 -57 58 -68 21 -23 55 -76 63 -100 4 -10 10 -18 14 -18 5 0 18 -20 30
          -45 12 -25 25 -45 29 -45 4 0 18 -24 32 -52 13 -29 29 -58 35 -65 7 -7 20 -35
          30 -62 10 -28 23 -52 29 -55 8 -3 20 34 35 102 13 59 28 110 33 113 13 9 14
          118 1 127 -6 4 -24 34 -40 67 -15 33 -36 69 -46 79 -10 11 -18 26 -18 33 0 7
          -8 21 -17 31 -10 9 -24 28 -30 42 -20 40 -37 64 -51 73 -6 4 -19 21 -27 37
          -15 29 -35 54 -87 109 -16 16 -28 33 -28 38 0 14 -184 184 -235 217 -16 10
          -34 24 -40 31 -5 7 -27 22 -47 33 -21 10 -36 21 -33 23 2 3 -29 18 -68 34 -40
          16 -77 34 -83 40 -6 6 -47 20 -90 30 -44 11 -81 23 -84 26 -17 23 -286 42
          -412 29z"/>
          <path d="M21900 12112 c0 -6 3 -12 8 -14 4 -1 14 -16 22 -33 l16 -30 128 -3
          c101 -2 127 0 122 10 -3 7 -9 20 -12 29 -3 9 -15 21 -27 27 -30 14 -257 26
          -257 14z"/>
          <path  d="M5473 12023 c-13 -2 -23 -9 -23 -14 0 -5 -6 -9 -14 -9 -26 0 -84 -40
          -89 -61 -3 -12 -2 -31 3 -44 9 -24 60 -65 80 -65 6 0 18 -6 25 -14 15 -14 181
          -44 191 -34 3 3 -2 13 -11 22 -16 16 -65 119 -65 137 0 6 -7 17 -15 25 -8 9
          -15 26 -15 40 0 25 -12 28 -67 17z"/>
          -5 0 -9 36 -12 125 -12 98 0 125 3 125 13 0 30 -35 47 -99 47 -39 0 -60 4 -56
          10 7 11 -127 14 -138 3z"/>
          <path d="M6419 11972 c-23 -65 -34 -88 -46 -100 -19 -20 -43 -69 -37 -75 8 -8
          66 14 83 31 8 7 25 18 38 23 41 15 58 93 26 121 -35 29 -53 30 -64 0z"/>
          <path d="M22010 11922 c0 -5 5 -23 10 -39 11 -29 13 -30 93 -36 45 -4 112 -7
          148 -7 l66 0 -16 34 c-9 18 -19 32 -23 30 -3 -2 -8 0 -10 6 -4 12 -268 24
          -268 12z"/>
          <path id="6"  d="M12114 11821 c3 -4 -20 -11 -52 -14 -31 -2 -77 -13 -101 -23 l-44
          -17 8 -656 c4 -361 10 -658 14 -661 5 -3 11 -203 15 -445 3 -242 9 -567 13
          -722 l6 -282 596 6 c415 4 598 9 604 17 4 6 5 63 2 126 -3 63 -8 529 -10 1035
          -3 505 -8 927 -11 937 -3 10 -9 18 -13 18 -4 0 -18 20 -31 45 -13 25 -27 45
          -31 45 -4 0 -18 19 -32 43 -29 50 -54 79 -158 185 -74 75 -162 153 -182 161
          -5 2 -18 12 -28 22 -11 11 -37 26 -59 35 -22 9 -38 19 -36 23 3 4 -23 18 -57
          31 -34 13 -64 27 -67 30 -10 12 -129 41 -194 47 -36 3 -66 10 -66 14 0 5 -21
          9 -46 9 -25 0 -43 -4 -40 -9z"/>
          <path   d="M22060 11819 c0 -25 22 -49 45 -49 14 0 25 -4 25 -9 0 -6 56 -11 125
          -13 107 -3 125 -1 125 12 0 34 -37 50 -115 50 -43 0 -75 4 -75 10 0 6 -28 10
          -65 10 -39 0 -65 -4 -65 -11z"/>
          <path  d="M22090 11731 c0 -6 4 -11 8 -11 4 0 16 -22 26 -49 l17 -49 107 -6
          c59 -4 133 -9 165 -12 l58 -5 -16 38 c-9 21 -21 43 -28 48 -7 6 -16 18 -20 28
          -5 14 -19 17 -79 17 -40 0 -110 3 -155 7 -60 4 -83 3 -83 -6z"/>
          <path  d="M11760 11667 c-76 -47 -80 -55 -80 -176 0 -63 4 -112 10 -116 6 -4
          10 -56 10 -123 0 -64 7 -317 15 -562 8 -245 17 -555 21 -690 4 -135 10 -247
          14 -250 5 -3 12 -167 16 -365 l9 -360 25 3 c20 2 22 1 10 -7 -11 -8 -6 -11 23
          -11 l38 0 -6 323 c-3 177 -10 531 -15 787 -6 256 -13 714 -17 1018 -7 613 -1
          572 -73 529z"/>
          <path id="7"  d="M4987 11588 c3 -29 9 -57 13 -63 4 -5 11 -30 15 -55 9 -56 34 -120
          47 -120 5 0 21 -12 35 -26 15 -15 47 -36 72 -47 25 -11 51 -25 57 -32 7 -6 38
          -20 71 -30 32 -10 60 -21 63 -25 6 -9 156 -46 225 -56 28 -4 53 -12 57 -18 10
          -15 606 -16 616 0 4 6 36 14 72 18 96 10 265 46 268 57 2 5 12 9 21 9 22 0
          144 40 151 49 3 4 21 17 42 29 39 25 54 54 64 135 4 29 10 54 14 57 4 3 11 38
          15 78 l7 74 -30 -16 c-17 -9 -35 -22 -39 -29 -8 -14 -73 -47 -91 -47 -6 0 -17
          -6 -24 -14 -16 -15 -153 -51 -238 -62 -30 -4 -57 -10 -60 -15 -11 -17 -259
          -34 -495 -34 -245 0 -455 17 -455 36 0 5 -9 9 -20 9 -31 0 -233 49 -240 58 -3
          5 -33 19 -67 31 -35 13 -63 27 -63 32 0 4 -16 16 -36 25 -20 9 -38 23 -41 31
          -3 7 -11 13 -19 13 -10 0 -12 -12 -7 -52z"/>
          <path d="M22156 11574 c4 -16 7 -30 8 -30 1 -3 302 -24 330 -24 23 0 24 2 13
          29 -13 34 -23 36 -230 47 l-128 6 7 -28z"/>
          <path id="8" d="M17800 11528 c-36 -6 -67 -13 -70 -17 -5 -7 -170 -31 -365 -52 -60
          -7 -112 -16 -115 -19 -3 -4 -106 -17 -230 -30 -124 -13 -227 -26 -230 -30 -3
          -4 -133 -19 -290 -34 -157 -15 -294 -31 -305 -36 -11 -5 -137 -19 -280 -31
          -143 -13 -262 -26 -265 -29 -3 -4 -131 -17 -285 -30 -154 -13 -282 -27 -285
          -30 -5 -7 -643 -61 -707 -60 -18 0 -33 -4 -33 -8 0 -5 -123 -16 -272 -25 -150
          -10 -312 -20 -359 -23 l-87 -6 -18 -119 c-9 -65 -21 -119 -26 -119 -4 0 -8
          -12 -8 -26 l0 -26 98 7 c53 4 99 11 102 15 3 3 156 19 340 35 184 16 337 31
          340 35 3 4 128 17 278 30 149 13 272 28 272 32 0 4 12 8 28 8 58 -1 647 54
          652 61 3 3 39 9 80 11 199 14 496 42 500 48 3 4 129 17 280 30 151 13 277 26
          280 30 3 3 158 19 345 34 187 15 349 32 360 36 11 5 91 14 178 21 153 12 157
          12 157 35 0 12 4 24 9 26 9 3 51 209 51 250 0 21 -16 21 -120 6z"/>
          <path  d="M22190 11509 c0 -7 5 -29 12 -50 8 -27 17 -39 29 -39 10 0 94 -7 185
          -15 92 -9 168 -14 170 -13 1 2 -6 24 -17 49 l-20 46 -67 7 c-37 3 -95 6 -129
          6 -35 0 -63 4 -63 10 0 6 -22 10 -50 10 -31 0 -50 -4 -50 -11z"/>
          <path  d="M22220 11390 c0 -6 4 -9 9 -5 5 3 11 -5 13 -17 4 -27 29 -33 196 -43
          61 -4 112 -12 112 -16 0 -5 20 -9 45 -9 50 0 49 -1 34 33 -12 26 -55 37 -145
          37 -23 0 -44 4 -46 9 -5 14 -218 25 -218 11z"/>
          <path  d="M22260 11310 c0 -26 23 -110 30 -110 5 0 10 -10 12 -22 3 -21 12 -24
          108 -37 58 -8 107 -17 110 -21 7 -9 212 -32 219 -24 3 4 0 9 -6 11 -7 3 -13 9
          -13 14 0 5 -16 42 -35 83 l-34 74 -151 12 c-94 8 -150 17 -150 24 0 6 -5 4
          -10 -4 -6 -10 -10 -11 -10 -2 0 7 -14 12 -35 12 -19 0 -35 -4 -35 -10z"/>
          <path  d="M22314 11114 c3 -9 6 -22 6 -30 0 -9 11 -14 29 -14 16 0 31 -4 33 -9
          3 -8 314 -51 367 -51 18 0 22 4 16 18 -4 9 -8 22 -8 28 0 11 -30 16 -269 45
          -54 7 -98 16 -98 21 0 4 -18 8 -41 8 -33 0 -40 -3 -35 -16z"/>
          <path  d="M17514 11073 l-31 -4 -7 -883 c-9 -1041 -2 -1513 23 -1519 9 -3 55
          -5 104 -6 l87 -2 0 230 c0 127 -4 231 -9 231 -4 0 -11 280 -15 623 -3 342 -9
          783 -13 980 l-6 357 -51 -2 c-28 0 -65 -3 -82 -5z"/>
          <path  d="M22343 10990 c5 -27 13 -50 17 -50 4 0 11 -19 14 -43 l7 -44 162 -23
          c89 -13 164 -26 167 -30 6 -9 153 -31 159 -24 3 3 1 8 -6 12 -6 4 -21 41 -34
          82 -13 41 -31 82 -39 91 -9 10 -41 19 -84 24 -38 4 -77 11 -85 16 -13 6 -243
          39 -279 39 -5 0 -5 -22 1 -50z"/>
          <path id="9" d="M17200 11017 c-69 -5 -127 -13 -130 -16 -3 -4 -122 -15 -265 -25
          -143 -9 -279 -19 -302 -23 l-43 -5 7 -332 c3 -182 10 -332 15 -334 4 -2 8 -61
          8 -130 0 -70 7 -343 15 -607 8 -264 15 -492 15 -506 0 -25 3 -27 63 -33 112
          -12 272 -38 277 -46 3 -4 79 -20 170 -35 91 -16 167 -31 170 -35 3 -4 42 -13
          87 -20 80 -13 113 -9 83 10 -13 8 -15 143 -15 1039 0 811 3 1033 13 1042 6 7
          12 26 12 41 0 32 18 30 -180 15z"/>
          <path id="10"  d="M5550 10956 c0 -30 5 -58 10 -61 13 -8 14 -1372 1 -1377 -5 -1 -11
          -74 -13 -160 l-5 -158 189 0 c119 0 188 4 188 10 0 6 66 10 178 10 l177 0 -4
          83 c-2 46 -8 85 -15 89 -8 5 -11 218 -10 800 l0 793 -100 -9 c-115 -10 -519 8
          -524 24 -2 6 -19 10 -38 10 l-34 0 0 -54z"/>
          <path id="11" d="M7785 10943 c-16 -3 -32 -8 -35 -12 -3 -3 -30 -14 -61 -24 -31 -9
          -66 -26 -79 -37 -13 -11 -26 -20 -29 -20 -14 0 -95 -87 -103 -112 -6 -16 -14
          -28 -19 -28 -7 0 -49 -119 -49 -141 0 -5 213 -9 503 -9 276 0 497 4 491 8 -6
          4 -19 32 -29 62 -10 30 -29 66 -41 79 -13 13 -24 28 -24 34 0 9 -61 69 -124
          123 -19 17 -115 54 -140 54 -8 0 -16 5 -18 10 -4 12 -193 22 -243 13z"/>
          <path  d="M16370 10933 c0 -5 -37 -13 -81 -16 -45 -4 -83 -8 -85 -10 -2 -2 1
          -95 6 -208 5 -112 10 -259 10 -327 0 -80 4 -121 10 -117 6 4 10 -30 10 -93 0
          -177 27 -790 46 -1039 4 -46 12 -83 18 -85 6 -2 40 -2 75 0 l64 4 -7 381 c-4
          210 -11 384 -15 387 -4 3 -13 176 -20 385 -20 638 -23 740 -27 744 -2 2 -4 0
          -4 -6z"/>
          <path id="12"  d="M15880 10890 c-102 -9 -192 -19 -200 -22 -8 -4 -179 -19 -380 -33
          -201 -15 -374 -30 -385 -35 -11 -5 -159 -19 -330 -31 -170 -12 -312 -25 -315
          -29 -3 -3 -155 -17 -337 -30 -183 -13 -333 -27 -333 -32 0 -4 -27 -8 -60 -8
          l-60 0 0 -493 c0 -272 3 -650 7 -842 l6 -348 401 6 c221 4 740 10 1154 14 413
          3 752 10 752 15 0 4 88 8 196 8 l197 0 -7 77 c-3 42 -6 118 -6 170 0 52 -4 92
          -8 89 -9 -6 -15 148 -32 899 -6 264 -13 517 -16 563 -6 81 -6 82 -32 81 -15
          -1 -110 -9 -212 -19z"/>
          <path  d="M13280 9662 c0 -813 -3 -1221 -10 -1217 -6 4 -10 -38 -10 -119 l0
          -126 59 0 c33 0 65 2 73 5 11 4 13 224 12 1261 -1 785 -4 1258 -10 1262 -6 4
          -21 23 -34 42 -13 19 -27 37 -31 40 -3 3 -16 21 -28 40 l-21 35 0 -1223z"/>
          <path d="M22391 10783 c5 -25 11 -48 13 -50 2 -2 68 -13 147 -25 79 -11 146
          -24 149 -27 3 -4 49 -14 103 -22 109 -17 107 -18 86 52 -13 43 -7 41 -174 65
          -66 10 -122 21 -125 24 -5 6 -157 30 -191 30 -14 0 -15 -7 -8 -47z"/>
          <path  d="M22410 10681 c0 -17 4 -31 8 -31 5 0 14 -44 21 -98 l13 -98 156 -12
          c86 -7 159 -16 162 -21 3 -4 33 -11 67 -14 34 -4 80 -9 101 -13 l40 -6 -13 58
          c-32 147 -39 166 -60 174 -11 4 -88 18 -170 31 -83 12 -152 26 -155 30 -3 3
          -30 10 -60 13 -30 4 -67 9 -82 12 -26 5 -28 3 -28 -25z"/>
          <path id="13" d="M7323 10463 c-14 -3 -23 -12 -23 -22 0 -21 20 -84 30 -91 3 -3 17
          -34 29 -69 13 -36 29 -70 36 -77 9 -9 137 -11 564 -7 l551 6 7 26 c3 14 12 31
          18 38 11 11 30 93 37 163 l3 25 -190 6 c-215 7 -1028 9 -1062 2z"/>
          <path  d="M22464 10351 c3 -47 8 -86 10 -88 2 -2 62 -9 132 -15 71 -6 131 -14
          134 -18 6 -7 279 -45 284 -39 3 3 -24 169 -28 176 -3 4 -336 43 -370 43 -20 0
          -35 4 -32 9 4 5 -25 11 -65 13 l-72 3 7 -84z"/>
          <path  d="M22494 10163 c4 -43 9 -79 11 -81 2 -2 34 -11 72 -18 37 -8 70 -18
          73 -23 3 -4 49 -18 102 -30 53 -12 98 -25 100 -29 2 -7 219 -62 225 -58 5 4
          -21 162 -28 166 -4 3 -9 19 -11 35 -2 25 -8 31 -33 33 -16 2 -32 7 -35 11 -6
          10 -220 39 -347 47 -51 4 -93 11 -93 15 0 5 -10 9 -21 9 -21 0 -22 -3 -15 -77z"/>
          <path id="14"  d="M7612 10018 c12 -173 19 -612 11 -715 l-9 -103 86 -1 c47 -1 208 -4
          357 -8 l273 -6 -8 85 c-9 111 -9 586 1 718 l7 102 -251 0 c-164 0 -248 3 -244
          10 4 6 -36 10 -112 10 l-118 0 7 -92z"/>
          <path  d="M22507 9977 c2 -45 6 -87 8 -94 2 -6 23 -14 47 -18 24 -4 45 -11 48
          -15 3 -4 57 -19 120 -34 63 -15 117 -31 120 -35 3 -4 51 -18 108 -31 56 -13
          102 -27 102 -32 0 -4 10 -8 23 -8 23 0 23 0 14 88 -10 89 -16 102 -54 102 -12
          0 -23 4 -25 8 -1 5 -55 21 -118 37 -63 15 -117 31 -120 35 -3 4 -50 18 -105
          30 -55 13 -102 27 -105 30 -3 4 -19 10 -37 13 l-31 6 5 -82z"/>
          <path  d="M22520 9696 l0 -143 50 -7 c28 -4 50 -11 50 -15 0 -5 42 -19 93 -31
          50 -13 94 -26 97 -30 6 -9 229 -60 260 -60 12 0 18 -4 15 -10 -4 -6 7 -10 25
          -10 l31 0 -5 68 c-3 37 -8 102 -12 144 -6 76 -6 77 -37 87 -18 5 -49 13 -69
          17 -21 3 -38 9 -38 13 0 4 -46 18 -102 31 -57 13 -105 27 -108 32 -6 7 -211
          58 -236 58 -11 0 -14 -27 -14 -144z"/>
          <path  d="M22520 9466 c0 -41 -3 -81 -6 -90 -5 -12 2 -18 25 -22 17 -4 31 -10
          31 -13 0 -4 45 -18 100 -31 55 -13 100 -27 100 -30 0 -4 60 -20 133 -36 72
          -15 134 -31 137 -35 3 -4 29 -11 58 -15 l52 -7 0 39 c0 22 -3 62 -6 90 l-7 52
          -73 12 c-41 7 -74 16 -74 20 0 4 -17 11 -37 15 -77 13 -208 47 -213 54 -3 4
          -49 18 -102 31 -53 13 -95 27 -92 32 3 4 -2 8 -10 8 -13 0 -16 -14 -16 -74z"/>
          <path  d="M22517 9323 c-3 -5 -7 -57 -8 -118 l-4 -110 33 -3 c18 -2 30 -8 27
          -13 -4 -5 2 -9 12 -9 31 0 248 -43 253 -50 3 -4 61 -18 130 -30 69 -13 127
          -26 130 -30 3 -4 17 -9 33 -12 l27 -5 0 111 0 111 -99 18 c-55 10 -101 22
          -103 26 -2 4 -50 18 -108 30 -58 13 -118 29 -135 37 -23 10 -170 54 -182 54
          -1 0 -4 -3 -6 -7z"/>
          <path id="15"  d="M5560 9110 c-386 -8 -580 -18 -580 -31 0 -5 -29 -9 -65 -9 -36 0 -65
          -4 -65 -8 0 -10 67 -32 94 -32 10 0 15 -4 12 -8 -3 -5 29 -19 69 -31 41 -12
          75 -25 75 -29 0 -4 28 -18 61 -32 34 -13 67 -29 73 -35 6 -5 29 -22 51 -36 41
          -28 225 -205 225 -217 0 -4 12 -21 28 -38 15 -16 40 -55 56 -85 16 -29 33 -56
          37 -59 5 -3 18 -28 30 -55 12 -28 26 -53 31 -56 4 -3 18 -32 29 -65 12 -32 24
          -61 28 -64 8 -5 36 -94 53 -167 5 -24 13 -43 17 -43 4 0 18 -57 31 -127 13
          -71 27 -130 31 -133 10 -7 38 -238 45 -378 4 -67 11 -122 16 -122 4 0 8 -63 8
          -140 0 -77 -4 -140 -8 -140 -5 0 -12 -53 -15 -117 -7 -132 -36 -355 -47 -363
          -4 -3 -10 -23 -13 -45 -13 -81 -42 -195 -49 -195 -4 0 -8 -5 -8 -12 0 -20 -52
          -183 -61 -188 -4 -3 -16 -31 -27 -64 -11 -32 -24 -61 -29 -65 -5 -3 -20 -32
          -32 -63 -13 -32 -26 -58 -30 -58 -4 0 -20 -27 -36 -60 -16 -33 -32 -60 -36
          -60 -4 0 -14 -16 -24 -36 -9 -19 -23 -38 -31 -41 -8 -3 -14 -10 -14 -15 0 -16
          -79 -116 -127 -161 -18 -17 -33 -37 -33 -44 0 -8 -8 -17 -17 -20 -9 -4 -41
          -30 -71 -60 -30 -29 -58 -53 -62 -53 -5 0 -19 -12 -32 -27 -13 -16 -40 -37
          -61 -48 -22 -11 -49 -29 -60 -40 -12 -12 -41 -29 -64 -39 -79 -35 -63 -43 75
          -38 70 3 129 8 132 12 5 6 283 20 835 41 160 6 298 15 307 20 10 5 38 9 63 9
          43 0 125 38 125 58 0 4 24 18 53 31 28 13 57 30 62 36 6 7 34 20 63 30 28 9
          52 20 52 25 0 10 83 35 168 52 31 6 60 15 63 20 17 26 369 25 424 -1 11 -5 52
          -19 92 -30 39 -12 78 -27 85 -34 7 -6 36 -23 63 -36 28 -14 55 -31 60 -38 25
          -34 189 -42 434 -22 144 11 152 15 199 89 9 14 22 29 30 33 7 5 20 26 28 48 9
          21 20 39 24 39 4 0 20 28 34 62 15 35 31 65 34 68 4 3 18 39 32 80 13 41 27
          77 31 80 4 3 18 46 30 95 13 50 27 92 30 95 8 6 45 179 55 258 4 28 10 52 14
          52 4 0 11 19 14 43 13 92 36 305 37 355 1 29 6 55 11 58 11 7 11 324 0 324 -5
          0 -12 57 -15 128 -6 136 -35 374 -45 382 -4 3 -19 65 -34 139 -15 74 -32 137
          -36 140 -5 3 -17 39 -28 80 -10 41 -23 79 -29 85 -5 6 -19 37 -30 70 -10 32
          -24 64 -30 70 -6 6 -20 35 -30 64 -11 29 -27 61 -35 70 -9 10 -25 39 -35 65
          -10 26 -23 51 -29 55 -6 4 -20 24 -30 45 -11 21 -28 48 -37 59 -10 11 -19 24
          -21 30 -2 6 -30 6 -82 -1 -154 -22 -556 -7 -556 20 0 5 -8 9 -17 9 -25 0 -156
          42 -169 55 -6 5 -35 21 -65 35 -56 26 -71 36 -119 83 -15 15 -33 27 -39 27 -6
          0 -23 18 -37 40 -15 22 -30 40 -35 40 -9 0 -49 86 -49 105 0 8 -5 15 -10 17
          -13 4 -32 119 -25 156 l5 29 -92 7 c-110 7 -933 5 -1408 -4z m2072 -889 c49
          -21 81 -45 144 -111 76 -78 81 -84 97 -117 9 -17 20 -33 24 -35 16 -6 92 -175
          109 -240 10 -37 21 -65 24 -63 4 2 18 -39 31 -93 13 -53 27 -99 30 -102 3 -3
          15 -57 25 -120 26 -160 26 -636 1 -785 -10 -55 -22 -101 -27 -103 -6 -2 -10
          -11 -10 -19 0 -22 -41 -157 -49 -163 -4 -3 -20 -38 -35 -77 -16 -40 -33 -73
          -37 -73 -4 0 -17 -21 -29 -46 -13 -25 -32 -56 -44 -68 -11 -13 -34 -39 -51
          -60 -32 -39 -46 -53 -93 -95 -37 -32 -60 -48 -90 -61 -13 -6 -30 -17 -38 -25
          -9 -8 -41 -22 -72 -31 -31 -9 -59 -20 -62 -25 -20 -31 -771 -47 -935 -20 -44
          7 -82 16 -85 20 -3 3 -29 17 -57 30 -29 14 -53 28 -53 33 0 5 -8 12 -17 15
          -25 9 -112 95 -113 111 0 7 -7 15 -15 18 -8 4 -27 29 -41 57 -14 29 -29 54
          -33 57 -4 3 -18 32 -31 65 -13 33 -29 69 -36 80 -7 11 -20 49 -29 85 -9 36
          -20 67 -24 70 -11 8 -29 121 -42 260 -15 170 -7 553 16 690 10 58 20 107 24
          110 4 3 17 45 30 95 12 49 26 92 30 95 4 3 18 33 30 67 13 34 26 63 30 65 4 2
          20 29 35 61 14 31 30 57 34 57 4 0 16 14 27 32 10 18 51 64 91 103 100 98 113
          108 164 136 25 13 48 28 51 32 3 4 36 18 72 31 37 13 64 26 61 28 -7 7 162 27
          311 37 66 5 231 9 367 10 220 1 251 -1 290 -18z"/>
          <path id="16"  d="M7185 8147 c-115 -5 -211 -13 -213 -18 -2 -5 -11 -9 -20 -9 -9 0 9
          -12 41 -27 31 -14 57 -29 57 -34 0 -4 18 -18 40 -30 22 -13 40 -26 40 -30 0
          -5 22 -30 48 -56 26 -26 57 -63 67 -80 11 -18 22 -33 26 -33 4 0 17 -25 30
          -55 12 -30 26 -55 30 -55 4 0 17 -30 30 -67 12 -38 25 -70 29 -73 10 -7 47
          -177 56 -258 4 -34 11 -65 15 -68 12 -7 12 -414 0 -414 -4 0 -11 -39 -15 -87
          -10 -120 -44 -317 -56 -321 -6 -2 -10 -11 -10 -20 0 -30 -44 -157 -57 -165 -7
          -4 -13 -15 -13 -23 0 -17 -29 -76 -40 -84 -4 -3 -17 -21 -27 -40 -29 -50 -93
          -122 -175 -195 -12 -11 -43 -32 -68 -47 -25 -15 -40 -28 -35 -28 6 0 -15 -12
          -45 -27 -30 -14 -60 -32 -68 -39 -7 -8 -20 -14 -28 -14 -8 0 -14 -4 -14 -9 0
          -10 504 -26 551 -17 34 7 110 36 119 46 3 3 27 16 55 30 27 14 63 37 79 53 17
          15 34 27 39 27 10 0 157 147 157 157 0 5 12 22 26 38 15 17 33 46 41 65 8 19
          21 39 28 45 7 5 21 33 31 60 10 28 21 52 25 55 11 8 38 101 51 175 6 39 17 73
          22 77 15 10 15 656 0 666 -6 4 -14 37 -18 74 -8 82 -46 271 -55 278 -4 3 -17
          39 -29 79 -12 41 -26 77 -30 80 -4 4 -16 29 -26 56 -10 28 -24 55 -30 60 -6 6
          -22 31 -35 58 -13 26 -35 60 -50 76 -14 16 -39 46 -56 66 -16 20 -42 42 -57
          50 -14 8 -32 20 -40 27 -42 41 -58 42 -423 25z"/>
          <path id="17" d="M6655 8094 c-59 -13 -105 -27 -105 -34 0 -4 -13 -13 -28 -19 -15 -6
          -41 -23 -57 -37 -17 -15 -40 -33 -52 -41 -13 -9 -23 -18 -23 -22 0 -4 -25 -33
          -55 -66 -30 -32 -55 -64 -55 -71 0 -7 -6 -17 -13 -21 -21 -13 -127 -211 -127
          -237 0 -8 -5 -16 -10 -18 -6 -2 -20 -37 -31 -78 -12 -41 -25 -77 -29 -80 -13
          -9 -41 -182 -47 -292 -3 -60 -10 -108 -14 -108 -5 0 -9 -36 -9 -80 0 -44 4
          -80 9 -80 4 0 11 -48 14 -107 8 -132 35 -317 48 -321 5 -2 9 -11 9 -19 0 -29
          42 -156 56 -170 8 -8 14 -19 14 -24 0 -16 41 -101 56 -116 8 -8 14 -18 14 -22
          0 -7 52 -69 81 -96 6 -5 23 -22 37 -36 15 -14 47 -34 72 -44 25 -11 48 -23 51
          -28 15 -20 147 -31 233 -18 48 7 86 17 86 22 0 5 12 12 28 15 66 17 108 42
          168 102 18 17 38 32 43 32 6 0 20 14 30 31 10 17 28 37 40 45 11 8 21 21 21
          29 0 8 9 20 19 27 10 7 32 40 48 73 15 33 33 65 38 70 6 6 19 35 30 65 10 30
          24 60 29 66 6 6 20 46 31 90 10 43 22 81 26 84 11 8 37 158 45 258 4 50 11 89
          15 86 5 -3 9 63 9 145 0 83 -4 151 -9 151 -4 0 -11 35 -14 78 -7 83 -35 234
          -46 242 -4 3 -18 41 -31 85 -13 44 -27 82 -31 85 -3 3 -17 25 -29 50 -12 25
          -25 47 -29 50 -10 8 -51 69 -51 75 0 11 -115 115 -132 121 -10 3 -18 10 -18
          15 0 6 -21 20 -47 31 -27 11 -51 25 -54 30 -14 22 -192 44 -254 32z m105 -224
          c0 -5 9 -10 20 -10 11 0 45 -8 74 -19 62 -22 158 -110 197 -182 13 -24 29 -50
          36 -57 7 -7 20 -39 29 -72 9 -33 20 -60 24 -60 20 0 60 -275 60 -418 0 -150
          -36 -416 -58 -432 -5 -3 -16 -31 -26 -63 -10 -32 -24 -63 -31 -70 -7 -7 -18
          -30 -26 -51 -8 -22 -26 -51 -41 -65 -16 -14 -28 -31 -28 -37 0 -13 -94 -94
          -110 -94 -6 0 -13 -7 -16 -15 -9 -22 -98 -45 -174 -45 -65 0 -170 24 -170 38
          0 4 -15 16 -34 26 -18 10 -46 36 -62 57 -16 22 -36 48 -45 59 -9 11 -24 41
          -34 67 -10 27 -24 57 -31 68 -7 11 -21 61 -31 110 -10 50 -21 92 -24 95 -13
          10 -29 214 -29 369 0 151 15 320 29 331 4 3 15 40 25 83 10 42 23 83 30 90 7
          6 23 34 36 61 13 27 31 57 41 67 10 10 25 29 34 43 10 14 22 26 29 26 6 0 26
          14 44 30 32 28 118 60 161 60 12 0 21 5 21 10 0 6 18 10 40 10 22 0 40 -4 40
          -10z"/>
          <path  fill="black" d="M6526 7724 c-39 -14 -116 -80 -116 -100 0 -5 -9 -20 -20 -32 -24 -25
          -44 -78 -55 -140 -4 -23 -10 -42 -15 -42 -15 0 -34 -196 -34 -355 -1 -162 19
          -365 35 -365 5 0 9 -10 9 -23 0 -34 39 -162 50 -165 6 -2 10 -9 10 -17 0 -21
          49 -125 59 -125 6 0 18 -13 28 -30 9 -16 23 -30 30 -30 7 0 17 -9 23 -20 26
          -48 223 -54 272 -7 7 7 25 18 40 26 45 23 135 117 154 160 10 23 21 41 26 41
          4 0 17 33 29 73 12 39 25 74 29 77 7 5 40 189 40 223 0 13 -5 13 -37 1 -54
          -19 -166 -17 -232 3 -47 15 -70 32 -142 103 -51 51 -94 104 -107 133 -12 26
          -26 47 -30 47 -11 0 -52 165 -52 209 0 19 -4 30 -10 26 -6 -4 -10 18 -10 59 0
          36 4 66 9 66 4 0 11 24 14 53 8 61 35 149 51 159 17 12 -5 8 -48 -8z"/>
          <path  fill="black" d="M6666 7714 c-32 -31 -8 -84 38 -84 31 0 46 18 46 56 0 44 -51 62 -84
          28z"/>
          <path fill="black" d="M6851 7646 c-39 -14 -60 -47 -61 -97 0 -48 18 -99 36 -99 8 0 17 -7
          20 -15 4 -11 21 -15 55 -15 39 0 55 5 79 27 17 14 30 32 30 40 0 7 5 13 10 13
          6 0 10 18 10 40 0 22 -4 40 -10 40 -5 0 -13 12 -16 26 -4 17 -18 31 -40 40
          -40 17 -67 17 -113 0z"/>
          <path  fill="black" d="M7159 9063 c-7 -64 21 -201 46 -219 8 -6 15 -15 15 -21 0 -16 84 -95
          113 -105 15 -6 27 -13 27 -18 0 -4 28 -18 63 -31 34 -12 64 -26 67 -29 13 -17
          244 -60 323 -60 26 0 47 -4 47 -10 0 -12 216 -14 224 -2 3 5 40 12 83 15 89 7
          274 42 282 54 3 4 39 19 79 32 39 13 72 28 72 32 0 4 21 17 46 29 25 13 48 29
          51 36 3 8 10 14 17 14 10 0 85 76 140 140 17 20 46 80 46 96 0 7 6 15 13 17 6
          3 -28 6 -76 6 l-88 1 -10 -30 c-6 -16 -15 -27 -20 -24 -5 3 -9 0 -9 -6 0 -11
          -55 -74 -94 -108 -10 -10 -32 -29 -48 -43 -16 -15 -47 -32 -69 -39 -21 -7 -39
          -17 -39 -21 0 -9 -75 -33 -155 -50 -33 -7 -63 -18 -67 -23 -11 -17 -500 -15
          -505 2 -3 6 -15 12 -27 12 -40 0 -198 51 -219 71 -11 10 -26 19 -31 19 -16 0
          -102 82 -109 103 -4 9 -11 17 -16 17 -12 0 -31 55 -31 91 0 53 -7 59 -76 59
          -35 0 -64 -3 -65 -7z"/>
          <path  fill="black" d="M7415 9060 c-10 -16 26 -121 44 -128 9 -3 29 -18 44 -34 15 -15 52
          -35 82 -44 30 -10 55 -21 55 -25 0 -4 44 -15 98 -25 174 -32 545 -19 585 21 7
          6 26 15 42 19 44 10 115 40 115 48 0 3 17 17 38 29 42 26 122 103 122 118 0 5
          -8 9 -17 9 -52 -3 -1032 13 -1109 18 -63 4 -94 2 -99 -6z"/>
          <path d="M22499 9056 c0 -6 -4 -44 -8 -83 -7 -72 -6 -73 17 -73 13 0 21 -4 18
          -8 -2 -4 55 -18 127 -30 72 -13 134 -27 137 -31 3 -4 59 -18 125 -31 66 -12
          122 -26 125 -30 3 -5 29 -12 58 -16 l52 -7 0 90 0 89 -77 13 c-43 7 -82 17
          -88 21 -5 4 -62 18 -125 30 -63 12 -117 25 -120 30 -9 13 -240 48 -241 36z"/>
          <path  id="18"  d="M9016 8981 c-9 -26 -25 -57 -34 -67 -10 -11 -28 -34 -40 -51 -28 -40
          -108 -119 -142 -141 -14 -9 -29 -22 -33 -29 -4 -7 -22 -20 -40 -29 -18 -9 -36
          -23 -40 -30 -4 -8 -17 -14 -27 -14 -10 0 -22 -4 -25 -10 -3 -5 -24 -14 -45
          -20 -22 -6 -40 -15 -40 -20 0 -6 -11 -10 -24 -10 -14 0 -28 -4 -31 -10 -7 -11
          23 -80 34 -80 4 0 18 -24 32 -52 13 -29 27 -55 30 -58 9 -7 49 -96 49 -109 0
          -5 7 -17 16 -25 8 -9 25 -42 36 -74 11 -31 23 -59 27 -62 4 -3 18 -43 31 -90
          13 -47 27 -87 31 -90 9 -6 59 -211 59 -240 0 -10 4 -20 9 -22 10 -3 51 -261
          51 -316 0 -17 4 -32 10 -34 25 -9 27 -841 1 -857 -4 -4 -11 -37 -15 -76 -11
          -116 -38 -300 -45 -305 -4 -3 -19 -65 -34 -139 -15 -74 -30 -137 -35 -140 -4
          -3 -18 -46 -31 -96 -12 -49 -25 -92 -29 -95 -4 -3 -18 -39 -31 -80 -13 -41
          -27 -77 -30 -80 -10 -7 -61 -147 -61 -166 0 -14 45 -21 52 -6 4 10 65 52 77
          52 5 0 11 7 14 15 4 8 23 21 44 30 21 9 43 21 48 28 11 14 110 57 132 57 8 0
          12 4 9 8 -6 10 108 38 219 53 105 15 288 5 395 -21 46 -11 87 -24 90 -28 3 -4
          31 -18 63 -31 33 -13 70 -34 84 -47 14 -13 31 -24 37 -24 7 0 32 -18 56 -40
          25 -22 51 -40 58 -40 7 0 11 -4 8 -9 -3 -5 1 -11 8 -14 8 -3 201 -9 428 -12
          227 -4 416 -9 419 -11 4 -2 89 -4 190 -4 167 -1 184 0 196 17 7 10 24 30 38
          46 14 15 25 32 25 37 0 5 8 17 19 27 10 10 26 41 37 68 10 28 21 52 25 55 4 3
          18 46 31 95 13 50 26 92 29 95 10 8 38 213 49 354 13 166 13 547 0 701 -14
          159 -41 359 -49 365 -4 3 -18 59 -31 125 -13 66 -26 122 -30 125 -4 3 -17 46
          -30 95 -13 50 -26 92 -30 95 -4 3 -18 38 -31 78 -12 39 -27 72 -31 72 -4 0 -8
          6 -8 12 0 17 -52 152 -60 158 -4 3 -18 30 -31 60 -12 30 -26 57 -30 60 -3 3
          -17 30 -30 60 -13 30 -28 60 -35 65 -6 6 -22 32 -35 60 -13 27 -32 58 -42 68
          -10 10 -23 32 -29 48 -5 16 -13 27 -18 24 -4 -2 -17 16 -30 40 -13 24 -27 42
          -31 39 -5 -3 -9 0 -9 6 0 18 -67 106 -163 214 -69 77 -195 206 -201 206 -3 0
          -29 23 -58 51 -28 28 -72 64 -97 82 -25 17 -49 34 -52 39 -5 8 -162 16 -766
          39 -178 7 -323 16 -323 21 0 4 -37 8 -83 8 l-83 0 -18 -49z"/>
          <path  id="19" d="M4500 9004 c-94 -11 -220 -37 -220 -45 0 -5 -7 -9 -16 -9 -26 0 -168
          -52 -179 -65 -5 -7 -31 -20 -57 -30 -27 -10 -48 -21 -48 -25 0 -3 -18 -16 -40
          -29 -22 -12 -40 -26 -40 -29 0 -4 -15 -16 -32 -27 -33 -19 -218 -201 -273
          -268 -16 -21 -39 -47 -51 -60 -11 -12 -29 -37 -39 -54 -11 -18 -22 -33 -26
          -33 -3 0 -17 -22 -30 -50 -13 -27 -27 -50 -31 -50 -4 0 -20 -26 -35 -57 -15
          -32 -31 -60 -34 -63 -11 -9 -76 -135 -88 -173 -7 -21 -18 -43 -24 -50 -7 -6
          -23 -46 -36 -87 -13 -41 -29 -84 -36 -95 -7 -11 -23 -65 -36 -120 -12 -55 -26
          -102 -30 -105 -4 -3 -18 -68 -30 -145 -13 -77 -26 -142 -30 -145 -3 -3 -12
          -70 -20 -150 -26 -267 -13 -807 20 -885 5 -11 19 -72 31 -135 12 -63 26 -116
          31 -118 5 -2 9 -10 9 -17 0 -25 52 -178 64 -191 7 -6 20 -33 30 -58 10 -25 21
          -46 26 -46 4 0 17 -15 28 -34 48 -82 256 -286 291 -286 5 0 11 -6 14 -13 3 -8
          21 -22 41 -31 20 -9 36 -21 36 -25 0 -4 32 -20 70 -35 39 -15 70 -31 70 -35 0
          -4 35 -18 78 -31 42 -14 80 -28 83 -33 8 -11 211 -47 265 -47 24 0 46 -4 49
          -10 8 -12 175 -14 175 -1 0 4 35 11 78 15 83 7 203 32 212 44 3 4 40 18 83 32
          42 13 77 26 77 30 0 4 29 20 65 36 36 16 65 32 65 36 0 3 17 17 38 29 20 13
          43 31 50 41 7 10 18 18 23 18 9 0 60 50 205 200 14 14 22 29 18 32 -3 4 4 13
          15 20 12 7 33 37 47 66 13 28 28 52 33 52 4 0 18 23 31 50 13 28 26 50 30 50
          3 0 14 23 24 50 10 28 24 56 30 63 7 7 25 50 41 97 16 47 32 87 36 90 6 5 9
          16 35 110 8 30 21 69 28 85 8 17 22 73 32 125 10 52 22 97 25 100 14 10 59
          332 59 418 0 22 5 42 10 44 13 4 14 499 2 507 -5 3 -12 43 -15 88 -8 106 -47
          346 -57 353 -4 3 -15 40 -24 83 -9 44 -22 93 -30 110 -8 18 -22 58 -31 89 -9
          32 -23 64 -31 72 -8 8 -14 18 -14 23 0 15 -53 143 -60 143 -4 0 -17 22 -30 50
          -13 27 -26 48 -28 45 -3 -2 -15 16 -27 41 -12 24 -31 52 -43 60 -12 8 -22 20
          -22 27 0 12 -84 94 -144 142 -22 17 -46 37 -55 45 -9 8 -37 26 -61 39 -25 14
          -50 30 -57 36 -6 6 -44 22 -85 35 -40 12 -75 26 -78 31 -16 23 -328 55 -430
          43z m908 -1609 c66 -20 172 -118 172 -159 0 -30 -6 -31 -93 -12 -32 6 -55 15
          -51 19 13 13 -146 8 -160 -5 -13 -13 0 -39 45 -90 9 -10 22 -37 28 -60 7 -23
          19 -47 26 -54 7 -8 23 -58 35 -111 12 -54 27 -109 33 -122 16 -32 17 -389 1
          -399 -6 -4 -16 -27 -22 -52 -11 -51 -44 -142 -53 -150 -4 -3 -17 -25 -29 -50
          -12 -25 -26 -47 -30 -50 -4 -3 -18 -24 -30 -47 -12 -24 -26 -43 -31 -43 -4 0
          -19 -18 -31 -40 -13 -22 -28 -40 -35 -40 -6 0 -14 -8 -17 -17 -3 -10 -29 -38
          -57 -63 -28 -25 -65 -57 -81 -72 -17 -16 -34 -28 -37 -28 -3 0 -17 -10 -31
          -23 -14 -12 -53 -36 -87 -52 -34 -16 -60 -31 -58 -35 2 -3 -31 -16 -73 -30
          -43 -14 -80 -28 -83 -32 -14 -20 -156 -40 -309 -45 -143 -4 -179 -2 -270 17
          -57 12 -107 24 -110 28 -3 4 -32 18 -65 32 -33 13 -66 30 -73 37 -7 7 -19 13
          -26 13 -8 0 -31 16 -52 36 -22 19 -50 42 -64 51 -14 8 -37 31 -50 50 -13 20
          -37 49 -52 65 -15 16 -30 38 -33 49 -4 10 -10 19 -15 19 -5 0 -19 22 -31 50
          -12 27 -24 48 -28 46 -3 -2 -17 29 -31 70 -13 41 -27 74 -31 74 -18 0 -43 190
          -42 315 0 127 23 282 42 294 5 3 14 25 21 49 7 23 16 42 19 42 4 0 7 -83 6
          -184 0 -135 3 -188 13 -197 6 -7 12 -21 12 -31 0 -24 41 -141 51 -148 5 -3 19
          -26 32 -51 26 -49 134 -159 155 -159 7 0 11 -4 8 -9 -4 -5 14 -19 39 -31 25
          -12 45 -26 45 -31 0 -9 154 -59 182 -59 9 0 18 -6 21 -12 3 -10 46 -13 172
          -13 126 0 169 3 173 13 2 6 12 12 23 12 24 0 172 50 179 60 3 4 25 18 50 30
          25 12 47 26 50 29 3 4 22 17 43 30 41 25 68 47 128 101 53 49 60 56 93 94 16
          18 38 45 50 58 20 24 29 38 59 90 6 12 17 28 23 35 7 6 20 35 30 63 10 27 21
          50 25 50 18 0 44 159 44 270 0 149 -28 300 -56 300 -11 0 -19 -4 -19 -9 0 -5
          -15 -14 -32 -20 -18 -6 -51 -26 -72 -44 -98 -80 -131 -111 -141 -131 -12 -24
          -61 -54 -71 -43 -7 7 23 98 36 107 4 3 17 29 30 58 13 28 27 52 31 52 4 0 10
          8 13 18 4 9 20 33 37 52 17 19 41 46 53 60 25 28 49 45 86 61 13 5 28 15 31
          21 11 17 138 47 205 47 33 1 84 -6 112 -14z"/>
          <path  fill="black" d="M5300 6990 c0 -22 5 -40 10 -40 6 0 10 -53 10 -140 0 -77 -4 -140 -9
          -140 -4 0 -11 -25 -14 -56 -7 -68 -38 -207 -48 -214 -4 -3 -18 -32 -30 -65
          -12 -33 -28 -65 -35 -72 -6 -7 -22 -33 -34 -58 -12 -25 -25 -45 -29 -45 -3 0
          -17 -18 -31 -40 -14 -22 -28 -40 -32 -40 -3 0 -16 -16 -28 -35 -12 -19 -28
          -35 -35 -35 -8 0 -22 -13 -32 -30 -9 -16 -22 -30 -28 -30 -5 0 -32 -20 -60
          -45 -27 -25 -55 -45 -61 -45 -5 0 -19 -9 -31 -19 -11 -11 -40 -29 -64 -41 -24
          -13 -46 -26 -49 -29 -3 -4 -34 -18 -70 -31 -36 -13 -67 -27 -70 -32 -3 -4 -39
          -15 -80 -25 -56 -12 -122 -17 -257 -17 -168 -1 -180 -2 -164 -17 31 -29 150
          -49 288 -49 143 0 307 23 330 45 8 8 20 15 28 15 16 0 107 38 115 48 3 4 25
          18 50 31 25 14 47 28 50 31 3 4 19 16 35 26 33 20 47 33 141 121 62 59 154
          166 154 180 0 5 11 21 25 37 13 15 32 48 41 72 9 24 20 44 24 44 9 0 50 124
          50 151 0 9 6 19 13 22 16 5 18 404 1 415 -6 4 -14 25 -18 47 -11 61 -39 145
          -48 145 -4 0 -8 -18 -8 -40z"/>
          <path d="M5231 6664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path  fill="black"  d="M5213 6597 c-7 -26 -17 -47 -23 -47 -5 0 -10 -7 -10 -16 0 -24 -52
          -125 -82 -158 -15 -16 -26 -32 -25 -35 4 -13 -202 -225 -259 -267 -22 -15 -43
          -33 -49 -38 -5 -6 -35 -24 -65 -40 -30 -16 -57 -32 -60 -36 -3 -3 -34 -17 -70
          -30 -36 -13 -67 -27 -70 -30 -3 -4 -41 -16 -85 -26 -147 -35 -495 -21 -538 20
          -7 7 -31 18 -55 26 -24 7 -53 24 -65 37 -12 12 -26 23 -31 23 -5 0 -18 8 -29
          18 -20 17 -20 17 -2 -15 11 -18 22 -33 26 -33 4 0 28 -20 54 -45 26 -24 61
          -50 76 -57 16 -7 29 -17 29 -21 0 -4 22 -17 50 -29 28 -11 50 -24 50 -28 0 -4
          28 -16 63 -26 86 -25 281 -25 395 0 45 11 82 23 82 27 0 5 5 9 11 9 23 0 129
          43 124 50 -4 7 73 44 87 42 4 -1 19 12 35 28 15 17 32 30 36 30 11 0 114 87
          143 120 12 14 36 40 53 59 17 19 33 43 37 53 3 10 9 18 14 18 5 0 19 18 30 40
          11 22 24 40 29 40 5 0 11 8 14 18 16 52 51 138 58 142 13 9 41 154 37 192 -3
          32 -3 31 -15 -15z"/>
          <path  fill="black"  d="M10415 8941 c11 -5 33 -20 48 -35 15 -14 32 -26 36 -26 4 0 16 -9 26
          -20 10 -11 22 -20 26 -20 4 0 35 -27 69 -59 34 -32 70 -66 79 -74 17 -16 112
          -120 160 -177 41 -48 93 -118 109 -146 7 -13 17 -24 21 -24 4 0 21 -25 36 -55
          16 -30 32 -55 35 -55 4 0 17 -21 30 -47 13 -27 29 -52 35 -58 6 -5 20 -30 31
          -55 10 -25 25 -52 33 -61 7 -9 22 -41 32 -70 11 -30 23 -55 28 -57 5 -2 19
          -31 32 -65 12 -34 26 -64 29 -67 4 -3 17 -34 29 -70 13 -36 27 -70 31 -75 5
          -6 19 -47 31 -90 12 -44 25 -82 29 -85 4 -3 20 -61 35 -130 15 -69 31 -127 35
          -130 9 -5 50 -250 50 -292 0 -15 4 -28 9 -30 15 -5 31 -282 31 -524 0 -227
          -16 -504 -30 -514 -4 -3 -10 -30 -14 -60 -9 -73 -37 -204 -45 -210 -4 -3 -20
          -45 -36 -94 -16 -49 -37 -98 -47 -108 -10 -10 -18 -25 -18 -33 0 -8 -8 -23
          -17 -33 -19 -19 -53 -68 -53 -77 0 -3 31 -5 69 -5 67 0 70 1 83 30 7 17 16 30
          20 30 4 0 17 19 29 43 12 23 25 44 29 47 4 3 17 34 29 70 13 36 26 67 30 70
          10 6 47 163 57 238 3 29 10 52 14 52 9 0 27 146 41 330 22 276 -6 885 -40 910
          -4 3 -15 52 -25 110 -20 117 -38 192 -47 200 -3 3 -16 48 -29 100 -13 52 -27
          97 -31 100 -7 5 -31 81 -46 148 -3 12 -8 22 -12 22 -4 0 -20 39 -36 88 -16 48
          -35 93 -41 99 -7 7 -20 35 -29 63 -9 27 -20 50 -24 50 -4 0 -15 23 -25 50 -10
          28 -23 54 -30 58 -6 4 -20 27 -31 52 -10 25 -24 50 -30 55 -6 6 -24 35 -39 65
          -16 30 -35 59 -42 63 -8 4 -14 14 -14 21 0 7 -10 24 -22 38 -13 13 -30 37 -38
          52 -8 15 -24 37 -35 49 -12 12 -32 37 -45 56 -18 27 -71 86 -128 143 -45 47
          -145 138 -151 138 -3 0 -17 10 -31 23 -71 65 -86 70 -204 74 -71 2 -104 0 -91
          -6z"/>
          <path  id="21"   d="M10770 8931 c0 -6 4 -11 8 -11 11 0 226 -213 252 -251 13 -17 32 -41
          44 -53 11 -12 35 -40 51 -61 17 -22 42 -54 58 -71 15 -17 27 -36 27 -41 0 -6
          10 -21 23 -34 12 -14 33 -46 47 -72 14 -26 28 -47 32 -47 4 0 14 -15 21 -33 8
          -18 20 -39 28 -47 8 -8 25 -39 38 -67 14 -29 27 -53 31 -53 4 0 19 -31 34 -70
          15 -38 31 -70 35 -70 4 0 18 -31 31 -69 13 -39 26 -68 30 -66 3 2 16 -32 29
          -76 13 -43 27 -79 31 -79 3 0 20 -46 35 -102 16 -57 32 -105 35 -108 7 -6 36
          -124 46 -187 4 -24 10 -43 14 -43 7 0 23 -80 41 -220 7 -52 16 -97 20 -100 13
          -10 29 -262 29 -455 0 -217 -17 -498 -31 -503 -5 -2 -9 -16 -9 -30 0 -41 -42
          -286 -50 -292 -4 -3 -13 -30 -19 -60 -15 -65 -34 -123 -42 -130 -3 -3 -18 -39
          -33 -80 -15 -41 -30 -77 -34 -80 -4 -3 -15 -17 -24 -32 -13 -20 -14 -28 -5
          -36 13 -10 1627 -48 1809 -43 10 1 21 15 28 36 6 19 15 35 19 35 9 0 61 128
          61 148 0 7 4 11 9 8 4 -3 16 27 26 67 9 39 23 85 31 100 7 16 23 79 36 140 13
          62 26 114 29 117 9 8 38 239 50 410 15 198 5 529 -20 724 -11 82 -24 152 -28
          155 -4 3 -18 58 -31 121 -13 63 -27 117 -32 120 -4 3 -13 25 -20 50 -18 72
          -34 114 -47 128 -7 7 -13 19 -13 26 0 16 -40 109 -49 116 -4 3 -18 30 -30 60
          -13 30 -27 57 -31 60 -3 3 -17 25 -31 50 -13 25 -30 51 -37 58 -7 8 -19 27
          -28 43 -16 31 -26 45 -75 99 -16 17 -29 35 -29 39 0 10 -59 64 -96 86 -16 10
          -36 26 -44 35 -8 10 -35 28 -59 41 -25 13 -56 34 -69 47 -14 12 -30 22 -36 22
          -6 0 -22 12 -35 25 -13 14 -29 25 -35 25 -7 0 -21 8 -32 19 -11 10 -42 31 -70
          46 -27 15 -51 30 -54 34 -3 4 -25 18 -50 32 -25 13 -47 28 -49 32 -2 5 -23 17
          -47 27 -24 11 -44 23 -44 29 0 5 -23 17 -50 27 -28 10 -58 25 -68 34 -9 9 -39
          27 -67 40 -27 13 -52 27 -55 31 -3 4 -34 19 -70 34 -36 15 -67 30 -70 34 -3 4
          -30 18 -60 30 -30 13 -57 27 -60 30 -6 8 -139 51 -158 51 -7 0 -12 4 -12 10 0
          16 -53 19 -450 30 -212 5 -429 13 -482 16 -70 5 -98 3 -98 -5z"/>
          <path  fill="black"  d="M12739 8926 c-2 -2 -130 -5 -284 -7 -154 -1 -283 -4 -288 -6 -12 -7
          17 -30 68 -53 27 -12 54 -28 61 -35 7 -7 34 -21 58 -31 25 -11 46 -22 46 -25
          0 -4 26 -19 58 -35 31 -15 59 -31 62 -34 3 -4 24 -17 48 -29 23 -12 42 -25 42
          -29 0 -4 27 -20 60 -35 33 -16 60 -32 60 -37 0 -4 21 -18 46 -30 25 -13 57
          -33 71 -46 14 -13 32 -24 39 -24 7 0 23 -11 36 -24 13 -13 38 -32 57 -42 18
          -11 47 -31 63 -46 17 -16 34 -28 38 -28 5 0 22 -13 38 -30 17 -16 34 -30 39
          -30 4 0 8 149 9 330 l1 330 -212 0 c-116 0 -214 -2 -216 -4z"/>
          <path  id="22"  d="M15560 8922 c-113 -1 -621 -6 -1130 -10 l-925 -7 -8 -333 c-5 -183
          -7 -336 -4 -338 7 -7 239 16 262 26 11 5 139 19 285 31 146 13 267 26 270 29
          3 4 127 17 275 30 149 13 272 27 275 30 3 4 151 20 330 35 179 16 327 32 330
          35 3 4 127 17 275 30 149 13 272 26 275 30 3 3 127 17 275 30 149 13 272 26
          275 30 3 4 152 19 333 35 180 16 327 32 327 37 0 4 22 8 48 8 27 0 51 2 53 4
          2 3 3 25 1 50 l-4 45 -46 7 c-26 4 -49 10 -52 13 -3 4 -66 17 -140 30 -74 13
          -137 26 -140 30 -3 4 -66 18 -140 30 -74 13 -137 27 -140 31 -3 4 -46 14 -96
          23 -92 17 -382 19 -1064 9z"/>
          <path  d="M22461 8769 c-13 -108 -10 -118 31 -119 9 0 18 -4 20 -8 3 -8 119
          -43 186 -57 18 -4 30 -10 28 -14 -3 -4 35 -17 84 -30 49 -13 90 -26 92 -30 2
          -4 39 -17 83 -30 44 -13 82 -26 85 -30 3 -5 17 -11 31 -14 l26 -7 6 98 c4 53
          7 120 7 148 l0 52 -92 17 c-51 10 -95 21 -98 25 -3 4 -59 17 -125 30 -66 13
          -122 26 -125 30 -5 6 -182 40 -212 40 -12 0 -18 -22 -27 -101z"/>
          <path  d="M22436 8599 c-3 -17 -6 -42 -6 -55 0 -13 -4 -24 -10 -24 -5 0 -10
          -13 -10 -30 0 -25 4 -30 25 -30 14 0 25 -3 25 -8 0 -4 48 -21 108 -37 59 -16
          109 -32 112 -36 3 -4 42 -17 88 -30 45 -12 82 -26 82 -31 0 -4 6 -8 13 -8 19
          0 151 -42 157 -49 3 -4 22 -12 43 -18 37 -11 37 -11 37 18 0 16 5 29 10 29 6
          0 10 26 10 59 0 63 -1 65 -80 87 -14 4 -27 10 -30 14 -3 3 -43 17 -90 30 -47
          13 -88 28 -91 33 -6 8 -59 24 -149 44 -14 3 -28 10 -31 15 -5 8 -177 57 -200
          58 -4 0 -9 -14 -13 -31z"/>
          <path  id="23" d="M17680 8571 c0 -7 -28 -11 -72 -11 -64 0 -193 -11 -453 -38 l-70 -7
          -6 -490 c-8 -634 -19 -1019 -30 -1023 -5 -2 -9 -48 -9 -102 0 -108 -15 -461
          -30 -724 -11 -186 -12 -189 -80 -202 -19 -4 -37 -10 -40 -14 -5 -7 -268 -50
          -307 -50 -12 0 -23 -4 -25 -9 -3 -9 -385 -61 -447 -61 -18 0 -30 -4 -27 -8 4
          -7 -384 -53 -446 -52 -16 0 -28 -4 -28 -9 0 -4 -39 -11 -87 -15 -301 -24 -488
          -36 -556 -36 -50 0 -76 -4 -72 -10 3 -5 2 -10 -4 -10 -5 0 -12 6 -14 13 -4 9
          -6 9 -6 0 -1 -16 -381 -19 -381 -3 0 6 -26 10 -58 10 -62 0 -82 11 -82 46 0
          12 -4 24 -9 26 -9 3 -35 324 -47 583 -4 77 -10 142 -14 145 -13 9 -60 1181
          -60 1508 l0 184 -117 -7 c-65 -4 -120 -11 -123 -14 -3 -4 -63 -13 -135 -19
          -189 -18 -305 -34 -305 -41 0 -3 12 -19 28 -36 29 -32 48 -60 58 -87 3 -10 10
          -18 15 -18 5 0 21 -25 34 -55 14 -30 29 -55 33 -55 5 0 16 -23 26 -50 10 -28
          24 -56 31 -63 6 -7 22 -44 35 -82 13 -39 26 -72 30 -75 9 -7 26 -55 46 -130
          10 -36 21 -67 25 -70 4 -3 17 -48 29 -100 11 -52 25 -104 30 -115 11 -24 50
          -236 50 -276 0 -15 4 -29 9 -31 11 -4 31 -273 31 -428 0 -143 -19 -423 -30
          -430 -4 -3 -10 -32 -14 -65 -11 -101 -39 -260 -46 -265 -4 -3 -17 -50 -30
          -105 -12 -55 -26 -102 -30 -105 -4 -3 -19 -47 -33 -99 -14 -52 -30 -97 -34
          -100 -10 -7 -45 -112 -39 -118 2 -2 41 1 87 7 103 12 304 13 423 0 100 -10
          258 -40 266 -49 23 -28 92 -33 555 -42 l480 -9 63 65 c35 36 67 65 71 65 4 0
          16 9 26 20 10 11 24 20 30 20 7 0 15 6 18 14 3 8 22 22 41 31 20 10 36 21 36
          25 0 5 25 20 55 35 30 14 55 29 55 34 0 5 28 19 63 32 34 12 65 27 68 31 3 5
          39 20 80 34 41 13 88 31 104 38 17 8 77 22 135 32 58 10 108 23 112 28 11 17
          490 15 496 -1 2 -7 12 -13 22 -13 28 0 204 -42 210 -50 3 -4 36 -17 74 -29 38
          -12 81 -31 94 -42 14 -10 30 -19 37 -19 7 0 23 -10 37 -22 14 -13 46 -34 72
          -48 26 -14 52 -33 57 -42 5 -10 15 -18 22 -18 7 0 41 -27 77 -60 36 -33 68
          -60 71 -60 9 0 115 -106 139 -140 11 -16 35 -44 51 -62 41 -44 51 -57 79 -98
          41 -60 52 -61 71 -2 6 20 15 39 18 42 4 3 18 39 31 80 14 41 30 80 35 86 6 6
          21 52 35 102 13 51 27 92 31 92 4 0 18 44 30 98 13 53 27 99 31 102 4 3 17 57
          30 120 12 63 25 117 29 120 7 5 49 282 49 323 0 14 4 27 9 29 12 4 31 295 31
          485 0 175 -18 465 -29 473 -4 3 -13 52 -20 110 -17 144 -34 240 -42 240 -3 0
          -17 52 -29 115 -13 64 -25 113 -28 110 -3 -3 -16 37 -29 87 -13 51 -27 95 -32
          98 -4 3 -20 47 -36 97 -16 51 -31 91 -33 88 -3 -3 -16 25 -29 62 -13 37 -27
          69 -32 72 -5 4 -17 26 -27 51 -9 25 -24 53 -32 62 -8 10 -25 40 -38 68 -13 28
          -30 58 -39 66 -8 9 -15 21 -15 28 0 7 -10 24 -22 38 -13 14 -32 40 -42 59 -44
          77 -69 89 -213 96 -55 3 -83 1 -83 -6z"/>
          <path fill="black"  d="M8365 8530 c-45 -6 -59 -32 -26 -49 11 -6 28 -27 37 -46 9 -19 20
          -35 25 -35 4 0 20 -27 36 -60 15 -33 30 -60 34 -60 4 0 17 -27 29 -60 12 -33
          26 -60 30 -60 4 0 18 -30 30 -67 12 -38 26 -70 30 -73 8 -6 60 -189 60 -212 0
          -9 4 -18 9 -20 5 -1 20 -52 32 -113 13 -60 26 -112 29 -115 8 -6 37 -187 46
          -285 4 -38 12 -73 18 -77 17 -11 15 -660 -1 -666 -7 -2 -13 -17 -13 -32 0 -52
          -42 -355 -49 -360 -4 -3 -16 -48 -26 -100 -10 -52 -23 -108 -30 -125 -8 -16
          -25 -73 -40 -125 -15 -52 -30 -97 -34 -100 -4 -3 -18 -38 -31 -77 -13 -40 -27
          -73 -31 -73 -3 0 -17 -27 -29 -60 -12 -33 -26 -60 -30 -60 -4 0 -21 -26 -36
          -57 -16 -32 -37 -64 -46 -71 -37 -27 -22 -42 41 -42 l59 0 19 45 c10 25 22 45
          26 45 4 0 17 21 28 47 11 26 24 50 28 53 7 5 44 97 58 145 4 11 10 22 14 25 8
          6 49 140 49 163 0 8 4 17 9 19 10 3 51 188 51 227 0 12 4 21 9 21 14 0 61 400
          61 521 0 33 4 59 10 59 6 0 10 83 10 230 0 127 -4 230 -8 230 -5 0 -12 48 -15
          108 -8 130 -46 435 -56 442 -4 3 -17 57 -30 120 -13 63 -26 117 -30 120 -4 3
          -17 43 -30 90 -13 47 -26 87 -30 90 -4 3 -20 42 -36 88 -16 45 -31 82 -35 82
          -3 0 -17 26 -30 58 -13 31 -27 59 -30 62 -4 3 -17 24 -29 48 -12 23 -25 42
          -30 42 -5 0 -11 8 -15 18 -6 17 -42 82 -48 86 -2 1 -21 -1 -43 -4z"/>
          <path  id="24"   d="M16725 8469 c-88 -11 -299 -34 -470 -51 -170 -17 -312 -34 -315 -38
          -3 -3 -122 -17 -265 -30 -143 -13 -262 -26 -265 -30 -3 -3 -122 -17 -265 -30
          -143 -13 -262 -26 -265 -30 -3 -3 -120 -16 -260 -29 -140 -12 -266 -25 -279
          -27 l-24 -5 7 -427 c4 -234 11 -429 15 -432 5 -3 9 -54 10 -115 1 -96 15 -424
          37 -902 4 -79 10 -143 14 -143 4 0 10 -57 13 -127 4 -71 10 -158 13 -195 l7
          -67 45 -7 c110 -17 1035 23 1052 45 3 4 136 19 295 35 160 16 292 32 295 36 3
          4 94 17 202 30 108 13 195 27 192 32 -3 4 7 8 22 8 47 0 349 53 352 61 2 5 10
          9 18 9 10 0 14 13 14 45 0 25 4 45 8 45 5 0 12 78 16 173 28 702 36 927 36
          1034 0 67 4 123 9 125 6 2 13 213 17 516 l7 512 -64 -1 c-35 -1 -136 -10 -224
          -20z"/>
          <path    d="M22386 8352 c-9 -50 -16 -97 -16 -107 0 -9 -5 -13 -11 -10 -7 5 -9
          -3 -7 -21 3 -24 11 -30 73 -53 39 -14 72 -28 75 -31 3 -4 43 -20 90 -35 47
          -15 87 -31 90 -35 3 -4 36 -17 75 -29 38 -12 72 -25 75 -29 5 -8 137 -52 153
          -52 5 0 15 -7 23 -15 9 -8 23 -15 33 -15 16 0 21 17 35 123 24 185 28 171 -54
          193 -38 10 -70 21 -70 25 0 3 -37 17 -82 29 -46 13 -85 27 -88 30 -7 9 -100
          40 -121 40 -10 0 -24 6 -31 13 -7 7 -55 23 -106 35 -51 13 -99 25 -106 28 -10
          4 -18 -19 -30 -84z"/>
          <path  d="M22337 8157 c-2 -7 -10 -45 -17 -85 -16 -85 -17 -83 73 -108 31 -9
          57 -20 57 -24 0 -4 41 -20 90 -36 50 -16 90 -32 90 -36 0 -3 34 -16 75 -29 41
          -12 75 -25 75 -29 0 -4 37 -18 83 -31 45 -12 88 -29 95 -36 7 -7 24 -13 36
          -13 22 0 25 7 36 79 7 44 9 84 6 89 -3 6 -28 15 -56 22 -27 7 -50 15 -50 19 0
          4 -35 18 -77 31 -43 13 -80 26 -83 30 -3 4 -36 17 -75 30 -38 13 -72 26 -75
          30 -3 4 -43 20 -90 35 -47 15 -87 31 -90 35 -5 7 -31 18 -72 30 -18 5 -28 4
          -31 -3z"/>
          <path fill="black" d="M2970 8133 c0 -5 21 -17 46 -28 25 -11 67 -43 93 -72 26 -28 52 -48
          58 -45 17 12 47 102 37 112 -6 6 -38 13 -72 16 -34 4 -62 11 -62 15 0 5 -22 9
          -50 9 -27 0 -50 -3 -50 -7z"/>
          <path fill="black" d="M13280 8099 c0 -12 38 -70 53 -80 10 -7 34 -46 43 -71 3 -10 10 -18
          15 -18 5 0 18 -19 30 -42 12 -24 25 -45 30 -48 4 -3 20 -32 35 -65 15 -33 34
          -66 41 -73 7 -7 13 -18 13 -23 0 -6 15 -43 34 -82 19 -40 40 -93 47 -119 6
          -27 15 -48 19 -48 4 0 20 -51 35 -112 16 -62 32 -115 36 -118 4 -3 18 -73 30
          -155 13 -83 26 -152 30 -155 4 -3 12 -95 18 -205 12 -205 1 -566 -18 -610 -5
          -11 -19 -90 -31 -175 -12 -85 -26 -156 -31 -158 -5 -2 -9 -11 -9 -20 -1 -32
          -56 -243 -62 -237 -3 4 -9 -9 -13 -27 -10 -47 -36 -122 -44 -128 -4 -3 -18
          -32 -31 -65 -13 -33 -32 -72 -42 -86 l-18 -27 63 -6 c34 -4 70 -4 80 0 18 6
          110 186 123 239 4 17 11 32 15 35 5 3 18 44 31 90 12 47 25 87 29 90 8 6 59
          251 59 286 0 13 4 24 8 24 24 0 56 443 48 650 -7 191 -33 433 -47 438 -5 2 -9
          13 -9 25 0 34 -52 291 -60 297 -4 3 -18 46 -30 95 -13 50 -26 92 -30 95 -4 3
          -17 37 -29 77 -12 39 -26 73 -30 75 -4 2 -20 39 -36 83 -15 44 -32 82 -35 85
          -4 3 -18 25 -30 50 -12 25 -26 47 -30 50 -4 3 -16 21 -26 40 -18 34 -28 47
          -86 110 -25 29 -30 30 -107 30 -52 0 -81 -4 -81 -11z"/>
          <path fill="black" d="M2548 8078 c-27 -6 -48 -14 -48 -19 0 -5 -6 -9 -14 -9 -22 0 -110
          -42 -119 -56 -4 -8 -14 -14 -22 -14 -8 0 -24 -13 -37 -30 -12 -16 -27 -30 -33
          -30 -13 0 -48 -37 -85 -90 -14 -19 -30 -39 -37 -45 -6 -5 -22 -35 -34 -65 -13
          -30 -29 -61 -35 -67 -7 -7 -20 -48 -30 -90 -10 -43 -21 -80 -25 -83 -13 -9
          -29 -171 -29 -295 0 -121 16 -296 29 -305 3 -3 14 -45 24 -94 10 -50 26 -108
          36 -130 10 -23 29 -69 41 -103 13 -35 26 -63 30 -63 5 0 18 -20 30 -45 29 -59
          95 -135 187 -214 31 -27 127 -70 156 -71 10 0 16 -4 12 -9 -14 -23 218 -27
          234 -4 3 5 22 15 41 22 81 30 100 44 100 74 0 16 -4 26 -10 22 -6 -4 -10 18
          -10 58 0 35 -3 66 -6 69 -3 3 -18 -1 -34 -8 -51 -26 -139 -35 -220 -23 -41 6
          -79 14 -85 19 -5 4 -23 10 -38 14 -15 4 -25 11 -22 16 3 5 -4 11 -15 15 -11 3
          -28 17 -39 31 -10 13 -23 24 -28 24 -5 0 -20 18 -33 41 -14 22 -32 48 -41 57
          -9 10 -24 38 -34 65 -10 26 -21 47 -25 47 -7 0 -36 88 -46 143 -3 20 -10 37
          -14 37 -24 0 -53 346 -40 470 11 107 29 190 41 190 5 0 9 6 9 14 0 21 40 106
          50 106 5 0 11 9 15 20 5 17 70 86 124 132 28 24 97 58 116 58 9 0 14 4 11 8
          -5 9 54 24 140 37 44 7 224 -20 234 -34 3 -5 21 -17 40 -28 41 -22 100 -76
          100 -92 0 -22 18 -10 25 16 3 15 11 31 17 34 5 4 10 22 11 40 1 33 -23 62
          -127 154 -23 21 -66 45 -80 45 -8 0 -21 6 -29 14 -32 32 -224 46 -329 24z"/>
          <path  d="M22293 7967 c-11 -14 -43 -125 -43 -149 0 -10 22 -23 63 -38 34 -12
          64 -26 67 -30 3 -4 43 -20 90 -35 47 -15 87 -31 90 -35 3 -4 32 -16 65 -28 33
          -11 78 -30 100 -40 22 -11 66 -28 98 -38 31 -10 57 -21 57 -25 0 -10 70 -30
          80 -24 4 3 11 26 15 52 4 27 10 50 14 53 5 3 10 21 14 41 l5 35 -61 18 c-34
          10 -64 21 -67 25 -3 4 -39 18 -80 31 -41 13 -77 27 -80 31 -3 3 -38 16 -77 28
          -40 13 -73 26 -73 29 0 8 -44 24 -125 47 -27 8 -51 19 -53 25 -2 5 -10 10 -18
          10 -8 0 -28 7 -43 15 -23 11 -31 12 -38 2z"/>
          <path  d="M22220 7763 c0 -10 -9 -43 -19 -73 -10 -30 -16 -60 -14 -67 3 -6 44
          -24 93 -40 49 -15 87 -31 84 -34 -2 -4 13 -11 34 -17 48 -13 106 -35 112 -43
          3 -3 34 -16 70 -28 36 -13 67 -26 70 -30 3 -4 43 -21 90 -37 47 -16 86 -32 88
          -36 2 -4 19 -12 39 -19 46 -15 55 -4 77 83 9 37 16 69 16 72 0 2 -30 14 -67
          27 -38 12 -70 25 -73 29 -3 4 -43 20 -90 36 -47 16 -86 32 -88 36 -2 4 -33 17
          -70 29 -37 12 -69 25 -72 29 -3 4 -44 20 -92 36 -48 15 -85 31 -82 35 5 8 -59
          29 -88 29 -10 0 -18 -8 -18 -17z"/>
          <path  fill="black" d="M2573 7763 c-13 -2 -23 -9 -23 -14 0 -5 -7 -9 -15 -9 -24 0 -83 -35
          -112 -66 -64 -69 -133 -229 -133 -308 0 -14 -4 -26 -10 -26 -6 0 -10 -45 -10
          -116 0 -74 4 -113 10 -109 6 4 10 -7 10 -27 0 -18 7 -69 15 -113 22 -119 27
          -139 35 -145 4 -3 17 -35 29 -73 13 -37 29 -73 36 -81 8 -8 24 -32 36 -55 11
          -22 24 -41 28 -41 3 0 18 -11 32 -24 47 -43 90 -59 175 -64 87 -5 179 15 208
          44 13 13 16 43 16 165 0 93 4 149 10 149 6 0 10 23 10 50 0 49 -1 50 -31 50
          -40 0 -119 20 -119 31 0 4 -10 11 -22 15 -29 9 -95 76 -131 131 -57 90 -71
          154 -72 318 0 163 17 250 55 283 11 9 20 22 20 29 0 13 -6 14 -47 6z"/>
          <path d="M2690 7736 c0 -33 13 -46 45 -46 20 0 35 32 28 58 -4 17 -13 22 -39
          22 -32 0 -34 -2 -34 -34z"/>
          <path fill="black" d="M2858 7717 c-27 -10 -48 -50 -48 -88 0 -24 21 -69 32 -69 5 0 16 -7
          24 -15 9 -8 28 -15 44 -15 16 0 35 7 44 15 8 8 19 15 24 15 5 0 14 11 20 25
          44 97 -37 173 -140 132z"/>
          <path  d="M22140 7523 c-14 -43 -28 -88 -31 -100 -7 -21 -2 -24 47 -39 30 -8
          54 -20 54 -25 0 -5 5 -9 12 -9 19 0 152 -43 158 -51 3 -3 41 -16 85 -28 44
          -12 82 -26 85 -30 5 -8 168 -61 188 -61 7 0 12 -4 12 -9 0 -4 20 -14 45 -21
          36 -10 46 -10 58 1 18 19 52 149 41 158 -5 4 -35 16 -66 26 -32 11 -58 22 -58
          26 0 4 -40 19 -90 35 -49 16 -90 32 -90 36 0 4 -30 17 -67 28 -37 11 -70 24
          -73 29 -3 4 -35 18 -72 30 -38 13 -68 26 -68 30 0 6 -125 51 -141 51 -3 0 -16
          -35 -29 -77z"/>
          <path  d="M22068 7338 c-10 -24 -18 -49 -18 -56 0 -6 -4 -12 -8 -12 -4 0 -16
          -25 -27 -56 -11 -31 -25 -63 -33 -70 -21 -21 2 -38 91 -64 42 -13 77 -26 77
          -30 0 -4 10 -10 23 -13 56 -13 172 -50 177 -56 3 -4 41 -18 85 -31 44 -12 82
          -26 85 -30 3 -3 47 -19 98 -35 50 -16 92 -32 92 -37 0 -4 9 -8 19 -8 16 0 24
          15 42 73 13 39 26 74 30 77 5 4 39 109 39 123 0 2 -35 14 -77 27 -43 13 -80
          26 -83 30 -3 3 -47 19 -97 35 -51 16 -93 32 -93 35 0 4 -6 7 -12 7 -30 2 -153
          44 -156 53 -2 6 -9 10 -15 10 -20 0 -152 43 -157 52 -4 4 -19 10 -35 13 -28 6
          -31 3 -47 -37z"/>
          <path  d="M21943 7053 c-27 -66 -86 -188 -93 -193 -11 -9 -32 -68 -27 -77 3 -4
          28 -17 56 -28 28 -11 51 -23 51 -26 0 -4 32 -20 70 -35 39 -15 70 -31 70 -36
          0 -4 6 -8 12 -8 20 0 118 -43 118 -52 0 -4 8 -8 18 -8 10 0 22 -4 28 -9 5 -5
          26 -14 47 -21 20 -6 37 -15 37 -19 0 -5 35 -21 78 -36 42 -15 79 -31 82 -35 5
          -7 66 -30 80 -30 4 0 10 13 13 29 3 16 12 35 19 42 12 13 41 83 56 134 3 11
          11 25 18 32 6 7 20 38 30 70 10 32 22 61 27 64 5 4 -14 12 -42 20 -28 7 -51
          16 -51 20 0 7 -156 59 -178 59 -7 0 -12 4 -12 9 0 4 -37 19 -82 31 -46 13 -86
          27 -89 32 -3 5 -48 20 -100 35 -52 15 -96 30 -100 35 -5 7 -94 38 -112 38 -4
          0 -15 -17 -24 -37z"/>
          <path  d="M21794 6752 c-5 -4 -23 -37 -39 -74 -15 -38 -31 -68 -35 -68 -11 0
          -44 -80 -36 -88 8 -7 58 -35 146 -78 30 -15 57 -30 60 -34 3 -4 32 -20 65 -35
          33 -16 62 -32 65 -35 3 -3 30 -17 60 -29 30 -13 58 -27 61 -32 3 -5 32 -19 65
          -30 32 -12 61 -24 64 -28 6 -9 140 -61 157 -61 6 0 19 20 29 45 9 25 20 45 24
          45 4 0 17 25 29 56 11 30 28 62 36 71 27 26 13 45 -47 66 -32 10 -58 23 -58
          28 0 5 -5 9 -11 9 -19 0 -143 53 -157 67 -7 7 -20 13 -27 13 -16 0 -87 31 -95
          42 -3 3 -32 17 -65 29 -33 12 -62 25 -65 29 -3 3 -34 21 -70 38 -36 18 -76 39
          -90 47 -28 16 -49 19 -66 7z"/>
          <path  d="M21628 6453 c-13 -27 -28 -52 -35 -58 -7 -5 -19 -27 -28 -47 -8 -20
          -21 -41 -28 -45 -7 -4 -24 -30 -36 -56 -23 -47 -23 -49 -5 -62 11 -8 24 -15
          30 -15 6 0 23 -10 38 -23 15 -13 43 -29 64 -37 20 -7 39 -16 42 -21 6 -9 75
          -48 165 -93 39 -20 66 -36 60 -36 -5 0 15 -12 45 -26 30 -14 57 -29 60 -33 3
          -5 30 -19 60 -31 30 -13 60 -29 65 -36 6 -6 28 -19 49 -28 46 -19 56 -14 88
          53 13 25 29 52 36 59 6 8 22 36 33 63 12 27 24 47 27 44 3 -3 16 18 27 47 26
          63 21 72 -60 102 -27 11 -87 36 -133 58 -45 21 -87 38 -92 38 -6 0 -10 4 -10
          9 0 5 -26 19 -57 32 -32 13 -63 28 -69 34 -6 5 -35 21 -65 35 -30 14 -57 30
          -61 36 -4 6 -28 19 -53 28 -25 10 -45 21 -45 25 0 7 -59 31 -78 31 -6 0 -21
          -21 -34 -47z"/>
          <path  d="M21441 6167 c-6 -6 -11 -17 -11 -24 0 -7 -9 -17 -20 -23 -11 -6 -20
          -17 -20 -24 0 -7 -11 -25 -25 -40 -14 -15 -25 -29 -25 -31 0 -2 -11 -21 -25
          -42 l-26 -37 23 -18 c13 -10 29 -18 36 -18 6 0 12 -4 12 -8 0 -4 28 -21 63
          -37 34 -16 68 -36 76 -43 7 -7 31 -20 52 -29 22 -8 39 -19 39 -23 0 -5 25 -18
          55 -31 30 -12 55 -26 55 -29 0 -4 24 -17 53 -30 28 -13 61 -31 72 -40 11 -10
          39 -24 62 -33 24 -9 51 -25 60 -34 10 -10 38 -25 62 -33 42 -14 44 -13 62 10
          11 14 23 30 28 36 5 6 18 29 30 52 11 22 26 46 33 54 19 20 38 56 38 71 0 7
          -25 24 -55 37 -30 13 -55 27 -55 31 0 3 -29 18 -65 34 -36 15 -65 31 -65 35 0
          4 -22 17 -50 28 -27 12 -50 25 -50 29 0 5 -22 17 -49 27 -26 9 -57 25 -67 34
          -11 10 -41 28 -69 41 -27 13 -52 26 -55 30 -3 3 -27 17 -55 31 -27 14 -54 30
          -60 36 -15 17 -52 23 -64 11z"/>
          <path  d="M21247 5897 c-17 -27 -54 -71 -83 -101 -35 -36 -36 -53 -3 -66 16 -7
          29 -16 29 -21 0 -7 16 -18 80 -51 14 -7 33 -21 43 -31 10 -10 32 -23 48 -29
          16 -5 27 -13 25 -17 -3 -4 16 -18 42 -31 26 -14 49 -27 52 -31 3 -4 28 -19 55
          -34 28 -16 52 -31 55 -34 3 -3 23 -15 45 -26 22 -11 49 -27 60 -36 11 -8 40
          -24 65 -34 25 -10 50 -24 55 -30 6 -6 24 -18 41 -27 37 -18 40 -16 109 93 5 9
          23 35 38 59 l29 43 -26 22 c-14 12 -44 30 -68 41 -24 10 -45 21 -48 24 -3 4
          -27 17 -55 30 -27 13 -55 29 -61 34 -6 6 -35 22 -65 36 -30 14 -60 31 -68 38
          -7 7 -31 20 -52 29 -22 8 -39 18 -39 22 0 3 -25 17 -55 30 -30 13 -55 27 -55
          31 0 4 -28 20 -62 35 -35 15 -67 34 -71 41 -12 21 -43 17 -60 -9z"/>
          <path  d="M21078 5692 c-21 -21 -38 -43 -38 -50 0 -7 -7 -12 -15 -12 -8 0 -15
          -3 -15 -8 0 -4 -21 -29 -46 -55 -43 -46 -45 -49 -29 -67 9 -10 30 -24 46 -30
          16 -7 29 -16 29 -21 0 -4 20 -18 45 -30 25 -12 45 -26 45 -29 0 -4 22 -19 49
          -34 27 -14 51 -30 53 -34 2 -4 23 -18 48 -32 25 -13 54 -32 64 -42 11 -10 25
          -18 31 -18 6 0 20 -8 31 -18 10 -10 45 -30 76 -45 31 -15 58 -30 60 -34 1 -5
          26 -19 53 -33 28 -14 53 -30 58 -37 4 -6 23 -17 43 -23 34 -11 37 -10 63 19
          15 17 34 43 42 58 8 16 21 33 30 40 9 7 22 26 29 42 7 17 16 29 20 26 8 -5 20
          18 20 39 0 6 -22 20 -50 31 -27 11 -50 23 -50 27 0 4 -26 20 -57 34 -32 15
          -60 30 -63 34 -3 4 -24 17 -47 29 -23 11 -44 25 -48 30 -3 5 -30 21 -60 36
          -30 14 -55 29 -55 34 0 4 -22 18 -50 31 -27 13 -50 27 -50 30 0 4 -20 18 -44
          31 -25 13 -43 26 -40 30 2 4 -8 12 -24 18 -38 14 -85 44 -95 59 -12 18 -18 15
          -59 -26z"/>
          <path fill="black" d="M3100 5473 c-36 -9 -67 -19 -70 -23 -3 -4 -38 -17 -77 -29 -40 -13
          -73 -26 -73 -31 0 -4 -23 -16 -50 -26 -28 -10 -58 -25 -68 -34 -9 -9 -34 -26
          -54 -39 -21 -12 -38 -26 -38 -30 0 -4 -9 -12 -19 -17 -58 -27 -317 -284 -355
          -352 -6 -9 -20 -28 -33 -42 -13 -14 -23 -31 -23 -38 0 -7 -7 -15 -15 -19 -9
          -3 -22 -18 -30 -33 -8 -16 -21 -34 -28 -42 -7 -7 -26 -37 -43 -65 -16 -29 -33
          -53 -37 -53 -4 0 -16 -20 -27 -45 -11 -25 -23 -45 -28 -45 -4 0 -19 -25 -32
          -55 -13 -30 -27 -55 -31 -55 -4 0 -19 -25 -34 -55 -14 -30 -29 -55 -34 -55 -5
          0 -16 -17 -24 -39 -9 -21 -22 -45 -30 -53 -8 -8 -25 -34 -37 -59 -13 -24 -26
          -46 -30 -49 -4 -3 -20 -30 -35 -60 -15 -30 -31 -57 -35 -60 -4 -3 -17 -24 -28
          -47 -12 -22 -25 -43 -29 -45 -5 -1 -19 -23 -32 -48 -14 -25 -31 -51 -38 -58
          -14 -15 -8 -36 8 -27 5 4 12 0 15 -8 3 -8 28 -25 57 -38 28 -13 62 -34 76 -46
          13 -13 29 -23 36 -23 6 0 22 -10 35 -22 27 -26 56 -44 83 -54 9 -3 17 -10 17
          -14 0 -8 107 -90 119 -90 4 0 19 -12 34 -27 14 -16 37 -36 51 -46 24 -17 37
          -57 17 -57 -22 0 -70 23 -75 36 -3 8 -12 14 -20 14 -17 0 -76 30 -91 46 -5 6
          -32 22 -60 35 -27 13 -57 32 -67 42 -10 9 -24 17 -31 17 -6 0 -25 11 -41 25
          -15 13 -48 32 -72 41 -24 9 -44 20 -44 24 0 11 -109 52 -124 47 -7 -3 -20 -19
          -27 -36 -8 -17 -17 -31 -21 -31 -4 0 -15 -15 -24 -32 -9 -18 -27 -43 -40 -55
          -32 -30 -30 -39 11 -53 19 -6 38 -17 42 -24 5 -7 29 -23 56 -35 26 -13 47 -26
          47 -30 0 -4 20 -18 45 -31 25 -13 45 -26 45 -30 0 -3 20 -17 45 -29 25 -12 48
          -28 52 -35 5 -7 22 -20 38 -29 17 -8 41 -25 55 -36 13 -12 40 -32 60 -43 19
          -12 41 -30 48 -40 7 -10 20 -18 27 -18 8 0 22 -11 31 -25 9 -14 22 -25 29 -25
          8 0 24 -13 37 -29 13 -15 35 -36 50 -46 16 -9 28 -24 28 -31 0 -8 6 -14 14
          -14 10 0 21 30 37 98 12 53 26 99 30 102 4 3 18 59 30 125 12 66 25 122 29
          125 4 3 18 73 31 155 13 83 26 152 29 155 3 3 19 77 34 165 15 88 31 162 35
          165 4 3 17 54 30 115 12 60 26 112 30 115 4 3 18 43 31 90 13 47 30 91 37 98
          7 7 13 19 13 26 0 15 40 108 49 116 4 3 15 25 26 50 10 25 26 54 34 65 9 11
          25 36 36 55 10 19 27 44 38 55 10 11 26 34 34 50 9 17 24 36 34 43 11 7 19 18
          19 23 0 14 211 224 233 232 9 3 17 11 17 16 0 5 22 22 50 37 27 15 48 30 45
          33 -2 4 23 17 56 30 32 12 59 26 59 31 0 5 14 11 30 15 17 4 30 11 30 17 0 16
          -57 68 -75 68 -24 0 -98 -32 -119 -52 -11 -10 -29 -21 -40 -24 -12 -4 -29 -13
          -39 -22 -21 -18 -55 -39 -79 -48 -10 -3 -18 -10 -18 -15 0 -4 -20 -22 -45 -39
          -24 -17 -87 -74 -139 -128 -120 -122 -128 -131 -157 -178 -13 -22 -27 -41 -31
          -42 -5 -2 -20 -26 -33 -53 -14 -27 -29 -49 -33 -49 -4 0 -18 -27 -31 -60 -13
          -33 -27 -58 -30 -55 -4 2 -18 -27 -31 -66 -13 -38 -28 -69 -32 -69 -4 0 -17
          -33 -29 -72 -12 -40 -30 -91 -39 -113 -10 -22 -26 -76 -36 -120 -10 -44 -21
          -82 -24 -85 -8 -6 -50 -210 -50 -239 0 -11 -4 -21 -9 -23 -5 -1 -16 -57 -26
          -123 -9 -66 -21 -124 -25 -129 -5 -5 -16 -6 -25 -2 -13 5 -16 14 -12 39 4 17
          9 65 13 105 4 39 11 72 15 72 5 0 9 12 9 27 0 45 42 268 51 271 5 2 9 11 9 20
          0 33 42 192 51 192 5 0 9 5 9 12 0 18 43 152 50 158 4 3 16 32 28 65 18 54 80
          184 100 210 4 6 18 30 32 55 13 25 32 54 42 64 10 11 18 25 18 31 0 7 11 24
          25 39 14 15 25 29 25 32 0 10 46 69 73 92 15 13 27 28 27 33 0 4 23 31 51 58
          63 61 33 58 -36 -4 -28 -25 -53 -45 -57 -45 -10 0 -97 -90 -116 -120 -10 -14
          -27 -33 -39 -43 -13 -10 -23 -25 -23 -32 0 -8 -9 -19 -20 -25 -10 -6 -27 -26
          -36 -45 -9 -19 -20 -35 -24 -35 -4 0 -20 -24 -35 -52 -15 -29 -31 -55 -35 -58
          -4 -3 -18 -25 -30 -50 -12 -25 -25 -47 -29 -50 -3 -3 -17 -27 -30 -55 -14 -27
          -27 -52 -30 -55 -10 -9 -41 -80 -41 -95 0 -7 -6 -20 -13 -27 -14 -14 -67 -145
          -67 -166 0 -6 -4 -12 -9 -12 -5 0 -19 -33 -32 -72 -12 -40 -25 -75 -29 -78 -3
          -3 -13 -30 -22 -60 -12 -44 -19 -56 -38 -58 -21 -4 -22 -1 -15 35 4 21 10 40
          14 43 4 3 18 49 31 103 13 53 27 97 32 97 4 0 8 6 8 14 0 21 46 157 51 151 3
          -2 18 36 34 85 17 48 32 86 34 84 3 -3 17 26 32 63 16 37 28 64 29 58 0 -5 12
          15 26 45 14 30 29 57 33 60 4 3 18 25 32 49 13 25 34 56 47 69 12 14 22 31 22
          38 0 8 9 19 20 26 11 7 20 18 20 25 0 6 14 23 30 36 17 14 30 30 30 35 0 5 15
          23 33 39 17 17 54 54 81 82 27 28 53 51 57 51 5 0 21 14 36 30 14 17 32 30 40
          30 7 0 16 7 20 15 3 9 23 25 44 36 22 11 39 23 39 27 0 4 28 18 61 32 34 13
          67 30 75 37 7 7 50 23 96 35 46 12 85 26 86 30 2 4 15 8 28 8 15 0 24 6 24 15
          0 18 -14 18 -100 -2z"/>
          <path id="25" d="M16655 5485 c-11 -2 -66 -9 -122 -15 -57 -7 -103 -15 -103 -19 0 -4
          -39 -15 -86 -25 -47 -11 -91 -24 -98 -31 -6 -7 -42 -22 -79 -34 -37 -13 -73
          -28 -80 -35 -6 -7 -34 -23 -60 -35 -27 -13 -60 -34 -74 -47 -14 -13 -30 -24
          -36 -24 -10 0 -23 -11 -89 -74 -20 -20 -41 -36 -47 -36 -6 0 -16 -10 -22 -22
          -6 -13 -24 -33 -40 -46 -16 -13 -29 -30 -29 -37 0 -8 -8 -20 -18 -27 -10 -7
          -29 -33 -43 -58 -13 -25 -27 -47 -31 -50 -4 -3 -16 -28 -28 -55 -12 -27 -25
          -52 -30 -55 -11 -7 -47 -142 -56 -212 -4 -27 -10 -48 -15 -48 -5 0 -9 -65 -9
          -145 0 -90 4 -145 10 -145 6 0 10 -12 10 -27 0 -16 11 -59 25 -98 13 -38 25
          -80 25 -92 0 -12 5 -23 11 -25 6 -2 21 -62 33 -133 13 -72 26 -137 29 -145 4
          -8 11 -62 17 -119 10 -99 27 -180 40 -191 4 -3 19 -42 35 -87 16 -46 31 -83
          35 -83 4 0 17 -22 29 -50 12 -27 27 -50 32 -50 5 0 9 -6 9 -12 0 -7 18 -36 39
          -65 22 -28 44 -60 50 -71 30 -57 305 -328 344 -341 7 -2 22 -13 34 -24 24 -21
          53 -40 92 -57 13 -6 29 -17 35 -24 6 -7 32 -22 58 -32 27 -11 48 -22 48 -26 0
          -3 39 -19 88 -34 48 -15 89 -31 92 -35 11 -16 182 -41 310 -46 107 -4 159 0
          249 15 63 11 117 24 120 29 3 5 39 20 80 33 41 13 80 30 87 37 7 7 38 23 68
          34 31 12 56 25 56 30 0 4 12 14 28 21 42 22 74 42 92 58 10 8 34 29 55 45 57
          45 205 192 205 204 0 5 9 16 21 25 12 8 26 25 33 38 27 51 41 73 52 80 6 4 19
          29 29 55 10 26 24 54 31 61 7 7 21 38 31 70 10 31 21 60 26 63 4 3 18 55 29
          115 18 91 22 147 22 344 1 204 -2 252 -23 365 -12 72 -26 132 -30 135 -4 3
          -18 47 -31 98 -13 50 -27 92 -31 92 -4 0 -17 29 -30 65 -12 36 -26 65 -29 65
          -4 0 -19 32 -34 70 -15 39 -31 70 -35 70 -3 0 -17 23 -31 52 -14 29 -35 63
          -47 76 -13 14 -23 31 -23 38 0 7 -6 17 -13 21 -11 7 -67 87 -67 96 0 9 -198
          213 -237 244 -53 42 -81 63 -87 63 -3 0 -17 11 -32 25 -15 14 -33 25 -40 25
          -7 0 -21 8 -31 19 -10 10 -47 29 -83 42 -36 12 -67 26 -70 30 -7 9 -151 49
          -178 49 -12 0 -22 4 -22 9 0 20 -368 50 -445 36z m312 -449 c40 -10 70 -22 67
          -27 -3 -5 2 -9 10 -9 23 0 138 -52 160 -72 11 -10 25 -18 32 -18 6 0 22 -11
          35 -25 13 -13 29 -25 36 -25 6 0 20 -11 31 -25 11 -14 24 -25 29 -25 10 0 192
          -177 211 -206 6 -10 16 -29 22 -41 5 -13 13 -23 18 -23 5 0 19 -19 32 -42 13
          -24 29 -47 35 -53 6 -5 20 -30 30 -55 10 -25 24 -52 31 -60 14 -19 54 -137 54
          -162 0 -10 4 -18 9 -18 27 0 54 -279 41 -422 -9 -106 -28 -209 -39 -218 -3 -3
          -15 -31 -26 -64 -10 -32 -24 -64 -30 -70 -5 -6 -21 -35 -35 -64 -13 -30 -32
          -58 -42 -63 -10 -5 -18 -15 -18 -23 0 -7 -15 -26 -32 -43 -18 -17 -39 -37 -46
          -45 -63 -63 -124 -111 -177 -139 -22 -12 -42 -25 -45 -29 -7 -10 -154 -60
          -178 -60 -11 0 -22 -6 -24 -12 -6 -17 -325 -19 -336 -2 -4 6 -25 14 -47 18
          -51 9 -155 47 -155 56 0 4 -18 13 -41 20 -22 8 -50 24 -62 37 -12 12 -26 23
          -32 23 -19 1 -179 149 -222 207 -15 21 -35 43 -44 50 -9 7 -22 26 -29 43 -7
          16 -16 30 -20 30 -4 0 -18 19 -30 43 -12 23 -25 44 -29 47 -9 7 -37 68 -52
          113 -7 20 -16 37 -20 37 -4 0 -17 33 -29 73 -12 39 -26 74 -30 77 -11 7 -50
          196 -50 239 0 17 -4 31 -10 31 -6 0 -10 45 -10 115 0 63 4 115 9 115 4 0 11
          28 14 63 7 79 36 197 48 197 5 0 9 6 9 14 0 17 40 109 51 116 5 3 19 26 32 50
          34 64 194 220 226 220 5 0 11 6 14 13 2 7 31 23 63 36 33 14 61 28 64 32 3 5
          42 17 85 28 102 26 330 24 442 -3z"/>
          <path id="26"  d="M16586 4910 c-49 -10 -92 -23 -95 -27 -3 -5 -30 -20 -58 -33 -29 -14
          -53 -28 -53 -33 0 -4 -10 -12 -22 -18 -56 -25 -133 -95 -166 -151 -9 -16 -19
          -28 -23 -28 -4 0 -17 -25 -29 -55 -12 -30 -26 -55 -30 -55 -5 0 -18 -42 -30
          -92 -27 -115 -37 -303 -21 -418 12 -94 40 -212 51 -220 4 -3 17 -35 29 -72 13
          -38 26 -68 30 -68 4 0 18 -24 32 -52 13 -29 27 -55 32 -56 4 -2 16 -20 27 -40
          10 -21 39 -59 64 -86 25 -27 46 -52 46 -55 0 -11 97 -101 108 -101 6 0 20 -13
          32 -30 12 -16 27 -30 34 -30 7 0 21 -8 32 -18 10 -10 46 -31 79 -46 33 -16 62
          -32 65 -36 7 -10 141 -50 169 -50 11 0 21 -4 21 -10 0 -6 40 -10 100 -10 55 0
          100 4 100 9 0 5 19 11 43 15 59 9 137 36 137 47 0 5 5 9 10 9 31 0 230 171
          230 197 0 7 8 18 18 25 10 7 29 36 42 63 13 28 29 55 35 62 7 6 20 37 30 70
          10 32 22 60 26 63 17 11 41 225 34 310 -8 114 -25 210 -36 210 -5 0 -9 5 -9
          12 0 18 -43 152 -50 158 -4 3 -17 28 -31 55 -13 28 -26 52 -29 55 -3 3 -18 26
          -33 51 -14 26 -33 49 -42 53 -8 3 -15 12 -15 20 0 8 -11 23 -25 34 -14 11 -25
          24 -25 29 0 16 -46 54 -55 46 -4 -5 -5 -3 -2 4 7 12 -46 63 -65 63 -6 0 -8 3
          -5 6 7 8 -36 44 -52 44 -6 0 -15 8 -20 18 -5 10 -32 29 -60 42 -28 13 -51 27
          -51 31 0 3 -25 17 -55 29 -30 12 -55 26 -55 30 0 13 -146 42 -235 46 -64 3
          -116 -2 -179 -16z"/>
          <path d="M20832 5427 c-86 -85 -181 -172 -217 -199 -30 -22 -45 -38 -45 -48 0
          -3 19 -14 43 -25 23 -11 50 -27 61 -37 10 -9 31 -22 47 -28 16 -7 29 -16 29
          -21 0 -5 23 -18 50 -30 28 -12 50 -25 50 -30 0 -5 25 -21 55 -35 30 -15 55
          -30 55 -34 0 -3 21 -17 48 -30 26 -13 51 -29 55 -37 4 -7 14 -13 21 -13 7 0
          24 -10 38 -22 27 -25 56 -43 103 -67 17 -8 34 -20 38 -28 4 -7 14 -13 21 -13
          7 0 25 -11 40 -25 14 -13 27 -24 29 -24 1 1 15 -5 31 -12 26 -12 30 -11 47 11
          34 45 64 81 92 110 15 15 27 31 27 35 0 8 80 111 109 141 32 33 25 48 -34 74
          -30 13 -55 27 -55 31 0 3 -21 17 -47 30 -27 13 -50 27 -53 30 -3 4 -27 19 -55
          33 -90 49 -141 81 -165 104 -13 12 -29 22 -35 22 -6 0 -20 8 -31 18 -27 23
          -53 40 -91 57 -18 9 -33 19 -33 24 0 6 -8 12 -19 16 -10 3 -30 16 -43 28 -28
          25 -82 57 -95 57 -4 0 -36 -28 -71 -63z"/>
          <path id="27"  d="M9130 5415 c-41 -8 -76 -19 -78 -24 -2 -6 -10 -11 -17 -11 -22 0
          -124 -40 -138 -55 -7 -7 -32 -22 -55 -34 -23 -12 -42 -26 -42 -32 0 -5 -8 -12
          -17 -16 -40 -14 -214 -192 -236 -240 -6 -13 -14 -23 -19 -23 -4 0 -17 -20 -29
          -45 -12 -25 -24 -45 -29 -45 -4 0 -20 -33 -36 -72 -16 -40 -33 -78 -39 -84 -6
          -6 -20 -40 -30 -75 -10 -35 -22 -66 -26 -69 -7 -6 -35 -115 -45 -177 -4 -24
          -10 -43 -14 -43 -7 0 -13 -33 -31 -185 -22 -192 -3 -613 30 -635 4 -3 16 -40
          26 -84 10 -43 24 -83 30 -90 6 -6 19 -37 29 -69 10 -32 23 -63 30 -70 6 -7 22
          -37 36 -67 13 -30 29 -59 34 -65 6 -5 21 -31 34 -57 13 -27 27 -48 30 -48 4 0
          16 -16 27 -36 10 -19 27 -40 37 -45 10 -5 18 -15 18 -22 0 -25 212 -227 239
          -227 5 0 15 -9 20 -19 6 -10 23 -23 39 -29 15 -5 35 -16 43 -24 16 -16 134
          -68 154 -68 7 0 15 -5 17 -11 4 -11 138 -49 174 -49 12 0 24 -6 26 -12 6 -18
          320 -18 325 0 3 6 14 12 26 12 37 0 172 39 175 50 2 5 9 10 16 10 17 0 140 50
          146 60 3 4 24 17 48 29 23 12 42 26 42 31 0 6 6 10 13 10 8 0 22 9 32 20 10
          11 21 20 25 20 11 0 145 133 143 141 -1 4 7 15 17 24 11 10 20 24 20 30 0 7 6
          15 14 18 8 3 22 26 31 51 10 25 21 46 25 46 4 0 20 39 36 88 16 48 31 89 35
          92 7 6 26 101 39 195 13 92 12 396 0 510 -14 123 -19 145 -36 145 -8 0 -14 4
          -14 9 0 5 -15 12 -32 16 -48 10 -132 37 -138 45 -9 11 -141 43 -148 36 -3 -4
          -1 -10 5 -14 19 -12 43 -185 43 -302 0 -112 -25 -293 -40 -298 -6 -2 -10 -10
          -10 -18 0 -21 -38 -127 -48 -133 -5 -3 -19 -28 -33 -55 -13 -28 -33 -60 -44
          -72 -84 -95 -111 -123 -120 -124 -6 0 -23 -12 -38 -26 -15 -14 -49 -35 -75
          -46 -26 -12 -49 -24 -52 -27 -27 -36 -265 -52 -390 -26 -47 9 -87 20 -90 24
          -3 4 -33 18 -67 31 -35 14 -63 28 -63 32 0 4 -15 15 -32 24 -18 9 -42 24 -53
          34 -11 10 -47 44 -80 74 -33 31 -63 56 -67 56 -5 0 -8 5 -8 10 0 6 -9 20 -20
          32 -11 12 -20 28 -20 35 0 8 -4 11 -10 8 -5 -3 -21 17 -35 45 -14 27 -29 50
          -33 50 -4 0 -18 27 -31 60 -13 33 -27 60 -30 60 -6 0 -34 66 -47 110 -3 14
          -10 27 -14 30 -13 8 -51 200 -57 288 -3 45 -10 82 -14 82 -5 0 -9 36 -9 81 0
          51 4 78 10 74 6 -4 10 11 10 37 0 80 47 311 61 302 4 -2 10 9 14 26 8 36 34
          90 52 108 7 7 13 20 13 27 0 7 12 26 28 42 15 15 38 44 51 64 13 20 39 46 58
          59 18 13 31 25 28 28 -5 5 10 15 73 47 17 10 32 21 32 25 0 4 28 18 63 31 34
          12 65 28 69 33 9 14 316 15 326 1 4 -5 37 -20 74 -33 37 -12 79 -33 94 -47 15
          -14 32 -25 37 -25 27 0 203 -186 219 -231 7 -22 18 -28 54 -34 24 -3 44 -9 44
          -13 0 -4 44 -21 98 -37 53 -16 99 -32 102 -36 6 -8 90 -32 95 -27 5 5 -37 122
          -45 128 -4 3 -20 34 -35 70 -15 36 -30 67 -34 70 -4 3 -18 29 -31 58 -14 28
          -28 52 -33 52 -4 0 -15 16 -23 35 -8 20 -21 38 -29 42 -8 3 -15 11 -15 18 0 6
          -9 20 -20 30 -11 10 -20 22 -20 27 0 9 -111 118 -121 118 -3 0 -21 14 -39 30
          -18 17 -39 30 -46 30 -7 0 -21 8 -31 19 -19 19 -126 61 -157 61 -9 0 -15 4
          -12 9 19 30 -335 51 -464 26z"/>
          <path id="28"   d="M1250 5403 c-8 -3 -17 -9 -20 -12 -3 -4 -25 -15 -50 -26 -58 -24
          -172 -141 -197 -201 -10 -24 -21 -44 -24 -44 -13 0 -59 -157 -59 -201 0 -10
          -4 -19 -9 -19 -14 0 -24 -169 -16 -260 4 -44 11 -77 16 -74 5 3 9 -6 9 -20 0
          -40 50 -187 71 -209 10 -12 19 -27 19 -34 0 -7 11 -20 24 -28 13 -9 26 -25 29
          -35 3 -11 16 -24 29 -29 12 -5 34 -18 46 -29 13 -12 44 -24 69 -28 48 -7 153
          11 153 26 0 5 8 11 19 15 21 7 121 100 121 113 0 5 10 20 23 34 24 27 77 131
          77 152 0 8 4 16 10 18 11 4 49 168 50 216 0 17 5 32 10 32 6 0 10 43 10 110 0
          67 -4 110 -10 110 -5 0 -10 15 -10 33 0 38 -39 199 -50 207 -4 3 -15 23 -25
          44 -19 40 -89 116 -107 116 -5 0 -16 7 -24 15 -11 11 -36 15 -92 14 -42 0 -84
          -3 -92 -6z"/>
          <path fill="black" d="M1573 5368 c26 -25 47 -49 47 -55 0 -5 9 -18 19 -30 25 -26 47 -70
          56 -110 3 -18 10 -33 15 -33 13 0 30 -143 30 -250 0 -111 -17 -253 -31 -258
          -5 -2 -9 -12 -9 -22 0 -23 -40 -153 -49 -160 -4 -3 -17 -30 -30 -60 -13 -30
          -30 -59 -37 -63 -8 -4 -14 -14 -14 -20 0 -22 -116 -135 -165 -162 -30 -16 -29
          -16 35 -10 91 9 183 33 193 50 4 8 13 15 19 15 16 0 108 92 108 108 0 7 6 15
          14 18 7 3 24 28 37 57 13 29 27 54 30 57 9 7 36 87 44 133 4 20 11 37 15 37
          12 0 30 157 30 258 0 94 -17 233 -29 242 -4 3 -14 31 -24 62 -10 32 -23 63
          -30 71 -18 19 -37 50 -43 69 -8 23 -59 54 -104 63 -19 4 -38 11 -41 16 -3 5
          -34 12 -70 15 l-64 6 48 -44z"/>
          <path id="29" d="M7051 5339 c-41 -5 -77 -13 -80 -17 -3 -5 -24 -12 -46 -16 -68 -12
          -145 -37 -145 -47 0 -5 -5 -9 -11 -9 -29 0 -208 -108 -238 -144 -7 -9 -18 -16
          -23 -16 -11 0 -61 -51 -128 -129 -25 -30 -49 -59 -55 -65 -24 -28 -55 -76 -55
          -86 0 -5 -5 -10 -11 -10 -5 0 -16 -15 -24 -34 -8 -19 -20 -40 -28 -48 -7 -7
          -26 -43 -41 -80 -16 -38 -32 -68 -36 -68 -4 0 -17 -33 -30 -72 -14 -40 -28
          -75 -31 -78 -4 -3 -18 -46 -31 -95 -13 -50 -27 -93 -31 -96 -10 -7 -56 -270
          -57 -321 0 -20 -5 -38 -12 -40 -17 -6 -18 -460 0 -466 6 -2 12 -17 12 -34 0
          -42 51 -292 61 -298 5 -3 18 -36 29 -73 11 -37 25 -67 30 -67 6 0 10 -6 10
          -14 0 -18 38 -104 49 -111 5 -3 19 -25 30 -49 12 -24 31 -54 41 -67 11 -13 32
          -39 47 -58 42 -52 121 -131 131 -131 4 0 22 -13 38 -29 16 -16 50 -39 74 -52
          25 -13 48 -27 51 -31 3 -5 32 -18 65 -29 32 -12 61 -24 64 -28 21 -30 256 -56
          372 -41 97 12 190 31 183 38 -2 3 25 15 62 28 36 13 69 27 72 32 3 5 20 15 36
          23 55 28 80 44 93 62 7 9 19 17 27 17 7 0 23 13 33 28 11 15 32 34 46 42 14 7
          26 18 26 23 0 6 15 24 33 41 17 17 46 49 62 70 17 21 37 46 45 55 8 9 26 37
          39 61 13 25 32 52 41 62 10 9 26 37 36 62 11 25 22 46 25 46 4 0 17 26 29 58
          13 31 27 62 32 68 10 13 58 163 58 181 0 7 4 13 9 13 8 0 51 206 51 248 0 11
          4 22 9 24 14 5 31 236 32 423 0 177 -18 395 -33 395 -4 0 -8 9 -8 20 0 28 -40
          193 -48 200 -4 3 -20 44 -35 93 -16 48 -32 87 -36 87 -5 0 -16 21 -26 48 -10
          26 -23 52 -29 57 -6 6 -22 28 -35 50 -13 22 -29 44 -37 48 -8 4 -14 14 -14 21
          0 17 -150 166 -167 166 -8 0 -16 7 -20 16 -3 9 -24 25 -47 36 -23 11 -57 28
          -76 38 -19 11 -57 25 -85 32 -27 7 -53 16 -56 21 -6 9 -157 27 -209 26 -19 -1
          -68 -5 -109 -10z m169 -268 c3 -3 31 -15 64 -26 32 -10 64 -24 70 -31 7 -6 25
          -19 41 -27 17 -9 45 -32 64 -51 19 -20 38 -36 42 -36 5 0 9 -4 9 -10 0 -5 10
          -20 23 -34 47 -51 71 -87 92 -136 12 -28 24 -48 27 -45 2 3 16 -28 29 -67 13
          -40 27 -75 31 -78 3 -3 15 -43 25 -90 26 -121 26 -548 -1 -735 -10 -71 -21
          -132 -25 -135 -4 -3 -17 -49 -30 -102 -12 -54 -27 -98 -32 -98 -5 0 -9 -5 -9
          -11 0 -15 -52 -143 -60 -149 -4 -3 -17 -24 -29 -47 -12 -24 -26 -43 -31 -43
          -5 0 -11 -8 -14 -18 -4 -10 -20 -34 -37 -53 -17 -19 -41 -45 -52 -59 -12 -14
          -40 -39 -62 -55 -22 -16 -42 -33 -45 -37 -4 -4 -28 -19 -55 -33 -28 -14 -45
          -25 -40 -26 21 -1 -113 -40 -157 -46 -27 -3 -48 -10 -48 -14 0 -5 -43 -9 -95
          -9 -52 0 -95 4 -95 9 0 4 -19 11 -42 14 -54 8 -148 38 -148 47 0 5 -17 15 -38
          23 -42 17 -192 152 -192 173 0 7 -6 17 -14 21 -7 4 -21 22 -29 40 -9 17 -23
          37 -30 45 -8 7 -24 40 -36 73 -12 33 -26 62 -30 65 -10 6 -51 180 -51 214 0
          13 -6 26 -12 29 -10 3 -13 61 -13 247 0 186 3 244 13 248 6 2 12 14 12 27 0
          38 39 228 48 234 4 3 14 26 21 51 12 44 89 210 102 220 4 3 18 29 33 58 14 28
          31 52 36 52 6 0 14 10 19 23 17 45 202 247 226 247 7 0 15 6 18 13 3 8 27 24
          54 37 26 12 50 26 53 30 6 10 136 50 161 50 10 0 19 4 19 10 0 16 207 18 220
          1z"/>
          <path id="30" d="M6990 5010 c0 -5 -11 -10 -23 -10 -38 0 -151 -40 -170 -61 -10 -10
          -23 -19 -28 -19 -16 0 -59 -36 -52 -44 3 -3 0 -6 -8 -6 -8 0 -41 -28 -73 -62
          -31 -35 -68 -74 -82 -88 -13 -14 -24 -31 -24 -37 0 -7 -9 -17 -20 -23 -11 -6
          -27 -26 -35 -45 -7 -19 -18 -35 -22 -35 -5 0 -22 -29 -38 -65 -16 -36 -32 -65
          -35 -65 -4 0 -17 -31 -30 -69 -13 -38 -27 -68 -30 -66 -7 5 -35 -100 -46 -175
          -4 -25 -11 -47 -15 -50 -18 -12 -31 -322 -19 -465 6 -77 15 -142 19 -145 4 -3
          15 -42 24 -87 9 -45 32 -116 52 -158 19 -41 35 -79 35 -84 0 -5 6 -11 13 -14
          8 -3 22 -22 31 -42 10 -20 24 -39 32 -42 8 -3 21 -18 30 -34 8 -16 21 -29 30
          -29 8 0 17 -7 20 -15 3 -8 27 -26 52 -41 26 -15 49 -30 52 -34 8 -12 102 -39
          158 -46 28 -4 52 -10 52 -15 0 -5 32 -9 70 -9 40 0 70 4 70 10 0 6 10 10 23
          10 32 0 169 37 176 47 3 5 30 21 59 36 29 15 60 34 70 42 65 56 112 103 112
          111 0 6 12 23 28 40 15 16 36 47 47 69 11 22 25 45 32 52 6 7 19 36 29 65 9
          29 21 55 25 58 10 7 59 218 59 254 0 14 4 26 9 26 14 0 34 244 34 415 0 162
          -17 327 -34 333 -5 2 -9 11 -9 20 0 25 -50 182 -58 182 -4 0 -18 27 -31 60
          -13 33 -28 60 -32 60 -5 0 -15 15 -23 34 -8 19 -24 39 -35 46 -12 6 -21 16
          -21 23 0 14 -78 97 -90 97 -4 0 -17 9 -30 20 -27 23 -138 60 -181 60 -16 0
          -29 5 -29 10 0 6 -27 10 -60 10 -33 0 -60 -4 -60 -10z"/>
          <path  fill="black" d="M3315 5229 c-27 -11 -52 -24 -55 -28 -3 -5 -31 -20 -62 -35 -32 -15
          -58 -31 -58 -35 0 -3 -14 -15 -31 -25 -35 -21 -229 -208 -229 -221 0 -5 -9
          -18 -20 -30 -30 -29 -65 -82 -101 -150 -17 -33 -35 -62 -38 -65 -4 -3 -18 -30
          -30 -60 -13 -30 -27 -57 -31 -60 -4 -3 -17 -32 -29 -65 -12 -33 -26 -62 -30
          -65 -8 -6 -61 -182 -61 -204 0 -9 -4 -16 -9 -16 -8 0 -51 -169 -51 -202 0 -10
          -4 -18 -9 -18 -4 0 -19 -64 -31 -142 -13 -79 -26 -145 -30 -148 -5 -4 -44
          -216 -58 -320 -2 -19 -8 -37 -11 -40 -4 -3 -17 -54 -30 -115 -12 -60 -26 -112
          -30 -115 -4 -3 -18 -43 -31 -90 -13 -47 -30 -91 -37 -98 -7 -7 -13 -16 -13
          -19 0 -10 166 24 185 38 23 16 390 59 507 59 33 0 58 5 60 11 5 16 1120 17
          1126 0 2 -7 33 -11 85 -11 72 0 206 -7 728 -36 l116 -7 6 48 c9 65 9 213 0
          218 -5 3 -109 11 -233 18 -124 7 -227 16 -230 19 -9 13 -545 30 -945 30 -400
          0 -797 -16 -773 -32 8 -5 -2 -8 -25 -8 -31 0 -38 3 -35 18 4 21 81 39 208 49
          47 3 87 10 90 14 10 15 675 32 1062 27 213 -3 385 -1 382 4 -3 4 -12 8 -20 8
          -8 0 -14 6 -14 14 0 8 -13 25 -29 38 -16 14 -41 44 -57 69 -16 24 -32 45 -35
          47 -4 2 -17 31 -30 65 -12 34 -26 64 -31 68 -9 6 -48 152 -48 180 0 9 -6 19
          -12 22 -10 3 -13 59 -13 237 0 178 3 234 13 238 6 2 12 12 12 21 0 30 40 175
          49 181 6 3 19 27 30 53 11 26 25 47 31 47 5 0 10 4 10 10 0 32 101 140 160
          170 24 12 50 28 57 36 8 7 39 21 71 31 31 10 59 21 60 25 2 5 13 8 24 8 11 0
          18 5 15 13 -5 15 -435 10 -454 -5 -7 -5 -22 -30 -33 -54 -12 -24 -25 -44 -30
          -44 -4 0 -18 -26 -31 -57 -13 -32 -28 -63 -35 -70 -6 -6 -21 -40 -34 -75 -13
          -35 -27 -65 -32 -68 -4 -3 -17 -37 -29 -75 -12 -39 -24 -72 -28 -75 -7 -6 -51
          -179 -51 -203 0 -8 -4 -17 -9 -19 -27 -9 -58 -397 -44 -546 9 -99 6 -116 -23
          -110 -27 5 -34 49 -41 248 -4 132 -1 215 12 335 9 88 20 162 25 165 4 3 10 23
          14 45 14 80 49 210 57 215 5 3 18 36 29 73 11 37 24 67 29 67 4 0 18 26 31 58
          13 31 27 59 30 62 4 3 20 25 35 50 16 24 34 51 42 58 23 23 15 30 -52 37 -54
          5 -68 4 -89 -12 -14 -10 -26 -24 -26 -30 0 -7 -11 -20 -25 -29 -14 -9 -25 -20
          -25 -25 0 -4 -15 -29 -32 -55 -18 -25 -41 -60 -52 -78 -10 -17 -21 -33 -25
          -36 -11 -7 -41 -71 -41 -85 0 -7 -8 -21 -17 -32 -10 -11 -29 -54 -43 -94 -14
          -41 -28 -76 -32 -79 -4 -3 -16 -41 -28 -85 -12 -44 -27 -93 -35 -110 -7 -16
          -20 -77 -30 -135 -9 -58 -20 -114 -25 -125 -11 -25 -27 -158 -36 -300 -5 -82
          -11 -113 -22 -121 -27 -20 -42 8 -42 78 0 109 22 383 31 386 5 2 9 19 9 37 0
          48 49 328 59 335 4 3 18 47 31 98 13 50 27 92 31 92 3 0 16 27 29 61 12 33 30
          69 41 80 10 10 19 26 19 34 0 17 24 59 52 89 10 11 18 23 18 28 0 4 29 37 65
          72 36 35 65 68 65 73 0 9 -98 33 -133 33 -17 0 -167 -149 -167 -166 0 -7 -41
          -62 -67 -92 -13 -14 -23 -31 -23 -38 0 -8 -8 -22 -17 -32 -10 -9 -29 -41 -42
          -70 -14 -28 -28 -52 -31 -52 -4 0 -18 -28 -31 -62 -12 -35 -26 -65 -30 -68 -8
          -6 -59 -157 -59 -176 0 -8 -4 -14 -8 -14 -5 0 -19 -46 -32 -102 -12 -57 -26
          -107 -30 -113 -18 -25 -51 -364 -47 -480 2 -33 -1 -40 -19 -43 -17 -2 -22 5
          -32 48 -7 28 -12 65 -12 81 0 16 -4 29 -10 29 -6 0 -10 30 -10 70 0 39 4 70 9
          70 4 0 11 35 14 78 8 97 37 265 47 272 7 5 34 103 47 173 3 15 9 27 13 27 5 0
          18 32 29 70 12 39 25 70 29 70 4 0 18 29 30 65 13 36 29 68 36 73 6 4 20 27
          31 52 10 25 27 54 37 66 10 11 18 25 18 31 0 5 11 22 25 37 14 15 25 29 25 31
          0 11 53 80 70 92 11 8 20 19 20 25 0 6 19 28 42 50 45 42 44 49 -14 68 -21 6
          -38 16 -38 21 0 16 -18 9 -48 -18 -16 -16 -50 -47 -75 -70 -26 -23 -47 -44
          -47 -48 0 -3 -16 -23 -36 -43 -20 -20 -43 -50 -51 -67 -9 -16 -22 -34 -29 -38
          -7 -4 -21 -23 -30 -42 -9 -19 -20 -35 -24 -35 -5 0 -18 -21 -31 -47 -12 -27
          -26 -50 -29 -53 -4 -3 -18 -25 -30 -50 -12 -25 -26 -47 -30 -50 -4 -3 -19 -39
          -35 -80 -16 -41 -31 -77 -35 -80 -4 -3 -17 -36 -30 -75 -12 -38 -26 -75 -31
          -80 -4 -6 -18 -51 -30 -100 -12 -50 -25 -92 -29 -95 -8 -6 -47 -206 -56 -292
          -4 -32 -11 -58 -15 -58 -5 0 -9 -20 -9 -44 0 -55 -8 -76 -28 -76 -13 0 -14 20
          -9 153 6 153 32 339 48 345 5 2 9 13 9 25 0 29 50 211 60 217 4 3 17 35 29 73
          13 37 27 67 32 67 5 0 9 6 9 14 0 15 39 108 49 116 3 3 19 32 35 65 16 33 32
          62 35 65 3 3 11 14 17 25 26 48 40 69 62 93 12 14 22 31 22 38 0 7 6 17 13 21
          7 4 20 19 29 33 8 14 24 35 35 47 11 12 28 34 39 49 10 15 31 38 47 52 15 14
          27 30 27 37 0 6 14 21 30 33 38 28 38 47 2 66 -26 13 -31 13 -53 -3 -20 -14
          -123 -108 -151 -138 -98 -104 -168 -186 -168 -197 0 -4 -13 -21 -30 -38 -16
          -16 -30 -36 -30 -43 0 -8 -9 -19 -20 -25 -11 -6 -20 -17 -20 -25 0 -7 -11 -26
          -24 -42 -13 -15 -36 -53 -51 -83 -15 -30 -31 -57 -35 -60 -4 -3 -18 -29 -31
          -57 -12 -29 -26 -53 -30 -53 -4 0 -10 -13 -14 -30 -4 -16 -11 -32 -16 -35 -5
          -4 -9 -15 -9 -25 0 -10 -6 -25 -14 -32 -7 -8 -26 -54 -42 -104 -16 -49 -32
          -91 -35 -94 -4 -3 -17 -43 -29 -90 -13 -47 -26 -90 -31 -95 -4 -6 -18 -62 -31
          -125 -13 -63 -26 -117 -29 -120 -4 -3 -10 -22 -13 -44 -7 -46 -14 -58 -29 -53
          -7 2 -10 21 -9 48 5 75 42 303 50 308 4 4 18 51 31 106 13 55 27 102 31 105 4
          3 17 39 29 80 13 41 26 77 30 80 8 6 61 141 61 157 0 6 6 13 13 16 6 2 21 28
          32 56 11 28 22 51 25 51 4 0 17 23 30 50 13 28 26 50 30 50 3 0 18 25 34 55
          15 30 31 55 35 55 4 0 17 18 29 39 12 21 29 41 37 45 8 3 15 11 15 18 0 7 20
          36 45 63 24 28 45 53 45 56 0 8 155 163 203 202 37 31 48 58 25 56 -7 0 -35
          -9 -63 -20z"/>
          <path  d="M20498 5130 c-23 -22 -52 -49 -64 -60 -12 -11 -26 -20 -32 -20 -6 0
          -21 -13 -33 -29 -12 -16 -30 -32 -40 -34 -11 -3 -19 -9 -19 -14 1 -8 60 -48
          110 -73 14 -7 27 -16 30 -20 3 -3 26 -17 53 -29 26 -13 47 -26 47 -30 0 -4 26
          -21 58 -37 31 -16 66 -36 76 -46 11 -10 25 -18 30 -18 6 0 24 -11 40 -25 15
          -13 48 -34 72 -46 24 -11 44 -24 44 -28 0 -4 23 -18 50 -31 28 -13 50 -26 50
          -30 0 -3 19 -17 43 -30 23 -13 44 -26 47 -30 3 -3 28 -19 55 -35 28 -16 56
          -35 63 -42 14 -13 32 -9 32 7 0 5 25 33 55 62 30 29 55 58 55 65 0 6 14 21 30
          33 40 29 39 55 -5 76 -19 9 -35 19 -35 23 0 3 -21 17 -47 31 -26 14 -58 35
          -72 48 -13 12 -30 22 -37 22 -8 0 -22 8 -32 18 -17 16 -27 22 -84 55 -12 6
          -31 20 -42 30 -11 9 -25 17 -31 17 -6 0 -21 9 -32 19 -11 11 -45 32 -74 47
          -30 16 -55 32 -57 36 -2 4 -24 18 -50 30 -25 13 -54 31 -64 41 -10 9 -24 17
          -31 17 -7 0 -22 9 -34 19 -11 10 -34 26 -50 35 -31 15 -32 15 -75 -24z"/>
          <path fill="black" d="M10137 5098 c11 -13 32 -36 47 -52 14 -16 26 -35 26 -42 0 -7 8 -19
          18 -26 10 -7 29 -35 42 -63 13 -27 27 -52 31 -55 3 -3 17 -30 30 -60 13 -30
          29 -61 35 -68 7 -6 16 -21 19 -32 6 -19 15 -20 336 -20 286 0 329 2 329 15 0
          8 3 15 8 15 4 0 18 41 31 90 14 50 28 90 32 90 4 0 15 20 25 45 9 25 22 49 28
          53 6 4 21 22 34 40 22 31 22 32 2 32 -11 0 -20 5 -20 10 0 6 -36 10 -87 10
          -49 0 -266 9 -483 20 -217 11 -419 20 -449 20 l-54 0 20 -22z"/>
          <path id="31"  d="M13905 5093 c-49 -8 -92 -18 -95 -22 -3 -4 -27 -13 -55 -20 -66 -15
          -105 -31 -105 -42 0 -5 -7 -9 -16 -9 -17 0 -126 -49 -134 -60 -3 -4 -24 -17
          -46 -28 -23 -12 -44 -28 -47 -37 -4 -8 -12 -15 -19 -15 -11 0 -26 -12 -106
          -91 -51 -48 -122 -132 -122 -143 0 -6 -12 -22 -25 -35 -14 -13 -25 -29 -25
          -35 0 -7 -8 -21 -18 -32 -10 -10 -31 -50 -47 -89 -16 -38 -33 -72 -36 -75 -4
          -3 -18 -41 -31 -85 -12 -44 -25 -82 -29 -85 -11 -9 -30 -162 -36 -291 l-6
          -127 56 -16 c30 -9 60 -16 66 -16 6 0 11 -4 11 -8 0 -5 39 -19 88 -32 48 -13
          89 -26 92 -30 5 -8 106 -40 125 -40 9 0 12 39 12 138 0 128 17 281 32 292 4 3
          15 36 25 73 10 37 24 74 30 80 6 7 24 36 39 65 16 29 38 63 50 76 12 13 40 45
          62 70 21 25 45 46 51 46 7 0 19 9 26 19 8 11 36 30 63 43 28 12 52 26 55 30
          10 12 88 37 140 44 28 4 50 10 50 15 0 5 63 9 140 9 87 0 140 -4 140 -10 0 -5
          10 -10 22 -10 33 0 198 -42 198 -50 0 -4 15 -14 33 -21 84 -34 297 -204 297
          -237 0 -6 8 -17 18 -24 10 -7 32 -35 47 -63 16 -27 35 -55 41 -62 6 -7 20 -35
          30 -63 10 -27 21 -50 25 -50 4 0 18 -33 31 -72 12 -40 25 -75 29 -78 14 -12
          40 -191 51 -352 9 -134 9 -205 0 -296 -13 -130 -40 -274 -52 -282 -5 -3 -19
          -32 -31 -65 -12 -32 -33 -70 -45 -84 -13 -14 -24 -29 -24 -34 0 -8 -76 -84
          -129 -129 -18 -15 -48 -33 -66 -40 -19 -6 -35 -15 -35 -19 0 -10 -45 -26 -125
          -45 -38 -10 -72 -20 -75 -24 -8 -12 -166 -30 -257 -30 -87 0 -224 17 -233 29
          -3 4 -38 17 -78 30 -40 13 -79 29 -85 35 -7 6 -28 20 -47 30 -42 23 -63 41
          -141 121 -84 86 -92 96 -114 138 -11 20 -23 37 -26 37 -4 0 -14 19 -24 43 -9
          23 -27 65 -40 92 -13 28 -25 57 -28 65 -3 9 -29 21 -63 29 -33 7 -61 16 -64
          20 -6 8 -152 51 -174 51 -9 0 -16 4 -16 8 0 10 -130 45 -138 37 -7 -7 27 -153
          36 -157 4 -2 20 -42 36 -90 15 -48 31 -88 35 -90 4 -2 17 -28 30 -58 13 -30
          27 -57 32 -60 4 -3 19 -29 32 -58 13 -28 26 -50 28 -47 3 2 17 -21 33 -51 16
          -30 31 -52 34 -49 3 3 12 -7 19 -22 19 -42 36 -65 61 -86 12 -10 22 -22 22
          -27 0 -5 35 -44 78 -87 106 -105 133 -127 190 -152 12 -6 22 -15 22 -20 0 -5
          21 -19 48 -31 26 -12 49 -26 52 -29 3 -4 37 -18 75 -32 39 -13 75 -29 81 -35
          7 -6 52 -19 100 -30 138 -30 414 -27 552 5 56 13 102 27 102 31 0 4 19 12 43
          19 42 12 207 90 217 102 3 4 28 19 55 34 28 16 52 31 55 35 3 4 32 29 65 56
          80 65 175 169 175 193 0 6 4 11 8 11 5 0 17 17 28 38 10 20 24 44 30 52 6 8
          22 42 35 75 13 33 27 62 30 65 8 6 49 167 49 192 0 11 4 17 8 14 4 -2 8 3 9
          12 1 9 6 55 12 102 21 161 7 602 -19 620 -4 3 -10 25 -14 50 -13 93 -39 215
          -46 220 -4 3 -17 43 -29 89 -11 47 -25 87 -29 90 -4 3 -14 25 -21 49 -7 24
          -27 73 -45 110 -18 37 -39 82 -47 100 -8 17 -17 32 -20 32 -4 0 -12 15 -19 33
          -7 17 -23 43 -35 57 -12 14 -31 40 -41 59 -10 18 -34 50 -52 70 -77 83 -146
          151 -153 151 -4 0 -21 12 -37 28 -51 48 -83 72 -96 72 -7 0 -21 8 -32 18 -24
          22 -100 62 -119 62 -7 0 -20 6 -27 13 -7 7 -47 23 -88 36 -41 12 -84 26 -95
          32 -79 36 -361 48 -520 22z"/>
          <path  fill="black" d="M8033 5093 c-187 -4 -194 -6 -150 -55 26 -30 43 -54 60 -88 6 -14 19
          -33 27 -42 9 -10 24 -40 34 -68 10 -27 22 -50 26 -50 5 0 19 -34 31 -75 13
          -41 26 -75 30 -75 3 0 17 -45 30 -100 13 -55 27 -100 31 -100 5 0 8 -10 9 -22
          0 -13 12 18 27 67 14 50 28 92 31 95 4 3 17 39 30 80 13 41 27 77 30 80 4 3
          18 30 30 60 13 30 27 57 30 60 4 3 17 26 29 51 13 26 31 54 42 64 11 10 20 23
          20 30 0 7 10 23 23 37 12 13 28 32 36 41 15 19 38 18 -456 10z"/>
          <path id="32"  d="M11330 5024 c-8 -15 -23 -36 -32 -46 -10 -10 -18 -23 -18 -28 0 -6
          -11 -27 -24 -48 -14 -20 -35 -66 -47 -102 -12 -36 -26 -68 -31 -71 -10 -7 -48
          -197 -48 -244 0 -19 -4 -35 -10 -35 -6 0 -10 -26 -10 -59 0 -56 1 -59 27 -66
          15 -3 30 -10 33 -14 3 -4 55 -20 115 -36 61 -16 112 -32 115 -36 4 -5 94 -32
          160 -46 14 -3 27 -9 30 -13 5 -6 130 -43 190 -55 19 -4 37 -11 40 -16 3 -4 46
          -18 95 -30 50 -13 92 -26 95 -30 3 -4 43 -17 90 -29 47 -12 87 -25 90 -30 3
          -4 55 -20 115 -36 61 -15 112 -30 115 -34 3 -4 43 -17 89 -29 46 -13 91 -27
          100 -32 22 -12 154 -49 175 -49 9 0 16 -4 16 -10 0 -5 8 -10 19 -10 16 0 19
          12 24 133 5 130 35 343 46 332 3 -3 15 31 28 77 12 46 27 84 33 86 5 2 10 10
          10 17 0 16 38 107 48 115 4 3 20 31 36 63 16 31 33 57 38 57 5 0 13 10 18 23
          17 39 33 58 130 154 52 52 119 109 149 127 30 17 56 37 58 43 3 9 -52 10 -219
          6 -211 -5 -1289 10 -1688 24 l-185 6 -15 -29z"/>
          <path fill="black" d="M6245 4995 c-12 -5 -585 -32 -862 -41 -112 -4 -203 -11 -203 -16 0
          -4 -60 -8 -134 -8 -74 0 -137 -4 -140 -9 -5 -8 55 -13 312 -27 67 -4 122 -11
          122 -15 0 -5 9 -9 20 -9 33 0 81 -29 122 -73 21 -23 38 -44 38 -48 0 -3 10
          -17 23 -31 27 -31 57 -85 57 -104 0 -8 8 -22 18 -32 11 -11 27 -47 37 -80 l19
          -62 42 0 c23 0 44 -4 46 -8 2 -5 48 -12 104 -15 l101 -7 19 60 c10 33 22 60
          25 60 4 0 17 27 29 60 12 33 26 60 30 60 4 0 18 27 31 59 13 33 26 58 29 56 4
          -2 19 21 35 51 16 29 32 54 37 54 4 0 8 5 8 10 0 15 41 72 63 88 31 23 12 41
          -28 27z"/>
          <path id="33" d="M9136 4970 c-38 -10 -72 -22 -75 -27 -3 -4 -23 -17 -45 -28 -35 -18
          -146 -121 -146 -135 0 -4 -11 -20 -24 -35 -14 -16 -32 -48 -41 -72 -9 -24 -20
          -43 -24 -43 -4 0 -19 -33 -32 -72 -13 -40 -27 -75 -31 -78 -17 -13 -38 -182
          -38 -307 0 -124 24 -331 40 -343 3 -3 17 -43 30 -90 13 -47 27 -87 32 -91 4
          -3 19 -34 34 -68 14 -35 32 -70 40 -78 8 -8 14 -20 14 -27 0 -7 11 -25 25 -40
          14 -15 25 -31 25 -35 0 -4 9 -16 20 -26 11 -10 20 -24 20 -32 0 -7 6 -13 13
          -13 7 0 21 -14 31 -31 10 -16 28 -34 39 -40 28 -12 56 -30 78 -50 9 -10 33
          -23 53 -30 20 -7 36 -16 36 -19 0 -4 19 -13 43 -20 23 -7 56 -17 72 -22 52
          -16 236 -22 309 -9 71 13 157 39 166 50 3 4 21 17 41 29 47 28 114 92 122 115
          4 9 10 17 15 17 5 0 22 28 37 63 15 34 31 64 35 67 10 8 39 120 46 183 4 31
          11 57 15 57 11 0 12 263 1 276 -5 5 -14 44 -21 87 -21 128 -23 133 -96 156
          -35 12 -71 21 -79 21 -8 0 -16 4 -18 9 -1 5 -50 22 -108 36 -58 15 -107 31
          -110 34 -3 4 -45 17 -95 30 -49 12 -91 26 -93 31 -7 21 -28 8 -52 -29 -13 -22
          -27 -38 -31 -35 -4 2 -11 -1 -14 -7 -4 -6 -27 -19 -52 -30 -63 -27 -169 -26
          -231 2 -49 22 -112 76 -112 94 0 6 -9 21 -20 33 -32 34 -47 145 -32 244 6 45
          15 84 19 87 5 3 21 32 38 64 16 31 33 57 37 57 4 0 16 8 26 18 22 22 87 42
          138 42 47 0 114 -18 114 -30 0 -16 62 -40 119 -47 31 -3 58 -9 61 -12 3 -3 56
          -17 119 -29 62 -13 120 -26 127 -29 8 -3 14 -1 14 4 0 12 -80 103 -91 103 -4
          0 -19 11 -33 25 -14 14 -32 25 -40 25 -8 0 -19 6 -23 13 -4 7 -40 23 -78 35
          -92 29 -257 30 -359 2z"/>
          <path  fill="black" d="M14916 4946 c3 -8 18 -19 33 -26 15 -6 42 -23 59 -38 85 -74 63 -68
          266 -75 179 -5 186 -5 186 14 0 10 4 19 8 19 8 0 52 96 52 113 0 4 -137 7
          -305 7 -258 0 -304 -2 -299 -14z"/>
          <path  d="M20260 4941 c0 -5 -17 -20 -37 -32 -21 -13 -44 -31 -51 -41 -7 -10
          -19 -18 -26 -18 -7 0 -24 -11 -37 -24 -13 -13 -43 -34 -66 -46 -24 -12 -43
          -24 -43 -26 0 -2 25 -15 55 -29 30 -15 57 -30 60 -35 3 -5 33 -21 65 -36 33
          -15 60 -30 60 -33 0 -4 25 -18 55 -32 30 -13 55 -27 55 -31 0 -5 20 -17 45
          -29 25 -12 45 -25 45 -29 0 -5 25 -20 55 -35 30 -14 55 -30 55 -34 0 -5 13
          -14 29 -19 16 -6 38 -19 48 -29 10 -10 40 -28 66 -41 26 -13 47 -27 47 -31 0
          -5 14 -15 32 -22 17 -7 50 -27 72 -44 23 -16 53 -36 69 -42 15 -7 27 -17 27
          -23 0 -25 33 -5 99 61 39 40 71 75 71 78 0 4 16 23 36 44 l36 37 -26 11 c-14
          7 -26 16 -26 20 0 4 -24 20 -52 34 -29 15 -55 30 -58 34 -3 5 -19 15 -37 24
          -17 9 -37 23 -45 30 -7 7 -33 24 -58 38 -25 13 -54 32 -64 42 -11 9 -25 17
          -32 17 -6 0 -27 13 -46 28 -20 15 -54 36 -77 46 -22 11 -41 22 -41 26 0 5 -22
          19 -49 33 -26 14 -65 37 -85 51 -21 14 -46 28 -57 31 -10 4 -19 10 -19 14 0 5
          -22 19 -50 32 -27 13 -50 27 -50 31 0 5 -11 8 -25 8 -14 0 -25 -4 -25 -9z"/>
          <path  d="M2000 4801 c0 -27 -5 -84 -11 -126 -6 -42 -8 -79 -6 -82 3 -2 13 11
          22 31 10 20 21 36 25 36 5 0 19 21 32 48 13 26 28 50 35 55 12 8 53 70 53 81
          0 3 -34 6 -75 6 l-75 0 0 -49z"/>
          <path  fill="black" d="M5092 4797 c26 -25 57 -64 69 -86 11 -23 25 -41 29 -41 5 0 16 -20
          26 -45 9 -25 22 -49 29 -53 11 -7 55 -134 55 -160 0 -7 4 -12 9 -12 5 0 17
          -44 27 -97 27 -138 27 -430 0 -560 -10 -51 -22 -93 -25 -93 -4 0 -18 -31 -30
          -70 -13 -38 -27 -70 -32 -70 -5 0 -15 -15 -23 -32 -15 -36 -40 -69 -99 -131
          -20 -22 -35 -42 -33 -45 9 -8 282 -1 318 9 23 6 45 21 60 42 12 17 25 34 29
          37 8 7 49 126 49 144 0 8 4 16 10 18 22 8 60 325 60 507 -1 183 -35 465 -60
          481 -4 3 -15 29 -24 58 -10 29 -23 58 -29 65 -7 6 -19 25 -27 40 -31 60 -100
          107 -158 107 -17 0 -32 4 -34 9 -1 5 -57 12 -123 16 l-120 7 47 -45z"/>
          <path fill="black" d="M9153 4833 c-13 -2 -23 -8 -23 -13 0 -5 -10 -12 -22 -16 -24 -7 -57
          -42 -58 -61 0 -6 -6 -13 -12 -16 -10 -3 -13 -36 -13 -119 0 -96 3 -117 18
          -132 9 -10 17 -24 17 -32 0 -7 19 -34 43 -58 47 -50 88 -63 163 -51 57 8 108
          57 125 118 15 54 6 187 -16 251 -17 48 -67 106 -93 106 -7 0 -11 4 -8 9 11 17
          -62 25 -121 14z"/>
          <path id="34" d="M4750 4820 c0 -5 -9 -10 -21 -10 -27 0 -119 -39 -144 -62 -38 -34
          -140 -136 -152 -151 -6 -8 -24 -39 -39 -68 -16 -30 -32 -56 -36 -60 -4 -3 -18
          -45 -30 -95 -13 -49 -27 -92 -33 -96 -16 -11 -13 -380 3 -385 6 -3 12 -15 12
          -27 0 -35 38 -160 49 -164 6 -2 11 -10 11 -18 0 -8 8 -29 17 -47 9 -18 17 -35
          18 -39 0 -4 7 -16 16 -26 9 -10 27 -38 40 -62 26 -47 74 -98 123 -132 17 -12
          33 -25 36 -28 13 -14 52 -29 103 -41 32 -8 57 -17 57 -21 0 -5 20 -8 45 -8 25
          0 45 4 45 9 0 5 19 11 42 15 51 8 90 30 137 78 60 61 83 91 110 143 14 28 28
          52 31 55 7 6 37 78 46 110 4 14 10 27 15 30 18 12 42 235 36 345 -6 122 -27
          265 -38 265 -4 0 -15 32 -24 70 -10 39 -23 76 -30 83 -6 7 -18 30 -25 52 -22
          69 -47 107 -125 189 -48 50 -79 71 -123 80 -17 4 -32 12 -32 17 0 5 -31 9 -70
          9 -40 0 -70 -4 -70 -10z"/>
          <path  fill="black" d="M15120 4793 c0 -5 16 -26 36 -48 20 -22 46 -51 58 -65 12 -14 31 -38
          41 -55 10 -16 23 -34 29 -40 6 -5 22 -30 36 -55 13 -25 32 -54 42 -64 18 -19
          18 -19 18 18 0 52 21 180 30 186 8 5 31 102 25 108 -4 4 -226 21 -277 22 -21
          0 -38 -3 -38 -7z"/>
          <path id="35"  d="M9460 4735 c0 -22 12 -45 20 -40 14 9 33 -136 26 -206 l-6 -68 52
          -15 c29 -8 67 -18 83 -22 17 -4 32 -10 35 -14 3 -4 43 -17 90 -29 46 -12 87
          -26 90 -30 3 -4 57 -20 120 -35 63 -16 117 -32 120 -36 3 -4 46 -18 95 -30 50
          -12 92 -26 95 -29 3 -4 55 -20 115 -35 61 -16 112 -32 115 -36 3 -4 30 -13 60
          -19 65 -15 123 -34 130 -42 3 -3 48 -16 100 -29 52 -14 97 -27 100 -30 6 -7
          141 -46 193 -55 20 -4 37 -11 37 -15 0 -4 42 -17 93 -30 50 -13 94 -26 97 -30
          3 -3 48 -17 100 -30 52 -14 97 -27 100 -31 3 -4 52 -20 110 -34 58 -15 107
          -31 110 -35 3 -3 45 -17 95 -29 49 -12 92 -26 95 -30 3 -4 57 -20 120 -35 63
          -16 117 -32 120 -36 3 -4 46 -18 95 -30 50 -12 92 -26 95 -30 3 -4 47 -17 98
          -30 50 -12 92 -25 92 -29 0 -4 35 -15 78 -25 81 -18 144 -37 152 -46 3 -3 48
          -16 100 -29 52 -13 97 -27 100 -31 3 -4 45 -17 95 -29 49 -12 92 -26 95 -30 3
          -4 57 -20 120 -36 63 -16 117 -32 120 -36 3 -4 46 -18 95 -30 50 -12 92 -25
          95 -29 3 -4 57 -20 120 -36 63 -16 117 -32 120 -36 3 -4 46 -17 95 -29 50 -13
          92 -26 95 -31 4 -4 20 -10 37 -14 30 -6 31 -5 38 37 8 49 20 76 44 100 9 9 24
          25 33 35 10 11 26 28 38 38 11 11 20 22 20 26 0 4 -25 13 -55 20 -30 7 -55 16
          -55 20 0 4 -12 10 -27 13 -46 8 -148 40 -153 48 -3 4 -41 17 -85 29 -44 12
          -82 25 -85 29 -3 4 -52 20 -110 36 -58 15 -107 32 -110 36 -3 4 -46 17 -95 30
          -50 12 -92 25 -95 30 -3 4 -41 18 -85 30 -44 11 -84 25 -90 29 -5 4 -55 20
          -110 36 -55 15 -102 30 -105 34 -3 3 -43 17 -90 30 -47 13 -87 26 -90 30 -3 3
          -52 19 -110 34 -58 15 -107 31 -110 35 -3 4 -43 17 -89 29 -47 12 -87 25 -90
          30 -3 4 -46 18 -96 31 -49 12 -92 26 -95 30 -3 4 -52 20 -110 36 -58 15 -107
          30 -110 34 -3 4 -45 18 -95 31 -49 12 -92 26 -95 30 -3 4 -43 18 -90 30 -47
          13 -87 26 -90 30 -3 3 -52 19 -110 34 -58 16 -107 31 -110 36 -3 4 -47 17 -97
          30 -51 12 -93 26 -93 30 0 4 -15 10 -32 13 -66 12 -208 53 -203 59 3 3 -37 15
          -90 28 -52 13 -95 26 -95 29 0 4 -45 18 -100 31 -55 14 -100 28 -100 31 0 4
          -51 20 -114 35 -63 15 -113 30 -111 34 2 3 -43 16 -101 30 -57 13 -104 27
          -104 31 0 3 -44 17 -97 29 -54 12 -100 26 -103 31 -3 4 -59 20 -125 35 -66 14
          -122 30 -125 34 -3 4 -60 18 -127 32 -95 20 -123 23 -123 13z"/>
          <path d="M19930 4710 c-19 -11 -30 -19 -25 -20 6 0 -18 -14 -52 -30 -33 -16
          -63 -33 -65 -37 -1 -4 -24 -18 -50 -30 -27 -13 -48 -26 -48 -31 0 -4 27 -18
          60 -31 33 -13 60 -27 60 -30 0 -4 29 -20 65 -36 36 -16 65 -31 65 -35 0 -3 25
          -17 55 -30 30 -13 55 -27 55 -30 0 -4 22 -17 50 -30 27 -13 48 -25 45 -28 -2
          -3 22 -17 54 -32 32 -16 60 -32 62 -37 2 -4 25 -18 52 -31 26 -13 47 -26 47
          -30 0 -4 27 -20 60 -36 33 -16 60 -33 60 -38 0 -4 20 -17 45 -29 25 -12 45
          -25 45 -29 0 -5 20 -19 45 -31 25 -12 45 -26 45 -30 0 -7 62 -39 77 -39 8 0
          139 125 186 178 18 20 18 21 0 39 -10 10 -39 29 -63 42 -25 14 -47 27 -50 31
          -3 3 -27 19 -55 35 -27 16 -58 37 -69 47 -11 10 -25 18 -31 18 -6 0 -20 8 -31
          18 -11 9 -30 23 -42 30 -12 6 -32 18 -44 25 -12 6 -28 17 -35 24 -7 6 -34 22
          -60 35 -26 13 -55 31 -65 41 -10 9 -24 17 -31 17 -7 0 -22 9 -34 19 -11 11
          -40 29 -64 41 -24 13 -46 26 -49 30 -3 4 -32 20 -65 36 -33 16 -62 32 -65 36
          -8 10 -76 38 -92 38 -7 -1 -29 -9 -48 -20z"/>
          <path fill="black" d="M10425 4608 c14 -29 25 -58 25 -64 0 -28 41 -52 140 -79 58 -15 107
          -31 110 -35 3 -4 50 -18 105 -31 55 -13 102 -26 105 -30 3 -4 26 -10 53 -14
          42 -6 47 -5 47 11 0 25 18 197 26 252 l6 42 -320 0 -321 0 24 -52z"/>
          <path  d="M13985 4544 c-11 -3 -44 -10 -72 -15 -29 -6 -59 -16 -65 -22 -7 -7
          -32 -21 -54 -31 -23 -10 -59 -33 -80 -52 -22 -18 -42 -34 -46 -34 -5 0 -8 -5
          -8 -11 0 -6 -15 -25 -32 -44 -64 -65 -98 -116 -98 -146 0 -6 -6 -17 -14 -23
          -16 -12 -56 -135 -56 -171 0 -13 -6 -25 -12 -27 -20 -7 -18 -359 2 -379 20
          -19 66 -39 90 -39 11 0 20 -4 20 -9 0 -4 37 -19 83 -31 45 -13 84 -27 87 -31
          3 -3 50 -19 105 -34 55 -15 102 -30 105 -34 3 -4 43 -18 90 -31 47 -13 87 -27
          90 -30 3 -4 40 -17 83 -30 42 -13 77 -27 77 -32 0 -4 16 -8 35 -8 19 0 35 5
          35 10 0 6 18 10 40 10 22 0 40 -4 40 -10 0 -5 11 -10 24 -10 76 0 170 -81 182
          -156 3 -24 10 -44 15 -44 5 0 9 -36 9 -80 0 -44 -4 -80 -8 -80 -4 0 -14 -21
          -21 -47 -18 -59 -78 -117 -141 -135 -25 -7 -81 -13 -125 -13 -128 0 -206 46
          -245 144 l-17 46 -102 25 c-57 14 -100 28 -97 31 3 3 -37 15 -89 28 -51 12
          -95 26 -97 30 -3 8 -169 53 -175 47 -7 -6 30 -81 43 -89 8 -4 14 -15 14 -23 0
          -8 14 -28 30 -46 17 -17 30 -34 30 -37 0 -9 107 -121 115 -121 8 0 67 -42 80
          -57 5 -6 30 -20 55 -32 25 -11 41 -21 35 -21 -15 -2 137 -50 157 -50 9 0 18
          -6 21 -12 3 -10 43 -13 157 -13 114 0 154 3 158 13 2 6 13 12 25 12 29 0 159
          37 166 48 3 4 21 14 38 21 31 12 113 87 158 142 28 35 50 77 65 124 7 22 16
          42 20 45 10 6 50 150 50 177 0 12 4 23 9 25 6 2 15 62 22 134 9 102 9 160 0
          260 -7 71 -16 131 -20 134 -5 3 -11 23 -15 45 -12 70 -38 155 -47 155 -5 0 -9
          6 -9 14 0 17 -51 138 -61 146 -3 3 -14 21 -24 40 -10 19 -23 42 -31 50 -7 8
          -28 32 -46 52 -18 21 -44 53 -58 73 -14 19 -29 35 -35 35 -5 0 -30 18 -55 40
          -24 22 -47 40 -51 40 -4 0 -20 11 -36 24 -15 12 -55 33 -88 45 -33 12 -62 26
          -65 30 -16 26 -303 61 -365 45z"/>
          <path d="M19600 4522 c-19 -9 -38 -20 -41 -25 -3 -4 -36 -19 -74 -32 -38 -14
          -72 -29 -75 -33 -5 -8 -136 -52 -153 -52 -5 0 -14 -6 -22 -13 -7 -8 -52 -22
          -99 -33 -47 -10 -86 -22 -86 -26 0 -5 -11 -8 -24 -8 -41 0 -37 -15 10 -36 24
          -10 44 -22 44 -25 0 -4 21 -17 48 -29 26 -12 50 -28 54 -34 4 -7 25 -18 46
          -25 22 -8 51 -23 65 -36 15 -12 45 -30 69 -41 24 -10 49 -24 56 -32 16 -14 83
          -46 95 -44 4 1 7 -2 7 -8 0 -13 116 -70 126 -62 4 4 4 2 1 -5 -5 -7 10 -18 39
          -29 25 -10 51 -22 58 -28 15 -15 100 -56 115 -56 7 0 9 -4 6 -10 -3 -5 0 -10
          7 -10 7 0 25 -6 39 -14 14 -7 37 -16 51 -19 15 -4 33 -13 40 -20 7 -7 39 -23
          71 -36 31 -13 57 -27 57 -31 0 -4 25 -15 55 -25 30 -9 57 -21 61 -26 3 -5 14
          -9 25 -9 10 0 19 -4 19 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 6 10 13 10
          8 0 22 9 32 20 10 11 24 20 32 20 7 0 16 6 19 13 3 8 37 40 77 73 40 32 104
          90 143 128 l72 70 -47 24 c-25 14 -48 28 -51 32 -3 3 -24 17 -47 30 -24 13
          -43 27 -43 30 0 4 -22 17 -50 30 -27 13 -50 27 -50 31 0 4 -20 17 -44 28 -24
          12 -57 33 -72 46 -16 14 -35 25 -41 25 -7 0 -21 8 -31 18 -10 9 -44 30 -75 46
          -31 16 -57 32 -57 36 0 4 -24 17 -52 31 -29 13 -55 27 -58 30 -3 3 -25 17 -50
          29 -25 12 -47 25 -50 29 -3 3 -34 20 -70 36 -36 17 -67 33 -70 36 -9 12 -82
          39 -104 38 -11 0 -37 -8 -56 -17z"/>
          <path  fill="black" d="M5707 4108 l2 -213 64 -3 63 -3 18 121 c10 66 21 120 25 120 10 0 42
          150 35 161 -3 5 -32 9 -65 9 -33 0 -59 4 -59 10 0 6 -19 10 -42 10 l-43 0 2
          -212z"/>
          <path fill="black"  d="M18845 4269 c-27 -4 -134 -11 -237 -15 -104 -4 -188 -10 -188 -13 0
          -9 95 -51 114 -51 9 0 16 -4 16 -10 0 -5 12 -10 28 -10 41 0 376 -52 382 -59
          3 -4 71 -17 153 -30 81 -13 147 -27 147 -31 0 -4 21 -11 48 -15 26 -3 58 -9
          72 -11 23 -5 24 -5 5 7 -11 7 -22 15 -25 19 -3 4 -27 17 -55 31 -27 13 -52 27
          -53 32 -2 4 -29 19 -60 34 -32 14 -59 30 -62 34 -3 4 -25 17 -50 30 -25 13
          -47 25 -50 28 -3 4 -19 14 -37 24 -33 18 -67 20 -148 6z"/>
          <path   d="M18405 4198 c11 -50 25 -155 25 -190 0 -21 5 -38 10 -38 6 0 10 -60
          10 -161 l0 -160 50 -21 c28 -11 50 -25 50 -29 0 -5 6 -9 14 -9 20 0 116 -41
          116 -50 0 -4 25 -15 55 -25 31 -9 61 -22 68 -29 15 -14 137 -66 155 -66 7 0
          11 -4 8 -8 -3 -5 24 -19 60 -32 35 -13 64 -27 64 -30 0 -4 26 -17 58 -30 31
          -13 63 -30 70 -37 7 -7 19 -13 26 -13 17 0 109 -40 116 -50 3 -4 30 -18 60
          -31 30 -12 57 -26 60 -30 8 -12 128 -59 149 -59 20 0 83 33 111 57 28 25 115
          82 158 104 23 11 42 25 42 29 0 4 16 17 35 29 19 11 35 24 35 30 0 5 -25 19
          -55 31 -30 12 -55 25 -55 29 0 3 -34 19 -75 36 -41 16 -75 33 -75 36 0 4 -26
          17 -57 30 -32 12 -60 26 -63 30 -3 5 -22 15 -42 23 -20 9 -42 21 -50 29 -7 7
          -43 26 -80 41 -37 16 -65 32 -63 36 2 4 -13 13 -35 20 -22 7 -48 21 -57 30
          -10 9 -45 29 -78 44 -33 16 -62 31 -65 35 -8 10 -63 42 -82 48 -10 3 -18 9
          -18 13 0 4 -17 15 -39 23 -21 9 -45 22 -52 29 -8 7 -39 26 -69 42 -30 15 -80
          41 -110 57 -30 16 -61 34 -69 41 -20 17 -83 48 -98 48 -7 0 -13 4 -13 10 0 10
          -98 60 -118 60 -7 0 -12 4 -12 9 0 8 -60 41 -74 41 -4 0 -4 -10 -1 -22z"/>
          <path fill="black" d="M18605 4140 c3 -5 12 -10 20 -10 7 0 20 -6 27 -13 7 -7 36 -24 63
          -37 28 -13 56 -30 64 -38 7 -7 31 -20 52 -29 22 -8 39 -19 39 -23 0 -4 22 -17
          49 -29 27 -11 55 -27 63 -34 7 -7 37 -24 66 -37 28 -14 52 -27 52 -31 0 -3 24
          -16 53 -30 28 -13 54 -28 56 -32 1 -5 26 -19 55 -33 69 -33 160 -84 166 -92 3
          -4 30 -18 60 -32 30 -13 60 -29 65 -35 6 -6 35 -21 65 -34 30 -13 57 -27 60
          -31 3 -3 31 -17 63 -30 31 -13 57 -27 57 -30 0 -4 32 -19 70 -34 39 -15 70
          -31 70 -34 0 -4 21 -16 46 -26 43 -18 48 -18 80 -3 19 9 34 20 34 25 0 4 20
          21 44 36 25 15 47 34 50 42 3 8 12 14 20 14 8 0 23 11 34 25 11 14 25 25 30
          25 26 0 21 22 -7 31 -17 6 -31 15 -31 19 0 4 -34 20 -75 35 -41 15 -75 31 -75
          34 0 4 -23 15 -50 25 -28 10 -56 24 -63 30 -7 6 -43 25 -82 41 -38 16 -72 32
          -75 36 -3 4 -28 17 -55 29 -27 12 -52 25 -55 29 -3 4 -27 18 -55 30 -27 13
          -52 26 -55 30 -9 13 -127 61 -149 61 -11 0 -21 4 -21 8 0 5 -60 19 -132 32
          -73 13 -135 27 -138 30 -3 4 -75 18 -160 31 -85 12 -157 26 -160 30 -4 5 -207
          39 -235 39 -3 0 -3 -4 0 -10z"/>
          <path fill="black" d="M10608 4013 c6 -2 12 -17 12 -32 0 -25 5 -29 55 -41 30 -7 53 -16 50
          -19 -2 -4 35 -17 83 -31 48 -13 89 -26 92 -30 6 -8 153 -50 175 -50 12 0 15 5
          11 16 -3 9 -6 23 -6 33 0 13 -17 22 -70 35 -38 10 -70 21 -72 25 -2 4 -52 20
          -113 36 -60 16 -112 32 -115 35 -7 8 -84 30 -101 29 -10 0 -11 -2 -1 -6z"/>
          <path   fill="black" d="M10646 3788 c8 -133 -3 -120 138 -153 68 -16 123 -32 121 -35 -2 -3
          40 -16 93 -29 54 -13 99 -28 102 -32 4 -5 20 -12 38 -15 l32 -7 -22 59 c-12
          33 -25 61 -29 64 -3 3 -10 20 -14 39 -4 19 -13 40 -19 46 -11 11 -190 65 -215
          65 -7 0 -18 6 -25 13 -7 6 -49 21 -94 31 -45 11 -79 23 -76 28 3 4 -4 8 -15 8
          -19 0 -20 -5 -15 -82z"/>
          <path  id="36"  d="M11194 3777 c6 -56 43 -179 56 -187 4 -3 17 -24 29 -48 23 -44 40
          -69 79 -109 12 -13 22 -27 22 -31 0 -13 106 -117 151 -147 24 -16 50 -38 57
          -47 7 -10 19 -18 26 -18 7 0 22 -9 34 -21 13 -11 48 -32 80 -46 31 -15 59 -29
          62 -33 3 -4 32 -17 65 -31 33 -13 62 -27 65 -30 3 -3 46 -17 95 -29 50 -13 92
          -27 95 -31 10 -14 168 -32 290 -33 114 -1 257 17 270 33 3 4 28 14 57 23 28 9
          74 33 102 52 28 20 54 36 58 36 5 0 13 8 19 18 12 19 -3 112 -18 112 -5 0 -8
          12 -8 28 0 40 -27 57 -126 82 -49 12 -91 25 -94 29 -3 5 -45 18 -95 30 -49 13
          -92 26 -95 30 -6 9 -188 61 -213 61 -9 0 -17 4 -17 9 0 4 -39 19 -87 31 -49
          13 -90 27 -93 31 -3 4 -46 18 -95 31 -50 13 -92 26 -95 29 -6 7 -86 33 -157
          50 -29 7 -53 16 -53 19 0 4 -39 17 -87 30 -49 13 -90 26 -93 30 -3 5 -50 20
          -105 35 -55 15 -102 30 -105 34 -3 4 -22 12 -44 18 l-39 12 7 -52z"/>
          <path id="37" d="M5663 3674 c-13 -59 -26 -110 -31 -113 -4 -3 -20 -45 -35 -93 -16
          -49 -31 -88 -35 -88 -4 0 -17 -22 -30 -49 -24 -54 -83 -118 -120 -132 -13 -5
          -54 -9 -91 -9 -37 0 -102 -3 -143 -6 l-75 -7 -6 -141 c-7 -163 -17 -201 -55
          -211 -38 -9 -413 6 -406 16 3 5 -34 9 -81 9 l-87 0 7 -27 c3 -15 10 -30 14
          -33 3 -3 15 -25 26 -50 10 -25 23 -49 29 -55 6 -5 24 -29 39 -53 32 -49 86
          -101 105 -102 6 0 12 -4 12 -10 0 -5 13 -16 29 -25 16 -8 36 -22 45 -31 16
          -16 145 -64 171 -64 7 0 15 -4 17 -9 2 -5 47 -14 100 -21 104 -12 329 2 336
          21 2 5 12 9 22 9 26 0 194 50 200 59 3 5 27 18 55 30 27 12 52 25 55 29 3 4
          24 19 47 34 37 24 58 42 112 91 24 23 61 68 88 109 l24 36 -27 79 c-15 43 -31
          80 -35 83 -7 6 -49 170 -49 195 0 9 -4 13 -10 10 -5 -3 -10 0 -10 8 0 7 -7 59
          -15 114 -16 108 -33 276 -41 411 -5 81 -6 82 -32 82 -15 0 -43 3 -62 6 l-35 6
          -22 -108z"/>
          <path  fill="black" d="M18450 3590 c0 -22 -4 -40 -10 -40 -5 0 -10 -17 -10 -38 0 -50 -38
          -254 -48 -261 -5 -3 -16 -33 -26 -66 -32 -107 -33 -99 24 -123 29 -12 60 -22
          68 -22 8 0 12 -3 9 -7 -4 -3 21 -17 56 -31 34 -14 64 -29 67 -33 6 -9 97 -49
          113 -49 6 0 18 -6 26 -14 9 -8 41 -24 71 -36 30 -12 57 -25 60 -29 7 -11 99
          -51 117 -51 8 0 11 -4 8 -10 -8 -13 39 -24 76 -16 16 3 38 14 49 24 12 11 38
          24 60 31 22 6 40 16 40 20 0 5 25 19 55 31 30 12 55 26 55 30 0 4 22 17 49 29
          27 11 55 27 63 34 7 7 32 21 56 31 23 11 54 30 68 43 14 13 32 23 40 23 7 0
          14 5 14 10 0 6 -7 10 -15 10 -8 0 -15 4 -15 8 0 5 -30 22 -67 37 -38 16 -70
          32 -73 36 -3 3 -30 17 -60 30 -30 12 -57 25 -60 28 -6 7 -16 12 -140 69 -47
          22 -95 43 -107 46 -13 4 -23 11 -23 16 0 5 -10 12 -22 15 -13 4 -56 23 -96 42
          -40 19 -95 43 -122 52 -28 10 -48 20 -45 23 3 3 -22 15 -55 27 -33 12 -60 26
          -60 30 0 4 -19 14 -42 21 -24 8 -54 21 -68 30 -14 9 -37 21 -52 28 -28 12 -28
          11 -28 -28z"/>
          <path  fill="black" d="M20604 3597 c-45 -47 -189 -167 -201 -167 -6 0 -18 -11 -27 -25 -9
          -14 -23 -25 -31 -25 -7 0 -20 -9 -27 -19 -8 -10 -30 -29 -51 -41 -20 -12 -37
          -25 -37 -29 0 -9 -78 -61 -91 -61 -5 0 -9 -4 -9 -9 0 -5 -17 -20 -37 -32 -21
          -13 -42 -28 -48 -35 -5 -6 -28 -22 -50 -35 -22 -13 -47 -30 -55 -38 -8 -7 -29
          -20 -47 -28 -18 -7 -33 -17 -33 -22 0 -5 -20 -20 -45 -32 -25 -12 -43 -25 -40
          -27 2 -3 -17 -15 -43 -27 -26 -12 -54 -28 -62 -36 -8 -8 -39 -26 -67 -39 -29
          -14 -53 -28 -53 -33 0 -4 -20 -16 -45 -27 -25 -11 -45 -24 -45 -30 0 -5 -4
          -10 -10 -10 -14 0 -124 -51 -130 -61 -3 -4 -28 -17 -55 -29 -27 -12 -52 -25
          -55 -29 -3 -4 -27 -16 -54 -26 -26 -10 -53 -24 -60 -30 -11 -11 -136 -65 -151
          -65 -3 0 -13 -6 -20 -14 -8 -8 -39 -22 -70 -31 -30 -10 -55 -21 -55 -25 0 -4
          -40 -20 -90 -36 -49 -15 -90 -31 -90 -35 0 -4 -33 -17 -72 -29 -40 -12 -75
          -25 -78 -29 -3 -4 -41 -18 -85 -31 -44 -13 -82 -27 -85 -31 -3 -4 -50 -19
          -105 -34 -55 -15 -102 -31 -105 -35 -3 -4 -48 -17 -100 -30 -52 -13 -97 -26
          -100 -30 -3 -3 -53 -17 -112 -30 -109 -24 -138 -40 -73 -40 21 0 35 5 35 13 0
          8 4 7 10 -3 5 -8 10 -10 10 -4 0 6 81 20 203 34 111 12 204 26 207 29 3 4 86
          18 185 30 99 13 187 28 195 32 8 4 101 20 207 35 106 15 190 31 187 36 -3 4 5
          8 18 8 36 0 282 43 288 50 3 3 64 17 135 30 72 13 132 26 135 30 3 3 68 19
          145 35 77 15 142 31 145 34 3 4 51 18 107 31 57 13 101 26 98 28 -3 3 48 18
          113 35 64 16 119 32 122 36 6 8 139 51 158 51 7 0 12 4 12 9 0 5 32 19 70 31
          39 12 70 25 70 30 0 5 28 18 63 29 34 12 82 35 107 51 25 16 50 30 57 30 7 0
          16 6 20 13 4 8 28 24 52 38 39 21 59 36 120 92 58 53 100 131 101 185 0 20 4
          31 10 27 6 -3 10 -1 10 4 0 6 -4 11 -10 11 -5 0 -10 10 -10 21 0 57 -25 102
          -96 172 -90 90 -104 101 -153 126 -21 11 -41 24 -43 28 -1 5 -28 19 -58 33
          -30 14 -57 28 -60 31 -15 19 -46 17 -66 -4z"/>
          <path fill="black" d="M10650 3541 c0 -85 -21 -265 -35 -296 -7 -16 -16 -44 -20 -61 -6 -29
          -3 -33 42 -60 26 -15 56 -36 67 -46 11 -10 25 -18 31 -18 6 0 20 -8 31 -17 30
          -27 135 -98 174 -118 19 -10 37 -21 40 -24 3 -4 23 -18 45 -31 22 -13 49 -32
          59 -42 11 -10 25 -18 31 -18 6 0 22 -10 35 -22 27 -25 75 -55 103 -64 9 -4 17
          -10 17 -15 0 -5 18 -18 40 -29 22 -11 40 -24 40 -29 0 -5 8 -11 18 -14 24 -8
          70 -38 100 -64 14 -13 30 -23 37 -23 6 0 20 -9 32 -19 11 -11 40 -29 64 -42
          24 -12 48 -27 54 -34 5 -7 30 -23 55 -36 24 -13 52 -31 61 -39 9 -8 31 -24 50
          -35 81 -48 95 -57 104 -69 6 -7 31 -22 57 -35 25 -12 52 -28 58 -36 7 -8 32
          -25 56 -37 24 -12 44 -25 44 -28 0 -4 18 -15 40 -25 23 -10 44 -24 48 -30 4
          -7 32 -25 62 -40 30 -15 60 -34 65 -42 16 -21 117 -31 410 -38 143 -4 265 -11
          270 -15 6 -4 113 -10 238 -14 221 -7 228 -6 210 11 -10 10 -36 27 -58 38 -22
          11 -46 25 -53 32 -6 6 -33 22 -60 35 -26 13 -50 28 -54 34 -4 6 -25 19 -48 29
          -22 10 -40 21 -40 25 0 3 -22 17 -50 31 -27 13 -50 27 -50 31 0 4 -24 20 -52
          34 -29 15 -55 30 -58 34 -3 4 -25 18 -50 31 -24 13 -52 31 -62 41 -10 10 -23
          18 -30 18 -6 0 -21 9 -32 19 -12 11 -45 31 -73 46 -29 14 -53 30 -53 34 0 4
          -22 18 -50 31 -27 13 -50 26 -50 30 0 4 -25 19 -55 35 -30 15 -55 31 -55 34 0
          4 -22 18 -50 31 -27 13 -50 27 -50 31 0 4 -21 18 -47 31 -26 13 -58 33 -71 46
          -14 12 -31 22 -38 22 -8 0 -22 8 -32 18 -17 16 -27 22 -84 55 -12 6 -30 19
          -40 28 -10 9 -38 22 -61 30 -23 7 -47 18 -53 24 -6 6 -43 21 -83 34 -39 13
          -69 26 -67 29 3 3 -20 15 -51 28 -31 13 -59 27 -62 32 -3 5 -25 19 -48 31 -24
          12 -43 25 -43 30 0 5 -8 12 -17 15 -10 3 -27 12 -38 19 -26 16 -175 166 -175
          177 0 7 -19 21 -105 75 -17 11 -39 27 -48 36 -9 9 -58 27 -109 39 -51 13 -95
          26 -98 30 -3 4 -41 18 -85 30 -44 12 -84 26 -90 30 -5 4 -43 17 -82 28 l-73
          20 0 -47z"/>
          <path  fill="black" d="M8166 3508 c-3 -13 -6 -33 -6 -45 0 -13 -4 -23 -10 -23 -5 0 -10 -10
          -10 -22 0 -33 -38 -188 -54 -223 -8 -16 -22 -58 -32 -92 -9 -35 -20 -63 -23
          -63 -4 0 -17 -33 -30 -72 -14 -40 -32 -82 -42 -93 -30 -36 -33 -62 -7 -76 13
          -6 26 -18 31 -25 10 -17 111 -54 148 -54 18 0 28 -4 24 -10 -4 -6 13 -10 44
          -10 28 0 51 4 51 9 0 4 21 11 48 14 51 6 174 44 182 57 3 4 15 12 28 19 21 11
          21 13 7 39 -23 41 -38 62 -62 90 -13 14 -23 30 -23 37 0 6 -9 20 -19 32 -11
          11 -29 40 -41 64 -13 24 -26 46 -30 49 -10 7 -50 100 -50 117 0 8 -4 12 -8 9
          -5 -3 -20 27 -33 67 -13 40 -26 74 -30 77 -4 3 -15 38 -24 78 -10 39 -19 72
          -20 72 -2 0 -5 -10 -9 -22z"/>
          <path  fill="black" d="M14320 3198 c-33 -7 -57 -22 -89 -55 -40 -42 -44 -50 -48 -108 -6
          -64 11 -140 28 -130 5 4 9 0 9 -7 1 -23 54 -75 89 -86 42 -15 121 -15 157 -1
          79 29 126 143 103 248 -8 40 -20 60 -51 89 -22 20 -49 40 -61 43 -51 14 -95
          16 -137 7z"/>
          <path  fill="black" d="M15637 3155 c-3 -16 -9 -52 -12 -80 -3 -27 -7 -54 -8 -60 -2 -12 151
          -98 161 -90 5 4 -27 73 -40 87 -8 7 -27 41 -43 76 -16 34 -33 62 -36 62 -4 0
          -9 8 -11 18 -3 11 -6 7 -11 -13z"/>
          <path  d="M10570 3091 c-6 -22 -14 -41 -18 -43 -4 -2 -18 -30 -31 -63 -14 -33
          -27 -62 -31 -65 -3 -3 -19 -27 -34 -54 -29 -50 -43 -68 -87 -114 -14 -15 -30
          -34 -35 -43 -5 -9 -47 -49 -94 -89 -47 -40 -87 -74 -89 -76 -5 -5 37 -41 72
          -62 15 -9 27 -19 27 -23 0 -4 17 -17 38 -30 20 -12 42 -27 47 -33 17 -19 74
          -56 85 -56 5 0 10 -4 10 -9 0 -5 17 -20 38 -32 20 -13 39 -27 41 -32 1 -4 21
          -20 44 -34 22 -14 45 -29 51 -34 6 -5 25 -20 42 -34 17 -13 34 -22 38 -20 3 3
          4 2 1 -1 -5 -7 38 -44 50 -44 4 0 18 -11 30 -24 27 -29 56 -31 575 -47 206 -7
          377 -16 378 -21 2 -4 47 -8 100 -8 54 0 193 -5 311 -10 198 -10 258 -8 203 7
          -12 3 -22 9 -22 13 0 3 -25 20 -55 35 -30 16 -55 32 -55 36 0 3 -22 17 -50 30
          -27 13 -50 27 -50 31 0 4 -15 14 -33 21 -18 8 -39 21 -48 29 -8 8 -37 27 -64
          43 -28 15 -52 30 -55 34 -3 4 -25 18 -49 32 -25 13 -56 34 -69 47 -14 12 -30
          22 -35 22 -6 0 -19 7 -29 17 -21 19 -52 38 -90 55 -16 7 -28 16 -28 20 0 4
          -18 17 -40 28 -22 11 -40 24 -40 29 0 5 -8 11 -17 15 -35 12 -103 52 -98 58 3
          3 -15 15 -40 27 -25 12 -45 25 -45 29 0 5 -20 19 -45 31 -25 12 -45 26 -45 30
          0 4 -15 15 -34 24 -19 9 -55 32 -81 51 -26 20 -53 36 -60 36 -8 0 -19 9 -24
          19 -6 10 -23 23 -39 29 -15 5 -35 16 -43 24 -8 8 -36 27 -64 43 -27 16 -54 35
          -58 42 -4 7 -14 13 -21 13 -7 0 -24 10 -38 23 -13 12 -44 33 -69 46 -24 14
          -46 27 -49 31 -8 10 -54 40 -62 40 -4 0 -12 -18 -18 -39z"/>
          <path  d="M18290 3024 c-11 -25 -20 -49 -20 -53 0 -4 -11 -18 -25 -31 -14 -13
          -25 -29 -25 -37 0 -7 -8 -21 -17 -31 -10 -10 -24 -29 -31 -43 -26 -50 -212
          -247 -258 -275 -16 -10 -17 -13 -4 -25 8 -8 40 -25 70 -38 30 -13 57 -27 60
          -30 10 -13 100 -51 122 -51 26 0 161 40 168 50 3 4 39 17 80 30 41 13 77 26
          80 30 3 4 43 20 90 35 47 15 87 31 90 35 3 4 33 18 68 30 34 12 62 27 62 31 0
          5 6 9 14 9 21 0 116 42 116 51 0 5 6 9 13 9 7 0 22 6 32 14 19 14 19 15 -10
          27 -17 8 -32 16 -35 19 -3 3 -32 16 -65 29 -33 14 -62 28 -65 31 -3 4 -30 18
          -60 31 -30 12 -57 26 -60 30 -3 4 -36 19 -75 34 -38 14 -72 30 -75 35 -4 6
          -33 20 -66 31 -32 12 -61 24 -64 27 -5 7 -77 42 -86 42 -2 0 -14 -21 -24 -46z"/>
          <path  d="M12922 2985 c-11 -14 -23 -25 -27 -25 -3 0 -21 -11 -39 -25 -18 -14
          -36 -25 -40 -25 -4 0 -14 -6 -21 -14 -8 -7 -45 -22 -82 -32 -37 -10 -71 -21
          -74 -26 -3 -5 -56 -14 -118 -20 -87 -9 -143 -9 -244 0 -73 7 -133 16 -135 22
          -4 12 -52 13 -52 1 0 -5 12 -13 28 -19 15 -5 36 -18 47 -28 11 -11 40 -30 65
          -43 25 -13 50 -29 55 -36 6 -6 33 -22 60 -36 28 -13 52 -27 53 -32 2 -4 21
          -17 43 -27 21 -11 39 -24 39 -28 0 -5 27 -21 60 -37 33 -16 60 -32 60 -37 0
          -4 15 -14 33 -21 18 -8 40 -20 48 -28 22 -20 49 -37 92 -55 20 -9 37 -19 37
          -23 0 -3 20 -17 45 -30 25 -13 45 -27 45 -31 0 -3 23 -17 50 -30 28 -13 50
          -26 50 -30 0 -4 25 -19 55 -35 31 -15 58 -31 60 -36 3 -4 26 -18 50 -30 25
          -12 45 -26 45 -29 0 -4 22 -18 49 -31 27 -13 48 -26 46 -29 -2 -4 23 -19 56
          -34 32 -15 59 -31 59 -35 0 -5 13 -14 29 -19 16 -6 40 -21 52 -33 19 -19 38
          -24 123 -30 56 -4 187 -8 291 -7 158 0 179 2 125 10 -36 5 -66 13 -68 18 -2 6
          -11 10 -20 10 -26 0 -187 42 -192 51 -3 4 -38 20 -78 35 -39 14 -72 30 -72 34
          0 5 -21 19 -47 32 -52 26 -57 29 -174 146 -116 115 -132 134 -166 200 -9 17
          -20 32 -24 32 -5 0 -17 20 -28 45 -11 25 -24 45 -28 45 -5 0 -17 23 -27 50
          -10 28 -24 56 -30 63 -13 14 -66 146 -66 165 0 6 -4 12 -9 12 -5 0 -19 32 -30
          70 -12 39 -26 70 -31 70 -5 0 -17 -11 -28 -25z"/>
          <path  d="M15604 2967 c-6 -7 -21 -54 -33 -105 -13 -51 -27 -92 -31 -92 -4 0
          -18 -32 -31 -72 -13 -39 -28 -76 -34 -82 -15 -17 -55 -96 -55 -111 0 -7 15
          -18 33 -26 17 -7 35 -18 40 -25 4 -7 21 -15 37 -19 16 -4 32 -11 35 -16 4 -5
          15 -9 25 -9 11 0 27 -8 37 -18 10 -11 39 -27 65 -38 27 -10 48 -22 48 -25 0
          -4 26 -17 58 -30 31 -13 65 -31 76 -41 10 -9 41 -25 67 -34 27 -10 49 -22 49
          -26 0 -5 21 -18 47 -29 26 -11 50 -24 53 -28 3 -4 26 -18 53 -30 26 -13 47
          -27 47 -31 0 -5 10 -12 23 -16 12 -3 34 -15 49 -25 14 -11 33 -19 41 -19 9 0
          18 -6 20 -12 4 -10 6 -10 6 0 1 7 14 12 32 12 56 0 464 51 469 59 3 4 78 18
          167 31 89 13 160 26 158 30 -3 4 21 10 53 14 50 6 107 25 75 26 -6 0 -18 6
          -25 13 -36 34 -74 41 -238 42 -167 2 -330 20 -330 36 0 5 -8 9 -17 9 -27 0
          -163 42 -163 50 0 4 -27 18 -60 30 -33 12 -60 26 -60 30 0 4 -27 19 -60 34
          -33 15 -60 31 -60 36 0 5 -18 19 -40 30 -22 11 -40 25 -40 30 0 6 -6 10 -13
          10 -8 0 -22 9 -32 20 -10 11 -21 20 -26 20 -10 0 -215 206 -242 244 -29 41
          -67 76 -83 76 -7 0 -14 4 -16 10 -1 5 -34 21 -73 35 -38 14 -71 30 -73 35 -5
          13 -15 12 -28 -3z"/>
          <path  d="M7861 2737 c-6 -6 -11 -17 -11 -24 0 -7 -4 -13 -10 -13 -5 0 -21 -17
          -34 -39 -14 -21 -36 -51 -50 -66 -33 -35 -32 -40 3 -56 16 -7 28 -16 25 -20
          -2 -4 16 -17 41 -29 25 -12 45 -25 45 -29 0 -4 27 -21 59 -36 33 -16 58 -32
          55 -36 -2 -4 9 -12 25 -19 16 -6 37 -19 48 -28 10 -9 29 -23 41 -29 55 -32 66
          -39 90 -60 14 -13 30 -23 37 -23 6 0 21 -9 32 -19 12 -11 38 -29 59 -40 20
          -11 40 -25 43 -30 4 -5 181 -14 421 -20 228 -6 456 -13 505 -16 80 -4 88 -3
          70 10 -11 8 -28 22 -38 32 -10 10 -22 18 -27 18 -5 0 -19 9 -32 20 -38 32 -87
          65 -95 65 -4 0 -19 12 -32 25 -13 14 -29 25 -35 25 -7 0 -21 8 -32 18 -34 29
          -83 62 -94 62 -5 0 -10 4 -10 10 0 5 -18 19 -39 29 -21 11 -45 26 -52 34 -8 7
          -32 24 -54 37 -22 13 -46 30 -54 37 -7 8 -31 23 -52 34 -21 10 -39 23 -39 28
          0 5 -8 11 -17 14 -29 9 -77 41 -91 60 -7 9 -26 17 -41 17 -30 0 -93 -19 -101
          -30 -9 -14 -162 -40 -229 -40 -87 0 -191 23 -203 44 -5 9 -15 16 -23 16 -8 0
          -32 18 -53 39 -29 30 -42 37 -51 28z"/>
          <path  d="M8590 2729 c-13 -6 -28 -15 -32 -22 -8 -14 9 -37 28 -37 8 0 24 -11
          36 -25 12 -14 28 -25 35 -25 7 0 21 -8 31 -17 25 -24 50 -40 90 -58 17 -9 32
          -20 32 -25 0 -5 18 -19 40 -30 22 -11 40 -24 40 -28 0 -4 10 -12 23 -18 66
          -34 87 -46 87 -55 0 -5 18 -18 40 -29 22 -11 40 -24 40 -29 0 -5 8 -11 18 -14
          23 -8 71 -38 93 -59 10 -9 32 -24 48 -33 17 -8 31 -19 31 -23 0 -4 21 -18 48
          -32 26 -13 53 -32 60 -42 16 -22 43 -26 237 -33 142 -5 158 -4 140 9 -11 8
          -37 27 -58 43 -22 15 -49 38 -62 51 -12 12 -25 22 -29 22 -9 0 -61 38 -94 68
          -14 12 -29 22 -33 22 -5 0 -16 8 -26 19 -12 12 -39 21 -83 25 -96 10 -180 27
          -180 37 0 5 -8 9 -17 9 -27 0 -173 50 -173 59 0 5 -20 16 -44 25 -24 9 -57 28
          -72 41 -16 14 -34 25 -39 25 -6 0 -21 10 -35 23 -14 12 -40 33 -58 46 -19 13
          -54 45 -80 72 -26 27 -49 49 -52 48 -3 0 -16 -5 -30 -10z"/>
          <path  d="M6041 2673 c-7 -14 -22 -37 -34 -51 -57 -64 -115 -120 -135 -131 -30
          -16 -19 -33 43 -67 30 -16 55 -32 55 -36 0 -4 18 -17 40 -28 22 -11 40 -24 40
          -28 0 -7 7 -11 65 -42 16 -8 39 -25 52 -37 12 -13 28 -23 36 -23 7 0 22 -8 33
          -18 60 -54 36 -52 540 -52 413 0 465 2 454 15 -7 8 -19 15 -26 15 -8 0 -14 5
          -14 10 0 6 -20 10 -44 10 -25 0 -48 -4 -51 -10 -8 -12 -245 -14 -245 -1 0 5
          -22 11 -49 15 -62 8 -204 45 -211 55 -3 4 -31 18 -62 30 -32 13 -58 27 -58 31
          0 4 -8 10 -17 13 -36 12 -90 50 -152 110 -16 15 -33 27 -38 27 -4 0 -28 23
          -53 51 -25 27 -54 61 -66 74 -12 13 -31 39 -43 59 -27 43 -43 45 -60 9z"/>
          <path  d="M7690 2527 c0 -7 -23 -33 -51 -58 -29 -24 -62 -54 -75 -66 -13 -12
          -41 -31 -64 -44 -22 -12 -40 -26 -40 -30 0 -4 -25 -17 -55 -30 -30 -12 -55
          -26 -55 -30 0 -4 -29 -15 -65 -24 -49 -14 -64 -22 -60 -32 3 -8 5 -16 5 -19 0
          -2 12 -10 28 -18 21 -11 119 -15 475 -21 246 -4 447 -12 447 -16 0 -5 32 -9
          70 -9 39 0 70 4 70 10 0 5 -17 19 -37 30 -86 47 -162 97 -157 102 3 3 -8 10
          -23 16 -15 5 -37 18 -49 28 -24 22 -50 40 -84 56 -14 7 -29 18 -35 24 -5 6
          -30 22 -54 35 -25 13 -56 34 -69 47 -14 12 -31 22 -39 22 -7 0 -21 9 -30 20
          -20 23 -53 27 -53 7z"/>
          <path  d="M10100 2520 c0 -5 -19 -19 -42 -31 -24 -12 -45 -25 -48 -29 -6 -9
          -156 -60 -176 -60 -8 0 -14 -4 -14 -9 0 -14 -108 -32 -232 -38 l-117 -6 32
          -28 c18 -16 37 -29 44 -29 7 0 17 -9 23 -20 6 -11 16 -20 23 -20 7 0 31 -18
          55 -39 24 -21 55 -44 70 -52 15 -8 36 -25 48 -39 20 -22 31 -25 111 -29 48 -1
          93 -6 98 -11 6 -4 123 -10 260 -14 138 -3 313 -9 390 -12 113 -4 136 -3 120 7
          -11 7 -24 18 -28 26 -4 7 -21 20 -37 28 -16 8 -40 25 -54 38 -13 12 -35 30
          -48 39 -12 10 -35 27 -50 38 -14 11 -29 20 -33 20 -4 0 -20 14 -37 30 -16 17
          -36 30 -43 30 -8 0 -19 9 -25 20 -6 11 -16 20 -23 20 -7 0 -24 11 -38 23 -13
          13 -45 37 -69 53 -25 16 -51 37 -58 46 -7 10 -20 18 -27 18 -8 0 -20 9 -27 20
          -13 20 -48 28 -48 10z"/>
          <path  d="M17820 2487 c-19 -12 -36 -25 -38 -29 -2 -4 -30 -20 -63 -34 -32 -15
          -59 -30 -59 -34 0 -4 -30 -18 -67 -30 -38 -12 -70 -26 -73 -31 -3 -5 -46 -19
          -95 -31 -50 -12 -92 -25 -95 -29 -3 -3 -32 -10 -65 -16 l-60 -9 40 -17 c21
          -10 43 -23 47 -30 9 -14 137 -15 146 0 4 5 45 17 92 26 124 26 184 41 190 47
          3 4 47 16 99 29 52 12 101 27 110 32 9 4 53 18 99 30 48 13 82 26 82 34 0 7
          -26 23 -57 36 -32 12 -60 26 -63 30 -8 12 -100 49 -118 48 -9 0 -33 -10 -52
          -22z"/>
          <path  d="M15376 2451 c-9 -16 -22 -31 -31 -34 -8 -4 -15 -13 -15 -21 0 -8 -11
          -23 -25 -34 -14 -11 -25 -24 -25 -28 -1 -14 -52 -67 -96 -100 -21 -16 -47 -39
          -57 -51 -10 -13 -25 -23 -32 -23 -8 0 -18 -6 -22 -14 -4 -8 -24 -23 -43 -33
          -19 -11 -35 -26 -35 -33 0 -7 27 -24 60 -38 33 -15 62 -29 65 -33 3 -3 30 -17
          60 -30 30 -13 62 -30 70 -37 12 -12 48 -13 220 -8 113 4 207 12 208 17 2 5 27
          9 55 9 61 0 433 27 495 36 23 4 42 10 42 15 0 5 -26 21 -57 36 -32 15 -60 30
          -63 34 -3 3 -26 16 -52 29 -27 13 -48 26 -48 30 0 3 -25 17 -55 30 -30 13 -55
          27 -55 31 0 3 -28 19 -62 34 -35 15 -65 31 -68 34 -7 8 -95 51 -105 51 -4 0
          -13 6 -19 13 -6 7 -31 22 -56 32 -25 11 -47 22 -50 26 -3 4 -34 19 -69 34 -35
          14 -70 33 -78 41 -22 22 -41 17 -57 -15z"/>
          <path  d="M5771 2424 c-25 -13 -47 -28 -50 -32 -3 -5 -17 -12 -31 -15 -47 -12
          -54 -22 -34 -46 10 -11 23 -21 29 -21 6 0 22 -10 35 -22 38 -34 88 -68 99 -68
          6 0 15 -9 21 -19 26 -49 78 -57 310 -44 112 6 120 7 96 20 -14 7 -26 17 -26
          21 0 4 -10 12 -23 18 -73 37 -93 49 -105 66 -7 10 -19 18 -26 18 -7 0 -22 9
          -34 20 -12 11 -42 31 -66 44 -25 13 -43 26 -41 28 5 5 -92 59 -102 57 -5 -1
          -28 -12 -52 -25z"/>
          <path  d="M17638 2063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path  d="M14900 2039 c-24 -11 -47 -24 -50 -28 -5 -8 -12 -10 -115 -37 -33 -9
          -62 -20 -65 -25 -3 -4 -48 -15 -100 -23 l-95 -16 169 5 c93 3 261 5 373 5 129
          0 203 4 203 10 0 6 -7 10 -15 10 -8 0 -15 4 -15 9 0 5 -27 19 -60 31 -33 12
          -60 26 -60 29 0 8 -100 51 -115 50 -6 0 -30 -9 -55 -20z"/>
          <path fill="black" d="M5258 2023 c-115 -2 -208 -7 -208 -12 0 -5 -41 -9 -92 -10 -51 -1
          -94 -3 -95 -5 -2 -1 95 -10 215 -20 119 -9 226 -21 237 -26 20 -9 87 -15 540
          -46 132 -10 242 -20 245 -24 4 -6 358 -30 695 -47 66 -3 122 -9 125 -13 5 -6
          543 -34 918 -47 100 -3 182 -9 182 -13 0 -6 431 -21 1190 -40 1174 -30 3568
          -17 4400 25 52 2 103 4 113 5 9 0 17 4 17 8 0 5 -136 12 -302 16 -167 4 -325
          9 -353 11 -27 2 -207 9 -400 15 -192 6 -359 15 -370 20 -18 7 -979 51 -1117
          50 -32 0 -58 4 -58 10 0 6 -26 10 -57 10 -141 -1 -1689 54 -1693 60 -3 4 -113
          10 -245 14 -132 4 -431 13 -665 21 -234 8 -495 15 -582 15 -108 0 -158 4 -160
          11 -3 9 -298 12 -1138 14 -624 1 -1228 0 -1342 -2z"/>
          </g>

          `,
        },
      ],
    ],
    listColorQuestion: [
      {
        color: "red",
      },
      {
        color: "Pink",
      },
      {
        color: "yellow",
      },
      {
        color: "blue",
      },
      {
        color: "green",
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P25-E2",
    audio: "",
    video: "",
    component: PaintColorType,
    titleQuestion: [
      {
        title: `1. COLOR YOUR FAVORITE TOYa`,
      },
    ],
    recorder: true,
    isAllowSubmit: true,
    totalInput: 5,
    isHiddenCheck: true,

    questionSVG: [
      [
        {
          version: "1.0",
          viewBox: "0 0 1617 1185",
          X: 0,
          y: 0,
          style: {
            width: 900,
            height: 900,
            marginTop: 9,
            // stroke: 'black',
            // fill: 'transparent',
            // stroke: '#000',
            // strokeLinecap: 'round',
            // strokeLinejoin: 'round',
            // strokeWidth: '50px',
          },
          g: `
          <g transform="translate(0.000000,1185.000000) scale(0.100000,-0.100000)"
          fill="transparent" style="cursor: pointer; stroke: black; stroke-width: 50px">
          <path id="1" d="M0 5925 l0 -5925 8085 0 8085 0 0 5925 0 5925 -8085 0 -8085 0 0
          -5925z m10190 5197 c0 -4 32 -16 71 -26 39 -10 78 -27 87 -37 9 -10 21 -19 27
          -19 5 0 28 -16 50 -35 22 -19 43 -35 47 -35 3 0 23 18 44 40 21 22 42 40 46
          40 4 0 17 9 28 20 10 10 46 25 79 31 33 7 58 16 55 21 -3 4 24 8 60 8 36 0 66
          -4 66 -8 0 -4 28 -14 62 -22 33 -7 69 -21 80 -32 10 -10 22 -18 26 -18 13 0
          87 -77 103 -107 8 -16 18 -31 22 -34 15 -11 37 -115 37 -179 0 -81 -23 -173
          -50 -200 -11 -11 -20 -27 -20 -34 0 -8 -16 -27 -34 -41 -31 -24 -34 -29 -25
          -53 6 -15 14 -29 18 -32 4 -3 20 -36 36 -75 38 -90 45 -97 92 -89 48 7 154
          -11 168 -30 6 -7 20 -16 32 -20 33 -11 129 -111 153 -161 12 -25 26 -45 30
          -45 5 0 16 -24 25 -52 10 -29 25 -66 35 -83 10 -16 26 -66 36 -110 10 -44 21
          -82 24 -85 11 -9 30 -146 30 -215 0 -66 -13 -110 -30 -100 -5 3 -15 -4 -22
          -14 -8 -11 -27 -25 -42 -31 -19 -7 -26 -15 -22 -28 2 -9 8 -33 12 -52 3 -19
          10 -37 14 -40 4 -3 17 -50 30 -105 12 -55 25 -102 29 -105 7 -5 13 -30 48
          -180 9 -41 20 -77 23 -80 4 -3 17 -50 30 -105 13 -55 27 -102 30 -105 4 -3 17
          -50 30 -105 12 -55 26 -102 30 -105 4 -3 15 -39 24 -81 l17 -76 54 10 c31 5
          63 6 73 2 9 -5 23 -9 30 -11 23 -4 41 -25 47 -55 4 -16 11 -29 16 -29 5 0 9
          -7 9 -16 0 -25 43 -199 50 -204 4 -3 17 -48 30 -100 13 -52 26 -97 31 -100 4
          -3 17 -50 28 -105 12 -55 26 -102 30 -105 11 -7 31 -93 31 -134 0 -49 -42 -92
          -108 -111 -49 -15 -63 -27 -43 -39 5 -3 19 -50 31 -103 12 -54 25 -100 29
          -103 7 -5 22 -63 42 -157 6 -29 15 -53 20 -53 5 0 9 -8 9 -17 0 -21 47 -214
          54 -220 2 -3 64 1 137 7 169 16 514 8 669 -15 63 -10 117 -21 120 -25 3 -4 25
          -10 50 -14 70 -11 214 -47 220 -55 3 -4 42 -18 87 -31 45 -13 80 -27 78 -30
          -2 -4 23 -15 57 -25 33 -11 66 -24 72 -30 6 -6 40 -23 76 -39 36 -15 67 -31
          70 -34 3 -4 25 -18 50 -31 25 -14 47 -28 50 -31 3 -4 24 -17 48 -29 23 -12 42
          -25 42 -30 0 -5 8 -12 18 -15 37 -13 92 -52 148 -103 13 -12 30 -26 38 -30 48
          -28 230 -203 312 -299 23 -27 50 -59 60 -71 46 -53 54 -64 54 -73 0 -6 4 -10
          9 -10 6 0 20 -18 32 -40 13 -22 26 -40 30 -40 4 0 14 -12 21 -27 23 -46 42
          -76 61 -97 9 -11 17 -25 17 -31 0 -6 8 -20 18 -31 10 -10 28 -44 41 -74 13
          -30 27 -57 31 -60 10 -6 60 -129 60 -147 0 -7 4 -13 9 -13 5 0 19 -33 32 -72
          13 -40 27 -75 30 -78 4 -3 18 -46 30 -95 13 -50 26 -92 29 -95 8 -7 38 -169
          46 -247 4 -34 10 -60 14 -58 12 7 20 -135 20 -344 0 -220 -9 -363 -21 -355 -5
          3 -9 -8 -9 -24 0 -44 -39 -265 -48 -272 -4 -3 -18 -49 -31 -102 -12 -53 -26
          -98 -29 -100 -4 -2 -18 -37 -32 -78 -13 -41 -28 -77 -32 -80 -4 -3 -19 -37
          -33 -75 -13 -38 -28 -71 -32 -74 -12 -9 -33 -49 -33 -64 0 -7 -8 -22 -18 -33
          -9 -10 -28 -41 -42 -69 -14 -27 -31 -54 -38 -58 -7 -4 -18 -23 -26 -40 -7 -18
          -20 -39 -28 -47 -13 -14 -58 -75 -98 -135 -8 -12 -25 -33 -38 -46 -40 -43 -86
          -95 -131 -147 -24 -29 -48 -52 -53 -52 -4 0 -37 -28 -72 -63 -35 -34 -80 -75
          -101 -92 -20 -16 -47 -39 -58 -50 -21 -19 -45 -36 -76 -51 -7 -4 -25 -18 -39
          -30 -14 -13 -30 -24 -37 -24 -6 0 -20 -8 -31 -18 -10 -10 -39 -29 -64 -42 -25
          -14 -50 -30 -56 -35 -6 -6 -31 -19 -55 -30 -24 -11 -49 -25 -56 -31 -7 -7 -42
          -23 -78 -35 -36 -13 -65 -27 -65 -31 0 -4 -30 -17 -67 -28 -37 -11 -70 -24
          -73 -28 -6 -9 -133 -47 -192 -57 -21 -4 -38 -10 -38 -14 0 -9 -144 -37 -235
          -47 -33 -4 -62 -11 -65 -15 -12 -18 -361 -31 -544 -19 -99 6 -183 14 -186 18
          -3 4 -67 18 -143 30 -75 12 -145 26 -155 32 -9 5 -62 21 -117 36 -55 14 -102
          29 -105 34 -3 4 -36 17 -75 30 -38 13 -72 26 -75 31 -3 4 -36 19 -75 34 -38
          14 -72 29 -75 33 -3 4 -27 18 -55 32 -27 14 -58 33 -69 42 -11 10 -26 18 -33
          18 -7 0 -22 9 -33 20 -10 10 -41 31 -68 44 -27 14 -52 32 -55 41 -4 8 -13 15
          -20 15 -8 0 -26 11 -40 24 -14 13 -36 31 -49 40 -64 45 -293 263 -335 320 -13
          16 -23 26 -23 20 0 -5 -6 1 -13 12 -12 22 -83 114 -112 147 -9 10 -26 34 -37
          53 -11 19 -23 34 -27 34 -3 0 -17 21 -31 47 -13 27 -28 50 -31 53 -8 6 -59
          105 -59 114 0 4 -8 16 -18 27 -11 11 -25 37 -32 59 -7 22 -16 40 -20 40 -3 0
          -17 30 -30 68 -13 37 -29 72 -35 78 -6 7 -22 52 -35 100 -12 49 -26 91 -30 94
          -9 6 -50 172 -50 201 0 10 -4 19 -8 19 -5 0 -17 55 -28 123 -27 176 -27 655 0
          839 10 71 22 128 26 128 4 0 11 21 15 47 9 68 37 177 46 183 4 3 12 25 18 50
          17 64 45 144 52 150 4 3 17 32 28 65 12 33 25 61 30 63 5 2 19 30 31 62 12 33
          26 60 30 60 4 0 19 27 34 60 15 33 30 60 35 60 4 0 17 20 29 45 12 25 25 45
          29 45 5 0 14 12 20 28 7 15 28 45 48 67 19 22 35 45 35 52 0 6 14 21 30 33 17
          12 30 27 30 33 0 6 11 23 25 37 13 14 73 76 132 137 59 62 112 113 116 113 5
          0 25 16 45 36 20 19 48 46 62 60 14 13 31 24 38 24 6 0 17 9 24 20 7 11 16 20
          20 20 9 0 76 44 106 70 13 11 29 20 36 20 7 0 21 8 32 18 10 10 40 28 67 41
          26 12 47 26 47 31 0 4 27 18 61 30 33 13 66 28 72 35 7 6 41 22 77 35 36 13
          67 26 70 30 3 3 14 10 26 15 21 10 21 11 6 75 -9 36 -19 67 -22 70 -3 3 -17
          50 -30 105 -12 55 -26 102 -29 105 -4 3 -20 61 -36 130 -15 69 -32 127 -36
          130 -3 3 -8 14 -10 25 -3 13 -13 21 -29 23 -66 7 -90 14 -90 26 0 8 -6 19 -14
          25 -8 7 -17 30 -21 52 -4 24 -12 39 -21 39 -8 0 -14 -4 -14 -8 0 -5 -38 -47
          -85 -95 -47 -47 -85 -91 -85 -97 0 -5 -6 -10 -14 -10 -7 0 -31 -21 -52 -46
          -22 -25 -141 -148 -266 -274 -126 -126 -228 -233 -228 -238 0 -5 -9 -12 -20
          -15 -11 -3 -23 -13 -27 -22 -3 -10 -95 -107 -205 -216 -109 -109 -198 -203
          -198 -210 0 -7 -3 -10 -6 -6 -7 7 -131 -116 -157 -155 -9 -16 -22 -28 -27 -28
          -6 0 -19 -9 -30 -20 -11 -11 -18 -20 -15 -20 2 0 -91 -96 -208 -213 -117 -116
          -217 -221 -222 -232 -6 -11 -20 -24 -33 -30 -12 -5 -22 -13 -22 -18 0 -4 -87
          -95 -194 -202 -107 -107 -200 -203 -206 -215 -6 -11 -16 -20 -23 -20 -7 0 -28
          -19 -47 -42 -19 -23 -100 -107 -178 -187 -111 -112 -143 -149 -139 -165 13
          -61 43 -162 49 -166 4 -3 12 -54 17 -113 11 -113 -2 -329 -20 -335 -5 -2 -9
          -11 -9 -19 0 -23 -41 -157 -50 -163 -4 -3 -18 -29 -31 -59 -12 -30 -33 -65
          -46 -79 -13 -14 -23 -30 -23 -36 0 -12 -11 -25 -125 -139 -48 -48 -92 -87 -96
          -87 -5 0 -17 -8 -27 -18 -10 -9 -40 -28 -67 -41 -28 -14 -55 -30 -62 -37 -7
          -6 -39 -20 -72 -30 -34 -10 -61 -21 -61 -25 0 -4 -19 -10 -42 -14 -84 -13 -78
          -2 -78 -146 l0 -129 170 0 170 0 0 -180 0 -180 -535 0 -535 0 0 180 0 180 175
          0 175 0 0 129 0 130 -57 12 c-32 7 -60 16 -63 19 -14 18 -143 41 -262 47 -73
          3 -135 9 -138 13 -3 4 -162 14 -355 24 -192 9 -371 19 -397 22 l-47 6 -7 -37
          c-3 -20 -11 -41 -18 -48 -6 -7 -24 -52 -40 -102 -16 -49 -32 -92 -35 -95 -4
          -3 -18 -30 -30 -60 -13 -30 -27 -57 -30 -60 -4 -3 -18 -27 -31 -55 -13 -27
          -32 -59 -42 -69 -10 -11 -18 -26 -18 -33 0 -7 -8 -21 -17 -31 -10 -10 -27 -36
          -38 -57 -11 -22 -28 -47 -37 -57 -9 -9 -25 -29 -34 -45 -22 -34 -33 -48 -72
          -90 -17 -18 -32 -37 -34 -42 -8 -22 -174 -201 -187 -201 -3 0 -15 -14 -26 -30
          -11 -16 -24 -30 -30 -30 -5 0 -31 -19 -56 -43 -74 -67 -130 -111 -150 -119
          -11 -4 -19 -12 -19 -17 0 -5 -12 -16 -27 -23 -49 -25 -76 -42 -102 -65 -14
          -13 -31 -23 -38 -23 -7 0 -20 -8 -30 -18 -10 -10 -34 -25 -53 -32 -19 -7 -44
          -21 -55 -30 -11 -9 -48 -27 -82 -40 -35 -12 -63 -26 -63 -30 0 -3 -31 -17 -70
          -30 -38 -13 -70 -27 -70 -30 0 -8 -179 -60 -204 -60 -8 0 -16 -5 -18 -10 -2
          -6 -57 -21 -123 -33 -66 -12 -122 -24 -125 -28 -13 -17 -244 -34 -465 -34
          -221 0 -441 17 -455 34 -3 4 -61 17 -130 29 -68 13 -127 26 -130 30 -3 4 -51
          21 -107 36 -57 16 -103 32 -103 35 0 4 -32 17 -70 30 -39 12 -70 26 -70 30 0
          5 -27 18 -60 30 -33 12 -65 27 -73 34 -20 18 -77 47 -93 47 -8 0 -17 6 -20 14
          -3 8 -18 19 -32 25 -30 12 -59 30 -80 49 -8 7 -32 22 -53 33 -21 10 -39 24
          -39 29 0 6 -10 15 -22 20 -36 16 -60 33 -86 63 -13 15 -28 27 -34 27 -5 0 -21
          14 -35 30 -13 17 -31 30 -39 30 -8 0 -14 7 -14 15 0 8 -4 15 -9 15 -12 0 -118
          106 -186 185 -27 32 -63 72 -80 89 -31 32 -47 56 -64 94 -6 12 -14 22 -19 22
          -5 0 -19 20 -32 45 -12 24 -26 43 -29 40 -4 -2 -17 19 -30 46 -13 27 -27 49
          -30 49 -4 0 -20 28 -36 63 -16 34 -33 64 -36 67 -4 3 -17 28 -29 55 -12 27
          -25 52 -29 55 -4 3 -18 37 -31 75 -13 39 -27 73 -32 76 -5 3 -20 48 -35 100
          -15 52 -30 96 -33 99 -4 3 -18 59 -31 125 -13 66 -27 122 -31 125 -4 3 -14 71
          -23 150 -27 227 -9 805 24 830 4 3 13 37 19 75 20 112 36 175 44 175 5 0 8 5
          8 12 0 19 52 182 60 188 4 3 17 37 30 75 13 39 27 72 31 75 4 3 17 28 29 55
          12 27 24 52 28 55 4 3 18 28 31 55 13 28 29 55 35 60 6 6 21 31 33 56 13 25
          32 54 43 64 11 10 20 24 20 30 0 7 10 23 23 36 24 25 61 80 62 90 0 3 15 18
          33 33 17 16 30 31 27 36 -3 4 7 15 21 24 13 10 30 28 36 42 6 13 17 24 25 24
          7 0 13 5 13 11 0 11 82 93 180 179 34 30 75 66 90 80 34 30 58 47 88 60 12 5
          22 13 22 18 0 4 23 21 50 37 28 16 50 32 50 35 0 4 23 17 50 30 28 13 50 26
          50 30 0 3 25 17 55 31 30 13 55 27 55 31 0 3 32 19 70 34 39 15 70 31 70 35 0
          4 31 17 69 29 39 12 71 25 73 29 3 7 135 51 155 51 6 0 13 4 15 8 3 9 167 48
          245 58 29 3 53 10 53 14 0 4 53 13 118 21 214 25 771 11 792 -20 3 -5 28 -11
          55 -15 57 -7 265 -54 250 -56 -5 -1 31 -13 80 -27 50 -15 92 -29 95 -33 7 -9
          110 -50 126 -50 8 0 14 -4 14 -8 0 -5 32 -22 70 -37 39 -15 70 -31 70 -35 0
          -3 25 -17 55 -29 30 -13 55 -27 55 -32 0 -5 20 -18 45 -29 25 -11 45 -24 45
          -30 0 -5 4 -10 10 -10 12 0 80 -45 105 -70 12 -11 26 -20 32 -20 7 0 18 -8 25
          -17 7 -10 31 -30 53 -45 22 -15 69 -54 105 -88 68 -64 80 -69 100 -45 7 8 17
          15 22 15 6 0 23 12 38 28 37 36 99 90 145 127 21 17 63 54 93 83 30 28 58 52
          62 52 4 0 15 9 25 20 10 11 24 20 32 20 8 0 11 5 8 10 -3 6 -3 10 2 10 4 0 25
          15 45 33 21 17 65 55 99 82 33 28 65 58 70 68 5 9 16 17 24 17 7 0 22 10 32
          22 10 13 36 36 58 52 50 37 80 63 117 101 15 17 33 30 38 30 5 0 25 16 44 35
          19 19 49 48 67 64 18 16 28 31 22 35 -6 3 -21 47 -33 96 -13 50 -27 92 -30 95
          -8 6 -40 121 -40 143 0 12 11 20 31 24 29 6 30 8 24 42 -4 20 -10 38 -14 41
          -4 3 -20 50 -35 105 -16 55 -31 102 -35 105 -4 3 -17 43 -31 90 -13 47 -26 87
          -30 90 -4 3 -15 35 -25 73 -10 37 -23 67 -29 67 -18 0 -171 -52 -176 -60 -11
          -17 -163 -35 -290 -34 -133 0 -270 18 -270 35 0 5 -7 9 -16 9 -26 0 -145 51
          -165 71 -11 11 -24 19 -30 19 -9 0 -89 79 -132 130 -15 18 -42 74 -52 110 -4
          14 -13 28 -19 32 -15 10 -15 276 0 286 6 4 17 32 24 62 12 53 26 90 39 100 3
          3 15 23 25 45 32 65 145 166 218 195 35 13 65 27 68 31 14 18 159 34 315 34
          190 0 527 -23 536 -37 3 -5 104 -9 225 -9 120 0 296 -4 389 -9 171 -9 386 -36
          395 -49 3 -4 36 -15 73 -25 37 -10 73 -24 80 -30 7 -6 29 -20 50 -31 21 -11
          44 -29 51 -40 8 -11 24 -30 35 -43 14 -14 24 -41 28 -71 7 -57 -5 -164 -17
          -156 -4 3 -15 -13 -24 -34 -11 -28 -26 -45 -51 -56 -19 -10 -33 -21 -30 -25 4
          -7 -45 -28 -102 -45 -13 -3 -23 -10 -23 -14 0 -5 -44 -19 -97 -31 -54 -13
          -100 -27 -103 -30 -3 -4 -70 -20 -150 -35 -80 -16 -147 -31 -150 -35 -3 -3
          -50 -15 -104 -28 -55 -12 -102 -26 -105 -30 -3 -5 -27 -15 -53 -23 -27 -7 -48
          -17 -48 -23 0 -16 24 -91 31 -96 4 -3 17 -41 29 -85 12 -44 25 -82 29 -85 3
          -3 16 -45 29 -95 13 -49 27 -92 31 -95 4 -3 20 -53 36 -112 16 -60 34 -108 40
          -108 5 0 23 7 39 15 15 8 30 15 31 15 2 0 11 -31 21 -70 10 -38 20 -67 23 -65
          2 3 14 -33 26 -80 13 -47 25 -88 28 -91 7 -7 170 27 177 36 3 4 68 19 145 35
          77 16 142 32 145 36 3 4 55 17 115 29 61 12 112 26 115 30 3 4 68 20 145 35
          77 16 142 32 145 36 3 4 57 18 120 31 63 13 117 26 120 30 3 4 55 17 115 29
          61 12 112 25 115 29 4 7 155 41 253 58 15 2 27 8 27 13 0 5 8 9 17 9 27 0 217
          44 223 51 3 3 58 17 122 30 64 13 115 26 113 29 -2 3 60 18 138 34 78 16 147
          32 152 36 6 4 60 18 120 31 61 13 111 26 113 30 2 4 56 17 122 30 65 12 117
          25 115 29 -2 3 60 19 138 34 78 16 144 32 147 36 3 4 57 17 120 30 63 13 117
          27 120 31 3 3 66 19 140 34 74 16 137 31 140 35 3 3 57 17 120 30 63 13 117
          27 120 30 3 4 13 9 23 13 10 4 17 17 17 30 0 28 35 77 55 77 7 0 15 6 18 13 6
          17 -3 70 -14 77 -4 3 -20 59 -35 125 -15 66 -30 122 -34 125 -3 3 -17 50 -30
          105 -13 55 -27 102 -30 105 -4 3 -17 51 -30 108 -13 56 -27 102 -30 102 -4 0
          -17 50 -30 110 -13 61 -27 110 -31 110 -4 0 -13 26 -19 58 -6 31 -16 73 -22
          93 l-10 36 -70 5 c-44 4 -74 11 -79 19 -5 8 -19 46 -30 84 -12 39 -25 73 -30
          76 -5 3 -19 66 -31 140 -12 75 -27 137 -34 142 -7 4 -19 13 -26 20 -7 6 -41
          25 -75 41 -34 16 -64 34 -66 40 -3 7 -22 20 -43 30 -22 11 -52 36 -69 56 -16
          19 -46 49 -66 65 -20 17 -47 45 -60 64 -13 19 -34 45 -47 58 -13 13 -28 37
          -34 53 -5 17 -14 30 -19 30 -5 0 -14 11 -18 23 -9 23 -12 23 -60 13 -28 -5
          -51 -14 -51 -18 0 -4 -49 -8 -110 -8 -67 0 -110 4 -110 10 0 6 -7 10 -15 10
          -22 0 -139 40 -145 50 -3 4 -18 14 -35 23 -27 14 -93 74 -138 127 -10 11 -28
          42 -40 68 -12 26 -25 50 -30 53 -11 8 -37 154 -37 209 0 56 26 201 37 209 5 3
          19 30 32 59 14 28 28 52 32 52 5 0 16 14 24 30 9 17 27 36 40 42 14 6 25 17
          25 23 0 7 20 23 44 36 25 13 43 26 41 30 -5 7 121 49 148 49 9 0 17 5 17 10 0
          6 45 10 115 10 63 0 115 -4 115 -8z"/>
          <path id="2" " d="M9961 11085 c-41 -7 -77 -18 -80 -23 -3 -5 -28 -17 -54 -27 -27 -10
          -51 -24 -54 -31 -3 -7 -20 -22 -39 -33 -19 -11 -37 -29 -41 -38 -4 -10 -15
          -24 -25 -31 -10 -7 -18 -19 -18 -26 0 -7 -9 -22 -21 -35 -24 -25 -59 -118 -59
          -156 0 -14 -4 -25 -10 -25 -6 0 -10 -33 -10 -80 0 -47 4 -80 10 -80 6 0 10
          -11 10 -25 0 -30 36 -136 48 -144 5 -3 17 -21 26 -39 9 -18 24 -36 32 -39 7
          -3 14 -11 14 -18 0 -8 15 -24 33 -36 17 -13 34 -27 35 -31 5 -12 98 -58 116
          -58 8 0 16 -5 18 -11 13 -38 368 -37 368 2 0 5 6 9 12 9 18 0 118 52 118 61 0
          4 16 17 35 29 19 12 35 26 35 32 0 5 9 21 20 35 11 14 20 22 20 17 0 -15 35
          59 50 106 8 25 19 48 25 52 14 10 14 266 -1 276 -6 4 -14 21 -18 37 -11 43
          -47 115 -73 143 -13 14 -23 28 -23 33 0 4 -20 20 -45 36 -25 15 -45 32 -45 36
          0 4 -21 18 -47 29 -27 11 -50 24 -53 28 -8 12 -94 29 -166 35 -39 2 -101 -2
          -143 -10z m207 -321 c23 -9 42 -19 42 -22 0 -4 10 -18 23 -32 59 -63 62 -195
          7 -250 -10 -10 -22 -24 -26 -31 -16 -25 -84 -49 -140 -49 -57 0 -124 21 -124
          39 0 6 -6 11 -14 11 -16 0 -40 35 -50 73 -3 15 -11 27 -16 27 -6 0 -10 23 -10
          50 0 28 4 50 8 50 5 0 14 18 21 40 6 22 16 40 21 40 5 0 11 9 15 19 3 10 16
          22 28 26 104 36 136 37 215 9z"/>
          <path fill="black" d="M10033 10752 c-13 -2 -23 -8 -23 -13 0 -5 -6 -9 -12 -9 -19 -1 -64
          -44 -82 -78 -31 -61 -14 -148 38 -194 17 -15 37 -27 44 -28 6 0 12 -4 12 -10
          0 -6 28 -10 65 -10 36 0 65 3 65 8 0 4 17 18 37 32 42 29 63 72 63 131 0 45
          -36 129 -55 129 -6 0 -20 8 -31 19 -20 18 -77 29 -121 23z"/>
          <path  id="3"  d="M10691 11080 c-18 -4 -36 -12 -40 -17 -3 -4 -24 -17 -46 -28 -37 -18
          -121 -95 -105 -95 4 0 20 -27 36 -60 15 -33 31 -60 35 -60 3 0 14 -27 23 -60
          9 -33 20 -60 25 -60 4 0 16 7 27 15 10 8 27 15 36 15 9 0 18 6 20 12 6 16 126
          17 136 2 4 -6 18 -15 32 -19 44 -12 96 -80 116 -150 10 -36 22 -65 26 -65 5 0
          8 -9 8 -19 0 -11 4 -22 8 -25 21 -13 102 119 102 165 0 11 5 17 10 14 6 -4 10
          25 10 79 0 47 -4 86 -9 86 -5 0 -12 15 -15 33 -14 76 -93 174 -161 198 -19 7
          -35 16 -35 20 0 22 -168 36 -239 19z"/>
          <path id="4" d="M10698 10705 c-66 -24 -78 -45 -78 -132 0 -43 -5 -96 -10 -117 -8
          -37 -1 -69 19 -86 3 -3 12 -17 19 -31 8 -14 27 -41 43 -60 17 -19 37 -46 45
          -61 8 -16 19 -28 24 -28 5 0 17 -9 27 -19 31 -33 117 -101 128 -101 5 0 27 15
          48 34 21 19 52 40 68 46 16 7 29 16 29 20 0 4 11 11 25 14 14 4 25 11 25 17 0
          12 -30 72 -40 79 -4 3 -15 29 -24 58 -10 29 -23 58 -30 65 -12 12 -56 142 -56
          165 0 7 -4 12 -9 12 -5 0 -14 15 -21 33 -16 49 -42 73 -94 91 -56 19 -88 19
          -138 1z"/>
          <path id="5"  d="M10580 10355 c0 -8 -4 -15 -8 -15 -5 0 -16 -16 -25 -36 -29 -61 -100
          -140 -149 -168 -27 -15 -48 -31 -48 -37 0 -5 -7 -9 -15 -9 -8 0 -15 -4 -15
          -10 0 -15 51 -102 76 -129 13 -14 24 -29 24 -32 0 -6 131 -137 189 -189 13
          -12 41 -31 62 -42 21 -11 39 -23 39 -28 0 -4 30 -20 68 -35 37 -16 70 -33 73
          -37 3 -5 14 -11 25 -13 59 -14 140 -40 144 -47 3 -4 38 -13 78 -20 92 -16 147
          -3 196 47 64 64 84 131 66 219 -17 84 -90 147 -185 161 -63 8 -168 37 -175 46
          -3 4 -27 17 -53 28 -26 11 -47 24 -47 28 0 5 -13 14 -29 21 -16 7 -42 25 -58
          41 -15 15 -40 35 -55 44 -16 10 -28 23 -28 30 0 7 -15 26 -32 43 -18 17 -40
          44 -48 60 -8 15 -23 36 -32 46 -10 10 -18 25 -18 33 0 8 -4 15 -10 15 -5 0
          -10 -7 -10 -15z"/>
          <path id="6" d="M11170 10170 c0 -5 -10 -10 -22 -10 -30 0 -128 -46 -128 -60 0 -5 -6
          -10 -13 -10 -8 0 -21 -8 -30 -18 -24 -26 -5 -45 68 -67 33 -10 62 -21 65 -25
          3 -3 36 -12 74 -19 73 -13 126 -35 126 -52 0 -6 4 -8 9 -4 10 5 51 -45 51 -63
          0 -6 7 -18 17 -27 20 -20 25 -161 6 -173 -6 -4 -14 -19 -18 -34 -10 -41 -78
          -105 -124 -119 -23 -7 -41 -16 -41 -21 0 -4 -22 -8 -50 -8 -27 0 -50 5 -50 10
          0 6 -8 10 -18 10 -26 0 -186 41 -192 49 -3 4 -13 11 -24 14 -17 7 -18 3 -12
          -55 4 -35 11 -65 15 -68 4 -3 13 -34 20 -69 14 -72 42 -164 52 -171 4 -3 8
          -14 11 -25 3 -19 11 -20 107 -19 57 1 106 6 110 11 3 6 29 13 56 17 78 11 175
          38 175 47 0 5 7 9 16 9 19 0 167 50 174 59 3 3 23 15 45 26 26 13 41 28 43 43
          2 12 8 20 13 17 5 -4 9 8 9 24 0 17 -4 31 -10 31 -5 0 -10 15 -10 33 0 54 -38
          251 -50 255 -5 2 -10 10 -10 18 0 26 -41 136 -56 151 -7 8 -14 20 -14 27 0 17
          -32 78 -55 104 -89 101 -158 152 -205 152 -11 0 -20 5 -20 10 0 6 -25 10 -55
          10 -30 0 -55 -4 -55 -10z"/>
          <path id="7" d="M11538 9223 c-10 -2 -18 -8 -18 -13 0 -4 -40 -18 -90 -30 -49 -12
          -90 -26 -90 -30 0 -4 -50 -16 -111 -26 l-110 -18 6 -36 c4 -19 10 -37 14 -40
          8 -5 51 -178 51 -204 0 -9 4 -16 9 -16 5 0 19 -44 31 -97 12 -54 26 -100 29
          -103 4 -3 18 -52 31 -110 12 -58 26 -107 30 -110 4 -3 19 -59 35 -125 15 -66
          31 -122 35 -125 4 -3 15 -41 25 -85 9 -44 21 -84 25 -89 8 -8 160 22 170 34 3
          4 51 17 108 30 56 13 102 27 102 31 0 4 18 10 40 14 30 5 40 11 40 26 0 10 -3
          19 -8 19 -4 0 -18 50 -32 110 -14 61 -28 110 -31 110 -4 0 -12 24 -18 53 -20
          94 -35 152 -42 157 -4 3 -19 59 -34 125 -15 66 -31 122 -35 125 -3 3 -17 50
          -30 105 -12 55 -26 102 -30 105 -3 3 -17 53 -31 112 -25 111 -26 113 -71 101z"/>
          <path id="8"  d="M5930 8641 c0 -4 -26 -11 -57 -15 -76 -9 -175 -36 -188 -51 -5 -7
          -25 -20 -43 -29 -18 -9 -36 -24 -39 -32 -3 -9 -16 -20 -30 -25 -13 -5 -28 -20
          -33 -34 -5 -14 -14 -25 -19 -25 -10 0 -61 -104 -61 -125 0 -8 -4 -15 -8 -15
          -15 0 -42 -147 -42 -228 0 -75 22 -191 39 -202 5 -3 16 -21 25 -40 22 -45 128
          -146 181 -172 22 -12 42 -24 45 -28 12 -17 180 -60 234 -60 20 0 36 -4 36 -10
          0 -6 42 -10 105 -10 58 0 105 4 105 9 0 4 29 11 65 15 65 7 196 42 205 56 3 4
          29 15 58 24 29 10 58 23 65 30 6 7 46 25 87 41 41 16 77 32 80 36 3 4 34 17
          70 30 35 12 70 27 76 33 6 7 47 20 90 30 43 10 81 22 84 27 3 4 66 20 140 35
          74 14 137 30 140 34 3 4 37 13 75 19 99 17 167 36 159 44 -4 4 1 7 12 7 25 0
          158 42 165 52 3 4 21 16 41 25 80 39 116 120 106 239 -4 50 -9 59 -49 96 -46
          42 -89 68 -113 68 -8 0 -22 7 -30 16 -16 15 -183 54 -236 54 -16 0 -30 4 -32
          9 -5 14 -298 31 -542 31 -122 0 -297 7 -391 15 -93 8 -194 15 -222 15 -29 0
          -53 5 -53 10 0 6 -57 10 -150 10 -82 0 -150 -4 -150 -9z"/>
          <path id="9"  d="M11975 8073 c-11 -3 -22 -8 -25 -12 -3 -4 -50 -17 -105 -30 -55 -13
          -104 -27 -110 -31 -5 -4 -55 -18 -110 -30 -55 -13 -102 -26 -105 -30 -3 -4
          -30 -13 -60 -19 -79 -17 -100 -33 -100 -80 0 -47 20 -151 30 -151 3 0 17 -49
          30 -110 14 -60 27 -110 31 -110 4 0 17 -45 29 -100 13 -55 27 -100 32 -100 4
          0 8 -6 8 -12 0 -7 12 -63 26 -125 26 -109 28 -113 62 -129 34 -16 39 -15 146
          11 61 16 113 31 116 35 3 4 47 18 98 30 50 12 92 26 92 29 0 4 45 18 100 31
          55 13 100 27 100 32 0 4 6 8 13 8 23 0 47 35 47 68 0 37 -41 215 -51 222 -3 3
          -16 48 -29 100 -12 52 -25 97 -29 100 -3 3 -17 50 -30 105 -13 55 -27 105 -32
          111 -5 6 -16 43 -25 81 -19 87 -41 113 -92 112 -20 -1 -46 -4 -57 -6z"/>
          <path id="10"  d="M11235 7767 c-27 -6 -52 -14 -55 -18 -3 -4 -66 -20 -140 -35 -74 -15
          -137 -31 -140 -35 -3 -4 -58 -18 -123 -31 -64 -14 -115 -27 -112 -29 2 -3 -61
          -18 -140 -34 -80 -16 -145 -31 -145 -35 0 -4 -53 -17 -117 -30 -65 -13 -120
          -26 -123 -31 -5 -7 -23 -11 -170 -40 -36 -7 -67 -16 -70 -20 -3 -3 -63 -19
          -135 -34 -71 -16 -132 -32 -135 -35 -3 -4 -57 -17 -120 -30 -63 -13 -117 -27
          -120 -30 -3 -4 -57 -17 -120 -30 -63 -13 -117 -27 -120 -30 -3 -3 -66 -19
          -140 -34 -74 -16 -137 -32 -140 -36 -3 -4 -60 -18 -127 -30 -67 -13 -120 -26
          -118 -30 2 -3 -49 -17 -113 -30 -64 -13 -119 -26 -122 -30 -3 -4 -66 -19 -140
          -35 -74 -15 -139 -31 -145 -35 -5 -5 -59 -18 -120 -30 -60 -12 -112 -25 -115
          -29 -3 -4 -68 -20 -145 -36 -77 -16 -142 -32 -145 -35 -3 -4 -57 -18 -120 -30
          -63 -13 -116 -27 -118 -32 -2 -4 -12 -8 -23 -8 -13 0 -19 -7 -19 -21 0 -25 41
          -172 50 -179 4 -3 17 -45 30 -95 13 -49 27 -92 31 -95 3 -3 14 -31 23 -62 10
          -37 22 -58 31 -58 8 0 15 4 15 9 0 4 51 18 113 31 61 12 114 25 117 29 3 4 68
          20 145 35 77 16 142 31 145 35 3 4 57 17 120 30 63 13 117 26 120 30 3 4 68
          20 145 36 77 16 142 32 145 36 3 4 56 17 118 29 61 13 112 27 112 31 0 5 9 9
          19 9 27 0 203 39 221 49 9 5 75 21 148 36 73 16 132 32 132 37 0 4 8 8 18 8
          26 0 216 43 222 50 3 4 57 17 120 30 63 13 117 27 120 31 3 3 66 19 140 34 74
          16 137 32 138 37 2 4 11 8 20 8 26 0 216 44 222 51 3 3 66 18 140 34 74 15
          137 31 140 34 3 4 57 18 120 31 63 13 117 26 120 30 3 3 57 17 120 30 63 13
          117 27 120 30 3 4 68 20 145 35 77 16 141 32 143 36 2 4 35 13 73 20 l71 12
          -7 36 c-4 20 -10 38 -15 41 -4 3 -18 52 -31 110 -13 58 -26 107 -30 110 -4 3
          -18 50 -31 105 -26 113 -26 113 -123 92z"/>
          <path  fill="black"  d="M6820 7750 c0 -5 -16 -14 -36 -19 -20 -5 -76 -29 -125 -53 -83 -40
          -89 -45 -83 -68 3 -14 10 -26 15 -28 5 -2 9 -11 9 -20 0 -20 43 -155 51 -162
          4 -3 19 -50 34 -105 15 -55 30 -102 34 -105 4 -3 17 -40 29 -84 12 -43 23 -81
          26 -84 8 -7 156 19 156 28 0 4 29 13 65 20 46 9 65 17 65 27 0 22 -23 97 -31
          103 -4 3 -18 47 -31 98 -13 51 -26 90 -29 88 -3 -3 -15 36 -28 87 -14 51 -28
          94 -31 97 -8 5 -53 156 -53 178 0 6 -9 12 -19 12 -10 0 -18 -4 -18 -10z"/>
          <path id="11" d="M11469 7244 c-2 -6 -67 -23 -144 -39 -77 -16 -142 -32 -145 -35 -3
          -4 -56 -17 -117 -30 -62 -13 -113 -26 -113 -30 0 -4 -18 -10 -40 -14 -21 -3
          -45 -13 -52 -21 -7 -8 -102 -105 -210 -215 -109 -110 -198 -202 -198 -204 0
          -3 -110 -115 -245 -249 -135 -134 -245 -249 -245 -255 0 -5 -16 -20 -36 -31
          -20 -12 -34 -24 -31 -27 2 -3 -121 -131 -274 -284 -154 -154 -279 -284 -279
          -289 0 -4 -18 -20 -40 -35 -22 -14 -39 -30 -38 -34 2 -4 -93 -102 -210 -219
          -116 -117 -212 -215 -212 -218 0 -3 -70 -76 -155 -160 -85 -85 -155 -157 -155
          -160 0 -3 29 -34 65 -67 36 -34 65 -68 65 -76 0 -9 3 -12 6 -9 8 7 44 -36 44
          -52 0 -6 8 -19 18 -29 9 -9 26 -36 37 -59 l20 -41 157 156 c87 87 158 160 158
          163 0 3 126 132 280 287 154 154 280 285 280 291 0 6 7 11 15 11 8 0 15 4 15
          9 0 5 135 146 301 312 246 250 696 708 754 769 38 41 117 120 293 296 105 106
          192 198 192 203 0 5 12 16 27 23 15 7 32 22 38 33 5 11 51 62 102 112 51 51
          95 103 99 117 6 25 -19 120 -27 100z"/>
          <path fill="black" d="M12065 7077 c-44 -12 -82 -24 -85 -28 -3 -3 -54 -19 -115 -35 -60
          -15 -111 -32 -113 -36 -2 -4 -10 -8 -18 -8 -15 0 -15 -1 12 -110 8 -30 14 -62
          14 -72 0 -10 4 -18 9 -18 5 0 19 -44 31 -97 12 -54 26 -100 29 -103 4 -3 17
          -50 30 -105 12 -55 26 -102 31 -105 4 -3 11 -27 15 -53 l7 -48 42 7 c22 3 43
          10 46 14 5 8 30 14 173 41 31 6 57 15 57 20 0 5 8 9 17 9 37 0 123 25 123 36
          0 24 -33 159 -40 164 -4 3 -18 50 -30 105 -12 55 -26 102 -29 105 -4 3 -18 50
          -31 105 -12 55 -26 102 -30 105 -4 3 -13 33 -20 68 -15 71 -9 70 -125 39z"/>
          <path id="12" d="M7090 7063 c-14 -3 -27 -8 -30 -12 -3 -4 -63 -20 -135 -36 -71 -16
          -132 -32 -135 -36 -3 -3 -22 -9 -42 -13 -21 -4 -38 -11 -38 -16 0 -6 4 -10 8
          -10 5 0 16 -35 26 -77 10 -43 24 -87 30 -98 7 -11 23 -59 37 -107 13 -49 27
          -88 31 -88 5 0 8 -7 8 -16 0 -22 51 -198 59 -204 4 -3 18 -45 31 -95 13 -49
          26 -92 30 -95 3 -3 16 -43 29 -90 13 -47 26 -87 30 -90 4 -3 20 -54 36 -115
          16 -60 32 -112 36 -115 4 -3 18 -46 30 -95 13 -50 27 -92 30 -95 4 -3 17 -43
          30 -90 13 -47 26 -87 30 -90 4 -3 18 -46 30 -95 12 -50 25 -92 29 -95 9 -6 60
          -182 60 -204 0 -9 4 -16 9 -16 5 0 16 -30 25 -68 22 -87 29 -100 48 -81 8 8
          41 23 74 34 32 10 62 23 65 27 6 9 125 33 217 43 52 6 62 10 58 24 -3 9 -13
          45 -22 81 -10 36 -20 67 -24 70 -8 6 -50 153 -50 175 0 9 -3 14 -8 11 -4 -2
          -20 45 -36 105 -15 60 -31 109 -35 109 -4 0 -18 42 -31 93 -13 50 -27 94 -30
          97 -4 3 -17 44 -30 93 -13 48 -27 87 -32 87 -4 0 -8 7 -8 15 0 22 -53 209 -61
          215 -4 3 -17 43 -30 90 -13 47 -26 87 -29 90 -3 3 -16 43 -29 90 -13 47 -26
          87 -30 90 -4 3 -20 55 -36 115 -15 61 -31 112 -35 115 -4 3 -18 43 -30 90 -13
          47 -26 87 -30 90 -3 3 -17 46 -30 95 -13 50 -28 93 -34 97 -12 8 -25 8 -66 1z"/>
          <path d="M10733 7063 c-13 -2 -23 -8 -23 -12 0 -5 -19 -11 -42 -15 -72 -11
          -243 -50 -248 -57 -3 -3 -58 -16 -122 -29 -65 -12 -118 -26 -118 -30 0 -4 -63
          -20 -140 -35 -77 -15 -140 -32 -140 -36 0 -5 -8 -9 -17 -9 -26 0 -217 -43
          -223 -50 -3 -4 -57 -17 -120 -30 -63 -13 -117 -27 -120 -30 -3 -4 -69 -20
          -147 -36 -78 -16 -140 -31 -138 -34 2 -3 -50 -17 -116 -30 -65 -13 -119 -26
          -119 -30 0 -3 -53 -17 -117 -30 -65 -13 -120 -27 -123 -30 -3 -4 -66 -19 -140
          -35 -74 -15 -137 -31 -140 -34 -3 -4 -57 -18 -120 -31 -63 -13 -117 -27 -120
          -30 -3 -4 -66 -19 -140 -35 -74 -15 -137 -31 -140 -35 -3 -3 -58 -17 -122 -30
          -65 -13 -118 -27 -118 -31 0 -3 -19 -9 -42 -13 -86 -14 -118 -26 -118 -46 0
          -10 4 -20 10 -22 5 -1 21 -50 36 -108 14 -58 29 -107 33 -110 4 -3 18 -43 31
          -90 13 -47 27 -87 30 -90 4 -3 18 -45 30 -95 13 -49 27 -92 30 -95 4 -3 20
          -52 35 -110 16 -58 32 -107 35 -110 4 -3 17 -44 30 -92 12 -49 26 -88 30 -88
          4 0 17 -43 29 -95 13 -52 27 -92 32 -89 5 3 9 -3 9 -14 0 -10 5 -33 11 -49 10
          -29 14 -31 77 -37 105 -10 252 -38 247 -46 -3 -4 8 -10 23 -13 44 -9 122 -39
          122 -47 0 -5 24 -20 53 -34 28 -14 64 -35 79 -47 l27 -22 117 119 c64 65 123
          126 131 134 9 8 32 32 52 53 20 21 138 141 264 266 125 126 227 233 227 239 0
          6 8 13 18 16 10 4 34 25 53 48 19 23 136 144 262 269 125 125 227 232 227 238
          0 6 13 17 29 26 16 8 32 24 36 35 3 11 13 20 20 20 8 0 20 10 26 22 7 12 101
          111 210 219 110 109 199 203 199 209 0 6 4 8 10 5 5 -3 15 5 21 18 6 14 22 30
          35 36 14 6 27 18 31 28 4 9 80 90 170 180 90 90 163 166 163 168 0 6 -34 4
          -67 -2z"/>
          <path id="13" d="M6781 6571 c-14 -9 -74 -59 -119 -99 -14 -12 -48 -41 -75 -65 -28
          -23 -67 -57 -86 -74 -20 -18 -39 -33 -43 -33 -3 0 -29 -22 -57 -50 -28 -27
          -55 -50 -60 -50 -6 0 -23 -16 -38 -35 -15 -19 -33 -35 -40 -35 -6 0 -20 -10
          -30 -23 -10 -12 -29 -29 -43 -38 -14 -9 -38 -28 -55 -43 -16 -14 -47 -41 -69
          -59 -21 -17 -53 -47 -70 -64 -18 -18 -37 -33 -43 -33 -5 0 -21 -12 -35 -27
          -14 -16 -44 -44 -68 -63 -65 -52 -140 -116 -181 -153 -39 -35 -70 -61 -144
          -122 -27 -22 -71 -61 -99 -87 -27 -27 -52 -48 -56 -48 -3 0 -19 -12 -36 -27
          -17 -16 -55 -48 -85 -72 -30 -24 -63 -52 -74 -62 -11 -10 -40 -35 -65 -57 -25
          -22 -60 -54 -79 -71 -19 -17 -37 -31 -41 -31 -4 0 -25 -18 -46 -40 -22 -22
          -44 -40 -50 -40 -7 0 -24 -16 -38 -35 -15 -19 -32 -35 -37 -35 -6 0 -24 -12
          -39 -27 -15 -16 -44 -41 -65 -58 -20 -16 -56 -47 -78 -67 -23 -21 -45 -38 -48
          -38 -4 0 -23 -16 -42 -35 -19 -19 -38 -35 -42 -35 -3 0 -21 -15 -39 -32 -18
          -18 -57 -53 -86 -78 -29 -25 -69 -60 -88 -77 -18 -18 -36 -33 -38 -33 -4 0
          -184 -149 -219 -181 -11 -10 -33 -31 -50 -46 l-30 -28 224 -3 c221 -2 224 -2
          245 20 23 24 58 55 115 101 18 15 30 27 25 27 -10 0 27 23 64 40 13 5 20 14
          17 20 -3 5 -3 10 2 10 4 0 22 13 38 28 17 15 48 41 70 57 42 32 108 90 161
          143 18 17 36 32 41 32 7 0 64 49 139 121 16 16 34 29 39 29 10 0 25 13 87 74
          20 20 41 36 47 36 5 0 19 11 30 25 11 15 35 36 53 49 37 24 65 49 126 109 22
          20 42 37 45 37 3 0 18 12 34 28 16 15 48 43 72 62 23 19 57 47 74 63 17 15 34
          27 39 27 4 0 8 7 8 15 0 8 5 15 11 15 6 0 25 12 43 26 52 43 147 127 177 157
          15 15 31 27 35 27 5 0 20 11 34 24 57 55 92 86 104 93 26 15 111 89 140 121
          16 17 34 32 39 32 5 0 20 10 32 23 13 12 44 40 70 62 25 22 65 57 88 78 22 20
          44 37 49 37 4 0 22 16 41 35 19 19 37 35 42 35 4 0 16 11 27 25 11 14 24 25
          29 25 6 0 25 15 42 33 40 40 109 97 118 97 18 0 17 40 -2 109 -12 42 -25 78
          -28 81 -4 3 -13 28 -21 55 -18 68 -23 76 -39 66z"/>
          <path id="14" d="M12508 6423 c-32 -2 -58 -8 -58 -13 0 -6 -9 -10 -20 -10 -11 0 -20
          -4 -20 -10 0 -5 4 -10 9 -10 4 0 41 -34 82 -76 41 -42 86 -88 101 -102 20 -18
          30 -39 34 -73 4 -27 10 -47 13 -44 4 2 18 -47 31 -108 l23 -112 136 -2 c137
          -1 332 -20 341 -32 3 -4 52 -17 110 -29 58 -13 107 -27 108 -32 2 -6 9 -10 17
          -10 20 0 139 -40 145 -48 3 -5 33 -19 68 -32 35 -13 69 -28 75 -34 7 -6 36
          -22 65 -35 28 -14 52 -28 52 -33 0 -4 15 -14 33 -21 18 -8 39 -20 48 -28 25
          -23 78 -59 87 -59 6 0 17 -11 26 -25 9 -14 21 -25 27 -25 16 0 166 -144 244
          -234 22 -25 52 -60 68 -76 15 -17 27 -34 27 -38 0 -4 13 -21 28 -38 15 -18 36
          -49 47 -70 11 -21 28 -46 38 -56 9 -10 17 -24 17 -31 0 -7 8 -22 18 -33 10
          -10 31 -50 47 -89 16 -38 35 -75 41 -82 6 -6 20 -41 30 -77 10 -36 22 -66 25
          -66 4 0 18 -44 30 -97 13 -54 26 -100 30 -103 4 -3 15 -57 25 -120 25 -160 26
          -509 1 -670 -10 -63 -22 -117 -25 -120 -4 -3 -18 -50 -31 -105 -13 -55 -27
          -102 -30 -105 -4 -3 -18 -34 -30 -69 -13 -36 -30 -71 -37 -79 -8 -7 -14 -19
          -14 -26 0 -18 -38 -103 -48 -108 -4 -2 -18 -25 -32 -53 -14 -27 -33 -59 -42
          -69 -10 -11 -18 -25 -18 -32 0 -7 -8 -19 -19 -26 -10 -7 -25 -26 -35 -43 -29
          -53 -41 -69 -63 -88 -13 -10 -23 -22 -23 -27 0 -5 -50 -58 -110 -117 -111
          -108 -138 -133 -195 -175 -16 -12 -36 -28 -44 -36 -7 -7 -30 -21 -51 -32 -21
          -11 -40 -23 -42 -27 -1 -5 -24 -18 -50 -31 -27 -13 -48 -27 -48 -32 0 -4 -26
          -18 -57 -31 -32 -12 -60 -26 -63 -30 -3 -4 -39 -20 -80 -35 -41 -15 -77 -30
          -80 -34 -6 -8 -153 -50 -174 -50 -9 0 -16 -4 -16 -8 0 -11 -153 -38 -300 -53
          -156 -16 -441 -6 -595 20 -65 12 -122 25 -125 29 -3 4 -45 17 -95 30 -49 13
          -92 27 -95 30 -3 4 -41 20 -85 35 -43 15 -82 31 -85 36 -3 5 -29 19 -57 31
          -29 12 -53 26 -53 31 0 4 -10 11 -22 15 -12 4 -48 24 -79 45 -31 22 -61 39
          -67 39 -6 0 -17 8 -24 18 -7 9 -24 25 -38 33 -14 9 -37 27 -52 40 -14 13 -48
          42 -74 64 -57 49 -130 125 -199 209 -16 20 -41 48 -54 63 -12 16 -29 40 -37
          56 -8 15 -19 27 -24 27 -4 0 -20 25 -35 55 -14 30 -29 55 -33 55 -5 0 -19 25
          -31 55 -14 32 -27 52 -34 48 -5 -3 -7 -1 -3 5 3 6 2 13 -3 16 -5 3 -17 31 -26
          61 -10 30 -21 55 -25 55 -8 0 -8 0 -40 110 -12 41 -25 77 -30 80 -9 6 -50 191
          -50 226 0 12 -4 24 -9 26 -11 4 -31 218 -31 339 0 110 19 322 30 329 4 3 10
          28 14 55 10 70 39 189 47 195 4 3 17 42 29 88 12 46 28 88 34 95 7 7 22 40 35
          75 13 34 27 62 30 62 4 0 18 25 32 55 13 30 28 55 32 55 4 0 16 19 26 43 10
          24 25 50 33 58 7 8 25 30 38 49 13 19 35 46 47 60 13 14 23 28 23 31 0 4 25
          32 55 63 30 31 55 59 55 64 0 4 21 25 47 47 26 22 62 55 80 73 17 17 36 32 42
          32 5 0 18 10 28 23 20 24 40 38 91 64 17 9 32 20 32 25 0 4 18 17 39 28 22 10
          41 23 43 27 1 5 27 19 56 32 28 14 52 31 52 39 0 31 -24 142 -30 142 -4 0 -15
          40 -25 88 -15 81 -15 92 0 152 8 36 20 74 24 85 5 11 15 43 22 70 6 28 16 56
          22 63 13 16 1 15 -57 -4 -26 -9 -45 -19 -41 -22 3 -3 -14 -12 -37 -19 -64 -21
          -122 -46 -133 -59 -5 -6 -31 -20 -57 -30 -27 -11 -48 -22 -48 -26 0 -5 -20
          -17 -45 -29 -25 -12 -45 -25 -45 -29 0 -5 -12 -15 -27 -22 -55 -28 -78 -43
          -91 -60 -7 -10 -19 -18 -27 -18 -8 0 -20 -8 -27 -17 -7 -10 -32 -31 -55 -47
          -24 -16 -62 -47 -86 -70 -24 -22 -70 -66 -103 -96 -32 -30 -81 -82 -110 -115
          -28 -33 -63 -73 -77 -90 -61 -70 -77 -91 -77 -100 0 -6 -12 -23 -27 -40 -27
          -28 -43 -52 -60 -85 -4 -8 -15 -24 -25 -34 -10 -11 -18 -26 -18 -33 0 -7 -8
          -21 -19 -32 -10 -10 -31 -45 -46 -77 -15 -33 -31 -61 -34 -64 -4 -3 -18 -33
          -30 -67 -13 -35 -27 -63 -30 -63 -4 0 -18 -34 -31 -75 -13 -41 -26 -72 -30
          -70 -3 2 -19 -49 -36 -113 -16 -64 -32 -119 -35 -122 -7 -6 -37 -158 -45 -235
          -4 -33 -12 -63 -18 -67 -17 -11 -15 -690 2 -696 6 -2 12 -14 12 -27 0 -42 40
          -259 49 -265 5 -3 12 -21 16 -40 11 -55 48 -184 55 -190 3 -3 15 -34 28 -70
          13 -36 27 -66 32 -68 6 -2 10 -10 10 -17 0 -17 39 -108 49 -115 4 -3 17 -30
          30 -60 13 -30 28 -59 34 -65 7 -5 22 -31 35 -57 13 -27 27 -48 31 -48 4 0 18
          -20 31 -45 13 -25 26 -45 31 -45 4 0 17 -19 29 -42 23 -44 33 -57 95 -119 19
          -19 35 -39 35 -43 0 -12 262 -268 302 -296 20 -13 34 -28 32 -32 -3 -4 0 -8 6
          -8 12 0 60 -33 98 -67 14 -13 31 -23 39 -23 7 0 16 -7 20 -15 3 -9 28 -27 56
          -42 28 -14 57 -32 65 -40 8 -8 32 -21 53 -30 22 -8 39 -18 39 -22 0 -3 27 -17
          60 -30 33 -13 60 -27 60 -31 0 -5 35 -21 78 -36 42 -15 79 -31 82 -35 3 -4 39
          -17 80 -30 41 -12 77 -25 80 -29 6 -7 160 -45 230 -56 25 -4 47 -10 50 -15 13
          -19 292 -43 510 -43 226 0 501 24 508 45 2 5 12 9 21 9 35 0 246 51 249 61 2
          5 9 9 15 9 19 0 152 43 157 51 3 4 39 20 80 35 41 15 77 30 80 33 3 4 29 17
          58 30 28 14 52 27 52 30 0 4 24 17 53 31 28 14 61 33 71 42 11 10 25 18 31 18
          7 0 23 11 37 24 14 12 32 26 39 30 31 15 56 32 72 46 8 8 39 33 67 55 29 22
          93 79 143 128 50 48 93 87 97 87 4 0 16 12 25 27 10 15 31 38 46 51 16 13 29
          27 29 32 0 4 15 24 33 42 17 19 43 49 57 67 14 17 33 40 43 51 9 11 17 23 17
          28 0 4 13 22 29 39 16 17 39 52 51 78 12 25 27 51 34 58 7 7 23 35 36 62 13
          28 27 52 31 55 3 3 17 30 30 60 12 30 27 57 31 61 4 3 20 39 34 80 15 40 30
          76 34 79 3 3 17 43 30 90 14 47 27 87 31 90 4 3 17 57 30 120 13 63 27 117 30
          120 26 21 52 453 40 656 -10 161 -30 326 -40 334 -3 3 -16 57 -29 120 -13 63
          -27 117 -31 120 -5 3 -18 44 -31 93 -13 48 -26 87 -30 87 -4 0 -20 38 -35 85
          -15 47 -31 85 -35 85 -4 0 -18 26 -31 58 -13 31 -28 62 -34 68 -6 6 -19 30
          -29 53 -11 22 -22 41 -26 41 -5 0 -20 24 -35 53 -15 28 -34 56 -41 60 -8 4
          -14 15 -14 23 0 8 -10 25 -22 38 -24 24 -68 82 -68 90 0 7 -53 68 -82 95 -16
          14 -28 29 -28 34 0 5 -48 55 -107 112 -60 56 -122 117 -140 134 -17 17 -34 31
          -38 31 -4 0 -30 20 -58 43 -29 24 -60 47 -69 50 -10 4 -18 10 -18 15 0 5 -24
          22 -52 37 -29 16 -60 35 -68 43 -8 8 -29 21 -47 29 -18 7 -33 17 -33 21 0 5
          -25 19 -55 31 -30 13 -55 26 -55 31 0 4 -34 20 -75 35 -41 15 -75 30 -75 34 0
          4 -35 18 -77 31 -43 14 -81 28 -84 33 -3 5 -39 17 -80 28 -41 10 -83 23 -94
          29 -26 13 -135 35 -255 51 -52 7 -98 17 -102 23 -7 10 -500 17 -610 9z"/>
          <path id="15" d="M3720 6411 c0 -4 -42 -11 -93 -15 -122 -8 -341 -44 -345 -56 -2 -6
          -11 -10 -20 -10 -30 0 -192 -42 -192 -50 0 -4 -36 -17 -80 -30 -44 -13 -80
          -26 -80 -30 0 -5 -34 -20 -75 -35 -41 -15 -75 -30 -75 -34 0 -3 -21 -15 -48
          -25 -26 -10 -56 -26 -67 -36 -11 -9 -39 -25 -62 -36 -24 -10 -43 -22 -43 -26
          0 -4 -22 -19 -50 -33 -27 -14 -50 -29 -50 -34 0 -5 -17 -18 -39 -29 -21 -11
          -50 -31 -64 -44 -14 -13 -43 -36 -63 -53 -20 -16 -54 -46 -75 -65 -157 -145
          -189 -176 -189 -184 0 -5 -15 -24 -32 -41 -18 -17 -46 -48 -63 -69 -16 -20
          -40 -47 -52 -60 -13 -13 -23 -26 -23 -30 0 -8 -37 -61 -62 -90 -10 -11 -18
          -25 -18 -31 0 -6 -8 -20 -18 -31 -10 -10 -29 -39 -42 -64 -14 -25 -30 -50 -36
          -57 -6 -6 -22 -38 -34 -70 -13 -32 -27 -60 -31 -63 -3 -3 -17 -30 -30 -60 -12
          -30 -26 -57 -30 -60 -4 -3 -17 -39 -30 -80 -12 -41 -26 -78 -31 -81 -4 -3 -20
          -54 -35 -115 -14 -60 -29 -111 -33 -114 -8 -6 -35 -168 -46 -270 -4 -36 -11
          -68 -16 -71 -14 -9 -13 -512 1 -517 6 -2 11 -22 11 -44 0 -61 41 -308 51 -308
          5 0 9 -8 9 -17 0 -26 52 -227 60 -233 4 -3 17 -36 30 -75 13 -38 26 -72 30
          -75 4 -3 17 -32 29 -65 12 -33 26 -62 30 -65 4 -3 20 -32 35 -65 15 -33 31
          -62 35 -65 3 -3 16 -26 29 -53 13 -26 27 -47 31 -47 4 0 18 -20 31 -45 13 -25
          26 -45 31 -45 4 0 17 -19 29 -42 12 -24 32 -53 44 -65 11 -12 35 -39 51 -59
          102 -124 185 -213 207 -220 10 -3 18 -12 18 -20 0 -8 4 -14 9 -14 5 0 27 -17
          48 -37 21 -21 58 -54 82 -73 24 -19 52 -43 62 -53 11 -9 21 -17 24 -17 7 0 64
          -40 83 -58 8 -7 32 -22 53 -33 21 -10 39 -23 39 -27 0 -4 19 -16 43 -26 23
          -11 51 -27 62 -36 11 -9 40 -25 65 -35 25 -11 47 -22 50 -26 8 -9 101 -49 116
          -49 7 0 19 -6 26 -14 8 -7 48 -24 89 -37 41 -13 76 -27 79 -31 6 -8 206 -58
          233 -58 9 0 17 -4 17 -8 0 -10 190 -41 304 -49 47 -3 86 -10 86 -14 0 -5 83
          -9 185 -9 117 0 185 4 185 10 0 6 25 10 56 10 75 0 338 39 342 51 2 5 12 9 21
          9 27 0 225 51 231 60 3 4 37 17 77 28 39 12 73 26 75 30 2 4 38 21 81 36 42
          15 77 31 77 35 0 4 25 18 55 30 30 13 55 26 55 30 0 4 23 18 50 30 28 12 50
          26 50 30 0 4 25 20 55 36 30 15 55 31 55 37 0 5 8 12 19 16 19 7 80 53 117 87
          11 11 36 31 55 44 19 13 44 37 57 52 13 16 28 29 33 29 9 0 101 93 184 187 22
          24 49 55 60 67 32 36 65 84 65 95 0 5 6 11 14 14 8 3 22 22 31 41 10 20 21 36
          25 36 4 0 20 24 35 53 15 28 31 54 35 57 4 3 18 25 30 50 12 25 26 47 30 50 9
          7 50 94 50 108 0 7 3 12 8 12 4 0 18 28 31 63 13 34 27 64 32 67 6 5 59 167
          59 183 0 4 -27 7 -60 7 -33 0 -60 4 -60 8 0 8 -448 34 -461 27 -3 -3 -12 -25
          -19 -50 -7 -25 -16 -45 -20 -45 -4 0 -20 -31 -36 -69 -16 -38 -32 -68 -35 -65
          -4 2 -14 -14 -22 -35 -9 -21 -22 -45 -29 -52 -8 -8 -24 -31 -37 -53 -13 -21
          -35 -50 -47 -64 -13 -14 -24 -29 -24 -35 0 -11 -17 -30 -173 -187 -63 -63
          -128 -126 -146 -140 -17 -14 -42 -34 -54 -45 -12 -11 -34 -26 -48 -34 -14 -7
          -39 -24 -55 -38 -16 -14 -46 -32 -66 -40 -21 -8 -38 -18 -38 -23 0 -4 -19 -15
          -42 -25 -24 -10 -50 -24 -58 -31 -8 -7 -46 -25 -85 -40 -38 -15 -72 -30 -75
          -33 -6 -7 -59 -26 -120 -41 -25 -7 -47 -15 -50 -20 -7 -10 -208 -50 -290 -57
          -36 -3 -68 -10 -71 -15 -9 -14 -422 -13 -427 1 -2 6 -18 11 -37 11 -60 0 -303
          46 -315 60 -3 3 -41 16 -85 29 -44 13 -82 26 -85 30 -3 4 -40 20 -82 36 -43
          15 -78 31 -78 35 0 5 -25 18 -55 31 -30 12 -55 26 -55 30 0 4 -21 17 -46 29
          -25 13 -57 33 -71 46 -14 13 -32 24 -39 24 -8 0 -19 9 -26 20 -7 11 -18 20
          -25 20 -6 0 -24 15 -39 33 -15 17 -30 32 -34 32 -5 0 -32 23 -60 50 -73 70
          -89 86 -135 140 -22 26 -52 58 -67 72 -16 15 -28 32 -28 40 0 7 -7 16 -15 19
          -8 3 -26 27 -41 52 -15 26 -33 52 -39 58 -6 6 -19 30 -29 53 -11 22 -22 41
          -26 41 -4 0 -17 24 -31 53 -13 28 -29 58 -36 65 -7 7 -20 37 -29 67 -10 30
          -21 55 -24 55 -4 0 -20 44 -36 98 -15 53 -31 99 -35 102 -7 6 -35 140 -45 220
          -4 30 -12 61 -18 69 -15 19 -15 519 0 529 6 4 14 30 17 57 13 98 41 225 49
          225 4 0 8 5 8 12 0 19 52 182 60 188 4 3 15 29 24 58 10 29 23 58 29 65 7 6
          23 36 36 65 14 28 27 52 31 52 3 0 14 18 24 40 10 22 23 46 30 53 7 7 25 33
          41 57 15 25 34 49 41 53 8 4 14 13 14 20 0 6 11 23 24 37 14 14 41 43 61 65
          63 71 188 195 195 195 4 0 22 14 41 30 50 45 61 53 99 79 19 13 40 29 45 35 6
          6 31 21 58 34 26 13 47 27 47 31 0 4 27 19 60 32 33 13 60 27 60 30 0 4 33 20
          73 35 39 16 74 31 77 34 3 4 39 16 80 29 41 12 83 26 92 31 21 12 207 50 241
          50 14 0 27 4 29 10 8 26 588 26 596 0 2 -5 15 -10 29 -10 36 0 226 -40 238
          -50 9 -7 87 -31 153 -47 12 -3 22 -9 22 -13 0 -4 34 -20 75 -35 41 -15 75 -30
          75 -35 0 -4 20 -15 45 -25 25 -9 51 -23 58 -30 7 -7 32 -22 55 -34 23 -12 42
          -25 42 -30 0 -5 8 -11 18 -15 25 -8 74 -38 99 -61 13 -11 35 -30 50 -42 16
          -12 43 -34 61 -49 29 -23 36 -25 56 -14 23 12 88 67 136 115 14 14 30 25 36
          25 6 0 22 12 36 28 23 24 62 59 153 137 17 14 33 25 38 25 13 0 7 29 -9 42 -8
          7 -32 29 -53 48 -50 46 -77 69 -126 105 -22 17 -51 40 -65 53 -14 12 -31 22
          -38 22 -7 0 -15 6 -18 14 -3 8 -30 27 -60 42 -30 15 -54 30 -54 34 0 4 -22 17
          -50 30 -27 13 -50 27 -50 31 0 4 -25 17 -55 30 -30 12 -55 26 -55 30 0 3 -35
          19 -77 34 -43 16 -80 31 -83 35 -3 4 -30 15 -60 25 -30 10 -73 26 -95 35 -22
          10 -74 26 -115 36 -41 10 -77 21 -80 25 -8 11 -266 50 -372 56 -54 3 -98 10
          -98 14 0 5 -76 9 -170 9 -93 0 -170 -4 -170 -9z"/>
          <path  fill="black" d="M12374 6342 c2 -4 -51 -18 -120 -32 -68 -13 -124 -27 -124 -31 0 -4
          -49 -17 -110 -29 -86 -18 -111 -27 -116 -42 -3 -10 -9 -16 -13 -14 -9 6 -56
          -193 -48 -201 7 -6 160 28 167 38 4 5 120 32 203 46 15 3 27 9 27 13 0 4 15
          10 33 12 75 11 207 42 207 48 0 4 20 11 45 14 25 4 45 11 45 15 0 18 -162 171
          -181 171 -11 0 -18 -4 -15 -8z"/>
          <path d="M6874 6225 c-9 -14 -22 -25 -29 -25 -7 0 -24 -12 -37 -27 -12 -16
          -35 -36 -50 -46 -15 -9 -42 -33 -60 -52 -17 -19 -35 -35 -41 -35 -5 0 -18 -11
          -29 -25 -11 -14 -24 -25 -31 -25 -6 0 -25 -15 -43 -32 -17 -18 -51 -49 -74
          -68 -23 -19 -64 -56 -92 -82 -28 -27 -54 -48 -58 -48 -4 0 -15 -9 -25 -20 -10
          -11 -21 -20 -26 -20 -4 0 -22 -15 -41 -32 -19 -18 -58 -53 -88 -78 -127 -106
          -206 -180 -192 -180 6 0 21 -18 33 -40 13 -22 26 -40 30 -40 4 0 14 -12 21
          -27 24 -46 42 -75 61 -95 9 -10 17 -24 17 -31 0 -7 9 -22 19 -34 11 -11 29
          -43 41 -72 13 -28 26 -51 30 -51 4 0 18 -28 31 -62 13 -35 28 -68 34 -75 7 -6
          20 -37 30 -70 9 -32 21 -62 25 -67 9 -10 721 45 715 55 -3 3 48 10 113 14 108
          7 224 21 195 24 -7 0 -13 9 -13 18 0 25 -52 207 -61 213 -4 3 -17 46 -30 95
          -12 50 -25 92 -29 95 -5 3 -19 46 -31 95 -13 50 -28 95 -34 101 -6 6 -19 47
          -29 90 -10 43 -22 81 -27 84 -4 3 -20 53 -35 110 -15 58 -30 107 -34 110 -3 3
          -17 43 -30 90 -13 47 -27 87 -31 90 -8 5 -34 98 -46 162 -7 37 -30 43 -49 13z"/>
          <path id="16" d="M12569 6136 c-2 -3 -42 -12 -89 -22 -47 -9 -87 -20 -90 -24 -3 -4
          -54 -18 -115 -30 -60 -12 -112 -25 -115 -29 -5 -8 -35 -15 -195 -47 -44 -9
          -82 -20 -85 -25 -4 -5 -18 -10 -33 -11 -23 -2 -40 -18 -19 -18 4 0 18 -53 31
          -117 14 -65 28 -120 32 -123 3 -3 19 -63 34 -135 15 -71 30 -132 34 -135 5 -3
          18 -57 31 -120 13 -63 27 -117 31 -120 4 -3 17 -54 29 -115 12 -60 25 -112 29
          -115 4 -3 20 -66 36 -140 16 -74 32 -137 36 -140 4 -3 10 -22 14 -43 5 -25 12
          -36 20 -33 7 2 33 8 59 11 25 4 46 11 46 15 0 4 15 10 33 13 90 16 233 50 237
          57 3 4 32 12 65 19 118 22 170 34 175 41 3 4 36 13 74 21 l68 13 -5 36 c-10
          63 -31 155 -37 160 -4 3 -17 55 -30 115 -13 61 -27 112 -31 115 -3 3 -17 55
          -29 115 -13 61 -26 112 -30 115 -4 3 -19 66 -35 140 -16 74 -32 137 -36 140
          -4 3 -17 55 -29 115 -12 61 -25 112 -30 115 -4 3 -15 47 -25 98 -14 73 -21 92
          -35 92 -9 0 -19 -2 -21 -4z"/>
          <path fill="black"  d="M12736 5759 c8 -35 16 -74 19 -86 5 -21 11 -23 80 -23 43 0 75 -4 75
          -10 0 -5 24 -10 53 -10 29 0 80 -5 113 -10 115 -20 179 -33 184 -40 3 -3 41
          -16 85 -29 44 -13 82 -26 85 -30 3 -5 35 -18 73 -30 37 -13 73 -28 80 -34 6
          -7 36 -23 65 -36 28 -14 52 -27 52 -30 0 -3 25 -19 55 -36 30 -16 55 -33 55
          -37 0 -3 18 -17 40 -29 22 -13 40 -26 40 -30 0 -4 16 -17 35 -29 19 -12 35
          -28 35 -36 0 -8 6 -14 13 -14 7 0 47 -37 89 -82 43 -46 88 -94 101 -108 13
          -14 32 -40 41 -57 9 -18 21 -33 26 -33 5 0 19 -20 31 -45 12 -25 26 -45 30
          -45 4 0 14 -17 22 -39 9 -21 22 -45 29 -52 16 -17 68 -134 68 -155 0 -8 4 -14
          9 -14 6 0 20 -33 33 -72 12 -40 25 -75 28 -78 8 -7 39 -143 47 -205 3 -27 10
          -53 15 -56 23 -15 20 -538 -3 -523 -5 3 -9 -9 -9 -27 0 -43 -42 -239 -50 -234
          -3 2 -15 -30 -25 -72 -10 -41 -25 -82 -33 -89 -7 -8 -22 -37 -32 -65 -9 -28
          -24 -59 -31 -68 -8 -9 -23 -36 -33 -61 -11 -25 -25 -49 -31 -55 -7 -5 -19 -24
          -28 -42 -9 -17 -22 -37 -30 -45 -7 -7 -23 -27 -35 -44 -28 -41 -108 -134 -153
          -180 -45 -46 -133 -124 -139 -124 -3 0 -17 -12 -31 -26 -13 -14 -44 -37 -69
          -52 -25 -14 -49 -30 -55 -36 -15 -17 -84 -56 -100 -56 -7 0 -15 -6 -18 -14 -3
          -7 -28 -22 -56 -32 -28 -10 -51 -21 -51 -25 0 -4 -37 -20 -82 -35 -46 -15 -85
          -30 -88 -34 -3 -4 -21 -11 -40 -16 -19 -4 -39 -9 -45 -10 -5 -2 -39 -8 -75
          -15 -36 -6 -67 -15 -70 -19 -10 -14 -189 -30 -338 -30 -160 0 -352 17 -352 31
          0 5 -9 9 -20 9 -35 0 -190 42 -190 51 0 5 -6 9 -14 9 -21 0 -158 48 -165 58
          -3 4 -28 16 -56 26 -27 10 -54 24 -60 31 -5 6 -34 23 -62 36 -29 13 -53 27
          -53 31 0 4 -20 19 -45 34 -25 14 -45 30 -45 34 0 5 -8 11 -17 14 -10 4 -32 19
          -49 34 -17 15 -56 49 -87 77 -32 27 -57 53 -57 56 0 4 -24 31 -52 61 -73 74
          -118 130 -118 145 0 6 -8 18 -17 25 -10 7 -29 34 -43 61 -13 26 -27 47 -31 47
          -3 0 -16 21 -28 48 -12 26 -29 60 -38 76 -8 16 -23 51 -33 78 -9 26 -19 46
          -22 43 -5 -6 -58 174 -58 199 0 8 -4 16 -9 18 -5 2 -18 55 -28 118 -25 160
          -25 481 1 638 10 61 22 112 26 112 4 0 13 26 19 58 19 83 44 162 52 162 4 0
          17 29 29 65 12 36 25 65 29 65 4 0 18 25 30 55 13 30 26 55 31 55 4 0 17 20
          29 45 12 25 26 45 31 45 6 0 10 4 10 10 0 13 42 73 97 137 68 79 257 263 271
          263 4 0 22 14 41 30 18 17 55 42 82 55 27 14 49 29 49 34 0 5 11 12 25 15 14
          4 25 14 25 23 0 31 -24 123 -32 123 -5 0 -8 11 -8 25 0 32 -9 31 -62 -4 -24
          -16 -53 -32 -65 -35 -13 -4 -23 -11 -23 -16 0 -5 -12 -15 -27 -22 -44 -22 -74
          -41 -99 -63 -13 -11 -37 -31 -54 -45 -37 -29 -245 -237 -277 -277 -13 -16 -33
          -41 -45 -58 -12 -16 -25 -32 -28 -35 -3 -3 -17 -23 -30 -45 -13 -22 -27 -42
          -30 -45 -4 -3 -20 -30 -36 -60 -16 -30 -34 -60 -40 -66 -6 -7 -18 -33 -28 -58
          -10 -25 -21 -46 -25 -46 -4 0 -18 -33 -31 -72 -14 -40 -28 -75 -32 -78 -4 -3
          -20 -55 -35 -115 -15 -61 -30 -112 -34 -115 -19 -14 -42 -256 -43 -440 0 -194
          24 -430 45 -438 5 -2 9 -13 9 -24 0 -31 53 -227 60 -223 4 2 14 -23 24 -55 10
          -33 26 -69 37 -81 10 -12 19 -28 19 -36 0 -16 29 -75 40 -83 4 -3 19 -31 34
          -62 15 -32 31 -58 36 -58 4 0 15 -15 23 -33 15 -31 37 -61 85 -113 12 -14 22
          -28 22 -33 0 -4 20 -28 45 -52 25 -24 48 -52 51 -61 3 -10 11 -18 17 -18 7 0
          21 -14 32 -30 11 -16 25 -30 30 -30 6 0 30 -19 56 -43 65 -60 129 -111 150
          -119 11 -4 19 -12 19 -17 0 -5 24 -21 53 -36 28 -15 59 -33 67 -40 8 -7 34
          -21 57 -30 24 -10 41 -20 39 -24 -3 -4 24 -18 60 -31 35 -13 64 -27 64 -31 0
          -5 82 -33 165 -56 11 -3 22 -9 25 -13 6 -8 195 -50 227 -50 12 0 23 -4 25 -9
          4 -12 205 -31 331 -31 112 0 340 19 347 29 3 3 60 17 127 30 68 14 121 27 118
          30 -3 2 36 17 86 32 50 15 94 31 98 36 3 5 29 17 56 27 28 10 57 24 65 31 8 6
          37 22 64 35 27 13 58 32 68 42 10 10 24 18 31 18 7 0 24 11 39 25 15 14 31 25
          36 25 5 0 17 9 27 20 10 11 22 20 27 20 5 0 19 11 32 25 13 14 28 25 33 25 5
          0 19 13 31 30 13 16 29 30 37 30 8 0 25 16 38 35 13 19 26 32 29 29 3 -3 16 9
          28 25 12 17 41 52 66 77 24 26 44 49 44 53 0 3 16 24 35 46 19 22 42 54 50 72
          9 18 20 33 24 33 5 0 16 16 25 35 9 19 22 40 29 47 7 7 23 37 36 66 14 28 27
          52 31 52 3 0 19 36 35 80 16 44 32 80 36 80 3 0 17 39 29 88 13 48 27 89 31
          92 9 7 36 137 45 220 4 36 12 68 18 72 15 10 15 466 0 476 -6 4 -14 36 -17 72
          -8 74 -38 223 -47 230 -3 3 -16 43 -30 90 -13 47 -30 91 -37 98 -7 7 -13 19
          -13 27 0 16 -38 108 -48 115 -4 3 -16 25 -27 50 -10 25 -24 50 -31 55 -6 6
          -19 26 -27 46 -8 19 -21 41 -29 49 -8 8 -27 36 -42 62 -15 25 -33 49 -41 52
          -8 3 -15 11 -15 18 0 7 -11 24 -24 38 -13 14 -54 57 -91 95 -37 39 -77 82 -90
          97 -13 15 -40 39 -61 53 -20 14 -44 34 -51 45 -8 11 -20 20 -27 20 -7 0 -22
          11 -34 25 -12 13 -40 34 -63 45 -23 12 -51 30 -62 41 -12 10 -27 19 -34 19 -7
          0 -22 9 -33 20 -11 11 -44 30 -73 41 -28 11 -54 25 -57 29 -3 5 -42 22 -85 37
          -44 15 -82 31 -85 35 -6 8 -156 48 -181 48 -10 0 -19 4 -21 9 -6 18 -192 42
          -365 48 l-172 6 15 -64z"/>
          <path  fill="black"  d="M3605 5793 c-66 -7 -122 -18 -125 -22 -3 -5 -50 -18 -104 -29 -55
          -12 -102 -25 -105 -29 -3 -5 -45 -21 -93 -37 -49 -16 -88 -32 -88 -36 0 -4
          -27 -18 -60 -31 -33 -13 -60 -26 -60 -29 0 -4 -25 -17 -55 -31 -30 -13 -55
          -27 -55 -31 0 -4 -22 -19 -49 -34 -28 -14 -52 -33 -55 -40 -3 -8 -12 -14 -20
          -14 -8 0 -23 -10 -33 -23 -10 -13 -25 -27 -34 -33 -37 -21 -199 -166 -199
          -178 0 -3 -20 -26 -45 -53 -25 -26 -45 -50 -45 -54 0 -3 -12 -20 -27 -37 -50
          -56 -73 -87 -95 -132 -12 -24 -30 -52 -40 -62 -10 -10 -18 -24 -18 -31 0 -7
          -9 -23 -20 -35 -11 -11 -29 -44 -40 -72 -11 -28 -26 -61 -34 -73 -7 -12 -22
          -47 -31 -77 -10 -30 -21 -57 -25 -60 -8 -6 -46 -152 -56 -217 -4 -24 -10 -43
          -14 -43 -14 0 -35 -228 -35 -390 0 -167 21 -404 35 -395 4 3 10 -14 14 -38 10
          -65 48 -211 56 -217 4 -3 17 -36 30 -75 13 -38 26 -72 31 -75 4 -3 17 -29 28
          -59 12 -29 28 -60 36 -68 8 -8 15 -20 15 -27 0 -7 10 -24 22 -38 13 -14 32
          -42 43 -64 18 -36 42 -69 95 -134 12 -14 24 -30 28 -36 30 -50 208 -234 225
          -234 4 0 20 -13 34 -30 13 -16 32 -30 41 -30 8 0 12 -3 9 -6 -7 -7 28 -37 56
          -47 9 -3 17 -10 17 -15 0 -5 23 -21 50 -36 28 -15 50 -30 50 -34 0 -4 24 -18
          52 -31 29 -13 58 -30 66 -37 7 -8 35 -21 62 -31 28 -10 50 -21 50 -25 0 -4 39
          -19 88 -35 48 -15 89 -31 93 -35 3 -4 50 -18 105 -30 54 -12 101 -25 104 -29
          3 -4 68 -15 145 -24 217 -27 685 -8 685 26 0 5 9 9 21 9 28 0 193 41 199 50 3
          3 44 19 93 35 48 15 87 31 87 35 0 3 26 17 58 30 31 13 63 29 69 36 7 7 31 20
          53 30 22 10 40 21 40 25 0 4 24 19 53 33 28 13 58 34 65 45 7 12 18 21 25 21
          6 0 36 23 66 50 30 28 57 50 60 50 10 0 73 63 126 125 28 32 62 70 78 83 15
          14 27 31 27 39 0 7 7 16 15 20 9 3 23 19 31 35 8 16 23 37 32 47 9 9 26 35 37
          56 11 21 25 43 30 49 14 14 55 94 55 106 0 6 9 19 20 30 11 11 20 28 20 38 0
          10 4 22 9 28 4 5 11 21 15 35 5 25 3 27 -41 33 -25 3 -69 6 -97 6 l-51 0 -18
          -50 c-10 -27 -23 -55 -30 -62 -7 -7 -23 -34 -36 -60 -12 -27 -26 -48 -30 -48
          -4 0 -18 -20 -30 -45 -12 -25 -26 -45 -30 -45 -3 0 -15 -15 -25 -32 -11 -18
          -31 -45 -45 -59 -14 -15 -46 -50 -70 -78 -24 -28 -46 -49 -49 -46 -3 3 -14 -9
          -24 -25 -10 -17 -26 -34 -36 -37 -9 -4 -31 -21 -49 -37 -47 -47 -64 -60 -108
          -86 -22 -13 -47 -31 -55 -39 -8 -7 -29 -20 -47 -28 -18 -7 -33 -17 -33 -21 0
          -5 -26 -19 -57 -32 -32 -13 -63 -29 -69 -35 -6 -6 -45 -22 -85 -35 -41 -14
          -79 -30 -85 -36 -7 -5 -49 -19 -95 -29 -46 -10 -87 -23 -90 -27 -12 -18 -238
          -41 -409 -41 -168 0 -407 24 -420 43 -3 4 -30 13 -60 19 -64 15 -123 33 -130
          41 -6 7 -123 49 -157 57 -13 3 -23 9 -23 15 0 5 -21 18 -47 29 -26 11 -50 23
          -53 27 -3 4 -26 19 -52 32 -27 14 -48 28 -48 32 0 4 -15 15 -32 24 -18 9 -44
          27 -58 40 -49 47 -93 84 -100 84 -10 0 -150 141 -150 150 0 4 -15 23 -32 42
          -55 59 -88 102 -88 114 0 6 -8 18 -17 27 -10 9 -29 37 -43 63 -14 26 -32 55
          -40 63 -8 9 -22 36 -30 61 -8 25 -20 48 -26 53 -7 4 -22 39 -34 77 -12 39 -25
          70 -30 70 -4 0 -10 12 -13 28 -18 88 -39 167 -46 172 -21 12 -44 293 -38 460
          5 161 23 320 37 330 4 3 17 51 31 108 13 56 26 102 30 102 4 0 19 38 34 85 15
          47 31 85 35 85 4 0 15 17 23 39 9 21 22 45 29 52 8 8 26 39 41 69 15 30 27 51
          27 45 0 -5 12 10 26 35 14 25 33 52 42 61 9 9 33 36 52 59 19 24 54 64 77 89
          24 25 43 49 43 54 0 4 5 7 11 7 12 0 44 27 87 73 14 15 31 27 38 27 7 0 18 9
          24 20 6 11 25 27 43 36 18 9 36 23 40 30 4 8 13 14 20 14 7 0 37 16 67 36 30
          20 73 43 95 52 22 9 46 21 52 28 7 7 43 22 80 35 38 12 70 25 73 29 3 4 46 18
          95 30 50 13 92 27 95 31 13 18 240 39 416 39 186 0 395 -21 402 -41 2 -5 11
          -9 21 -9 25 0 175 -40 181 -48 3 -4 41 -20 85 -35 43 -15 82 -31 85 -36 3 -4
          31 -18 63 -31 31 -13 57 -27 57 -31 0 -4 20 -17 44 -29 25 -11 59 -34 78 -50
          18 -16 38 -30 45 -30 7 0 17 -9 23 -20 6 -11 17 -20 25 -20 8 0 24 -13 37 -30
          25 -35 53 -39 68 -10 6 11 17 20 25 20 8 0 24 14 37 30 12 17 28 30 36 30 9 0
          12 6 8 16 -3 9 -6 20 -6 25 0 5 -6 9 -13 9 -8 0 -22 9 -32 20 -10 11 -22 20
          -28 20 -5 0 -22 12 -37 28 -45 43 -62 55 -114 86 -27 15 -57 36 -68 45 -10 9
          -22 16 -26 17 -15 1 -62 28 -62 34 0 4 -8 10 -17 13 -55 19 -97 37 -102 45 -3
          5 -44 22 -91 37 -47 15 -87 31 -90 35 -5 8 -105 34 -175 46 -22 4 -42 11 -46
          15 -10 16 -265 39 -424 38 -88 -1 -214 -8 -280 -16z"/>
          <path  d="M12803 5533 c6 -81 26 -184 37 -193 4 -3 12 -32 19 -65 7 -33 18 -82
          24 -110 5 -27 13 -52 17 -55 4 -3 19 -66 35 -140 15 -74 32 -138 38 -142 13
          -8 14 -97 1 -106 -9 -6 -44 -142 -44 -167 0 -6 27 -17 61 -24 33 -8 66 -19 72
          -25 7 -6 35 -22 62 -35 28 -14 55 -29 60 -35 14 -15 73 -56 80 -56 4 0 171
          164 371 364 233 233 362 369 358 377 -7 18 -131 123 -150 127 -8 2 -14 7 -14
          12 0 5 -18 19 -40 31 -22 13 -40 26 -40 31 0 4 -26 20 -57 35 -32 15 -63 32
          -69 38 -6 6 -31 19 -55 30 -24 11 -46 22 -49 26 -3 3 -45 20 -95 37 -49 17
          -85 32 -80 32 16 2 -178 49 -202 50 -13 0 -23 4 -23 8 0 10 -188 32 -269 32
          l-54 0 6 -77z"/>
          <path  d="M3728 5593 c-32 -2 -58 -8 -58 -13 0 -6 -14 -10 -31 -10 -39 0 -230
          -39 -244 -49 -5 -4 -50 -20 -100 -36 -49 -15 -92 -30 -95 -34 -3 -4 -30 -18
          -60 -30 -30 -13 -57 -26 -60 -30 -3 -3 -31 -19 -62 -35 -32 -16 -58 -32 -58
          -35 0 -4 -16 -15 -35 -24 -20 -9 -38 -24 -41 -32 -4 -8 -12 -15 -19 -15 -6 0
          -20 -9 -30 -20 -10 -11 -22 -20 -27 -20 -5 0 -21 -13 -35 -30 -13 -16 -31 -30
          -38 -30 -7 0 -20 -12 -30 -26 -20 -31 -22 -27 91 -140 73 -72 87 -82 128 -87
          46 -7 106 -47 106 -73 0 -6 11 -22 25 -35 13 -13 25 -29 25 -35 0 -6 11 -23
          25 -38 14 -15 28 -38 31 -52 5 -20 275 -304 289 -304 2 0 19 14 37 30 18 17
          38 30 44 30 7 0 14 6 17 13 3 8 32 24 66 36 33 13 61 27 63 32 3 10 136 39
          178 39 64 0 60 -39 60 530 0 575 6 532 -68 528 -20 -1 -63 -3 -94 -5z"/>
          <path  d="M3915 5589 c-3 -8 -4 -246 -3 -529 l3 -515 80 -8 c111 -12 350 8 374
          31 10 9 33 28 51 42 30 23 50 39 118 95 14 11 39 35 55 53 17 17 35 32 41 32
          5 0 23 15 40 33 17 17 49 46 70 62 21 17 46 37 55 45 9 9 34 29 56 45 22 17
          50 42 63 58 13 15 28 27 32 27 5 0 30 21 56 47 l46 47 -49 43 c-26 24 -56 46
          -65 49 -10 3 -18 9 -18 14 0 5 -18 19 -40 30 -22 11 -40 24 -40 29 0 10 -56
          41 -75 41 -6 0 -20 8 -31 18 -10 10 -44 28 -74 41 -30 13 -57 27 -60 30 -6 8
          -166 61 -185 61 -7 0 -15 4 -17 9 -4 10 -192 51 -237 51 -16 0 -31 4 -33 9 -1
          5 -49 12 -105 16 -82 6 -104 4 -108 -6z"/>
          <path id="17"  d="M5889 5389 c-19 -17 -53 -47 -77 -67 -23 -20 -58 -51 -78 -68 -20
          -18 -50 -44 -68 -58 -17 -15 -51 -44 -76 -66 -25 -22 -48 -40 -52 -40 -9 0
          -38 -30 -38 -40 0 -5 6 -15 13 -22 7 -7 24 -31 37 -53 13 -22 29 -45 35 -51 6
          -6 19 -30 29 -53 11 -22 22 -41 26 -41 4 0 20 -29 35 -65 26 -60 30 -65 59
          -65 40 0 560 42 564 46 5 4 -19 78 -27 84 -4 3 -18 33 -31 68 -14 34 -28 62
          -31 62 -4 0 -17 26 -30 58 -12 31 -26 59 -30 62 -3 3 -16 25 -29 49 -12 25
          -28 50 -35 58 -7 7 -23 33 -35 58 -12 25 -25 45 -30 45 -4 0 -13 14 -20 31 -7
          17 -24 37 -37 46 -12 8 -23 21 -23 29 0 25 -17 23 -51 -7z"/>
          <path  d="M11875 5353 c-28 -13 -52 -29 -53 -34 -2 -5 -18 -17 -35 -26 -33 -17
          -60 -38 -108 -85 -15 -16 -31 -28 -36 -28 -6 0 -24 -16 -41 -35 l-32 -35 354
          -352 c382 -380 386 -384 386 -369 0 5 -10 17 -22 25 -12 9 -19 16 -15 16 4 0
          -8 14 -28 31 -105 93 -105 93 -140 264 -9 44 -20 82 -24 85 -4 3 -18 57 -31
          120 -13 63 -26 117 -30 120 -6 4 -34 121 -48 203 -2 15 -8 27 -12 27 -4 0 -11
          20 -14 45 -4 24 -10 47 -14 49 -4 2 -30 -7 -57 -21z"/>
          <path  d="M11510 5050 c-37 -42 -85 -97 -110 -128 -26 -31 -70 -101 -70 -112 0
          -5 -5 -10 -11 -10 -5 0 -19 -22 -31 -50 -11 -27 -24 -50 -28 -50 -4 0 -18 -24
          -30 -52 -12 -29 -26 -55 -29 -58 -4 -3 -18 -39 -30 -80 -13 -41 -27 -77 -31
          -80 -10 -6 -45 -152 -56 -225 -4 -27 -11 -53 -16 -56 -4 -3 -11 -62 -15 -132
          l-6 -127 525 0 526 0 7 63 c9 84 33 177 51 194 8 8 14 21 14 29 0 17 25 59 57
          94 13 14 23 30 23 36 0 6 9 18 20 25 20 14 20 15 -14 49 -19 19 -40 40 -48 46
          -7 6 -25 24 -39 40 -14 16 -43 46 -65 66 -21 21 -45 43 -51 50 -13 13 -180
          180 -202 202 -106 104 -171 172 -171 178 0 4 -13 14 -28 22 -15 8 -34 26 -42
          40 -7 14 -19 26 -25 26 -7 0 -19 9 -26 20 -13 18 -15 18 -49 -20z"/>
          <path  d="M13657 4719 l-367 -369 22 -33 c13 -17 28 -36 34 -42 6 -5 28 -41 49
          -80 37 -69 64 -155 65 -207 0 -16 5 -28 10 -28 6 0 10 -16 10 -35 l0 -35 169
          0 c154 0 171 2 183 18 24 34 61 45 177 53 129 9 165 0 194 -45 l17 -26 150 0
          150 0 0 75 c0 81 -27 297 -39 305 -3 3 -17 49 -30 103 -12 53 -27 97 -32 97
          -5 0 -9 6 -9 12 0 18 -52 152 -61 158 -4 3 -17 26 -28 52 -10 25 -23 47 -28
          49 -4 2 -18 25 -31 52 -13 26 -27 47 -31 47 -4 0 -14 12 -21 28 -8 15 -22 36
          -32 47 -10 11 -31 37 -47 57 -16 21 -31 35 -35 31 -3 -3 -6 0 -6 8 0 11 -56
          81 -63 78 -1 0 -167 -167 -370 -370z"/>
          -30 -39 -16 -14 -30 -29 -30 -33 0 -4 -20 -32 -45 -62 -25 -30 -45 -59 -45
          -65 0 -6 -10 -22 -23 -36 -13 -15 -32 -46 -42 -71 -11 -25 -25 -49 -31 -55 -6
          -5 -20 -32 -30 -60 -10 -27 -21 -52 -26 -55 -10 -7 -58 -159 -58 -183 0 -10
          -4 -16 -8 -13 -10 6 -41 -147 -50 -236 -2 -32 -9 -55 -13 -52 -5 3 -9 -36 -9
          -85 l0 -91 94 0 93 0 -10 45 c-11 49 7 215 24 215 5 0 9 7 9 16 0 22 40 158
          49 164 4 3 20 38 35 78 16 39 32 72 35 72 3 0 17 21 31 48 14 26 33 56 42 66
          10 11 20 27 23 37 3 9 17 24 30 33 14 9 25 22 25 28 0 16 163 174 188 183 12
          3 22 9 22 13 0 4 -44 50 -99 103 l-98 96 -17 -28z"/>
          <path fill="black"  d="M5422 4989 c-13 -16 -28 -29 -34 -29 -5 0 -23 -13 -38 -30 -15 -16
          -24 -30 -20 -30 5 0 19 -20 31 -45 12 -25 26 -45 31 -45 4 0 17 -21 29 -47 12
          -27 28 -52 35 -56 8 -4 14 -15 14 -24 0 -14 12 -15 97 -9 54 4 100 9 102 11 6
          7 -22 69 -41 89 -10 11 -22 34 -28 52 -6 18 -17 35 -25 38 -8 3 -15 11 -15 18
          0 22 -84 138 -100 137 -8 0 -25 -13 -38 -30z"/>
          <path id="18"   d="M7753 4893 c-29 -2 -53 -8 -53 -14 0 -5 -9 -9 -20 -9 -31 0 -175 -40
          -178 -50 -2 -4 -30 -18 -63 -31 -34 -12 -63 -28 -66 -36 -3 -7 -11 -13 -18
          -13 -7 0 -27 -13 -45 -30 -18 -16 -35 -30 -39 -30 -8 0 -123 -113 -159 -155
          -12 -15 -35 -48 -49 -74 -15 -25 -30 -47 -34 -49 -4 -2 -17 -31 -30 -65 -12
          -34 -26 -65 -31 -68 -10 -7 -48 -174 -48 -216 0 -17 -4 -35 -10 -38 -12 -8
          -13 -172 -1 -179 4 -3 11 -33 15 -68 6 -64 35 -179 46 -188 3 -3 14 -27 24
          -55 10 -27 27 -59 37 -70 10 -11 22 -31 28 -45 23 -52 54 -92 141 -177 36 -34
          68 -63 72 -63 4 0 19 -11 34 -25 15 -14 32 -25 38 -25 6 0 24 -11 40 -25 15
          -13 49 -32 75 -42 25 -9 49 -21 52 -26 6 -8 142 -46 167 -47 9 0 12 41 12 170
          l0 170 -45 11 c-25 6 -43 14 -41 17 3 3 -16 15 -42 27 -40 19 -167 132 -210
          188 -17 23 -62 119 -62 134 0 6 -5 13 -10 15 -14 4 -40 148 -40 216 1 71 25
          213 39 221 5 3 19 31 31 61 12 30 30 63 41 73 10 10 19 21 19 26 0 18 153 161
          173 161 5 0 21 8 35 19 34 24 154 61 198 61 19 0 34 5 34 10 0 12 137 14 144
          1 3 -4 26 -11 52 -15 49 -6 135 -33 144 -44 3 -4 31 -20 63 -36 31 -16 57 -33
          57 -37 0 -4 16 -16 35 -28 19 -11 35 -27 35 -35 0 -7 11 -21 25 -30 14 -9 25
          -22 25 -29 0 -8 10 -22 23 -33 12 -11 31 -45 42 -77 10 -31 22 -57 26 -57 15
          0 33 -118 33 -215 0 -92 -18 -203 -33 -215 -4 -3 -15 -26 -24 -51 -10 -26 -29
          -60 -42 -75 -14 -16 -25 -33 -25 -38 0 -8 -76 -85 -131 -131 -13 -11 -42 -29
          -64 -40 -22 -11 -41 -23 -43 -27 -2 -4 -21 -12 -43 -18 l-39 -11 0 -170 0
          -171 33 7 c72 15 147 38 147 46 0 5 5 9 12 9 14 0 101 41 108 50 3 4 19 14 36
          23 34 18 59 37 141 112 76 69 88 81 104 113 8 15 19 31 26 35 7 4 21 28 32 52
          11 25 24 45 29 45 5 0 19 33 32 73 14 39 28 75 32 78 20 14 41 170 41 304 -1
          135 -21 291 -41 304 -5 3 -14 27 -21 53 -7 26 -22 56 -32 67 -10 11 -19 25
          -19 30 0 12 -48 103 -58 111 -5 3 -18 20 -31 37 -30 44 -143 153 -157 153 -7
          0 -14 7 -17 14 -3 8 -21 23 -39 32 -18 9 -37 23 -43 29 -5 7 -36 23 -67 35
          -32 13 -58 27 -58 30 0 4 -44 17 -97 31 -54 13 -101 27 -104 32 -6 9 -231 17
          -306 10z"/>
          <path   d="M5259 4852 c-14 -9 -26 -21 -27 -26 -1 -7 -12 -17 -76 -58 -11 -7
          -31 -25 -44 -40 -13 -16 -27 -28 -31 -28 -11 0 -91 -77 -91 -87 0 -4 4 -3 8 3
          4 6 99 18 216 27 115 9 212 19 214 22 6 6 -29 85 -38 85 -3 0 -14 17 -24 37
          -19 41 -56 83 -71 82 -5 0 -22 -8 -36 -17z"/>
          <path fill="black" d="M2865 4849 c-16 -5 -35 -16 -42 -25 -7 -9 -22 -22 -34 -28 -12 -6
          -27 -19 -33 -30 -6 -10 -33 -39 -59 -65 -27 -25 -56 -59 -64 -76 -9 -16 -24
          -36 -34 -43 -11 -7 -19 -20 -19 -28 0 -8 -9 -23 -19 -33 -11 -11 -30 -42 -43
          -70 -12 -28 -26 -51 -29 -51 -4 0 -19 -39 -35 -87 -16 -49 -32 -90 -36 -94
          -16 -11 -50 -214 -45 -266 10 -101 44 -118 215 -112 86 4 92 5 116 34 14 17
          26 38 26 48 0 9 5 17 10 17 6 0 10 9 10 20 0 33 42 190 50 190 5 0 16 24 25
          54 10 30 25 63 34 73 9 10 27 38 40 63 14 25 33 51 43 58 10 7 18 18 18 24 0
          6 30 39 66 74 61 58 67 68 72 112 4 47 -17 112 -36 112 -5 0 -16 18 -25 39
          -38 86 -92 114 -172 90z"/>
          <path  d="M6950 4782 c-168 -14 -307 -29 -310 -32 -3 -4 -52 -11 -110 -15 -58
          -4 -121 -10 -141 -13 l-36 -7 20 -80 c11 -44 24 -82 28 -85 8 -6 49 -208 49
          -246 0 -13 5 -24 10 -24 6 0 10 -13 10 -29 0 -16 3 -43 6 -60 l7 -31 208 0
          c202 0 209 1 209 20 0 11 5 20 10 20 6 0 10 7 10 16 0 20 41 137 50 144 4 3
          17 26 29 53 13 26 26 47 31 47 4 0 15 15 24 33 27 52 38 67 96 123 103 99 170
          155 201 166 10 4 19 12 19 18 0 14 -68 11 -420 -18z"/>
          <path fill="black" d="M12820 4718 c-52 -10 -97 -23 -100 -27 -3 -4 -66 -20 -140 -35 -74
          -15 -139 -31 -145 -36 -5 -4 -61 -18 -122 -31 -62 -13 -113 -27 -113 -30 0 -4
          29 -36 64 -71 109 -108 90 -100 187 -82 46 9 86 20 89 24 4 6 167 43 253 58
          15 2 27 8 27 13 0 5 11 9 25 9 26 0 45 25 45 59 0 12 4 21 8 21 5 0 14 24 21
          53 6 28 14 62 17 75 7 26 9 26 -116 0z"/>
          <path id="19"  d="M6190 4705 c-63 -6 -122 -13 -130 -17 -8 -3 -85 -11 -170 -18 -85 -7
          -156 -13 -157 -14 -5 -4 30 -101 38 -106 4 -3 18 -46 30 -95 13 -50 26 -92 30
          -95 3 -3 12 -36 18 -75 7 -38 15 -82 17 -97 l6 -28 284 0 c156 0 284 3 284 8
          0 27 -34 238 -39 242 -4 3 -18 56 -31 118 -13 61 -28 112 -32 112 -4 0 -10 16
          -14 35 -3 19 -9 36 -13 38 -3 2 -58 -2 -121 -8z"/>
          <path  fill="black" d="M5543 4641 l-53 -6 29 -60 c15 -33 34 -84 42 -112 7 -29 16 -53 20
          -53 8 0 49 -169 49 -201 0 -10 5 -19 10 -19 6 0 10 -7 10 -15 0 -12 17 -15 98
          -14 53 0 91 3 85 6 -7 2 -13 17 -13 32 0 39 -41 235 -50 241 -4 3 -17 40 -30
          83 -13 42 -26 77 -30 77 -4 0 -11 11 -14 25 -6 23 -11 25 -54 23 -26 -1 -71
          -4 -99 -7z"/>
          <path  d="M5385 4632 c-33 -2 -62 -7 -65 -11 -3 -4 -90 -15 -193 -23 -139 -11
          -192 -19 -205 -31 -9 -8 -35 -31 -57 -51 -22 -19 -58 -51 -79 -70 -22 -20 -42
          -36 -45 -36 -4 0 -27 -19 -52 -42 -25 -24 -61 -54 -79 -68 -56 -42 -110 -98
          -110 -113 0 -8 7 -18 16 -21 27 -10 1094 -7 1094 4 0 21 -23 115 -30 120 -4 3
          -12 28 -18 55 -20 91 -30 128 -40 134 -5 3 -11 15 -14 26 -16 55 -47 130 -55
          130 -4 1 -35 -1 -68 -3z"/>
          <path  d="M3133 4574 c-8 -20 -30 -48 -88 -109 -22 -23 -51 -56 -65 -74 -14
          -17 -32 -39 -40 -49 -14 -18 -25 -35 -52 -84 -7 -13 -16 -25 -20 -28 -8 -6
          -35 -79 -50 -135 -6 -22 -15 -42 -19 -45 -4 -3 -13 -36 -19 -72 -5 -37 -13
          -74 -16 -82 -5 -14 19 -16 218 -16 l224 0 13 90 c7 50 16 90 21 90 5 0 11 14
          15 31 10 46 46 123 61 132 8 4 14 13 14 20 0 7 10 23 23 37 50 57 55 45 -84
          184 -107 108 -130 126 -136 110z"/>
          <path id="20" d="M7760 4519 c-30 -5 -57 -13 -60 -18 -3 -4 -32 -16 -64 -26 -63 -20
          -106 -52 -106 -79 0 -9 4 -16 9 -16 4 0 12 -19 17 -42 11 -49 25 -57 51 -29
          10 11 39 27 63 36 24 9 47 20 50 25 12 19 161 33 230 22 36 -5 65 -9 66 -8 1
          0 -6 29 -14 62 -11 48 -20 65 -39 73 -28 13 -124 13 -203 0z"/>
          <path id="21"  d="M3823 4512 c-29 -2 -53 -7 -53 -13 0 -5 -9 -9 -19 -9 -29 0 -148 -39
          -163 -53 -7 -7 -23 -17 -35 -22 -26 -12 -109 -81 -138 -114 -11 -13 -30 -35
          -42 -49 -13 -13 -30 -42 -40 -63 -9 -22 -20 -39 -24 -39 -22 0 -59 -183 -59
          -290 0 -109 34 -273 59 -290 5 -3 18 -24 30 -47 11 -23 31 -53 43 -66 13 -13
          30 -32 38 -43 23 -28 108 -97 133 -109 12 -5 28 -15 35 -22 7 -7 45 -22 85
          -34 39 -12 83 -27 96 -33 33 -15 264 -13 269 2 2 7 11 12 20 12 29 1 150 47
          167 64 9 9 23 16 30 16 7 0 22 11 33 25 11 14 24 25 29 25 18 0 152 156 153
          177 0 9 -79 13 -307 16 -320 4 -358 8 -404 48 -13 12 -37 32 -52 45 -62 52
          -93 192 -67 300 11 47 25 68 78 129 27 30 73 58 122 72 56 16 65 22 125 81 19
          17 38 32 43 32 6 0 21 12 34 28 13 15 40 38 61 52 20 14 37 29 37 33 0 4 16
          17 35 28 19 12 35 25 35 30 0 11 -50 32 -120 50 -30 7 -57 17 -60 21 -7 8
          -129 14 -207 10z"/>
          <path d="M4205 4513 c-59 -6 -62 -18 -9 -38 51 -19 66 -15 92 23 16 24 15 24
          -83 15z"/>
          <path id="22" d="M12920 4498 c0 -31 42 -202 51 -208 7 -5 33 -104 46 -182 3 -16 9
          -28 13 -28 4 0 17 -45 30 -101 37 -166 22 -269 -52 -339 -75 -70 -98 -81 -187
          -87 -108 -8 -167 12 -232 77 -27 27 -49 53 -49 59 0 5 -6 16 -14 24 -8 8 -25
          62 -38 121 -13 59 -27 104 -30 101 -3 -3 -9 11 -12 32 -13 71 -40 178 -46 183
          -3 3 -17 49 -29 103 -13 53 -25 97 -28 97 -10 0 -103 -104 -103 -115 0 -6 -9
          -21 -19 -32 -19 -20 -61 -131 -61 -159 0 -7 -5 -14 -10 -16 -6 -2 -13 -39 -16
          -84 -8 -92 3 -237 17 -228 5 3 9 -1 9 -9 0 -26 33 -122 46 -132 6 -6 19 -26
          27 -45 8 -19 30 -51 48 -72 19 -20 39 -42 45 -50 25 -29 76 -67 118 -88 25
          -12 46 -26 48 -31 2 -6 46 -21 97 -35 143 -37 304 -33 441 13 24 8 47 17 50
          21 3 4 24 18 48 30 24 13 54 34 67 45 67 60 108 104 121 129 8 15 18 28 22 28
          8 0 35 57 54 115 7 22 16 42 20 46 35 26 34 374 -1 399 -4 3 -17 31 -29 63
          -12 32 -34 75 -49 95 -39 53 -156 172 -169 172 -6 0 -24 11 -39 25 -16 13 -55
          32 -87 41 -32 10 -58 21 -58 25 0 4 -13 9 -30 11 -24 4 -30 1 -30 -14z"/>
          <path  d="M8024 4468 c4 -23 11 -45 16 -48 4 -3 12 -18 15 -33 5 -16 19 -34 37
          -43 30 -15 30 -15 74 29 24 25 44 50 44 56 0 12 -63 42 -112 55 -16 3 -28 11
          -28 16 0 6 -12 10 -26 10 -26 0 -26 -2 -20 -42z"/>
          <path id="23" d="M12760 4452 c-36 -9 -67 -19 -70 -22 -3 -4 -57 -17 -120 -30 -63 -13
          -117 -26 -120 -30 -3 -4 -19 -11 -37 -14 -30 -7 -31 -9 -26 -44 3 -20 9 -39
          13 -42 4 -3 18 -50 30 -105 13 -55 26 -102 30 -105 4 -3 18 -52 30 -110 13
          -58 27 -107 31 -110 4 -3 13 -26 18 -51 6 -26 15 -58 21 -73 11 -30 78 -96 97
          -96 6 0 19 -8 29 -17 23 -24 173 -32 195 -11 8 7 37 24 64 38 28 14 50 30 50
          37 0 7 6 18 13 25 6 6 12 20 12 29 0 10 5 19 11 21 27 9 26 178 0 213 -9 12
          -16 36 -36 130 -9 38 -19 72 -23 75 -4 3 -20 59 -36 125 -15 66 -31 122 -36
          125 -4 3 -10 16 -11 30 -5 32 -38 35 -129 12z m123 -487 c33 -34 37 -44 37
          -90 0 -43 -5 -58 -28 -84 -29 -34 -90 -55 -127 -45 -81 22 -105 55 -105 142 0
          39 5 49 41 84 39 38 44 40 93 36 44 -4 57 -10 89 -43z"/>
          <path fill="black"  d="M12737 3952 c-10 -10 -17 -26 -17 -36 0 -9 -4 -14 -10 -11 -6 4 -10
          -9 -10 -32 0 -28 3 -34 10 -23 8 13 10 12 10 -2 0 -10 -6 -18 -12 -18 -9 0 -9
          -3 1 -9 8 -5 20 -7 28 -4 9 4 11 0 7 -10 -7 -19 37 -34 58 -21 7 5 20 7 28 6
          33 -5 62 85 44 133 -16 41 -108 59 -137 27z"/>
          <path id="23"  d="M8196 4355 c-25 -24 -46 -46 -46 -49 0 -2 16 -13 35 -25 19 -11 35
          -25 35 -32 0 -6 11 -20 25 -31 14 -11 25 -26 25 -34 0 -8 6 -17 14 -20 16 -6
          42 -80 51 -145 10 -69 15 -89 23 -89 4 0 31 23 60 52 47 47 52 55 46 83 -13
          58 -40 136 -46 130 -3 -3 -12 12 -21 33 -14 35 -132 172 -148 172 -4 0 -28
          -20 -53 -45z"/>
          <path  d="M7437 4330 c-31 -33 -63 -71 -69 -85 -6 -14 -15 -25 -19 -25 -4 0
          -12 -13 -17 -29 l-10 -29 71 -4 c79 -3 93 3 118 60 8 18 20 32 26 32 15 0 17
          17 4 22 -6 2 -12 14 -15 28 -13 70 -19 90 -25 90 -3 0 -32 -27 -64 -60z"/>
          <path  fill="black" d="M7805 4354 c-47 -8 -125 -35 -125 -44 0 -4 -10 -10 -23 -13 -36 -10
          -136 -112 -159 -164 -11 -27 -25 -50 -29 -53 -4 -3 -13 -39 -20 -79 -8 -57 -8
          -92 1 -153 7 -43 15 -76 18 -73 3 2 15 -19 27 -49 15 -37 41 -72 81 -110 32
          -31 64 -56 71 -56 6 0 19 -7 27 -16 15 -14 16 3 16 189 0 112 3 218 6 234 8
          40 71 108 113 122 77 25 183 -1 210 -53 8 -14 18 -26 22 -26 19 0 29 -90 29
          -273 l0 -198 29 16 c17 8 43 27 58 41 15 14 39 36 53 48 14 11 36 46 50 76 14
          30 28 57 32 60 4 3 12 37 18 76 12 74 -1 206 -21 212 -5 2 -9 9 -9 15 0 45
          -130 207 -167 207 -6 0 -18 7 -27 15 -35 35 -191 62 -281 49z"/>
          <path id="24" d="M3857 4123 c-15 -2 -25 -8 -22 -12 2 -5 -8 -11 -23 -14 -31 -6 -112
          -81 -112 -103 0 -8 -4 -14 -9 -14 -16 0 -31 -61 -31 -125 0 -65 14 -121 38
          -144 7 -8 20 -24 28 -36 8 -13 36 -33 62 -46 26 -13 50 -28 54 -34 12 -16
          3088 -19 3088 -3 0 7 -7 26 -16 43 -27 52 -47 219 -40 340 3 61 9 120 12 133
          l6 22 -1503 -1 c-827 -1 -1516 -4 -1532 -6z m124 -168 c39 -30 47 -46 48 -97
          1 -55 -13 -82 -55 -103 -22 -11 -40 -23 -42 -27 -5 -12 -72 -9 -72 2 0 6 -6
          10 -12 10 -7 0 -26 10 -41 23 -31 25 -43 75 -33 131 15 79 135 114 207 61z"/>
          <path id="25"  d="M3930 3925 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
          -7 -10 -15z"/>
          <path id="26"  d="M7301 4093 c-6 -21 -11 -48 -11 -60 0 -12 -4 -25 -10 -28 -5 -3 -10
          -37 -10 -76 0 -39 4 -69 10 -69 6 0 10 -14 10 -31 0 -44 38 -172 55 -185 8 -6
          19 -23 24 -37 10 -26 12 -27 98 -27 49 0 84 4 78 8 -16 11 -55 70 -55 82 0 6
          -6 13 -14 16 -16 6 -44 83 -53 149 -3 25 -10 45 -14 45 -5 0 -9 20 -9 45 0 25
          5 45 10 45 6 0 10 12 10 28 0 15 7 51 14 80 l14 52 -68 0 -69 0 -10 -37z"/>
          <path id="27" d="M7816 4055 c-8 -8 -19 -15 -24 -15 -15 0 -40 -33 -47 -62 -4 -16 -11
          -25 -16 -22 -5 3 -9 -261 -9 -633 l0 -638 152 2 153 3 3 607 c3 663 1 700 -46
          731 -15 9 -33 22 -40 29 -19 18 -107 17 -126 -2z"/>
          <path fill="black"  d="M8460 3990 c0 -7 -26 -38 -59 -69 -44 -44 -60 -68 -65 -97 -9 -51
          -37 -124 -47 -124 -4 0 -11 -10 -15 -22 -8 -25 -107 -128 -124 -128 -6 0 -9
          -4 -6 -9 3 -4 -12 -15 -34 -24 l-40 -15 0 -71 c0 -39 2 -71 5 -71 14 0 115 43
          115 49 0 4 12 14 28 21 34 17 172 158 172 176 0 8 9 23 19 33 23 23 61 143 61
          190 0 17 5 31 10 31 16 0 12 109 -5 126 -9 10 -15 11 -15 4z"/>
          <path fill="black"  d="M13995 3933 c-58 -4 -101 -18 -137 -46 -20 -16 -32 -66 -47 -187 -6
          -47 -15 -86 -21 -88 -5 -2 -10 -10 -10 -17 0 -24 -40 -144 -53 -157 -6 -7 -23
          -35 -36 -62 -13 -27 -31 -57 -40 -67 -71 -81 -111 -140 -111 -161 0 -10 -4
          -18 -10 -18 -5 0 -10 -9 -10 -20 0 -11 4 -20 8 -20 4 0 13 -15 20 -32 6 -18
          38 -61 71 -95 33 -35 57 -63 53 -64 -4 0 2 -4 13 -9 32 -14 72 -11 89 5 8 8
          19 15 24 15 5 0 17 9 27 20 10 11 21 17 26 15 4 -3 10 3 13 12 4 10 22 36 42
          58 19 22 42 53 50 68 8 15 24 37 35 48 10 10 19 25 19 32 0 7 8 22 18 33 19
          22 47 77 57 112 3 12 10 22 15 22 4 0 10 10 13 23 3 12 16 59 30 105 13 45 22
          82 18 82 -3 0 -1 4 6 8 17 11 43 237 34 290 -11 58 -60 103 -110 100 -20 -1
          -63 -3 -96 -5z"/>
          <path  d="M13480 3818 c0 -21 -4 -38 -10 -38 -5 0 -10 -10 -10 -23 0 -30 -39
          -170 -49 -177 -5 -3 -19 -29 -33 -57 -14 -29 -36 -62 -50 -73 -49 -38 -44 -51
          70 -165 118 -118 103 -115 167 -31 17 22 40 51 53 64 12 14 22 31 22 38 0 7 6
          17 14 21 7 4 24 33 37 63 13 30 26 57 30 60 12 8 49 155 56 221 3 32 10 59 14
          59 5 0 9 16 9 35 l0 34 -160 3 -160 3 0 -37z"/>
          <path  d="M14240 3768 c0 -52 -4 -88 -10 -88 -5 0 -10 -11 -10 -24 0 -35 -39
          -219 -48 -226 -4 -3 -18 -39 -32 -80 -13 -41 -29 -80 -37 -86 -7 -6 -13 -15
          -13 -19 0 -16 -46 -100 -67 -123 -13 -14 -23 -31 -23 -39 0 -7 -7 -16 -15 -19
          -8 -4 -22 -21 -31 -39 -16 -34 -27 -47 -93 -108 -22 -21 -41 -42 -41 -46 0 -5
          44 -51 97 -105 78 -77 101 -95 117 -90 12 4 23 11 25 17 2 6 20 27 40 48 20
          20 54 62 76 93 22 31 50 68 62 83 12 15 28 40 34 55 6 15 15 28 19 28 4 0 18
          27 30 59 12 33 27 62 34 64 6 3 23 39 36 81 13 42 26 76 30 76 4 0 17 44 31
          98 13 53 27 99 31 102 10 8 38 230 38 305 l0 64 -140 3 -140 3 0 -87z"/>
          <path  d="M11053 3733 c4 -64 10 -119 15 -122 5 -3 12 -31 16 -61 9 -67 46
          -223 55 -230 4 -3 16 -34 26 -69 10 -35 24 -69 29 -75 15 -16 46 -84 46 -101
          0 -8 6 -18 13 -22 7 -4 24 -32 37 -60 14 -29 28 -53 31 -53 4 0 19 -20 33 -45
          15 -25 31 -45 36 -45 6 0 10 -6 10 -12 1 -19 135 -168 151 -168 12 0 733 723
          735 737 1 7 -3 13 -9 13 -7 0 -23 17 -37 38 -14 20 -30 42 -36 47 -6 6 -19 30
          -29 55 -11 25 -23 47 -26 50 -10 7 -36 116 -44 183 l-7 57 -526 0 -525 0 6
          -117z"/>
          <path  d="M2160 3755 c0 -47 4 -85 9 -85 4 0 11 -26 13 -57 8 -86 40 -243 50
          -243 4 0 8 -6 8 -13 0 -20 50 -171 58 -177 5 -3 19 -33 31 -67 13 -35 27 -63
          31 -63 4 0 17 -22 29 -50 12 -27 25 -50 30 -50 4 0 19 -21 32 -46 13 -26 29
          -49 36 -51 7 -3 13 -11 13 -18 0 -6 9 -20 20 -30 11 -10 20 -22 20 -28 0 -5
          11 -18 25 -29 14 -11 25 -24 25 -29 0 -6 15 -25 34 -42 l33 -32 371 370 371
          371 -34 39 c-19 22 -35 45 -35 52 0 7 -6 16 -14 20 -15 9 -51 86 -61 132 -4
          17 -10 31 -15 31 -5 0 -14 41 -21 90 l-13 90 -246 0 c-183 0 -249 -3 -259 -12
          -15 -16 -271 -14 -300 2 -11 5 -69 10 -130 10 l-111 0 0 -85z"/>
          <path  d="M4485 3504 c-11 -25 -28 -52 -38 -59 -11 -7 -25 -25 -32 -38 -13 -25
          -10 -29 62 -101 l76 -76 56 0 c64 0 92 19 126 86 11 21 25 44 31 49 7 6 20 28
          30 50 10 22 21 42 24 45 7 5 30 68 30 81 0 5 -78 9 -172 9 l-173 0 -20 -46z"/>
          <path fill="black"  d="M4860 3479 c-13 -39 -29 -77 -35 -83 -6 -6 -21 -32 -34 -59 -13 -26
          -36 -61 -52 -76 -16 -16 -29 -34 -29 -39 0 -5 64 -12 143 -16 78 -3 176 -9
          218 -12 l76 -6 21 41 c12 23 22 48 22 56 0 8 4 15 10 15 5 0 19 33 31 73 12
          39 25 74 28 77 9 8 29 89 23 95 -3 3 -94 5 -201 5 l-196 0 -25 -71z"/>
          <path  d="M5310 3532 c0 -28 -40 -176 -49 -182 -5 -3 -19 -34 -32 -70 -12 -36
          -28 -68 -33 -72 -6 -4 -7 -10 -3 -14 8 -8 285 -26 297 -19 4 3 11 17 15 32 3
          15 11 30 16 34 11 6 49 139 49 169 0 11 4 20 9 20 5 0 12 14 15 30 3 17 9 44
          12 60 l6 30 -151 0 c-139 0 -151 -1 -151 -18z"/>
          <path  fill="black"  d="M5643 3535 c-7 -8 -13 -21 -13 -29 0 -26 -40 -190 -48 -196 -4 -3
          -17 -35 -29 -72 l-21 -68 43 0 c24 0 47 -4 50 -10 3 -5 28 -10 54 -10 l49 0
          17 65 c10 36 21 65 25 65 4 0 10 15 13 33 4 17 15 78 25 135 l19 102 -86 0
          c-61 -1 -90 -5 -98 -15z"/>
          <path id="28"  d="M5866 3523 c-3 -16 -10 -57 -17 -93 -6 -36 -14 -67 -18 -70 -4 -3
          -17 -45 -30 -95 -12 -49 -27 -93 -34 -97 -21 -13 2 -21 70 -24 38 -2 148 -9
          246 -14 98 -6 199 -10 225 -10 l48 0 19 93 c10 50 22 94 25 97 6 4 40 206 40
          234 0 3 -128 6 -284 6 l-284 0 -6 -27z"/>
          <path   d="M6476 3528 c-3 -13 -6 -38 -6 -55 0 -18 -4 -33 -10 -33 -5 0 -10 -11
          -10 -24 0 -38 -41 -240 -49 -246 -4 -3 -11 -17 -14 -31 l-7 -27 180 -7 c99 -4
          180 -11 180 -14 0 -4 114 -13 253 -20 138 -6 284 -14 322 -17 68 -5 69 -5 42
          11 -16 9 -37 25 -48 36 -10 10 -23 19 -27 19 -5 0 -38 27 -73 61 -35 33 -75
          70 -89 83 -27 24 -40 43 -66 94 -9 17 -19 32 -23 32 -4 0 -18 24 -32 53 -13
          28 -30 58 -36 65 -7 7 -13 20 -13 28 0 12 -38 14 -234 14 l-234 0 -6 -22z"/>
          <path fill="black" d="M7420 3542 c0 -13 106 -109 129 -117 11 -3 21 -10 21 -16 0 -9 47
          -31 93 -43 l27 -6 0 70 0 70 -40 16 c-22 9 -37 20 -34 25 3 5 -39 9 -95 9 -56
          0 -101 -4 -101 -8z"/>
          <path d="M11938 3001 c-323 -323 -396 -399 -359 -376 5 3 18 -6 30 -20 11 -14
          48 -48 83 -76 35 -27 71 -57 81 -65 11 -9 40 -27 65 -41 26 -14 49 -29 52 -33
          3 -4 25 -18 50 -30 25 -12 47 -27 50 -31 4 -5 33 -18 65 -29 32 -11 61 -24 65
          -28 6 -10 136 -51 185 -59 19 -3 35 -9 35 -13 0 -13 168 -40 287 -47 62 -3
          110 -10 107 -15 -3 -4 1 -8 9 -8 8 0 18 7 21 16 3 9 6 246 6 528 l0 512 -92
          12 c-50 6 -94 15 -97 21 -4 5 -20 13 -36 17 -45 11 -115 44 -115 53 0 5 -13
          14 -29 21 -16 6 -38 22 -50 36 -11 13 -27 24 -33 24 -7 -1 -178 -166 -380
          -369z"/>
          <path d="M13233 3357 c-15 -26 -117 -90 -172 -109 -31 -11 -58 -23 -61 -28 -6
          -9 -123 -30 -168 -30 l-32 0 0 -517 c0 -285 4 -524 9 -532 6 -10 13 -11 29 -2
          11 6 45 11 74 11 68 0 320 37 327 48 3 5 48 19 100 33 52 13 99 29 105 35 6 6
          35 19 63 28 28 9 61 26 73 36 12 11 42 27 66 36 24 9 44 20 44 25 0 4 12 14
          28 21 44 23 73 42 97 63 13 11 38 31 56 45 19 14 47 39 64 56 16 16 38 33 48
          37 9 3 17 11 17 16 0 5 -50 60 -111 121 -103 103 -114 111 -141 105 -55 -13
          -109 23 -184 122 -16 21 -36 47 -46 57 -11 12 -18 35 -18 61 0 23 -3 45 -7 49
          -5 4 -8 13 -8 19 0 7 -5 12 -12 12 -7 0 -55 44 -108 98 -95 96 -116 110 -132
          84z"/>
          <path d="M3056 2996 c-198 -198 -361 -366 -363 -372 -3 -10 1 -15 72 -78 57
          -50 157 -126 167 -126 4 0 16 -8 26 -17 10 -10 41 -29 68 -43 27 -14 51 -28
          54 -31 8 -9 96 -49 108 -49 7 0 12 -4 12 -9 0 -5 39 -21 88 -36 48 -14 95 -30
          104 -35 21 -11 207 -50 241 -50 14 0 27 -4 29 -9 2 -5 54 -12 116 -16 l112 -7
          0 525 c0 582 6 537 -66 537 -48 0 -174 30 -174 41 0 5 -5 9 -11 9 -34 0 -199
          100 -199 121 0 5 -6 9 -12 8 -7 -1 -175 -164 -372 -363z"/>
          <path  d="M4350 3340 c-8 -11 -20 -20 -27 -20 -6 0 -18 -9 -26 -20 -8 -11 -28
          -25 -46 -31 -49 -17 -24 -24 96 -27 59 -2 116 -5 126 -8 9 -3 17 -2 17 3 0 10
          -109 123 -118 123 -4 0 -14 -9 -22 -20z"/>
          <path  d="M4090 3199 c-14 -5 -59 -13 -100 -17 l-75 -7 0 -525 0 -525 110 3
          c61 2 112 7 113 13 2 5 15 9 28 9 34 0 238 41 244 49 3 4 46 20 95 35 50 16
          92 32 95 36 3 4 30 18 60 31 30 12 57 26 60 30 3 4 27 17 54 30 27 12 60 33
          74 46 14 13 31 23 38 23 7 0 19 8 26 18 7 9 24 25 38 33 14 9 43 33 65 52 22
          20 43 37 47 37 9 0 48 41 48 50 0 4 -49 57 -110 117 -60 61 -110 107 -110 104
          0 -4 -20 -8 -45 -10 -26 -1 -45 2 -45 8 0 6 -11 13 -25 17 -13 3 -33 21 -44
          40 -11 19 -24 34 -29 34 -5 0 -22 20 -38 45 -15 25 -31 45 -36 45 -10 0 -29
          75 -23 91 5 16 -20 49 -55 72 -20 13 -65 17 -230 21 -137 4 -213 3 -230 -5z"/>
          <path d="M4605 3180 c3 -5 12 -7 20 -3 21 7 19 13 -6 13 -11 0 -18 -4 -14 -10z"/>
          <path fill="black" d="M4670 3180 c-4 -3 -13 -22 -20 -44 -17 -51 0 -108 40 -138 17 -12 30
          -27 30 -33 0 -6 18 -30 39 -53 42 -45 79 -54 136 -32 32 12 101 85 153 160 14
          19 27 37 31 40 9 7 41 58 41 65 0 3 -6 5 -14 5 -8 0 -17 6 -19 13 -4 9 -8 8
          -16 -3 -6 -8 -11 -10 -11 -4 0 11 -374 34 -390 24z"/>
          <path d="M5145 3123 c-11 -15 -27 -42 -36 -58 -18 -34 -46 -69 -96 -122 -18
          -18 -33 -38 -33 -44 0 -5 -11 -14 -25 -19 -14 -5 -25 -13 -25 -18 0 -5 48 -57
          107 -116 77 -77 109 -103 115 -94 5 7 13 21 19 31 5 9 16 17 24 17 8 0 15 5
          15 11 0 14 46 70 52 64 3 -3 15 15 27 40 12 25 28 48 35 52 8 5 23 27 35 51
          12 23 25 42 29 42 4 0 18 25 31 55 13 30 27 55 31 55 4 0 11 16 17 35 l11 35
          -61 0 c-34 0 -105 3 -157 6 l-95 5 -20 -28z"/>
          <path  id="29"  d="M7364 2656 c-3 -7 -3 -79 -2 -161 l3 -150 515 0 515 0 0 160 0 160
          -513 1 c-384 1 -515 -1 -518 -10z m587 -79 c10 -12 18 -28 19 -34 0 -7 5 -13
          10 -13 6 0 10 -11 10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 -8 -10 -18 0 -28 -30
          -50 -78 -58 -100 -16 -164 98 -91 163 17 16 38 31 47 35 28 12 84 -2 103 -25z"/>
          <path  id="29" fill="black" d="M7843 2560 c-44 -18 -41 -104 5 -121 55 -21 102 11 102 70 0 43 -59
          71 -107 51z"/>
          </g>
          `,
        },
      ],
    ],
    listColorQuestion: [
      {
        color: "red",
      },
      {
        color: "Pink",
      },
      {
        color: "yellow",
      },
      {
        color: "blue",
      },
      {
        color: "green",
      },
    ],
  },
};

export default json;
