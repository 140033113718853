import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P17-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/4.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17heart.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/6.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17heart.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/8.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17heart.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E1/9.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P17-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/4.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17star.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/6.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17star.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/8.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17star.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E2/9.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P17-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17triangle.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17triangle.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17triangle.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E3/9.jpg",
        },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P17-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17square.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17square.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17square.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page17/E4/9.jpg",
        },
      ],
    ],
  },
};

export default json;
