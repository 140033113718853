import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN3-U6-P56-E1",
    audio: "https://cdn.sachso.edu.vn/mn3/Audios/Track 14.mp3",
    video: "",
    component: UI,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/E1/1.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 14.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/E1/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 14_football.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN3-U6-P56-E2",
    audio: "https://cdn.sachso.edu.vn/mn3/Audios/Track 14.mp3",
    video: "",
    component: UI,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/E2/1.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 14.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page56/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 14_basketball.mp3",
        },
      ],
    ],
  },
};

export default json;
